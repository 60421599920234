import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';

import GenericErrorPage from './GenericErrorPage';

describe('GenericErrorPage', () => {
  const env = global.process.env;
  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
  });

  afterAll(() => cleanup());

  describe('on render', () => {
    let title: HTMLElement, content: HTMLElement, button: HTMLElement;

    renderComponent(GenericErrorPage);

    beforeEach(() => {
      title = screen.getByTestId('generic-error-notice-title');
      content = screen.getByTestId('generic-error-notice-content');
      button = screen.getByTestId('button-action-secondary');
    });

    it('should have displayed generic text', () => {
      expect(title).toBeTruthy();
      expect(content).toBeTruthy();
      expect(button).toBeTruthy();

      expect(screen.getByText('An error has occured')).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
      expect(screen.getByText('Please try again later or contact your helpdesk.')).toBeTruthy();
    });
  });

  describe('on redirect with custom parameter', () => {
    let title: HTMLElement, content: HTMLElement, button: HTMLElement;

    const history = createMemoryHistory();
    const state = { errorBody: 'Custom error message' };
    history.push('/', state);

    renderComponent(GenericErrorPage, undefined, state, history);

    beforeEach(() => {
      title = screen.getByTestId('generic-error-notice-title');
      content = screen.getByTestId('generic-error-notice-content');
      button = screen.getByTestId('button-action-secondary');
    });

    it('should have displayed custom text', () => {
      expect(title).toBeTruthy();
      expect(content).toBeTruthy();
      expect(button).toBeTruthy();

      expect(screen.getByText('An error has occured')).toBeTruthy();
      expect(screen.getByText('Custom error message')).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
    });
  });

  describe('on button click', () => {
    let button: HTMLElement;
    renderComponent(GenericErrorPage);

    beforeEach(() => {
      button = screen.getByTestId('button-action-secondary');
    });

    it("should have redirected to 'Home'", () => {
      const history = createMemoryHistory();
      expect(button).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
      fireEvent.click(button);

      expect(history.location.pathname).toBe('/');
    });
  });

  describe('on display a custom title and custom actions', () => {
    const props = {
      title: 'Custom page title',
      actions: [
        {
          label: 'Some button label',
          action: () => undefined,
          look: 'secondary',
        },
      ],
    };

    renderComponent(GenericErrorPage, props);

    let title: HTMLElement, button: HTMLElement;

    beforeEach(() => {
      title = screen.getByTestId('generic-error-notice-title');
      button = screen.getByTestId('button-action-secondary-children');
    });

    it('should have displayed custom title via props', () => {
      expect(title).toBeTruthy();
      expect(screen.getByText(props.title)).toBeTruthy();
    });

    it('should have displayed custom page actions', () => {
      expect(button).toBeTruthy();
      expect(screen.getByText(props.actions[0].label)).toBeTruthy();
    });
  });
});
