import React, { FC, Suspense } from 'react';
import { useParams, Redirect, withRouter } from 'react-router-dom';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { serviceTypes, modulesConfig, coreServices, coreRoutes, SERVICE } from '../../config';
import withLang from '../context/withLang';
import { ChildProps } from '../types/Child.types';

import Account from './Account';
import HomePage from './HomePage';
import Legal from './Legal';

const coreModules = {
  Account: Account, //in the core
  Legal: Legal, //in the core
};

let modules = coreModules;

const applyModuleReplacements = (moduleName: string) => {
  return moduleName === 'Menu' ? 'Order' : moduleName;
};

for (const moduleName of Object.keys(modulesConfig)) {
  // modules[moduleName] = React.lazy( () => import('../modules/' + moduleName + '/containers/' + moduleName) ) ;
  //tmp immediate loading for debugging purpose:
  const moduleDestination = applyModuleReplacements(moduleName);

  try {
    modules[moduleName as keyof typeof modules] = require('../../' +
      moduleDestination +
      '/containers/' +
      moduleDestination).default;
  } catch (err) {
    /**
     * This will be the new way to require modules when they are all
     * converted to the new file structure and we are no longer using
     * the containers folder
     */
    modules[moduleName as keyof typeof modules] = require(`../../${moduleDestination}`).default;
  }
}

const Child: FC<ChildProps> = ({ location, services, label }) => {
  //convert path to actual page tag name, i.e. from snake case to Pascal case
  const { serviceRoot } = useParams<{ serviceRoot: string }>();

  if (!coreRoutes.includes(serviceRoot) && services.length === 0) return <LoadingPage />;

  //special case of home page
  if (serviceRoot === 'home') return <HomePage />;

  const serviceTypeInfo = Object.entries(serviceTypes).find(
    ([name, info]) => info.path === '/' + serviceRoot
  );

  if (!serviceTypeInfo)
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { from: location },
        }}
      />
    );

  const serviceTypeName = serviceTypeInfo[0];
  //pre-set the active module to the default module
  let activeModuleName = serviceTypes[serviceTypeName].defaultModule;
  //get the service, and if not present then redirect to home
  let service = services.find((el) => el.name === serviceTypeName);
  // User is redirected to the Error page if one of the following conditions are met:
  // 1: User has no access to the module
  // 2: Path did not match any existing module
  // Note: sites is not a service set by the backend - temporarily add as special  condition
  if (
    (!coreServices.includes(serviceTypeName as SERVICE) && !service) ||
    !coreModules[activeModuleName as keyof typeof coreModules]
  ) {
    //only accepted to not have the service active if it corresponds to a default core module
    localStorage.removeItem(LOCAL_STORAGE_KEYS.RETURN_URL);
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: { from: location, errorBody: label('Ref: Route Not Found') },
        }}
      />
    );
  }
  //check if another, non default module should apply
  const moduleSetupOption =
    !service || !service.setupOptions
      ? false
      : service.setupOptions.find((el) => el.code === 'module');
  if (moduleSetupOption) {
    const candidateModuleName = moduleSetupOption['value'];
    const candidateModuleConfig = modulesConfig[candidateModuleName as keyof typeof modulesConfig];

    //only keep the module if it is of the right service type
    if (candidateModuleConfig && candidateModuleConfig.serviceType === serviceTypeName)
      activeModuleName = candidateModuleName;
  }

  return (
    <div>
      <Suspense fallback={<LoadingPage />}>
        {React.createElement(modules[activeModuleName as keyof typeof modules])}
      </Suspense>
    </div>
  );
};

export default withRouter(withLang([__filename])(Child));
