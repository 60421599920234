import classNames from 'classnames';
import momentjs from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { InformationIcon, Time } from '../../../../assets/icons';
import { MenuIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import { DATE_FORMAT, SIZE } from '../../../../constants';
import useToggle from '../../../../helpers/hooks/useToggle';
import { formatDate } from '../../../../helpers/misc';
import useSite from '../../../Core/hooks/useSite';
import { useGetFoodFacilitiesQuery, useGetMenusQuery } from '../../api/index';
import { useMenusTranslation } from '../../hooks/useMenusTranslation';
import FacilityDetails from '../FacilityDetails/FacilityDetails';

import {
  buildFilteringState,
  buildSections,
  cacheSelectedFilters,
  getDefaultFiltersFromCache,
  getFilters,
  mapToListModel,
} from './ProductsList.helper';
import { ProductListFiltering, ProductsListParams } from './ProductsList.types';

import OldTile from '@/components/organisms/Tile';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import { UserSteps } from '@/types/userSteps.types';

import styles from './ProductsList.module.css';

const ProductsList = () => {
  const { label } = useMenusTranslation(__filename);
  const site = useSite();
  const { currentLanguageCode } = useLanguage();
  const { facilityId } = useParams<ProductsListParams>();

  const [selectedMoment, setSelectedMoment] = useState('');
  const [selectedDate, setSelectedDate] = useState(momentjs().format(DATE_FORMAT));

  const { isFetching: isLoadingGetFoodFacilities, data: dataGetFoodFacilities } =
    useGetFoodFacilitiesQuery({ siteId: site?.id || '' });
  const { isFetching: isLoadingGetMenus, data: dataGetMenus } = useGetMenusQuery(
    { facilityId, date: selectedDate },
    { skip: !facilityId }
  );

  const { facilities } = dataGetFoodFacilities || { facilities: [] };
  const { menus } = dataGetMenus || { menus: [] };

  const facility = facilities?.find(({ id }) => id === facilityId);

  const {
    state: isFacilityModalOpen,
    toggleOn: openFacilityModal,
    toggleOff: closeFacilityModal,
  } = useToggle(false);

  const { logUserSteps } = useUserStepsInsightsLogging();

  const openFacilityModalAndLog = () => {
    openFacilityModal();
    logUserSteps({ event: UserSteps.OpenedFacilityModal });
  };

  // Selecting the first menu of the retrieved menus, since it's an array
  // TODO: what to do if there are multiple menus? Is that a possibility?
  const [menu] = menus;
  const moments = useMemo(
    () => [...new Set(menu?.dayparts?.map((item) => item.name?.toLowerCase()))],
    [menu]
  );

  const defaultFilters = getDefaultFiltersFromCache(site?.id!, menu?.name, selectedDate);

  const getFilteringValue = useCallback(() => {
    return buildFilteringState({ moments, defaultFilters });
  }, [defaultFilters, moments]);

  const [filtering, setFiltering] = useState<ProductListFiltering>();

  useEffect(() => {
    if (!filtering) setFiltering(getFilteringValue());
  }, [filtering, getFilteringValue]);

  useEffect(() => {
    setSelectedMoment(moments[0]);
  }, [moments]);

  const listItems = mapToListModel({
    menu,
    languageCode: currentLanguageCode,
    isoCode: site?.currency?.isoCode || '',
    date: selectedDate,
    selectedMoment,
    facilityId,
  });

  const search = {
    searchableKeys: ['title'],
    placeholder: label('Ref: Search products list'),
    below: <>{facility?.menuNote}</>,
  };

  const sections = listItems?.length ? buildSections(listItems) : [];

  const config = {
    displayedItemsNum: 0,
    collapsibleSections: false,
    singleExpandedSection: false,
    disableInfiniteScroll: false,
  };

  if (sections.length) {
    config.collapsibleSections = true;
    config.singleExpandedSection = true;
    config.disableInfiniteScroll = true;
  }

  const filters = getFilters({
    label,
    menuItemsList: listItems,
    sections,
    moments,
    selectedDate,
    setSelectedMoment,
    setSelectedDate,
  });

  const listFilters = useMemo(
    () => ({
      filters,
      initialFiltering: filtering,
      handleFilteringChange: (newFiltering: ProductListFiltering) => {
        setFiltering(newFiltering);
        cacheSelectedFilters(newFiltering, site?.id!, menu?.name, momentjs().format(DATE_FORMAT));
      },
    }),
    [filtering, filters, menu?.name, site?.id]
  );

  const facilityTitle = (
    <div className={styles.facilityTitle}>
      <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
        {facility?.title}
      </Title>
    </div>
  );

  const facilityInfo = (
    <Button
      data-testid="product-list-facility-details"
      look={BUTTON_LOOK.TERTIARY}
      size={SIZE.SMALL}
      affix={InformationIcon}
      onClick={openFacilityModalAndLog}
      inheritStyle={'hideTextOnMobile'}
    >
      {label('Ref: Facility details')}
    </Button>
  );

  const displaySelectedDate = () => {
    if (selectedDate === momentjs().format(DATE_FORMAT)) return label('today');
    if (selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT)) return label('tomorrow');
    return formatDate(new Date(selectedDate), currentLanguageCode);
  };

  const isLoading = isLoadingGetFoodFacilities || isLoadingGetMenus;

  return (
    <ListPage
      hasBackLink
      twoTilesColumns
      data-testid="products-list-page"
      title={label('Ref: Page title')}
      sections={sections}
      config={config}
      items={listItems}
      search={search}
      filter={listFilters}
      isLoading={isLoading}
      hideFilterTitle
      itemRenderer={(item) => (
        <OldTile
          key={item.key || item.id}
          {...item}
          secondTitleLine={item.secondTitleLine ? item.secondTitleLine : undefined}
        />
      )}
      aside={
        <Column.Complementary>
          <MenuIllustration />
        </Column.Complementary>
      }
      listTopContent={
        <>
          <div className={styles.pageHeader}>
            <div className={styles.facility}>
              <div>{facilityTitle}</div>
              <div>{facilityInfo}</div>
            </div>

            <div className={styles.moment}>
              <Title tag={TITLE_TAG.SPAN} size={TITLE_SIZE.BODYMDEFAULT}>
                <span className={styles.dateIcon}>
                  <Time />
                </span>
                {displaySelectedDate()}
              </Title>
            </div>
          </div>

          {facility && (
            <FacilityDetails
              label={label}
              isFacilityModalOpen={isFacilityModalOpen}
              onFacilityModalDismiss={closeFacilityModal}
              facility={facility}
            />
          )}
        </>
      }
    >
      {isLoading && (
        <div className={classNames(styles.skeleton)}>
          {Array.apply(null, Array(10)).map((_, i) => (
            <TileSkeleton key={i} withoutActions />
          ))}
        </div>
      )}
    </ListPage>
  );
};

export default ProductsList;
