// Helper function for working with module setupOptions

// TODO transform to hook once rid of all class components
// TODO Once setupOptions is moved to react Context, remove setupOptions prop and utilise context

import { ServiceDefinitionSetupOptions } from '../modules/defaultServices';
import { setupOptionsSetsType, setupOptionReturnType } from '../types/common.types';

/**
 * @deprecated The method should not be used
 */
export const getSetupOption = (
  option: string,
  setupOptions: setupOptionsSetsType
): setupOptionReturnType => {
  if (getSetupOptionList(option, setupOptions)?.length > 1) {
    throw new Error(
      `To retrieve multiple options for "${option}" please use "getSetupOptionList function" `
    );
  }
  return getSetupOptionList(option, setupOptions)?.[0];
};

/**
 * @deprecated The method should not be used
 */
export const getSetupOptionList = (
  option: string,
  setupOptionsSets: setupOptionsSetsType
): setupOptionReturnType[] => {
  // Option key transformed into uppercase in withSetupOptions HOC
  const caseInSensitiveOption = option.toUpperCase();

  const defaultReturn = [
    {
      isActive: false,
      value: undefined,
      option: undefined,
    },
  ];

  if (caseInSensitiveOption in setupOptionsSets) {
    const optionsList = setupOptionsSets[caseInSensitiveOption];
    return optionsList.map((option) => {
      return {
        isActive: !(option.value === 'false' || option.value === null),
        value: option.value,
        option,
      };
    });
  }

  return defaultReturn;
};

/**
 * @deprecated The method should not be used
 */
export const getCustomSetupOption = (
  option: string,
  setupOptions: ServiceDefinitionSetupOptions[]
): setupOptionReturnType => {
  const caseInSensitiveOption = option.toUpperCase();

  const defaultReturn = {
    isActive: false,
    value: undefined,
    option: undefined,
  };
  const setupOption = setupOptions?.find(
    (opt) => opt.name?.toUpperCase() === caseInSensitiveOption
  );

  if (setupOption) {
    return {
      isActive: !(setupOption.value === 'false' || setupOption.value === null),
      value: setupOption.value,
      option: setupOption,
    };
  }

  return defaultReturn;
};

export default getSetupOption;
