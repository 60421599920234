import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '../../../Core/reducers/coreReducer';

import ExternalContentPage from './ExternalContentPage';

const mockPostVendorCode = jest.fn().mockResolvedValue({ data: { token: 'abc123' } });
jest.mock('@/modules/Core/api/vendor/vendorApi', () => ({
  usePostVendorCodeMutation: () => [() => mockPostVendorCode],
}));

describe('ExternalContentPage', () => {
  afterAll(() => cleanup());

  describe('render with valid url', () => {
    const history = createMemoryHistory({
      initialEntries: [`?url=${btoa('https://wikipedia.com')}`],
    });
    console.log(`${JSON.stringify(history)}`);
    renderComponent(ExternalContentPage, {}, {}, history);

    it('Should load iframe content', () => {
      const iframe = screen.getByTitle('iframe');
      expect(iframe).toBeTruthy();
    });
  });

  describe('render vendor with url and vendor code', () => {
    const history = createMemoryHistory({
      initialEntries: [`?url=${btoa('https://wikipedia.com')}&code=wibo`],
    });
    renderComponent(
      ExternalContentPage,
      { label: (text: string) => text },
      {
        Core: {
          ...coreDefaultState,
          user: {
            firstName: 'John',
            lastName: 'Doe',
            email: 'user@mail.com',
            contactId: '2f9a9dc5-cfe7-ec11-bb3c-000d3a23ba71',
          },
        },
      },
      history
    );

    it('Should load iframe content', () => {
      const iframe = screen.getByTitle('iframe');
      expect(iframe).toBeTruthy();
    });
  });

  describe('on invalid url display error', () => {
    let button: HTMLElement;
    renderComponent(ExternalContentPage);

    beforeEach(() => {
      button = screen.getByTestId('button-action-go-home');
    });

    it('should have redirected to Homepage', () => {
      const history = createMemoryHistory();
      expect(button).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
      fireEvent.click(button);

      expect(history.location.pathname).toBe('/');
    });
  });
});
