import * as H from 'history';
import momentjs from 'moment';
import moment from 'moment';

import {
  Filter,
  FilterPosition,
  FilterType,
} from '../../../components/atoms/Filters/Filters.types';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import { DATE_FORMAT } from '../../../constants';
import { LabelFunc } from '../../../context/withLang';
import { toLocalDate } from '../../../helpers/misc';
import { pagePaths, selectedMenuFiltersCacheKey } from '../config';
import {
  FacilityMenuItem,
  IDateFilter,
  IMenuSelectorState,
  MenuListFiltering,
  MenuSelectorAction,
  MenuSelectorActionType,
  MenuSelectorListItem,
  MenuType,
} from '../types/menuSelector.types';

export const getDateFilter = <T extends IDateFilter>({
  label,
  listElements,
  selectedDate,
  updateDate,
}: {
  label: LabelFunc;
  listElements: T[];
  selectedDate: string;
  updateDate: Function;
}): Filter[] => {
  const isDateToday = selectedDate === momentjs().format(DATE_FORMAT);
  const isDateTomorrow = selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT);

  return [
    {
      id: 'filter_date',
      position: FilterPosition.NOT_IN_MODAL,
      name: label('Ref: filter label: date', { textTransform: 'capitalize' }),
      options: [
        {
          value: momentjs().format(DATE_FORMAT),
          label: label('today', { textTransform: 'capitalize' }),
          default: isDateToday,
        },
        {
          value: momentjs().add(1, 'days').format(DATE_FORMAT),
          label: label('tomorrow', { textTransform: 'capitalize' }),
          default: isDateTomorrow,
        },
      ],
      displayType: FilterType.CALENDAR,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const selectedValue = selectedValues[0] ?? selectedDate;
        updateDate(selectedValue);
        return listElements
          .filter(
            (item) =>
              item.menu.date && momentjs(item.menu.date).format(DATE_FORMAT) === selectedValue
          )
          .map((item) => item.id);
      },
      minDate: momentjs().startOf('day').toDate(),
      maxDate: momentjs().add(10, 'days').startOf('day').toDate(),
    },
  ];
};

export const convertToMenuItems = ({
  facilities = [],
  menuType,
  getMenuImage,
}: {
  facilities: FacilityMenuItem[];
  menuType: MenuType;
  getMenuImage: (facilityId: string, menuLogoUrl?: string) => string | undefined;
}): MenuSelectorListItem[] => {
  let filteredFacilities = facilities.filter(
    (x) => !(x.menu.isScanAndGo && !moment().isSame(x.menu.date, 'day'))
  );

  if (menuType === MenuType.Orderable) {
    filteredFacilities = facilities.filter((x) => x.menu.isOrderable);
  }

  if (menuType === MenuType.NonOrderable) {
    filteredFacilities = facilities.filter((x) => !x.menu.isOrderable);
  }

  return filteredFacilities.map<MenuSelectorListItem>((facility: FacilityMenuItem) => {
    return {
      id: `${facility.id}_${facility.menu.id}`,
      facilityTitle: facility.title,
      facilityId: facility.id,
      menu: facility.menu,
      menuImageSource: getMenuImage(facility.id, facility.menu.logoUrl),
      'data-testid': `menu-selector-list-item-${facility.id}`,
    };
  });
};

export const cacheSelectedFilters = (filters: MenuListFiltering) =>
  localStorage.setItem(selectedMenuFiltersCacheKey, JSON.stringify(filters));

export const getDefaultFiltersFromCache = () => {
  const defaultFiltersCache = localStorage.getItem(selectedMenuFiltersCacheKey);
  const defaultFilters = defaultFiltersCache
    ? (JSON.parse(defaultFiltersCache) as MenuListFiltering)
    : null;

  if (defaultFilters?.filter_date) {
    const date = momentjs(new Date(toLocalDate(Object.keys(defaultFilters.filter_date)[0])))
      .startOf('day')
      .toDate();
    var today = momentjs(new Date()).startOf('day').toDate();

    if (date < today) defaultFilters.filter_date = { [momentjs(today).format('YYYY-MM-DD')]: true };
  }

  return defaultFilters;
};

export const redirectIfOnlyOneMenuAvailable = ({
  listElements,
  selectedDate,
  facilityId,
  history,
  isMenuModule,
}: {
  listElements: MenuSelectorListItem[];
  selectedDate: string;
  facilityId?: string;
  history: H.History<unknown>;
  isMenuModule: boolean;
}) => {
  if (listElements.length === 1 && facilityId) {
    if (isMenuModule) {
      history.push(
        pagePaths.MenuProductsList.replace(':facilityId', facilityId)
          .replace(':date', selectedDate)
          .replace(':menuId', listElements[0].menu.id.toString())
      );
    } else {
      history.push(
        pagePaths.ProductsList.replace(':facilityId', facilityId)
          .replace(':date', selectedDate)
          .replace(':menuId', listElements[0].menu.id.toString())
      );
    }
  }
};

export const redirectForScan = ({
  menuId,
  selectedDate,
  history,
  barcode,
  listElements,
}: {
  menuId: number;
  selectedDate: string;
  history: H.History<unknown>;
  barcode: string;
  listElements: MenuSelectorListItem[];
}) => {
  const facilityId = listElements.find((x) => x.menu.id === menuId)?.menu.facilityId;
  if (facilityId) {
    history.push(
      pagePaths.ProductsListWithBarcode.replace(':facilityId', facilityId)
        .replace(':date', selectedDate)
        .replace(':menuId', menuId.toString())
        .replace(':barcode', barcode)
    );
  }
};

export const redirectToMenuListOrProductsList = ({
  menuId,
  selectedDate,
  history,
  listElements,
  menuType = MenuType.All,
}: {
  menuId: number | undefined;
  selectedDate: string;
  history: H.History<unknown>;
  listElements: MenuSelectorListItem[];
  menuType?: MenuType;
}) => {
  if (menuId) {
    const facilityId = listElements.find((x) => x.menu.id === menuId)?.menu.facilityId;
    const pagePath =
      menuType === MenuType.NonOrderable ? pagePaths.MenuProductsList : pagePaths.ProductsList;
    if (facilityId) {
      const path = pagePath
        .replace(':facilityId', facilityId)
        .replace(':date', selectedDate)
        .replace(':menuId', menuId.toString());

      history.replace(path);
      return;
    }
  }

  menuType === MenuType.NonOrderable
    ? history.replace(pagePaths.MenuModule)
    : history.replace(pagePaths.Module);
};

export const filterForPreselectedFacility = (
  listElements: MenuSelectorListItem[],
  preselectedFacilityId?: string
) => {
  return listElements?.filter(
    (x) => x.menu.facilityId === preselectedFacilityId || !preselectedFacilityId
  );
};

export const handleSiteRedirection = async ({
  locationId,
  preselectedSiteId,
  preselectedLocationId,
  getPreselectedSiteId,
  switchSite,
  site,
}: {
  locationId?: string;
  preselectedSiteId?: string;
  preselectedLocationId?: string;
  getPreselectedSiteId: (locationId: string) => Promise<void>;
  switchSite: (siteId: string) => void;
  site: { id: string };
}) => {
  const wasPreselectedSiteFetched = !preselectedSiteId || preselectedLocationId !== locationId;
  const wasSiteChangedAlready = preselectedSiteId && preselectedSiteId !== site.id;
  if (wasPreselectedSiteFetched && locationId) {
    await getPreselectedSiteId(locationId);
    return;
  }
  if (wasSiteChangedAlready) {
    await switchSite(preselectedSiteId);
    return;
  }
};

export const isSiteRedirection = ({
  locationId,
  preselectedLocationId,
  preselectedSiteId,
  site,
}: {
  locationId?: string;
  preselectedLocationId?: string;
  preselectedSiteId?: string;
  site?: { id: string };
}) => locationId && (preselectedLocationId !== locationId || preselectedSiteId !== site?.id);

export const siteChangeNotification = ({
  hasSiteChanged,
  setSiteModalClosed,
  siteName,
  labelFn,
}: {
  hasSiteChanged: boolean;
  setSiteModalClosed: Function;
  siteName?: string;
  labelFn: Function;
}) => {
  return hasSiteChanged ? (
    <Notification
      look={NOTIFICATION_LOOK.SUCCESS}
      dismissable={true}
      title={labelFn('Ref: You have changed location')}
      onDismiss={() => setSiteModalClosed()}
    >
      {labelFn('Ref: You are now at')} {siteName}
    </Notification>
  ) : null;
};

export function menuSelectorReducer(
  state: IMenuSelectorState,
  action: MenuSelectorAction
): IMenuSelectorState {
  switch (action.type) {
    case MenuSelectorActionType.UPDATE_TIER:
      return {
        ...state,
        tier: action.tier,
      };
    case MenuSelectorActionType.UPDATE_SET_DISCOUNT_TIER_STATUS:
      return {
        ...state,
        setDiscountTierStatus: action.setDiscountTierStatus,
      };

    case MenuSelectorActionType.UPDATE_HAS_MENU_FETCHED:
      return {
        ...state,
        hasMenuFetched: action.hasMenuFetched,
      };
    default:
      return state;
  }
}
