import { withRouter } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import withLang from '../../context/withLang';

import { TroubleLoggingInFormSuccessPagePros } from './TroubleLoggingIn.types';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

export default withRouter(
  withLang([__filename])(({ history, label }: TroubleLoggingInFormSuccessPagePros) => {
    return (
      <NoticePage
        withNavBar={false}
        type={NOTICEPAGE_TYPE.SUCCESS}
        title={label('Ref: Title')}
        content={label('Ref: Body')}
        hideAllWidgets
        actions={[
          {
            label: label('ok', { textTransform: 'capitalize' }),
            action: () => history.push('/'),
          },
        ]}
        tabTitle={label('Ref: Title')}
      />
    );
  })
);
