import classNames from 'classnames';

import Title, { TITLE_SIZE } from '../../../components/atoms/Title';
import { LabelFunc } from '../../../context/withLang';
import { getPrice } from '../helpers/order.helper';
import { MenuItem } from '../types/orderState.types';
import { SiteCurrency } from '../types/productList.types';

import styles from './ProductDetails.module.css';

import { DefaultComponentWithoutChildrenPropsV2 } from 'src/types';

type MenuTitleOrderableProps = {
  menuItem: MenuItem | undefined;
  languageCode: string;
  site: { id: string; currency: SiteCurrency };
  calculatedPrice: number;
  discountPrice: number | undefined;
  redeemableQuantity?: number;
  label: LabelFunc;
  isLoading: boolean;
} & DefaultComponentWithoutChildrenPropsV2;

export const MenuTitleOrderable = ({
  menuItem,
  languageCode,
  site,
  calculatedPrice,
  discountPrice,
  redeemableQuantity = 0,
  label,
  isLoading,
  'data-testid': dataTestId,
}: MenuTitleOrderableProps) => {
  const priceWithoutDiscount = getPrice(calculatedPrice, languageCode, site.currency.isoCode);
  const priceLabelToDisplay =
    redeemableQuantity > 0 ? (
      label('Ref: Free')
    ) : discountPrice ? (
      <span>
        {getPrice(discountPrice, languageCode, site.currency.isoCode)}
        <span className={styles.menuPriceWithoutDiscount}>{priceWithoutDiscount}</span>
      </span>
    ) : (
      <span>{priceWithoutDiscount}</span>
    );
  return (
    <div data-testid={dataTestId}>
      <Title size={TITLE_SIZE.HEADLINES} className={classNames(styles.menuTitle)}>
        {menuItem?.name}
      </Title>
      <Title size={TITLE_SIZE.HEADLINEXS} className={classNames(styles.menuPrice)}>
        <span>{isLoading ? <span className={styles.loading}></span> : priceLabelToDisplay}</span>
      </Title>
    </div>
  );
};
