import { connect } from 'react-redux';

import withLoginStatus from '../../../../../context/withLoginStatus';
import { State } from '../../../../../types/state.types';
import { registerGuestUser, setGeography, checkEmailExists } from '../../../actions';
import { register } from '../../../actions';
import withLang from '../../../context/withLang';
import {
  RegisterGuestRequestType,
  RegisterRequestType,
  SetGeographyRequestType,
  CheckEmailExistsRequestType,
} from '../../../types';

import AccountInformation from './AccountInformation';

const mapStateToProps = (state: State) => {
  const { register, registerGuestUser } = state.Core?.locks;
  const { list: geographies, defaultGeoCode } = state.Shared.geographies;
  const currentGeoCode = state.Core.context.currentGeoCode || defaultGeoCode;

  const currentRegionCode =
    geographies.find((geo) => geo.code === currentGeoCode)?.regionCode || '';

  return {
    geographies,
    currentGeoCode,
    currentRegionCode,
    locks: {
      register: !!register,
      registerGuestUser: !!registerGuestUser,
    },
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchRegister: (request: RegisterRequestType) => {
      return dispatch(register(request));
    },
    dispatchRegisterGuestUser: (request: RegisterGuestRequestType) =>
      dispatch(registerGuestUser(request)),
    dispatchSetGeography: (request: SetGeographyRequestType) =>
      dispatch(setGeography({ geoCode: request.geoCode })),
    dispatchCheckEmailExists: (request: CheckEmailExistsRequestType) =>
      dispatch(checkEmailExists(request)),
  };
};

export default withLoginStatus(
  withLang(['OnboardingAccountInformation'])(
    connect(mapStateToProps, mapDispatchToProps)(AccountInformation)
  )
);
