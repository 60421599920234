import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { PermissionErrors } from '../../../../types/errors.types';
import { defaultState as coreDefaultState } from '../../../Core/reducers/coreReducer';
import { GetEventResponse } from '../../api/api.types';
import { EventType } from '../../types';

import EventDetailsWrapper from './DetailsWrapper';

const mockEventDetails: EventType = {
  id: '3c849437-f62c-ed11-97b0-0003ff4c9d2a',
  name: 'Event - Check external link',
  description: 'Test if external link is displayed for events.',
  startDate: '2022-09-05T14:06:00Z',
  endDate: '2022-11-30T19:06:00Z',
  publishedDate: '2022-09-04T18:30:00Z',
  eventDates: ['2022-11-28', '2022-12-06'],
  location: 'FR',
  sites: [],
  link: {
    url: 'https://ifmportal-test.sodexonet.com/',
    text: 'Link to iFM Portal',
  },
  preferences: [
    {
      id: 'b5d3e004-1529-e911-9461-0003ff4cb3ff',
      name: 'Vegetarian',
    },
    {
      id: '908ac037-ec55-e911-b047-0003ff4cc1c5',
      name: 'Test-Diet',
    },
    {
      id: 'cef1c266-af4e-e911-867a-0003ff4cd72c',
      name: 'Theme Nights',
    },
  ],
  images: [],
  isUserInterested: true,
  reactions: {
    reactions: [],
    me: null,
    total: 0,
  },
};

const mock: { data: GetEventResponse; isLoading: boolean } = {
  data: {
    eventDetails: mockEventDetails,
    permissionDenied: '',
    notFound: false,
    onDifferentSite: false,
  },
  isLoading: false,
};
jest.mock('../../api', () => ({
  useGetEventQuery: () => {
    return mock;
  },
}));

jest.setTimeout(100000);

jest.mock('@awesome-cordova-plugins/calendar', () => ({
  createEventInteractively: jest.fn(),
}));

const site = {
  id: '3c849437-f62c-ed11-97b0-0003ff4c9d2a',
  name: 'Testing site',
};

// TODO change once core default state and types are in sync
const coreState: any = {
  ...coreDefaultState,
};
coreState.context.site = site;

describe('EventDetailsWrapper', () => {
  afterAll(() => cleanup());

  describe('Event is under different contract', () => {
    beforeEach(() => {
      mock.data.permissionDenied = PermissionErrors.CONTRACT_DIFFERENT;
    });
    renderComponent(EventDetailsWrapper, undefined, coreState);

    it('should render correct error message', () => {
      expect(
        screen.getByText("Content you are trying to access isn't authorized for your account.")
      ).toBeTruthy();
    });
  });

  describe('Event is on hidden site', () => {
    beforeEach(() => {
      mock.data.permissionDenied = PermissionErrors.SITE_HIDDEN;
    });
    renderComponent(EventDetailsWrapper, undefined, coreState);

    it('should render correct error message', () => {
      expect(
        screen.getByText('Content you are trying to access belongs to a private site.')
      ).toBeTruthy();
    });
  });

  describe('Event is not found', () => {
    beforeEach(() => {
      mock.data.permissionDenied = undefined;
      mock.data.notFound = true;
    });
    renderComponent(EventDetailsWrapper, undefined, coreState);

    it('should render correct error message', () => {
      expect(screen.getByText('Content you are trying to access does not exist.')).toBeTruthy();
    });
  });

  // TODO add test for setting the interest
});
