import * as H from 'history';
import { useEffect, useState } from 'react';

import { LabelFunc } from '../../context/withLang';
export interface ITutorialsProps {
  content: any;
  viewTutorials: Function;
  redirectTo: string;
  history: H.History<unknown>;
  label: LabelFunc;
}

export type TutorialsAction = {
  label: string;
  action: () => void;
  look?: string;
  loading?: boolean;
};

export const useTutorials = ({
  content,
  viewTutorials,
  redirectTo,
  history,
  label,
}: ITutorialsProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!content || !content[index]) history.replace(redirectTo);
  }, [index, content, history, label, redirectTo, viewTutorials]);
  const isLast = index === content.length - 1;

  const actions = [
    {
      label: label(isLast ? 'Ref: ok' : 'next'),
      action: () => {
        if (viewTutorials) viewTutorials();
        setIndex(index + 1);
      },
    },
  ];

  return { index, actions };
};
