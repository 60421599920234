import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

import { ArrowRightIcon } from '../../../../assets';
import { WomanIllustration } from '../../../../assets/illustrations';
import Column from '../../../../components/organisms/Column';
import {
  getServicesWithNav,
  getServicePath,
  getItemLabel,
  ItemIcon,
} from '../../../../components/organisms/NavBar';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import withLoginStatus from '../../../../context/withLoginStatus';
import { getImagesSrcFromIds } from '../../../../helpers/images/images.helper';
import { widgetTileAction } from '../../../../helpers/misc';
import { SERVICE, serviceTypes, servicesHiddenInNav } from '../../../config';
import withLang from '../../context/withLang';

import { ServicesMenuPageProps } from './ServicesMenuPage.types';

import styles from './ServicesMenuPage.module.css';

const ServicesMenuPage: FC<ServicesMenuPageProps> = ({ services, isLoggedIn, history, label }) => {
  const [icons, setIcons] = useState<Record<string, string>>({});

  const getServiceIcons = useCallback(async () => {
    const imageIds = services
      ?.filter((service) => !!service.navigation?.imageId)
      .map((service) => service.navigation.imageId!);

    const images = await getImagesSrcFromIds(imageIds);

    const icons: Record<string, string> = {};
    services.forEach((service) => {
      if (service?.navigation?.imageId) {
        const icon = images.find((img) => img.id === service?.navigation?.imageId);
        if (icon) icons[service.navigation.name] = icon.content;
      }
    });

    setIcons(icons);
  }, [services]);

  useEffect(() => {
    getServiceIcons();
  }, [getServiceIcons]);

  if (!services || !services.length) history.push('/');

  var parsedUrl = new URL(window.location.href);
  const start = parseInt(parsedUrl.searchParams.get('start') || '') || 0;
  const servicesWithNav = getServicesWithNav(services, isLoggedIn)
    .slice(start)
    .filter((el) => serviceTypes[el.name] && !servicesHiddenInNav.includes(el.name as SERVICE));

  const items = useMemo(() => {
    return servicesWithNav.map((service) => {
      const isContentPage = [SERVICE.CONTENT_PAGE, SERVICE.EXTERNAL_WEBSITE].includes(
        service.name as SERVICE
      );
      const path = getServicePath(service);

      return {
        id: service.name + (isContentPage ? '/' + service.navigation.name : ''),
        imgElement: (
          <ItemIcon
            service={service}
            customIcons={icons}
            withoutWrapper
            className={styles.iconWrapper}
          />
        ),
        title: getItemLabel(service, label),
        highlightedActions: [
          {
            element: <ArrowRightIcon title={label('navigate')} />,
            // TODO: to be refactored since same action with tileAction
            action: (e: React.MouseEvent<HTMLInputElement>) => widgetTileAction(e, path, history),
          },
        ],
        tileAction: (e: React.MouseEvent<HTMLInputElement>) => widgetTileAction(e, path, history),
        'data-testid': `services-menu-page-list-item-${service.name}`,
      };
    });
  }, [history, icons, label, servicesWithNav]);

  return (
    <ListPage
      title={label('Ref: Page title')}
      hasBackLink={false}
      items={items}
      data-testid="services-menu-page-list"
      aside={
        <Column.Complementary>
          <WomanIllustration />
        </Column.Complementary>
      }
    />
  );
};

export default withLoginStatus(withRouter(withLang([__filename])(ServicesMenuPage)));
