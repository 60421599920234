import classNames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { FacilitySearchResults } from '../../components/FacilitySearchResult';
import { MenuSearchResults } from '../../components/MenuSearchResults';
import { ProductSearchResults } from '../../components/ProductSearchResults';
import { prepareInitialSearchLimitObject } from '../../helpers/globalSearchWidget.helper';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import SearchBar from '@/components/atoms/SearchBar/SearchBar';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import { TileSkeleton } from '@/components/molecules/Tile';
import ExpandableModal from '@/components/organisms/ExpandableModal/ExpandableModal';
import { WithLangProps } from '@/context/withLang';
import useToggle from '@/helpers/hooks/useToggle';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import { Service } from '@/modules/Core/types/State.types';
import { FacilityDataItem } from '@/modules/Facilities/types/types';
import { useGlobalSearchResults } from '@/modules/GlobalSearch/hooks/useGlobalSearchResults/useGlobalSearchResults';
import {
  ProductSearchResult,
  SearchResultType,
} from '@/modules/GlobalSearch/hooks/useGlobalSearchResults/useGlobalSearchResults.types';
import { FacilityMenu } from '@/modules/Order/types/orderState.types';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

import styles from './GlobalSearchWidget.module.css';

const GlobalSearchWidget = ({ label }: WithLangProps) => {
  const { logUserSteps } = useUserStepsInsightsLogging();

  const initialResultLimitAndIncrement = 6;
  const today = moment(new Date()).format('YYYY-MM-DD');

  const availableServices = useSelector((state: State) => {
    return state.Core.services.list;
  });

  const siteId = useSelector((state: State) => state.Core.context.site?.id || '');
  const [resultLimit, setResultLimit] = useState(() =>
    prepareInitialSearchLimitObject(initialResultLimitAndIncrement)
  );

  const [selectedResultType, setSelectedResultType] = useState<SearchResultType | undefined>();

  const currentResultTypeLimit =
    selectedResultType !== undefined ? resultLimit[selectedResultType] : 0;

  const incrementLimit = () => {
    if (selectedResultType === undefined) return;

    setResultLimit((current) => {
      const currentValue = current[selectedResultType];
      return {
        ...current,
        [selectedResultType]: currentValue + initialResultLimitAndIncrement,
      };
    });
  };

  const search = useMemo(
    () => ({
      searchableKeys: ['title'],
      placeholder: label('Ref: Search'),
    }),
    [label]
  );

  const { handleSearchTermChange, searchResults, clearSearchResults, isLoading, searchTerm } =
    useGlobalSearchResults();

  useMemo(() => {
    searchResults && setSelectedResultType(searchResults[0]?.type);
  }, [searchResults]);

  const {
    state: isGlobalSearchModalOpen,
    toggleOn: openGlobalSearchModal,
    toggleOff: hideGlobalSearchModal,
  } = useToggle(false);

  const onModalClose: () => void = () => {
    clearSearchResults();
    hideGlobalSearchModal();

    setResultLimit(() => prepareInitialSearchLimitObject(initialResultLimitAndIncrement));
  };

  const handleOnClick = () => {
    logUserSteps({ event: UserSteps.OpenedGlobalSearchModal });
    openGlobalSearchModal();
  };

  const generatePlaceholder = (services: Service[]): string => {
    const hasFoodMenuOrOrder = services.some((x) =>
      [SERVICE.FOOD_MENU.toString(), SERVICE.FOOD_ORDER.toString()].includes(x.name)
    );
    const hasFacility = services.some((x) => x.name === SERVICE.FACILITY);

    if (hasFacility) {
      if (hasFoodMenuOrOrder) {
        return label('Ref: Search a menu, product or facility');
      } else {
        return label('Ref: Search a facility');
      }
    } else if (hasFoodMenuOrOrder) {
      return label('Ref: Search a menu or product');
    }

    return label('Search');
  };

  const handleSearchTermChangeAndClearResultLimits = (searchTerm: string) => {
    handleSearchTermChange(searchTerm);

    setResultLimit(() => prepareInitialSearchLimitObject(initialResultLimitAndIncrement));
  };

  const searchHeader = search ? (
    <SearchBar
      {...search}
      handleChange={handleSearchTermChangeAndClearResultLimits}
      data-testid="modal-global-search-bar"
      withBackground={false}
      debounce={1000}
      focusOnRender
      placeholder={generatePlaceholder(availableServices)}
    />
  ) : null;

  const isLoadingData = isLoading;

  const selectedResultsByType =
    !isLoadingData &&
    searchResults &&
    searchResults.find((x) => x.type === selectedResultType)?.results;

  const itemsToDisplay =
    selectedResultsByType && selectedResultsByType.slice(0, currentResultTypeLimit);

  const handleButtonClick = (type: SearchResultType) => {
    setSelectedResultType(type);
  };

  const srOnlyTextContent = useMemo(() => {
    let message = `${label('Ref: Search results for')} ${searchTerm}, `;

    let sortedResults;

    if (searchResults && searchResults.length > 0) {
      sortedResults = [...searchResults];
      sortedResults.sort((x, y) => x.type - y.type);

      const resultMessages = sortedResults.map((searchResult) => {
        const resultTypeString = SearchResultType[searchResult.type].toLowerCase();
        const resultTypeForm =
          searchResult.results.length === 1
            ? label(`Ref: singular form of ${resultTypeString}`)
            : label(`Ref: ${SearchResultType[searchResult.type]}`);
        return `${searchResult.results.length} ${resultTypeForm}`;
      });

      message += resultMessages.join(', ');
    } else {
      message += label('Ref: No results body');
    }

    return message;
  }, [label, searchResults, searchTerm]);

  const filter = searchResults && (
    <div id="global-search-result-filter" className={classNames(styles.filterContainer)}>
      {searchResults
        .sort((x, y) => x.type - y.type)
        .map((searchResult) => {
          const resultTypeString = SearchResultType[searchResult.type].toLowerCase();
          const resultTypeForm =
            searchResult.results.length === 1 ? label(`Ref: result`) : label(`Ref: results`);
          const resultsForm = `${searchResult.results.length} ${resultTypeForm}`;
          const buttonLabel = `Ref: ${SearchResultType[searchResult.type]}`;

          return (
            <Button
              key={searchResult.type}
              look={
                selectedResultType === searchResult.type
                  ? BUTTON_LOOK.PRIMARY
                  : BUTTON_LOOK.SECONDARY_INLINE
              }
              onClick={() => handleButtonClick(searchResult.type)}
              data-testid={`button-search-result-type-${resultTypeString}`}
              className={classNames(styles.searchResultButton)}
              srOnlyText={label(`Ref: ${resultTypeString} button aria`, {
                replace: {
                  results_form: resultsForm,
                },
              })}
              type="button"
            >
              {`${label(buttonLabel)} (${searchResult.results.length})`}
            </Button>
          );
        })}
    </div>
  );

  const getFocusedSearchResultIndex = (resultLimit: number): number | undefined => {
    return resultLimit / initialResultLimitAndIncrement > 1
      ? resultLimit - initialResultLimitAndIncrement
      : undefined;
  };

  const renderedResults = useMemo(() => {
    let renderedSearchResuts;

    if (!itemsToDisplay) return null;

    const NthSearchResultToFocusOn = getFocusedSearchResultIndex(currentResultTypeLimit);

    switch (selectedResultType) {
      case SearchResultType.Products:
        const products = itemsToDisplay.map((item) => item as ProductSearchResult);
        renderedSearchResuts = (
          <ProductSearchResults
            products={products}
            menuDate={today}
            siteId={siteId}
            data-testid="product-search-results"
            focusedSearchResultIndex={NthSearchResultToFocusOn}
          />
        );
        break;
      case SearchResultType.Menus:
        const menus = itemsToDisplay.map((item) => item as FacilityMenu);
        renderedSearchResuts = (
          <MenuSearchResults
            menus={menus}
            label={label}
            menuDate={today}
            data-testid="menus-search-results"
            focusedSearchResultIndex={NthSearchResultToFocusOn}
          />
        );
        break;
      case SearchResultType.Facilities:
        const facilities = itemsToDisplay.map((item) => item as FacilityDataItem);
        renderedSearchResuts = (
          <FacilitySearchResults
            facilities={facilities}
            siteId={siteId}
            label={label}
            data-testid="facilities-search-results"
            focusedSearchResultIndex={NthSearchResultToFocusOn}
          />
        );
        break;
    }
    return renderedSearchResuts;
  }, [currentResultTypeLimit, itemsToDisplay, label, selectedResultType, siteId, today]);

  const seeMoreButton = (
    <Button
      id={'global-search-modal-see-more-button'}
      data-testid={'global-search-modal-see-more-button'}
      onClick={incrementLimit}
      look="secondary"
      className={classNames(styles.seeMoreButton)}
      type="button"
      aria-label={label('Ref: See more results')}
    >
      {label('Ref: See more')}
    </Button>
  );

  const noResults = (
    <div>
      <Title
        data-testid={'global-search-modal-no-results-title'}
        size={TITLE_SIZE.SUBTITLES}
        className={classNames('bodySBold', 'mb-S')}
      >
        {label('Ref: No results title')}
      </Title>
      <Title
        className={classNames('bodySBold', 'mb-S')}
        data-testid={'global-search-modal-no-results-body'}
        size={TITLE_SIZE.SUBTITLES}
      >
        {label('Ref: No results body')}
      </Title>
    </div>
  );

  const loadingPlaceholders = useMemo(
    () => (
      <>
        {Array.from({ length: 3 }).map((_, i) => (
          <TileSkeleton
            key={uuidv4()}
            withoutActions
            withoutChildren={false}
            className={'mb-M'}
            data-testid={`global-search-modal-skeleton-placeholder-${i}`}
          />
        ))}
      </>
    ),
    []
  );

  let modalContent = null;

  if (renderedResults) {
    modalContent = (
      <>
        {filter}
        {renderedResults}
        {selectedResultsByType &&
          selectedResultsByType.length > currentResultTypeLimit &&
          seeMoreButton}
      </>
    );
  } else if (isLoading) {
    modalContent = loadingPlaceholders;
  } else {
    modalContent = noResults;
  }

  return (
    <>
      <SearchBar
        placeholder={generatePlaceholder(availableServices)}
        handleClick={handleOnClick}
        handleKeyDown={handleOnClick}
        data-testid={'global-search'}
      />

      <ExpandableModal
        id="global-search-modal"
        header={searchHeader}
        isOpen={isGlobalSearchModalOpen}
        onDismiss={onModalClose}
        data-testid="global-search-modal"
        srOnlyTextContent={srOnlyTextContent}
      >
        {searchResults && (
          <div id="global-search-modal-container" className={classNames(styles.contentContainer)}>
            {modalContent}
          </div>
        )}
      </ExpandableModal>
    </>
  );
};

export default GlobalSearchWidget;
