const labels = {
  PrivacyPolicy: 'Política de privacidade',
  TermsOfSale: 'Condições de venda',
  TermsOfUse: 'Termos e Condições',
  home: 'Inicio',
  anytime: 'Em qualquer momento',
  anywhere: 'em qualquer lugar',
  Download: 'Download',
  scrollRightButton: 'Categorias de rolagem para a direita',
  scrollLeftButton: 'Categorias de rolagem à esquerda',
  calendar: 'Calendário',
  'See more': 'Ver mais',
  'See less': 'Ver menos',
  'from (followed by a date, or date and time)': 'a partir de',
  'to (followed by a date, or date and time)': 'para',
  'on (followed by a date)': 'Em',
  'at (followed by time)': 'no',
  'from (followed by a location)': 'a partir de',
  'to (followed by a location)': 'para',
  'on (followed by a site name)': 'Em',
  'from (followed by a person)': 'a partir de',
  previous: 'Não foi possível atualizar o local desejado.',
  next: 'Próximo',
  success: 'sucesso',
  failure: 'fracasso',
  today: 'Hoje',
  tomorrow: 'Amanhã',
  Monday: 'Segunda-feira',
  Tuesday: 'Terça-feira',
  Wednesday: 'quarta-feira',
  Thursday: 'quinta-feira',
  Friday: 'Sexta-feira',
  Saturday: 'sábado',
  Sunday: 'domingo',
  everyday: 'Todos os dias',
  'open all day': 'Aberto o dia inteiro',
  'opening hours': 'Horário de funcionamento',
  status: 'status',
  'to be confirmed': 'A confirmar',
  'only (as in filter only this)': 'só',
  edit: 'editar',
  submit: 'Enviar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  reset: 'Redefinir',
  Delete: 'Excluir',
  select: 'selecionar',
  switch: 'trocar',
  close: 'Fechar',
  clear: 'Claro',
  remove: 'remover',
  logout: 'sair',
  go: 'vai',
  'see (as in consult this page or document)': 'Vejo',
  'see all (as in see all items)': 'ver tudo',
  'see more (as in see more details for this item)': 'Ver mais',
  retry: 'tente novamente',
  'Ref: ok': 'OK',
  'yes (as in yes I accept)': 'sim',
  'no (as in no I do not accept)': 'Não',
  you: 'tu',
  'thank you': 'Obrigado',
  'sorry (standalone)': 'desculpe',
  'to home (as in navigate to the homepage)': 'para a página inicial',
  map: 'mapa',
  list: 'Lista',
  'Loading...': 'Carregando...',
  'Refreshing...': 'Atualizando...',
  'Please select': 'Por favor selecione',
  'Switch site': 'Troque de site aqui',
  'File size cannot exceed {max file size}':
    'O tamanho do arquivo não pode exceder {max file size}',
  Filters: 'filtros',
  'Add attachment': 'Juntar anexo',
  Search: 'Procurar',
  'More (as in: see more info)': 'Mais',
  'You can attach a maximum of {max files number} files.':
    'Você pode anexar no máximo {max files number} arquivos.',
  'There are no messages to display.': 'Não há mensagens a serem exibidas.',
  'Your selection did not return any result.': 'Sua seleção não retornou nenhum resultado.',
  'Ref: Popup header for missing required fields in a form':
    'Por favor, forneça informações adicionais',
  'Ref: Required field': '{ref} é um campo obrigatório.',
  email: 'E-mail',
  phone: 'Número de telefone',
  Account: 'Conta',
  Contacts: 'Contatos',
  Content: 'Contente',
  Events: 'Eventos',
  Facilities: 'Instalações',
  Shop: 'Comprar',
  Menu: 'Cardápio',
  Menus: 'Menus',
  Order: 'Pedido',
  Orders: 'Histórico de pedidos',
  Info: 'Info',
  FAQ: 'Perguntas frequentes',
  Feedback: 'Comentários',
  Review: 'Análise',
  Requests: 'Solicitações ',
  'Request Helpdesk': 'Solicitar suporte técnico',
  Location: 'Localização',
  Safety: 'Segurança',
  FreeCoffee: 'Café grátis',
  Newsfeed: 'Notícias',
  MyOrders: 'Minhas ordens',
  LoyaltyStamps: 'Selos de lealdade',
  Stamps: 'Selos',
  Stays: 'Estadias',
  Suppliers: 'Fornecedores',
  Surveys: 'Pesquisas',
  notifications: 'notificações',
  cart: 'carrinho',
  'Ref: Reset your order': 'Redefina seu pedido',
  'Ref: My language': 'Minha língua',
  Validate: 'Validar',
  'contact us': 'Contate-Nos',
  'Ref: Start typing': 'Começe a digitar',
  Occupancy: 'Ocupação',
  'see less': 'Veja menos',
  'see more': 'Ver mais',
  contacts: 'Contatos',
  Imprint: 'Imprimir',
  'Ref: camera access request title': 'Permitir acesso à câmera',
  'Ref: camera access request body':
    'Você deve ativar o uso de uma câmera neste site para usar o scanner de código QR',
  or: 'ou',
  'Ref: Input required': '{input_name} é necessário',
  'Enter a valid email address': 'Digite um endereço de e-mail válido',
  'Ref: Input error E-mail already exists':
    'Já existe uma conta com este e -mail que você inseriu. Por favor, faça o login em vez disso.',
  'Password must contain': 'A senha deve conter:',
  'Ref: Input password error strength':
    'A senha deve conter pelo menos 8 caracteres, 1 número, 1 letra e 1 caractere especial',
  Password: 'Senha',
  'New password': 'Nova Senha',
  'Confirm password': 'Confirme a Senha',
  'Ref: Password does not match': 'A senha e confirmar a senha não correspondem',
  'Ref: Old password does not match': 'Senha antiga não confere',
  'Ref: Password validation min8Characters': 'Pelo menos 8 caracteres',
  'Ref: Password validation min1Number': 'Pelo menos 1 dígito',
  'Ref: Password validation min1Letter': 'Pelo menos 1 carta',
  'Ref: Password validation min1SpecialChar': 'Pelo menos 1 personagem especial',
  Continue: 'Continuar',
  'Enter a valid phone number': 'Insira um número de telefone válido',
  'Phone is required': 'O telefone é necessário',
  'Go to home': 'Vá para casa',
  'Something went wrong': 'Algo deu errado',
  'Something went wrong, data not loaded': 'Algo deu errado, dados não carregados',
  'Change password': 'Mudar senha',
  'remove one': 'Remova um',
  'add one': 'Adicione um',
  'Confirm Filters': 'Confirme filtros',
  'Reset Filters': 'Redefinir filtros',
  '{selectedNum} active filters': '{selectedNum} filtros ativos',
  'Wrong input icon': 'Ícone de entrada errado',
  'Correct input icon': 'Ícone de entrada correto',
  'Show input text icon': 'Mostrar ícone de texto de entrada',
  'Hide input text icon': 'Ocultar o ícone de texto de entrada',
  'Required fields info': '* Os campos obrigatórios',
  'Ref: Select site on the map': 'Selecione seu site no mapa',
  'Ref: Geolocation disabled':
    'A geolocalização está desativada. Para ver sites próximos, ative -o.',
  'Ref: Reaction - LIKE': 'Gosta',
  'Ref: Reaction - LOVE': 'Amor',
  'Ref: Reaction - INTERESTING': 'Interessante',
  'Ref: Reaction - CONGRATE': 'Felicitar',
  'Ref: Share': 'Compartilhar',
  'Ref: Send by Email': 'Enviar por email',
  'Ref: Copy the link': 'Copie o link',
  'Ref: Link was copied': 'Link foi copiado',
  'Ref: unable to copy url': 'Incapaz de copiar URL',
  'Ref: External link': 'Link externo',
  'Ref: Step': 'Etapa',
  'input field': 'campo de entrada',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Aumentar o horário',
  'Decrease hours': 'Diminuir as horas',
  Hours: 'Horas',
  Minutes: 'Minutos',
  'Ref: filter label: favorites': 'Mostre apenas meus favoritos',
  'Ref: filter label: max calories': 'Max Calorias',
  'Ref: filter label: dishes': 'Pratos',
  'Ref: filter label: allergens': 'alérgenos',
  'Ref: filter label: highlight': 'Realçar ou ocultar filtros com pratos',
  'Ref: Highlight': 'Destaque',
  'Ref: Hide': 'Esconder',
  'Ref: Vegan': 'Vegan.',
  'Ref: Vegeterian': 'Vegetariano',
  'Ref: Mindful': 'Atento',
  'Ref: Highlight text':
    'Aqui você pode optar por hightlight ou esconder pratos no menu que contêm certos alérgenos',
  'Ref: Just now': 'Agora mesmo',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minutos atrás',
  'Ref: 1 hour ago': '1 hora atrás',
  'Ref: {amountHours} hours ago': '{amountHours} horas atrás',
  'Ref: Yesterday at {time}': 'Ontem às {time}',
  'Ref: {date} at {time}': '{date} às {time}',
  'Ref: filter label: date': 'Encontro',
  'Ref: expand menu': 'Expanda o menu',
  'Ref: collapse menu': 'menu de colapso',
  'Ref: My site': 'Meu site',
  'Ref: Recent': 'Recente',
  'Ref: Services': 'Serviços',
  'Ref: products in cart': 'Número de produtos no carrinho',
  'Ref: no product in cart': 'Nenhum produto no carrinho',
  'Ref: Survey': 'Enquete',
  'Access to camera was denied. Please allow access and try again':
    'O acesso à câmera foi negado. Aguarde o acesso e tente novamente',
  'Add an attachment': 'Adicione um anexo',
  'Take a picture': 'Tirar uma foto',
  'Add a file from your device': 'Adicione um arquivo do seu dispositivo',
  'Ref: Loading page content, please wait': 'Carregando o conteúdo da página, por favor aguarde',
  Star: 'Estrela',
  Stars: 'Estrelas',
  'Accommodation Request': 'Solicitação de acomodação',
  'Ref: Guest Accomodation Request': 'Solicitação de convidado',
  'Ref: Search a menu, product or facility': 'Pesquise um menu, produto ou instalação',
  ConnectionLost: 'Conexão de rede perdida',
  ConnectionRestored: 'Conexão de rede restaurada',
  'Ref: Skip to main content': 'Ir para o conteúdo principal',
  'Ref: No options': 'Sem opções',
  'Ref: Global search modal': 'Modal de pesquisa global',
  'Ref: Search a facility': 'Pesquise uma instalação',
  'Ref: Search a menu or product': 'Pesquise um menu ou produto',
  'Ref: Reaction': 'Reação',
  'Ref: times': 'vezes',
  'Ref: reaction. Change reaction': 'reação. Alterar reação',
  'Ref: No reaction. React': 'Sem reação. Reagir',
  'Ref: LIKE': 'Como',
  'Ref: LOVE': 'Amor',
  'Ref: INTERESTING': 'Interessante',
  'Ref: CONGRATE': 'Parabéns',
  'Ref: Open search': 'Abra a caixa de pesquisa',
  'Ref: Close search': 'Feche a caixa de pesquisa',
  'Ref: Total file size msg': 'O tamanho total do arquivo não pode exceder {max file size}',
  'Ref: Oops Title': 'Opa!',
  'Ref: Well Done': 'Bom trabalho {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Arrow de navegação - Continue',
  'Ref: Reload': 'recarregar',
  'Ref: All Dates': 'Todas as datas',
  'Ref: Today': 'Hoje',
  'Ref: Validate': 'Validar',
  'Ref: toast error title': 'Opa!',
  'Ref: toast error subtitle': 'Ocorreu um erro, por favor, tente novamente.',
  'Ref: Calendar': 'Calendário',
  'Ref: Selected': 'Selecionado',
  'Ref: Previous Month': 'Mês anterior',
  'Ref: Next Month': 'Próximo mês',
  'This module has been retired': 'Este módulo foi retirado',
};
export default labels;
