import moment from 'moment';
import { connect } from 'react-redux';

import { LOCAL_STORAGE_KEYS } from '../constants';
import { User } from '../types/user.types';

interface IRootState {
  [moduleId: string]: {
    user: User;
  };
}

function withLoginStatus(WrappedComponent: React.ComponentType<any>) {
  const mapStateToProps = (state: IRootState) => {
    return {
      isGuest: state.Core?.user?.isGuest,
      isLoggedIn: isLoggedIn(),
      user: state.Core?.user,
      isSSOUser: state.Core?.user?.isSSOUser,
    };
  };
  return connect(mapStateToProps)(WrappedComponent);
}

export const isLoggedIn = () => {
  let result = false;
  const accessTokenValidTill = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL);

  const validityLimit = Number(accessTokenValidTill) - 5000; //for slow networks, keep 5s of lag
  const accessTokenDate = moment(validityLimit);

  if (accessTokenDate.isValid()) {
    result = accessTokenDate.isSameOrAfter(Date.now()) || !window.navigator.onLine;
  }

  return result;
};

export default withLoginStatus;
