import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import DeletionFailure from './DeletionFailure';

describe('DeletionSuccess', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let primaryButton: HTMLElement;
    const label = (s: string) => s;

    renderComponent(DeletionFailure, { label });

    it('should display', () => {
      primaryButton = screen.getByTestId('button-action-primary');

      expect(screen.getByText('sorry (standalone)')).toBeTruthy();
      expect(screen.getByText('Ref: Body')).toBeTruthy();
      expect(primaryButton).toBeTruthy();
      expect(screen.getByText('Go to account')).toBeTruthy();
    });
  });

  describe('after clicking primary button', () => {
    let primaryButton: HTMLElement;
    const label = (s: string) => s;
    const history = createMemoryHistory();

    renderComponent(DeletionFailure, { label }, undefined, history);

    it('should redirect to account', () => {
      primaryButton = screen.getByTestId('button-action-primary');
      fireEvent.click(primaryButton);
      expect(history.location.pathname).toBe('/account');
    });
  });
});
