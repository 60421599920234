import classNames from 'classnames';
import moment from 'moment';

import { Calendar2Icon, ChevronRightIcon } from '../../../../assets/icons';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import List from '../../../../components/organisms/List/List';
import Modal from '../../../../components/organisms/Modal';
import { formatDate } from '../../../../helpers/misc';
import { useEventTranslation } from '../../hooks/useEventTranslation';

import { EventDatesModalProps } from './EventDatesModal.types';

import useLanguage from '@/modules/Core/hooks/useLanguage';

import styles from '../EventDetail/Details.module.css';

const EventDatesModal = ({
  eventDetails,
  nextDate,
  isSeeAllDatesModalOpen,
  setSelectedDate,
  closeSeeAllDatesModal,
  downloadEvent,
  allDatesOn,
}: EventDatesModalProps) => {
  const { label } = useEventTranslation('EventDetails');
  const { currentLanguageCode: languageCode } = useLanguage();
  const downloadNextDateEvent = () => {
    setSelectedDate(nextDate);
    setTimeout(() => {
      downloadEvent().catch((err) => console.error("Can't download calendar event", err));
    }, 50);
  };

  const goToAllDatesCalendarModal = () => {
    allDatesOn();
    closeSeeAllDatesModal();
  };

  // removing utc is needed because clients put their local time but CRM returns the same time as UTC without adjustment
  const removeUtcFromDateString = (date: string) => {
    const utcIndependentTime = date.replace('Z', '');
    return moment(utcIndependentTime).toDate();
  };

  const allDatesListItemTitle = (
    <Title size={TITLE_SIZE.BODYSDEFAULT} className={styles.allDatesListItemTitle}>
      {label('Ref: From')}
      <strong> {formatDate(removeUtcFromDateString(eventDetails.startDate), languageCode)} </strong>
      {label('Ref: to')}
      <strong> {formatDate(removeUtcFromDateString(eventDetails.endDate), languageCode)}</strong>
    </Title>
  );

  const nextDateListItemTitle = (
    <div className={styles.nextDateListItemTitleWrapper}>
      <Title size={TITLE_SIZE.BODYSDEFAULT} className={styles.nextDateListItemTitle}>
        {label('Ref: Coming date')}
        <strong>
          {' '}
          {formatDate(new Date(nextDate ?? ''), languageCode)},{' '}
          {moment.utc(eventDetails.startDate).format('LT')}
        </strong>
      </Title>
      <Title
        size={TITLE_SIZE.BODYSBOLD}
        className={classNames(styles.blockTitle, 'underlinedText')}
      >
        {label('Ref: Add to calendar')}
      </Title>
    </div>
  );

  const allDatesModalItems = [
    {
      id: 'allDatesListItem',
      label: allDatesListItemTitle,
      'data-testid': 'all-dates',
      action: goToAllDatesCalendarModal,
      icons: [
        {
          icon: Calendar2Icon,
        },
        {
          icon: ChevronRightIcon,
          endIcon: true,
        },
      ],
    },
    {
      id: 'addToCalendarListItem',
      label: nextDateListItemTitle,
      'data-testid': 'next-date',
      action: downloadNextDateEvent,
      icons: [
        {
          icon: Calendar2Icon,
        },
        {
          icon: ChevronRightIcon,
          endIcon: true,
        },
      ],
    },
  ];

  return (
    <Modal
      title={label('Ref: See all dates', { textTransform: 'capitalize' })}
      isOpen={isSeeAllDatesModalOpen}
      id="seeAllDates_modal"
      data-testid="eventDatesModal"
      onDismiss={() => closeSeeAllDatesModal()}
    >
      <List data-testid="all-dates-list" items={allDatesModalItems} />
    </Modal>
  );
};

export default EventDatesModal;
