import { RouteComponentProps, withRouter } from 'react-router';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { WithLangProps } from '../../../context/withLang';
import { isKiosk } from '../../../helpers/misc';
import KioskOrder from '../../Order/components/KioskOrder';
import { KioskOrderVariant } from '../../Order/types/KioskOrder.types';
import withLang from '../context/withLang';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

export default withRouter(
  withLang([__filename])(({ history, label }: RouteComponentProps & WithLangProps) => {
    if (isKiosk) {
      return <KioskOrder variant={KioskOrderVariant.FAIL} label={label} />;
    }
    return (
      <NoticePage
        hideAllWidgets
        type={NOTICEPAGE_TYPE.ERROR}
        title={label('Ref: Title')}
        content={label('Ref: Body')}
        actions={[
          {
            label: label('ok', { textTransform: 'capitalize' }),
            action: () => history.push('/'),
          },
        ]}
      />
    );
  })
);
