import momentjs from 'moment';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LabelFunc } from '../../context/withLang';
import { SharedState } from '../../modules/Core/types/State.types';
import { formatDate, formatTime } from '../misc';

const ONE_SEC = 1000;
const ONE_MIN = 60000;
const ONE_HOUR = 3600000;
const ONE_DAY = 86400000;

function getDiffFromNowInMs(date: Date): number {
  return Math.abs(new Date().getTime() - date.getTime());
}

function getTimeoutMs(date: Date): number {
  const diff = getDiffFromNowInMs(date);
  switch (true) {
    case diff < ONE_MIN:
      return ONE_SEC;
    case diff < ONE_HOUR:
      return ONE_MIN;
    case diff < ONE_DAY:
      return ONE_HOUR;
    default:
      return ONE_DAY;
  }
}

type Result = { text: string };

export default function useTimeAgo(date: string, label: LabelFunc): Result {
  const timeoutId = useRef<NodeJS.Timeout>();
  const [result, setResult] = useState<Result>({ text: '' });

  const languageCode = useSelector(
    (state: { Shared: SharedState }) => state.Shared.language.currentLanguageCode
  );

  const updateValue = useCallback((): void => {
    const dateMoment = momentjs(date);
    const dateObj = dateMoment.toDate();
    const diff = getDiffFromNowInMs(dateObj);
    const isYesterday = dateMoment.isSame(momentjs().subtract(1, 'days').startOf('day'), 'd');

    let value;

    if (diff <= ONE_MIN) {
      value = label('Ref: Just now');
    } else if (diff < ONE_HOUR) {
      const minutesAgo = momentjs().diff(date, 'minutes');
      value = label('Ref: {amountMinutes} minutes ago', {
        replace: { amountMinutes: String(minutesAgo) },
      });
    } else if (diff < ONE_DAY && !isYesterday) {
      const hoursAgo = momentjs().diff(date, 'hours');
      if (hoursAgo === 1) {
        value = label('Ref: 1 hour ago', { replace: { amountHours: String(1) } });
      } else {
        value = label('Ref: {amountHours} hours ago', {
          replace: { amountHours: String(hoursAgo) },
        });
      }
    } else if (diff < ONE_DAY * 2 && isYesterday) {
      const timeStr = formatTime(dateObj);
      value = label('Ref: Yesterday at {time}', { replace: { time: timeStr } });
    } else {
      const dateStr = formatDate(dateObj, languageCode);
      const timeStr = formatTime(dateObj);
      value = label('Ref: {date} at {time}', { replace: { date: dateStr, time: timeStr } });
    }

    setResult({ text: value });

    const ms = getTimeoutMs(dateObj);
    timeoutId.current = setTimeout(updateValue, ms);
  }, [date, languageCode, label]);

  useEffect(() => {
    updateValue();

    return () => clearTimeout(timeoutId.current);
  }, [updateValue]);

  return result;
}
