import { useMemo } from 'react';

import { toLocalDate } from '../../../../helpers/misc';
import ProductScanner from '../../components/ProductScanner';
import { useScannerComponentProps } from '../../types/reorderWidget.types';

export const useScannerComponent = ({
  scanSelected,
  menus,
  menuDate,
  site,
  currentLanguageCode,
  createOrderDraft,
  setScanSelected,
}: useScannerComponentProps) => {
  return useMemo(() => {
    if (!scanSelected) return null;

    const menu = menus.find((x) => x.id === scanSelected.menuId);
    if (!menu) return null;

    return scanSelected ? (
      <ProductScanner
        menuItems={menu.menuItems}
        facilityId={menu.facilityId}
        date={toLocalDate(menuDate)}
        menuId={menu.id}
        site={site}
        languageCode={currentLanguageCode}
        cartModificationDisabled={createOrderDraft}
        isScanOpen
        onDismiss={() => setScanSelected(undefined)}
        hideScanButton
        data-testid={'product-scanner'}
      />
    ) : null;
  }, [scanSelected, menus, menuDate, site, currentLanguageCode, createOrderDraft, setScanSelected]);
};
