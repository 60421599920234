import classNames from 'classnames';
import { FC, useEffect, useState, useMemo } from 'react';

import { NetworkIcon, EditOnIcon, InformationIcon } from '../../../../assets/icons';
import { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Button from '../../../../components/atoms/Button/Button';
import Tooltip, { TOOLTIP_POSITION } from '../../../../components/atoms/Tooltip';
import List from '../../../../components/organisms/List/List';
import { CONTROL_TYPE } from '../../../../components/organisms/List/List.types';
import Modal from '../../../../components/organisms/Modal/Modal';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { WithLangProps } from '../../../../context/withLang';
import { getFlagClassName } from '../../../../helpers/getFlagClassName';
import useGeoCode from '../../../../helpers/hooks/useGeoCode';
import useToggle from '../../../../helpers/hooks/useToggle';
import { DefaultComponentWithoutChildrenProps } from '../../../../types';

import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper';

import styles from './GeographySelector.module.css';

type GeographySelectorProps = {
  geographies: { name: string; code: string; regionCode: string }[];
  handleGeographySubmit: (geoCode: string) => void;
  defaultOpenModal?: boolean;
} & WithLangProps &
  DefaultComponentWithoutChildrenProps;

const GeographySelector: FC<GeographySelectorProps> = ({
  label,
  className,
  geographies,
  handleGeographySubmit,
  'data-testid': dataTestId = 'geography-selector',
  defaultOpenModal = false,
}) => {
  const { currentGeoCode, defaultGeoCode } = useGeoCode();

  const [selectedGeography, setSelectedGeography] = useState(currentGeoCode);
  const [modalSelectedGeography, setModalSelectedGeography] = useState(currentGeoCode);
  const {
    state: isModalOpen,
    toggleOn: openModal,
    toggleOff: hideModal,
  } = useToggle(defaultOpenModal);
  useEffect(() => {
    if (currentGeoCode) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, currentGeoCode);
      setModalSelectedGeography(currentGeoCode);
      setSelectedGeography(currentGeoCode);
    }
  }, [currentGeoCode, handleGeographySubmit]);

  const currentGeography = useMemo(() => {
    let currentGeography = geographies.find(({ code }) => code === selectedGeography);
    if (!currentGeography) {
      currentGeography = geographies.find(({ code }) => code === defaultGeoCode);
      if (currentGeography) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, currentGeography.code);
      }
    }

    return {
      name: currentGeography?.name,
      isoCode: currentGeography?.code,
    };
  }, [defaultGeoCode, geographies, selectedGeography]);

  const listOfGeographies = geographies.map((g) => ({
    id: g.code,
    label: (
      <div className={classNames(styles.labelWrapper)}>
        <span className={classNames(styles.flags, getFlagClassName(g.code))}></span>
        {g.name}
      </div>
    ),
    control: {
      type: CONTROL_TYPE.RADIO,
      props: {
        id: g.code,
        value: g.code,
        name: 'geography',
        checked: g.code === modalSelectedGeography,
        'data-testid': `geography-${g.code}-radio-button`,
      },
    },
    'data-testid': `${dataTestId}-geography-item-${g.code}`,
  }));

  const handleSubmit = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, modalSelectedGeography);
    setSelectedGeography(modalSelectedGeography);
    handleGeographySubmit(modalSelectedGeography);
    hideModal();
  };

  const onDismiss = () => {
    setModalSelectedGeography(selectedGeography);
    hideModal();
  };

  const flagClassName = getFlagClassName(currentGeography.isoCode);

  return (
    <>
      <div className={classNames(styles.geographyWrapper, className)} data-testid={dataTestId}>
        <div className={classNames(styles.iconTextWrapper, styles.geoLabelTooltipWrapper)}>
          <span className={styles.geoLabelWrapper}>
            <NetworkIcon />
            <span className={classNames('bodySBold')}>{label('Ref: Country')}</span>
          </span>
          <Tooltip
            place={TOOLTIP_POSITION.Top}
            className={styles.tooltip}
            children={<InformationIcon />}
            content={label('Ref: Country tooltip')}
            tooltipId={TOOLTIP_VARIANTS.DEFAULT} 
            />
        </div>
        <div
          data-testid={`${dataTestId}-country-selection-action`}
          className={classNames(styles.iconTextWrapper, styles.geoValueWrapper)}
          onClick={openModal}
          onKeyDown={(e) => e.key === 'Enter' && openModal()}
          role="button"
          title={label('Ref: Please select your country')}
          tabIndex={0}
        >
          {!!flagClassName && <span className={classNames(styles.flags, flagClassName)}></span>}
          {!!currentGeography.name && (
            <span className={classNames(styles.geoValue)}>{currentGeography.name}</span>
          )}
          <EditOnIcon />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        id="geography_modal"
        data-testid="geography-modal"
        onDismiss={onDismiss}
        title={label('Ref: Country')}
        contentClassName={styles.modalContent}
        footer={
          <Button
            data-testid={`${dataTestId}-geography-validate`}
            look={BUTTON_LOOK.PRIMARY}
            onClick={() => handleSubmit()}
            className={styles.modalButton}
            disabled={!modalSelectedGeography}
          >
            {label('Validate')}
          </Button>
        }
      >
        <Modal.ColumnLayout>
          <List
            items={listOfGeographies}
            data-testid={`${dataTestId}-list-geographies`}
            onChange={(option) => setModalSelectedGeography(option[0].id)}
            role="group"
            aria-labelledby={label('Ref: Country')}
          />
        </Modal.ColumnLayout>
      </Modal>
    </>
  );
};

export default GeographySelector;
