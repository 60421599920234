import React from 'react';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router';

import withLoginStatus from '../../../../context/withLoginStatus';
import withLang from '../../context/withLang';

import { LogoutButtonProps } from './LogoutButton.types';

export default withRouter(
  withLoginStatus(
    withLang([__filename])(({ logout, label, history }: LogoutButtonProps) => (
      <Button
        data-cy="button-logout"
        onClick={() => {
          logout();
          history.push('/');
        }}
      >
        {label('logout', { textTransform: 'capitalize' })}
      </Button>
    ))
  )
);
