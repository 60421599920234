import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { AuditSyncFrequency } from '../../../config';
import { LabelFunc } from '../../../context/withLang';
import { checkIfServiceAvailable } from '../../services';
import { AuditStateCacheService } from '../services/AuditStateCacheService';

import { useSync } from '.';

import toast from '@/components/atoms/ModalToast';
import { SERVICE } from '@/modules/config';
import { OfflineQueueService } from '@/services/OfflineQueueService';

let listener: any = null;
const synchronisationCacheKey: string = 'auditSynchronisationLastDate';
const autoSyncListenerCacheKey: string = 'autoSyncListener';

export const useAuditSynchronisation = async (label: LabelFunc, contactId: string) => {
  const auditsCache = AuditStateCacheService.getInstance();
  const isAuditServiceAvailable = checkIfServiceAvailable(SERVICE.AUDITS);
  /* 
        The key we generate is there to avoid having multiple listeners generated on the app.
        We store that in the cache under a single key, which means that the value will be overwritten several times.
        In the end, we retrieve the value from the cache, and compare it with the GUID that was generated here.
        This guarantees that only a single instance will ever run.
    */
  const listenerKey = uuid().toString();
  const platform = Capacitor.getPlatform();

  const setSynchronisationCache = async () => {
    await auditsCache.setValue(synchronisationCacheKey, Date.now());
  };

  const sync = useSync({
    successLabel: 'Sync success',
    errorLabel: 'Sync error',
    onSuccess: setSynchronisationCache,
  });

  useEffect(() => {
    if (!isAuditServiceAvailable) return;
    if (platform === 'web') return;
    if (!contactId) return;
    if (listener) return;

    (async () => {
      if (sync.isLoading) return;

      await auditsCache.setValue(autoSyncListenerCacheKey, listenerKey);
      const listenerCachedKey = await auditsCache.getValue(autoSyncListenerCacheKey);

      if (listenerKey !== listenerCachedKey) return;

      listener = await App.addListener('appStateChange', async ({ isActive }) => {
        if (!isActive) return;

        const { connected } = await Network.getStatus();
        if (!connected) return;

        const auditsQueue = OfflineQueueService.getInstance();

        const pendingAudits = await auditsQueue.getAll();
        const lastSynchronisation = await auditsCache.getValue(synchronisationCacheKey);

        // If the cache key is not set; or if it is, and the stored date is greater than 24 hours; AND there are audits in the offline queue
        if (
          !lastSynchronisation ||
          (lastSynchronisation && Date.now() - lastSynchronisation > AuditSyncFrequency) ||
          pendingAudits.length > 0
        ) {
          toast.info('Sync in progress', {
            position: 'top-center',
            closeOnClick: true,
            draggable: false,
            closeButton: false,
          });

          await sync.onFetchData();
        }
      });
    })();
  }, [auditsCache, contactId, isAuditServiceAvailable, listenerKey, platform, sync]);
};
