import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { State } from '../../../../types/state.types';
import { getAllLegalDocuments, getOneLegalDocument, acknowledgeLegalDoc } from '../../actions';
import { legalDocType, legalDocTypeCodesToNameMap } from '../../config';
import { checkIfAcknowledgeAfterLegalDocModification } from '../../helpers/helpers';

import LegalDocPage from './LegalDocPage';
import { LegalDocPageContainerProps } from './LegalDocPage.types';

const mapStateToProps = (state: State, ownProps: LegalDocPageContainerProps) => {
  const version = ownProps.match.params.version;
  const type = ownProps.match.params.document_type;
  const languageCode = ownProps.preferredLanguage?.languageCode;

  const {
    access: { termsOfUseToAcknowledge, privacyPolicyToAcknowledge },
    user: { acknowledgements },
    context: { currentGeoCode },
    legalDocs: { list: docsList, detailed: documents },
    locks: { getOneLegalDocument: locked },
  } = state.Core;

  const { defaultGeoCode } = state.Shared.geographies;

  let currentDocsId: { [key: string]: string } = {};
  if (docsList)
    docsList.forEach(
      (doc) =>
        (currentDocsId[
          legalDocTypeCodesToNameMap[doc.type.value as keyof typeof legalDocTypeCodesToNameMap]
        ] = doc.id)
    );

  let acknowledgedDocsId: { [key: string]: string } = {};
  let lastAcknowledgedOn: { [key: string]: number } = {};

  //warning: this will bug for users traveling between geographies because there is no guarantee that it is for the same geography. geography should be returned by the API for each acknowledgement
  if (acknowledgements) {
    acknowledgements.forEach((acknowledgement) => {
      let typeCode = acknowledgement.type.value;
      let documentType =
        legalDocTypeCodesToNameMap[typeCode as keyof typeof legalDocTypeCodesToNameMap];
      let acknowledgedOn = acknowledgement.acknowledgedOn;
      let documentId = acknowledgement.documentId;
      let candidateAcknowledgedOn = Date.parse(acknowledgedOn);
      if (
        acknowledgedDocsId[documentType as keyof typeof acknowledgedDocsId] === undefined ||
        candidateAcknowledgedOn >
          lastAcknowledgedOn[documentType as keyof typeof lastAcknowledgedOn]
      ) {
        acknowledgedDocsId[documentType as keyof typeof acknowledgedDocsId] = documentId;
        lastAcknowledgedOn[documentType as keyof typeof lastAcknowledgedOn] =
          Date.parse(acknowledgedOn);
      }
    });
  }

  let shouldAcknowledge = false;
  let documentId = currentDocsId[type];
  const skipTermOfUseOnWeb = !Capacitor.isNativePlatform() && type === legalDocType.terms_of_use;
  if (version === 'current' && !skipTermOfUseOnWeb) {
    //check if the doc should be acknowledged
    if (
      (type === legalDocType.terms_of_use && termsOfUseToAcknowledge) ||
      (type === legalDocType.privacy_policy && privacyPolicyToAcknowledge)
    ) {
      shouldAcknowledge = true;
    }
  } else if (version === 'acknowledged' && skipTermOfUseOnWeb) {
    documentId = acknowledgedDocsId[type];
  }

  let isAcknowledgeAfterLegalDocModification = false;
  if (shouldAcknowledge) {
    isAcknowledgeAfterLegalDocModification = checkIfAcknowledgeAfterLegalDocModification(
      acknowledgements,
      type
    );
  }

  const legalDocument = documents[documentId];

  return {
    documentId,
    legalDocument,
    languageCode,
    geoCode: currentGeoCode || defaultGeoCode,
    type,
    version,
    shouldAcknowledge,
    isAcknowledgeAfterLegalDocModification,
    locked,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: LegalDocPageContainerProps) => {
  const languageCode = ownProps.preferredLanguage?.languageCode;
  return {
    getAllLegalDocuments: (geoCode: string) =>
      dispatch(getAllLegalDocuments({ geoCode, languageCode })),
    getOneLegalDocument: (id: string) => dispatch(getOneLegalDocument({ id })),
    acknowledgeLegalDoc: (documentId: string, documentTypeCode: number) =>
      dispatch(acknowledgeLegalDoc({ documentId, documentTypeCode })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegalDocPage));
