import classNames from 'classnames';
import React from 'react';

import { MoreIcon, HomeIcon } from '../../../assets/icons';
import { getImagesSrcFromIds } from '../../../helpers/images/images.helper';
import { isIosMobile, isServiceType } from '../../../helpers/misc';
import { SERVICE, serviceTypes } from '../../../modules/config';
import { ServiceDefinition } from '../../../modules/defaultServices';
import Link from '../../atoms/Link';

// TODO migrate to css modules
import ItemIcon from './ItemIcon';
import { getServicePath, getServicesWithNav, getItemLabel } from './navBar.helpers';
import { NavBarProps, NavBarState } from './navBar.types';

import './navBar.css';

const itemHorizontalSpace: number = 80;
const itemVerticalSpace: number = 90;

class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.getNavItems = this.getNavItems.bind(this);
    this.getDynamicNavItems = this.getDynamicNavItems.bind(this);
    this.resizeLabels = this.resizeLabels.bind(this);
    this.state = {
      icons: {},
      iconsFetchInitialized: false,
    };
  }

  async fetchServiceIcons() {
    const { services } = this.props;

    if (!this.props.services?.length || this.state.iconsFetchInitialized) return;

    const imageIds = services
      ?.filter((service) => !!service.navigation?.imageId)
      .map((service) => service.navigation.imageId!);

    const images = await getImagesSrcFromIds(imageIds);
    const navIcons: Record<string, string> = {};

    services.forEach((service) => {
      if (service?.navigation?.imageId) {
        const icon = images.find((img) => img.id === service?.navigation?.imageId);
        if (icon) navIcons[service.navigation.name] = icon.content;
      }
    });

    this.setState({
      ...this.state,
      icons: navIcons,
      iconsFetchInitialized: true,
    });
  }

  componentDidMount() {
    if (this.props.direction !== 'vertical') this.resizeLabels();
    this.fetchServiceIcons();
  }

  componentDidUpdate() {
    if (this.props.direction !== 'vertical') this.resizeLabels();
    this.fetchServiceIcons();
  }

  // TODO move to css
  resizeLabels() {
    // to maximize the width of the labels, esp on small devices
    const items = document.querySelectorAll('.nav-item');
    const containerWidth = document.querySelector('#NavBar')?.clientWidth;
    if (!containerWidth) return;

    const theoreticalMaxWidth = containerWidth / items.length - 10;

    let requestedWidths = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let label = item.querySelector('.nav-item-label');
      if (!label) label = item.childNodes[0] as Element; //fall back onto 1st child, whatever it is
      requestedWidths[i] = label.clientWidth;
    }

    for (let i = 0; i < requestedWidths.length; i++) {
      let requestedWidth = requestedWidths[i];
      if (requestedWidth <= theoreticalMaxWidth) continue;
      let previousLabelRequestedWidth = i ? requestedWidths[i - 1] : theoreticalMaxWidth;
      let nextLabelRequestedWidth =
        i !== requestedWidths.length - 1 ? requestedWidths[i + 1] : theoreticalMaxWidth;
      let applicableMaxNeighbourWidth = Math.max(
        previousLabelRequestedWidth,
        nextLabelRequestedWidth
      );

      let newWidth;

      if (applicableMaxNeighbourWidth >= theoreticalMaxWidth) {
        //if no additional space avaialble, extend to max
        newWidth = theoreticalMaxWidth + 'px';
      } else {
        //otherwise, extend into the adjacent labels space
        newWidth =
          Math.min(requestedWidth, 2 * theoreticalMaxWidth - applicableMaxNeighbourWidth) + 'px';
      }

      (items[i].querySelector('.nav-item-label') as HTMLElement).setAttribute(
        'style',
        'width: ' + newWidth
      );
    }
  }

  getNavItems(servicesWithNav: ServiceDefinition[], maxNavItems: any) {
    const { location, label } = this.props;

    //if we don't have any service with nav item, no need to add the navbar
    if (!servicesWithNav.length) {
      return [];
    }

    //keep one slot for the ... menu if we have more services with nav items than slots in the navbar
    const maxNavItemForServices =
      servicesWithNav.length > maxNavItems ? maxNavItems - 1 : maxNavItems;
    let navItems = this.getDynamicNavItems(servicesWithNav, maxNavItemForServices);

    navItems.unshift(
      <span
        className={location.pathname === '/home' ? 'nav-item active-nav-item' : 'nav-item'}
        data-cy="navbar-item-home"
      >
        <Link
          data-testid="navbar-item-home"
          to="/home"
          srOnlyText="Open home"
          {...(location.pathname === '/home' ? { 'aria-current': 'page' } : {})}
        >
          <div className="iconWrapper">
            <HomeIcon />
          </div>
          <div className="nav-item-label">{label('home', { textTransform: 'capitalize' })}</div>
        </Link>
      </span>
    );

    //add the "..." menu if needed
    if (servicesWithNav.length > maxNavItemForServices) {
      navItems.push(
        <span
          className={location.pathname === '/services' ? 'nav-item active-nav-item' : 'nav-item'}
          data-cy="navbar-item-more"
        >
          <Link
            data-testid="navbar-item-more"
            to={'/services?start=' + maxNavItemForServices}
            srOnlyText="Open more menu"
            {...(location.pathname === '/services' ? { 'aria-current': 'page' } : {})}
          >
            <div className="nav-item-icon">
              <MoreIcon className="service_icon" />
            </div>
            <div className="nav-item-label">{label('More (as in: see more info)')}</div>
          </Link>
        </span>
      );
    }

    return navItems;
  }

  isPathExcluded(excludeSubPaths: string[] | undefined, currentPath: string) {
    if (!excludeSubPaths?.length) return false;

    return (
      excludeSubPaths.find((excludedSubPath) => currentPath.indexOf(excludedSubPath) !== -1) !==
      undefined
    );
  }

  getDynamicNavItems(servicesWithNav: ServiceDefinition[], maxNavItems: any) {
    const { location } = this.props;

    let navItems = [];

    for (let i = 0; i < maxNavItems && i < servicesWithNav.length; i++) {
      const service: ServiceDefinition = servicesWithNav[i];
      const serviceType = serviceTypes[service.name];

      //if we don't have a corresponding service type, ignore
      if (!serviceType) {
        continue;
      }

      let isActive = false;

      const itemPath = serviceType.path;
      const itemExcludeSubPath = serviceType.excludeSubPaths;

      if (isServiceType(service, SERVICE.CONTENT_PAGE)) {
        isActive =
          location.pathname === itemPath ||
          (location.pathname.startsWith(itemPath + '/') &&
            !this.isPathExcluded(itemExcludeSubPath, location.pathname) &&
            location.pathname.endsWith(service.navigation.name));
      } else {
        isActive =
          !this.isPathExcluded(itemExcludeSubPath, location.pathname) &&
          location.pathname.startsWith(itemPath) &&
          !new URLSearchParams(location.search).get('url');
      }

      navItems[i] = (
        <span
          className={classNames({
            'nav-item': true,
            'active-nav-item': isActive,
          })}
          data-cy={'navbar-item-' + service.name}
          data-cy-order={service.navigation.orderIndex}
        >
          <Link
            data-testid={`navbar-item-${service.name}`}
            to={getServicePath(service)}
            srOnlyText={'Open ' + service.navigation.name}
            {...(isActive ? { 'aria-current': 'page' } : {})}
          >
            <ItemIcon service={service} customIcons={this.state.icons} />
            <div className="nav-item-label">{getItemLabel(service, this.props.label)}</div>
          </Link>
        </span>
      );
    }

    return navItems;
  }

  render() {
    const { isLoggedIn, services, direction = 'horizontal' } = this.props;

    let servicesWithNav = getServicesWithNav(services, isLoggedIn);
    let maxNavItems;
    if (direction === 'vertical') {
      //90px per nav item
      maxNavItems = Math.floor(
        Math.min(
          servicesWithNav.length,
          ((document.querySelector('html') as HTMLElement).clientHeight - 100) / itemVerticalSpace -
            1
        )
      ); //minus 1 for the home
    } else {
      //min 80px per nav item
      maxNavItems = Math.floor(
        Math.min(
          servicesWithNav.length,
          (document.querySelector('html') as HTMLElement).clientWidth / itemHorizontalSpace - 1,
          5
        )
      ); //minus 1 for the home, max 6 items
    }

    let navItems = this.getNavItems(servicesWithNav, maxNavItems);

    if (!navItems.length) return <React.Fragment />;

    let key = 0;
    let containerClass = direction === 'vertical' ? 'NavBar-vertical' : 'NavBar-horizontal';

    return (
      <nav
        id="NavBar"
        className={classNames(containerClass, {
          'NavBar-horizontal-IosMobile': direction !== 'vertical' && isIosMobile(),
        })}
        data-cy="navbar"
      >
        {navItems.map((navItem) => (
          <React.Fragment key={key++}>{navItem}</React.Fragment>
        ))}
      </nav>
    );
  }
}

export default NavBar;
