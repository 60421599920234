import { Capacitor } from '@capacitor/core';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { State } from '../../../../types/state.types';
import {
  logout,
  getAcknowledgements,
  getAllLegalDocuments,
  getOneLegalDocument,
  acknowledgeLegalDoc,
} from '../../actions';
import { legalDocType, legalDocTypeNamesToCodeMap } from '../../config';
import { checkIfAcknowledgeAfterLegalDocModification } from '../../helpers/helpers';
import { Document as AcknowledgementDocument } from '../../types/legalDocsPage.types';

import LegalDocsPage from './LegalDocsPage';
import { LegalDocsPageContainerProps } from './LegalDocsPage.types';

const mapStateToProps = (state: State, ownProps: LegalDocsPageContainerProps) => {
  const languageCode = ownProps.preferredLanguage?.languageCode;
  const {
    access: { termsOfUseToAcknowledge, privacyPolicyToAcknowledge },
    context: { currentGeoCode },
    legalDocs: { list: docsList, detailed: documents },
    locks: {
      getAcknowledgements: getAcknowledgementsLocked,
      getAllLegalDocuments: getAllLegalDocumentsLocked,
      getOneLegalDocument: getOneLegalDocumentLocked,
    },
    user: { acknowledgements },
  } = state.Core;

  const { defaultGeoCode } = state.Shared.geographies;

  const isNativePlatform = Capacitor.isNativePlatform();
  const legalDocumentType = isNativePlatform
    ? legalDocType.terms_of_use
    : legalDocType.privacy_policy;

  const legalDocumentId = docsList?.find(
    (el) => el.type.value === legalDocTypeNamesToCodeMap[legalDocumentType]
  )?.id;

  const legalDocument = documents[legalDocumentId || ''];
  const isAcknowledgeAfterLegalDocModification = checkIfAcknowledgeAfterLegalDocModification(
    acknowledgements,
    legalDocumentType
  );

  return {
    geoCode: currentGeoCode || defaultGeoCode,
    languageCode,
    legalDocumentId,
    legalDocument,
    legalDocumentType,
    termsOfUseToAcknowledge,
    privacyPolicyToAcknowledge,
    getOneLegalDocumentLocked,
    isLocked: getAllLegalDocumentsLocked || getAcknowledgementsLocked || getOneLegalDocumentLocked,
    isAcknowledgeAfterLegalDocModification,
    isNativePlatform,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<State, void, Action>,
  ownProps: LegalDocsPageContainerProps
) => {
  const languageCode = ownProps.preferredLanguage?.languageCode;
  return {
    logout: () => dispatch(logout()),
    getAcknowledgements: () => dispatch(getAcknowledgements()),
    getAllLegalDocuments: (geoCode: string) =>
      dispatch(getAllLegalDocuments({ geoCode, languageCode })),
    getOneLegalDocument: (id: string) => dispatch(getOneLegalDocument({ id })),
    acknowledgeLegalDocs: (acknowledgements: AcknowledgementDocument[]) => {
      acknowledgements.forEach(({ documentId, documentTypeCode }) => {
        dispatch(acknowledgeLegalDoc({ documentId, documentTypeCode }));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalDocsPage);
