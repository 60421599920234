import { ionFireEvent } from '@ionic/react-test-utils';
import { screen, cleanup, fireEvent, act } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { APP_NAME } from '../../../../../constants';
import { defaultState as sharedDefaultState } from '../../../../../reducers/sharedReducer';
import Home from '../Home';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '@/modules/Core/reducers/coreReducer';
import { ServiceRequestProps } from '@/modules/ServiceRequest/types/types';

const mockDate = '2022-12-16T20:00:44Z';

jest.mock('@/helpers/misc', () => ({
  ...jest.requireActual('@/helpers/misc'),
  formatDateTime: () => mockDate,
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => {
    return jest.fn(() => jest.fn());
  },
  useSelector: (callback: any) => callback(mockState),
}));

const locationData = {
  building: { name: 'Accommodation Block A', id: '1' },
  floor: { name: 'Camp - A - External Area', id: '1' },
  wing: { name: 'Right wing', id: '1' },
  room: { name: '26.', id: '1' },
  property: { name: 'FMG Camp 145 Property', id: '1' },
  site: { name: 'FMG Camp 145', id: '1' },
};

const mock: { data: ServiceRequestProps[]; isLoading: boolean } = {
  data: [],
  isLoading: false,
};

jest.mock('../../../api/index', () => ({
  useGetServiceRequestsQuery: () => {
    return mock;
  },
}));

jest.mock('../../../actions', () => ({
  updateQrInfo: () => jest.fn(),
}));

const mockState = {
  Shared: {
    ...sharedDefaultState,
  },
  Core: {
    ...coreDefaultState,
  },
  ServiceRequest: {
    tutorials: {},
  },
};

describe('Home', () => {
  const env = global.process.env;
  const handleFilteringChange = jest.fn();
  const handleSearchChange = jest.fn();
  let primaryButton: HTMLElement;
  const getAllServiceRequests = jest.fn();

  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
    mock.data = [
      {
        id: '7446cf4e-7c7d-ed11-81ad-6045bd8c5dba',
        displayTitle: 'Test Title',
        title: 'Test Title',
        createdOn: mockDate,
        currentStatus: 'Open',
        description: '',
        modifiedOn: '2023-11-12 12:00:00',
        raisedFor: {
          contactId: '699ae152-fa57-ec11-8f8f-000d3a49640f',
          firstName: 'John',
          lastName: 'Wick',
          value: '',
          label: '',
        },
        referenceNumber: 'SR-13388-Y0C5W3',
        building: locationData.building,
        floor: locationData.floor,
        wing: locationData.wing,
        room: locationData.room,
        property: locationData.property,
        site: locationData.site,
        status: '',
      },
    ];
  });

  const props = {
    parentFilter: { filtering: { filter_sr_status: { all: true } }, handleFilteringChange },
    parentSearch: { handleSearchChange, searchString: '' },
  };

  afterAll(() => cleanup());

  describe('on initial render', () => {
    let allButton: HTMLElement;
    let openButton: HTMLElement;
    let pendingButton: HTMLElement;
    let closedButton: HTMLElement;
    let input: HTMLElement;

    renderComponent(Home, props);

    beforeEach(() => {
      allButton = screen.getByText('All');
      openButton = screen.getByText('All');
      pendingButton = screen.getByText('All');
      closedButton = screen.getByText('All');
      input = screen.getByTestId(
        'service-request-home-list-search-bar-list-page-search-filter-bar-input-field'
      );
      primaryButton = screen.getByText('Request service');
    });

    it('should have all 4 filter buttons and search input', () => {
      expect(allButton).toBeTruthy();
      expect(openButton).toBeTruthy();
      expect(pendingButton).toBeTruthy();
      expect(closedButton).toBeTruthy();
      expect(input).toBeTruthy();
    });

    it('should display', () => {
      expect(screen.getByText('Test Title')).toBeTruthy();
      expect(screen.getByText(mockDate)).toBeTruthy();
      expect(screen.getAllByText('Open')).toBeTruthy();
      expect(primaryButton).toBeTruthy();
      expect(getAllServiceRequests).toBeTruthy();
    });
  });

  describe('on filtering with search bar', () => {
    let input: HTMLElement;
    let tiles: HTMLElement[];

    renderComponent(Home, { ...props });

    beforeEach(() => {
      input = screen.getByTestId(
        'service-request-home-list-search-bar-list-page-search-filter-bar-input-field'
      );
      ionFireEvent.ionChange(input, 'Test');
    });

    it('should have the correct number of tiles listed', () => {
      tiles = screen.getAllByTestId(/ServiceRequestTile-.+-actions-0-content/i);
      expect(tiles.length).toEqual(1);
    });

    it('should filter the correct content', () => {
      expect(screen.getByText('Test Title')).toBeTruthy();
    });
  });

  describe('on primary button click', () => {
    let button: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(Home, { ...props }, undefined, history);

    beforeEach(async () => {
      button = screen.getByText('Request service');

      await act(async () => {
        fireEvent.click(button);
      });
    });

    it('should redirect to locate', async () => {
      expect(history.location.pathname).toBe('/service_request/create');
    });
  });

  describe('on SR tile click', () => {
    let serviceRequest: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(Home, { ...props }, undefined, history);

    beforeEach(() => {
      serviceRequest = screen.getByText('Test Title');
    });

    it('should redirect to SR detail', () => {
      fireEvent.click(serviceRequest);
      expect(history.location.pathname).toBe(`/service_request/${mock.data[0].id}/details`);
    });
  });
});
