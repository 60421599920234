import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isHelpdeskUser } from '../../../Core/helpers/tokensHelpers';

import { ServiceRequestIllustration } from '@/assets/illustrations';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { formatDate, formatTime } from '@/helpers/misc';
import { useGetServiceRequestLightDetailQuery } from '@/modules/ServiceRequestLight/api';
import { useServiceRequestLightSetupOption } from '@/modules/ServiceRequestLight/hooks/useServiceRequestLightSetupOption';
import { useServiceRequestLightTranslation } from '@/modules/ServiceRequestLight/hooks/useServiceRequestLightTranslation';
import { AttachmentData } from '@/types/common.types';
import { State } from '@/types/state.types';

const Details = (): ReactElement => {
  const { label } = useServiceRequestLightTranslation(__filename);
  const { id } = useParams<{ id: string }>();
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const { data, isLoading } = useGetServiceRequestLightDetailQuery({ id });

  const detailsTitle = useMemo(() => data && `${data.serviceRequestNumber} ${data.title}`, [data]);
  const detailsLocation = useMemo(
    () =>
      data &&
      `${data.siteName}  ${
        data.buildingText && data.buildingText !== '' ? `/ ${data.buildingText}` : ''
      } ${data.roomText && data.roomText !== '' ? `/ ${data.roomText}` : ''}`,
    [data]
  );

  const showAffectedPerson =
    useServiceRequestLightSetupOption('enableAffectedPerson') || isHelpdeskUser();
  const showCategories = useServiceRequestLightSetupOption('enableCategories');

  let detailsListItems: {
    id: string;
    label: string;
    value: any;
    wrapLine?: boolean;
    'data-testid': string;
  }[] = [
    {
      id: 'createdOn',
      label: label('Ref: createdOn'),
      value: data?.createDateTime
        ? `${formatDate(new Date(data?.createDateTime), currentLanguageCode)} -
        ${formatTime(new Date(data?.createDateTime))}`
        : '',
      'data-testid': 'createdOn',
    },
    {
      id: 'summary',
      label: label('Ref: summary'),
      value: data?.title,
      'data-testid': 'summary',
    },
  ];

  if (showCategories && data?.category && data?.category !== '') {
    detailsListItems.push({
      id: 'category',
      label: label('Ref: category'),
      value: data?.category,
      'data-testid': 'category',
    });
  }

  if (showAffectedPerson) {
    detailsListItems.push({
      id: 'raisedFor',
      label: label('Ref: request raised for'),
      value: data?.affectedPersonFullName,
      'data-testid': 'raised-for',
    });
  }

  detailsListItems = [
    ...detailsListItems,
    {
      id: 'phoneNumber',
      label: label('Ref: phoneNumber'),
      value: data?.phoneNumber,
      'data-testid': 'phone-number',
    },
    {
      id: 'location',
      label: label('Ref: location'),
      value: detailsLocation,
      'data-testid': 'location',
    },
    {
      id: 'description',
      label: label('Ref: description'),
      value: data?.description,
      'data-testid': 'description',
    },
  ];

  if (data?.attachments && data?.attachments.length > 0) {
    detailsListItems.push({
      id: 'attachments',
      label: label('Ref: attachments'),
      'data-testid': 'attachments',
      value: (
        <Attachments
          readOnly
          attachments={
            (data?.attachments?.map((at) => ({
              data: at.content,
              file: {
                name: at.fileName,
              },
            })) as unknown as AttachmentData[]) || []
          }
          closeButtonNotVisible
          displaySize={false}
          data-testid={`attachments-service-request-light-item-details-description-field`}
        />
      ),
      wrapLine: true,
    });
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      title={label('Ref: ServiceRequestLightDetails')}
      tabTitle={label('Ref: ServiceRequestLightDetails')}
      hasBackLink
      data-testid="service-request-light-details-page"
    >
      <Container>
        <Column.Main>
          <div>
            <Title tag={TITLE_TAG.H3} size={TITLE_SIZE.HEADLINES} className="mb-M">
              {detailsTitle}
            </Title>
            <Card>
              <List data-testid="service-request-light-details" items={detailsListItems} />
            </Card>
          </div>
        </Column.Main>
        <Column.Complementary>
          <ServiceRequestIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default Details;
