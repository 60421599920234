import { connect } from 'react-redux';

import { logout } from '../../actions';

import AccountDelete from './AccountDelete';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDelete);
