import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { APP_ERRORS } from '../../../../constants';
import GenericErrorPage from '../../../Core/components/GenericErrorPage/GenericErrorPage';
import { useGetContentPageQuery } from '../../api';
import Details from '../../components/DetailPage';
import useGetContentPageParams from '../../hooks/useGetContentPageParams';

const DetailPageWrapper = () => {
  const { contentPageId, navItemName } = useGetContentPageParams();

  const {
    data: contentPageResponse = {
      contentPageDetail: undefined,
      notFound: false,
    },
    isFetching,
  } = useGetContentPageQuery({ id: contentPageId });
  const { contentPageDetail, notFound } = contentPageResponse;

  if (notFound) {
    return <GenericErrorPage errors={[APP_ERRORS.NOT_FOUND]} />;
  }

  if (isFetching || !contentPageDetail) {
    return <LoadingPage />;
  }

  return <Details contentPage={contentPageDetail} navItemName={navItemName} />;
};

export default DetailPageWrapper;
