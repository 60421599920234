import { connect } from 'react-redux';

import { State } from '../../../types/state.types';
import AccountValidation from '../components/AccountValidation';
import withLang from '../context/withLang';

const mapStateToProps = (state: State) => {
  const { firstName, lastName = '' } = state.Core.user;

  return { firstName, lastName };
};

const mapDispatchToProps = () => {
  return {};
};

export default withLang([__filename])(
  connect(mapStateToProps, mapDispatchToProps)(AccountValidation)
);
