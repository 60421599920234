/**
 * NOTE:
 *
 * Only class components can be Error boundaries.
 *
 * There are a couple of libraries that we could use if we wanted to convert
 * this component, but that's just adding an extra layer which under the hood is
 * still a class component, but now, coming from an external package.
 *
 * For now the suggestions is to keep this component as is and wait for a new
 * react version that supports error boundaries with hooks.
 *
 * More information:
 * https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 */
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { AxiosResponse } from 'axios';
import * as H from 'history';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { axiosClient } from '../../../axios/axiosClient';

import DefaultErrorPage from './GenericErrorPage/DefaultErrorPage';

import './ErrorBoundary.css';

const initState = {
  hasError: false,
  hasServerError: false,
  error: null,
  errorMessage: null,
  errorId: null,
  serverErrorId: null,
};

export interface ErrorBoundaryProps extends RouteComponentProps {
  appInsights: ReactPlugin;
  logout: CallableFunction;
  children: React.ReactNode;
  history: H.History<unknown>;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  hasServerError: boolean;
  error: Error | null;
  errorMessage: string | null;
  errorId: string | null;
  serverErrorId: string | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  componentDidMount(): void {
    this.props.history.listen(() => {
      if (this.state.hasServerError || this.state.hasError) {
        this.resetServerErrorState();
      }
    });
    // catch axios responses and operate with them
    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const useErrorBoundary =
          error.config.opts?.useErrorBoundary !== undefined
            ? error.config.opts.useErrorBoundary
            : true;

        if (useErrorBoundary && error.response?.status >= 500) {
          this.serverErrorHandler(error.response);
        } else if (error.response?.status === 200) {
          // console.log('Error boundary received response 200', error.response);
        }
        return error.response || { error };
      }
    );
  }

  serverErrorHandler = (response: AxiosResponse) => {
    const serverErrorId = uuidv4();
    this.setState({ hasError: false, hasServerError: true, serverErrorId: serverErrorId });
    // app insight tracker for exception
    if (process.env.REACT_APP_INSIGHTS_KEY) {
      this.props.appInsights.trackException(
        {
          id: serverErrorId,
          severityLevel: SeverityLevel.Error,
          properties: {
            message: 'Server error',
          },
        },
        {
          requestMethod: response.config.method?.toUpperCase(),
          responseUrl: response.config.url,
          responseStatus: response.status,
        }
      );
    }
  };

  resetServerErrorState = () => {
    this.setState({ ...initState });
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    //Catch error in any components below ad re-render with error message
    const errorId = uuidv4();
    this.setState({
      hasError: true,
      error,
      errorMessage: error.toString(),
      errorId,
    });

    if (process.env.REACT_APP_INSIGHTS_KEY) {
      this.props.appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: {
          ...errorInfo,
          error_id: errorId,
        },
      });
    }
  }

  render() {
    const { hasError, hasServerError, errorId, serverErrorId } = this.state;

    if (hasServerError) {
      return <DefaultErrorPage errorId={serverErrorId ? serverErrorId : ''} />;
    }
    if (hasError) {
      return <DefaultErrorPage errorId={errorId ? errorId : ''} />;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
