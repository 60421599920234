import classNames from 'classnames';
import momentjs from 'moment';
import { Dispatch } from 'redux';

import { BuildingIcon, CreditCardIcon, ShoppingBagIcon } from '../../../assets/icons';
import { BUTTON_LOOK } from '../../../components/atoms/Button';
import { CONTROL_TYPE, ListItemProps } from '../../../components/organisms/List/List.types';
import { LabelFunc } from '../../../context/withLang';
import { formatDate } from '../../../helpers/misc';
import { ApiType } from '../../../modules/Core/api';
import MM from '../../../services/ModulesManager';
import { isBiteKiosk } from '../../Core/helpers/helpers';
import { getTokenPayload } from '../../Core/helpers/tokensHelpers';
import { removeItemsFromCart, updateItemInCart } from '../actions';
import PriceWithDiscount from '../components/PriceWithDiscount';
import { getCartInfo } from '../components/ProductsList/productList.helper';
import { orderConfig } from '../config';
import { PaymentSetupOptions, PaymentModality, DeliveryOptionType } from '../types/cart.types';
import {
  Cart,
  CartMenuItem,
  FacilityMenu,
  FulfillmentType,
  ICartModifier,
  OrderDraft,
} from '../types/orderState.types';

import { getMenuItemById } from './productDetails.helper';

import { getCustomSetupOption } from '@/helpers/getSetupOption';
import { GetLoyaltySchemesResponse } from '@/modules/LoyaltyStamps/api/api.types';
import { checkIsValidEmail } from '@/modules/Review/helpers/reviewForm.helper';

import styles from '../components/Cart.module.css';

export const getCartDateLabel = (
  date: Date | undefined,
  label: LabelFunc,
  languageCode: string
) => {
  if (!date) return null;

  const momentDate = momentjs(date).startOf('day');
  const todayDate = momentjs().startOf('day');
  const tomorrowDate = momentjs().add(1, 'days').startOf('day');

  if (momentDate.isSame(todayDate)) return label('Ref: Today');
  if (momentDate.isSame(tomorrowDate)) return label('Ref: Tomorrow');

  return formatDate(date, languageCode);
};

export const getCartNbItems = (cart: Cart) => {
  return cart.menuPortionItems?.reduce((nbCartItems, item) => nbCartItems + item.quantity, 0) ?? 0;
};

export const getModifierIdPerQuantity = (modifiers: ICartModifier[] | undefined) => {
  const modifierIdsPerQuantity: number[] = [];
  modifiers?.map((m) => {
    return m.values?.map((obj) => {
      for (let i = 0; i < obj?.quantity; i++) {
        modifierIdsPerQuantity.push(obj.valueId);
      }
      return modifierIdsPerQuantity;
    });
  });

  return modifierIdsPerQuantity;
};

const addPaymentOption = ({
  paymentOptions,
  option,
  optionLabel,
  selectedPaymentOption,
  icon,
  total,
}: {
  paymentOptions: ListItemProps[];
  option: PaymentModality;
  optionLabel: string;
  selectedPaymentOption?: PaymentModality;
  icon: React.ComponentType<{
    className?: string;
  }>;
  total?: React.ReactNode;
}) => {
  paymentOptions.push({
    id: option,
    name: `${optionLabel} - ${total}`,
    label: total ? (
      <>
        {optionLabel} - <span className={classNames(styles.paymentModalityTotal)}>{total}</span>
      </>
    ) : (
      optionLabel
    ),
    icons: [{ icon: icon }],
    control: {
      type: CONTROL_TYPE.RADIO,
      props: {
        id: option,
        checked: selectedPaymentOption === option,
        'data-testid': `payment-${option}-radio-button`,
      },
    },
    labelClassName: styles.paymentLabel,
    'data-testid': `payment-option-${option}`,
  });
};

export const getPaymentOptions = ({
  label,
  selectedPaymentOption,
  paymentSetupOptions,
  total,
  isConferenceCateringAvailable,
}: {
  label: Function;
  selectedPaymentOption?: PaymentModality;
  paymentSetupOptions: PaymentSetupOptions;
  total: React.ReactNode;
  isConferenceCateringAvailable: boolean;
}) => {
  const paymentOptions: ListItemProps[] = [];

  if (canPayOnline(paymentSetupOptions))
    addPaymentOption({
      paymentOptions,
      option: PaymentModality.PayNow,
      optionLabel: label('Ref: Pay now'),
      selectedPaymentOption,
      icon: CreditCardIcon,
      total,
    });

  if (paymentSetupOptions?.paymentOnPickupSetupOption.isActive)
    addPaymentOption({
      paymentOptions,
      option: PaymentModality.PayOnPickup,
      optionLabel: label('Ref: Pay on pickup'),
      selectedPaymentOption,
      icon: ShoppingBagIcon,
    });
  if (isConferenceCateringAvailable)
    addPaymentOption({
      paymentOptions,
      option: PaymentModality.ChargeToMyDepartment,
      optionLabel: label('Ref: Charge to my department'),
      selectedPaymentOption,
      icon: BuildingIcon,
    });

  return paymentOptions;
};

export const buildCartActions = ({
  label,
  checkCartAndPay,
  cart,
  orderDraft,
  isLoading,
  isGuest,
  isGuestOrdering,
  paymentSetupOptions,
  redirectToRegister,
  mustAcknowledgeTermsOfSale,
  isTermsOfSalesAcknowledged,
  paymentMethod,
  isOrderingPossible,
  openInfoModal,
  openContinueOrderingJourneyModal,
  syncTrackingId,
  isConferenceCateringAvailable,
  isScanAndGo,
  languageCode,
}: {
  label: Function;
  checkCartAndPay: (paymentInfo: { paymentMethod: PaymentModality }) => void;
  cart?: Cart;
  orderDraft?: OrderDraft;
  isLoading?: boolean;
  isGuest?: boolean;
  isGuestOrdering?: boolean;
  paymentSetupOptions: PaymentSetupOptions;
  mustAcknowledgeTermsOfSale: boolean;
  isTermsOfSalesAcknowledged: boolean;
  redirectToRegister: () => void;
  paymentMethod?: PaymentModality;
  isOrderingPossible: boolean;
  openInfoModal: () => void;
  openContinueOrderingJourneyModal: () => void;
  syncTrackingId: () => Promise<void>;
  isConferenceCateringAvailable: boolean;
  isScanAndGo: boolean;
  languageCode: string;
}) => {
  const { isCartButtonValidateLabelEnabled } = orderConfig();
  const actions = [];
  const isFreeOfCharge = orderDraft?.total === 0;
  const isLocationAndTimeValid =
    (cart?.pickupInformation?.pickupSpotId && cart?.pickupInformation?.pickupTimeSlotId) ||
    isScanAndGo;

  const isEmailValid =
    cart?.pickupInformation?.email && checkIsValidEmail(cart?.pickupInformation?.email);
  const isEmailForGuestProvided =
    (!!cart?.pickupInformation?.email?.length && isEmailValid) || !(isGuest && isGuestOrdering);

  const hideSubmitActions = isGuest && !isGuestOrdering;

  if (!!cart && getCartNbItems(cart) > 0 && !hideSubmitActions) {
    if (isFreeOfCharge) {
      actions.push({
        key: 'free',
        children: label('confirm'),
        action: () =>
          isOrderingPossible
            ? checkCartAndPay({ paymentMethod: PaymentModality.FreeOfCharge })
            : openInfoModal(),
        disabled:
          isLoading ||
          (mustAcknowledgeTermsOfSale && !isTermsOfSalesAcknowledged) ||
          !isEmailForGuestProvided ||
          !isLocationAndTimeValid,
      });
    } else {
      if (canPayOnline(paymentSetupOptions) && paymentMethod === PaymentModality.PayNow) {
        const cartInfo = getCartInfo(cart, orderDraft);
        const cartTotalWithDiscount = getCartDiscountTotal(cartInfo);
        const cartTotalWithoutDiscount = getCartOriginalTotal(cartInfo);

        actions.push({
          key: 'payNow',
          children: isLoading ? <span className={styles.loading}></span> : label('Ref: Pay now'),
          action: () => (isOrderingPossible ? checkCartAndPay({ paymentMethod }) : openInfoModal()),
          affix: isLoading ? ' ' : `${getCartNbItems(cart)}`,
          suffix: isLoading
            ? ' '
            : () => (
                <PriceWithDiscount
                  discountPrice={cartTotalWithDiscount}
                  originalPrice={cartTotalWithoutDiscount}
                  languageCode={languageCode}
                />
              ),
          disabled:
            isLoading ||
            (mustAcknowledgeTermsOfSale && !isTermsOfSalesAcknowledged) ||
            !isLocationAndTimeValid ||
            !isEmailForGuestProvided,
        });
      }

      if (
        paymentSetupOptions.paymentOnPickupSetupOption.isActive &&
        paymentMethod === PaymentModality.PayOnPickup
      ) {
        actions.push({
          key: 'payOnPickup',
          children: isLoading ? (
            <span className={styles.loading}></span>
          ) : (
            label(!isCartButtonValidateLabelEnabled ? 'Ref: Pay on pickup' : 'Ref: Validate')
          ),
          action: () => (isOrderingPossible ? checkCartAndPay({ paymentMethod }) : openInfoModal()),
          disabled:
            isLoading ||
            (mustAcknowledgeTermsOfSale && !isTermsOfSalesAcknowledged) ||
            !isLocationAndTimeValid ||
            !isEmailForGuestProvided,
        });
      }

      if (
        !paymentSetupOptions.disableContinueFlowFromKioskOnMobileSetupOption.isActive &&
        isBiteKiosk()
      ) {
        actions.push({
          key: 'kioskScanRedirectQr',
          children: label('Ref: Continue on your device'),
          look: BUTTON_LOOK.SECONDARY,
          action: async () => {
            await syncTrackingId();
            openContinueOrderingJourneyModal();
          },
          disabled: mustAcknowledgeTermsOfSale && !isTermsOfSalesAcknowledged,
        });
      }

      if (isConferenceCateringAvailable && paymentMethod === PaymentModality.ChargeToMyDepartment) {
        actions.push({
          key: 'chargeToDepartment',
          children: isLoading ? (
            <span className={styles.loading}></span>
          ) : (
            label('Ref: Charge to my department')
          ),
          action: () => (isOrderingPossible ? checkCartAndPay({ paymentMethod }) : openInfoModal()),
          disabled:
            isLoading ||
            (mustAcknowledgeTermsOfSale && !isTermsOfSalesAcknowledged) ||
            !isLocationAndTimeValid,
        });
      }
    }
  }

  if (hideSubmitActions) {
    actions.push({
      children: label('Ref: Continue'),
      action: redirectToRegister,
    });
  }

  return actions;
};

export const getUnavailableCartItemId = (cart: Cart, menu: FacilityMenu) => {
  const cartItemsUnavailable = cart.menuPortionItems?.filter((item) => {
    const menuItem = getMenuItemById(item.menuItemId, [menu]);
    return !menuItem;
  });

  return (cartItemsUnavailable || []).map((item) => item.id);
};

export const buildProductUnavailablePopup = (label: Function, setPopup: Function) => {
  return {
    message: label('Ref: Product is no more available'),
    header: label('Ref: notice'),
    onDismiss: () => setPopup(null),
    buttons: [label('Ref: ok')],
    'data-testid': 'cart-product-not-available-popup',
  };
};

export function canPayOnline(paymentSetupOptions: PaymentSetupOptions) {
  return (
    process.env.REACT_APP_USE_PAYMENT_MODULE === 'true' &&
    paymentSetupOptions?.merchantIdSetupOption?.isActive
  );
}

export const mergeCartItemsDuplicates = async ({
  cart,
  dispatch,
}: {
  cart: Cart;
  dispatch: Dispatch;
}) => {
  if (!cart?.menuPortionItems) return;
  const removedIds: string[] = [];

  for (const item of cart.menuPortionItems) {
    if (!removedIds.some((x) => x === item.id)) {
      const duplication = cart.menuPortionItems.find(
        (x) =>
          x.id !== item.id &&
          x.foodItemId === item.foodItemId &&
          x.uomId === item.uomId &&
          x.menuItemId === item.menuItemId &&
          x.modifiersDisplayText === item.modifiersDisplayText
      );

      if (duplication) {
        updateItemInCart({
          menuItem: { ...item, quantity: item.quantity + duplication.quantity },
          dispatch,
          processChange: false,
        });
        removeItemsFromCart({ menuItemsIds: [duplication.id], dispatch, processChange: false });
        removedIds.push(duplication.id);
      }
    }
  }
};

const conferenceCateringRole = 'Consumer App User - Conference Catering - Site';

export const canPlaceConferenceCateringOrder = (
  siteId: string,
  isConferenceCateringMenu?: boolean
): boolean => {
  if (isConferenceCateringMenu) {
    const { roles, registrationSiteId } = getTokenPayload();
    if (!roles) return false;

    if (Array.isArray(roles)) {
      return (
        roles.some((role: string) => role.toLowerCase() === conferenceCateringRole.toLowerCase()) &&
        registrationSiteId.toLowerCase() === siteId.toLowerCase()
      );
    } else {
      return (
        roles.toLowerCase() === conferenceCateringRole.toLowerCase() &&
        registrationSiteId.toLowerCase() === siteId.toLowerCase()
      );
    }
  }

  return false;
};

export const getCartDiscountTotal = (cartInfo?: {
  price: number;
  priceWithoutPromotionDiscounts?: number;
}) => {
  return cartInfo?.priceWithoutPromotionDiscounts && cartInfo?.priceWithoutPromotionDiscounts > 0
    ? cartInfo?.price ?? 0
    : undefined;
};

export const getCartOriginalTotal = (cartInfo?: {
  price: number;
  priceWithoutPromotionDiscounts?: number;
}) => {
  return cartInfo?.priceWithoutPromotionDiscounts && cartInfo?.priceWithoutPromotionDiscounts > 0
    ? cartInfo?.priceWithoutPromotionDiscounts
    : cartInfo?.price ?? 0;
};

export const getDefaultFulfillmentTypeId = (
  fulfillmentTypes: FulfillmentType[],
  table: string | undefined
) => {
  let defaultFulfillmentType: number;
  if (table) {
    defaultFulfillmentType =
      fulfillmentTypes.find((x) => x.type === DeliveryOptionType.DineIn)?.id ??
      fulfillmentTypes[0].id;
  } else {
    defaultFulfillmentType = fulfillmentTypes[0].id;
  }
  return defaultFulfillmentType;
};

export const isCartItemLoyaltyReward = (
  cartMenuItem: CartMenuItem,
  menuId?: number,
  loyaltySchemes?: GetLoyaltySchemesResponse[]
): boolean => {
  return loyaltySchemes && menuId && cartMenuItem
    ? loyaltySchemes.some(
        (item) =>
          item.menuIdsProvidingProductsRedeemable.some((i) => i === menuId) &&
          item.productsRedeemable.some(
            (p) => cartMenuItem.foodItemId === p.foodItemId && cartMenuItem.uomId === p.uomId
          )
      )
    : false;
};

export const isCartItemProvidingLoyaltyStamps = (
  cartMenuItem: CartMenuItem,
  menuId?: number,
  loyaltySchemes?: GetLoyaltySchemesResponse[]
): boolean => {
  return loyaltySchemes && menuId && cartMenuItem
    ? loyaltySchemes.some(
        (item) =>
          item.menuIdsProvidingProductsProvidingStamp.some((i) => i === menuId) &&
          item.productsProvidingStamp.some(
            (p) => cartMenuItem.foodItemId === p.foodItemId && cartMenuItem.uomId === p.uomId
          )
      )
    : false;
};

export const getPaymentSetupOptions = (): PaymentSetupOptions => {
  const setupOptions = MM<ApiType>().getApi('Core', 'getSetupOptions')('Retail', 'serviceType');
  const orderSetupOptions = MM<ApiType>().getApi('Core', 'getSetupOptions')(
    'Food.Order',
    'serviceType'
  );

  const merchantIdSetupOption = getCustomSetupOption('merchant', [
    ...setupOptions,
    ...orderSetupOptions,
  ]);
  const paymentOnPickupSetupOption = getCustomSetupOption('paymentOnPickup', [
    ...setupOptions,
    ...orderSetupOptions,
  ]);
  const disableContinueFlowFromKioskOnMobileSetupOption = getCustomSetupOption(
    'disableContinueFlowFromKioskOnMobile',
    setupOptions
  );

  return {
    merchantIdSetupOption,
    paymentOnPickupSetupOption,
    disableContinueFlowFromKioskOnMobileSetupOption,
  };
};
