import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { SiteIllustration } from '../../../assets/illustrations';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { LabelFunc } from '../../../context/withLang';
import { pagePaths } from '../config';
import withLang from '../context/withLang';
import useDynamicTabName from '../hooks/useDynamicTabName';

interface AccountStartPageProps extends RouteComponentProps<never> {
  children: React.ReactNode;
  goToNext: () => void;
  label: LabelFunc;
  contractId: string;
  getUserContextLocked?: boolean;
}

const AccountStartPage = ({
  label,
  goToNext,
  contractId,
  getUserContextLocked,
  history,
}: AccountStartPageProps) => {
  const checkIfOnboarded = useCallback(() => {
    if (!!contractId) history.replace(pagePaths.Home);
  }, [history, contractId]);

  useEffect(() => {
    checkIfOnboarded();
  }, [checkIfOnboarded]);

  useDynamicTabName(label('Ref: Header'));

  if (getUserContextLocked) return <LoadingPage />;

  return (
    <NoticePage
      hasBackLink={false}
      withNavBar={false}
      withTitleBar={false}
      img={<SiteIllustration height={344} width={344} />}
      title={label('Ref: Header')}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Ref: LetsGo button'),
          action: goToNext,
        },
      ]}
    />
  );
};

export default withRouter(withLang([__filename])(AccountStartPage));
