import momentjs from 'moment';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SelectOption } from '../../../../components/atoms/Select';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants';
import useToggle from '../../../../helpers/hooks/useToggle';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetMenusQuery, useGetSuggestionsQuery } from '../../api';
import { suggestionWidgetMenuCacheKey } from '../../config';
import { getSuggestionWidgetMenuDate } from '../../helpers/suggestionsWidget.helper';
import { MenuType } from '../../types/menuSelector.types';
import {
  OrderState,
  ScannableMenuItem,
  SharedState,
  StateWithOrder,
} from '../../types/orderState.types';
import { useScannerComponent } from '../ReorderWidget/useScannerComponent';

import { defaultDropdownOption, getDropdownOptions } from './SuggestionsWidget.helper';
import { useMenuBasedSuggestions } from './useMenuBasedSuggestions';
import { useSuggestionsToDisplay } from './useSuggestionsToDisplay';

import { LabelFunc } from '@/context/withLang';

export const useSuggestionsWidget = (site: ISite, label: LabelFunc) => {
  const currentLanguageCode = useSelector(
    (state: { Shared: SharedState }) => state.Shared?.language?.currentLanguageCode
  );
  const promotionalDiscounts = useSelector(
    (state: { Order: OrderState }) => state.Order.draft?.promotionalDiscountForItems
  );
  const createOrderDraft = useSelector(
    (state: StateWithOrder) => state.Order?.locks?.createOrderDraft
  );

  const [dropdownOptions, setDropdownOptions] = useState<SelectOption[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(getSuggestionWidgetMenuDate(site.id));
  const [selectedMenu, setSelectedMenu] = useState<SelectOption>(defaultDropdownOption(label));
  const [lastDisplayedIndex, setLastDisplayedIndex] = useState<number>(0);
  const [scanSelected, setScanSelected] = useState<ScannableMenuItem | undefined>();

  const {
    state: isCalendarModalOpen,
    toggleOn: openCalendarModal,
    toggleOff: closeCalendarModal,
  } = useToggle(false);

  const {
    data: menus = [],
    isLoading: isLoadingMenus,
    isError: errorFetchingMenus,
  } = useGetMenusQuery(
    {
      siteId: site.id,
      useCache: true,
      date: selectedDate,
      menuType: MenuType.Orderable,
      useErrorBoundary: false,
    },
    {
      // it's needed because ReorderWidget keeps the subscription for menu from todays date
      // which means that "keepUnusedDataFor" will never expire as long as ReorderWidget
      // is displayed on HomePage (it's displayed always)
      // it would be great to have it set on endpoint level, but it's not possible ATM
      // https://github.com/reduxjs/redux-toolkit/issues/2333#issuecomment-1129361737
      refetchOnMountOrArgChange: 30,
    }
  );

  useEffect(() => {
    if (errorFetchingMenus) return;
    setDropdownOptions(getDropdownOptions(menus, label));
  }, [menus, label, errorFetchingMenus]);

  const isLocalStorageUndefined = (storageData: string | null) => {
    return storageData === 'undefined';
  };

  useEffect(() => {
    if (errorFetchingMenus) return;
    if (menus.length > 0) {
      const menuFromCacheJSON = localStorage.getItem(`${suggestionWidgetMenuCacheKey}_${site.id}`);
      const menuFromCache =
        menuFromCacheJSON &&
        !isLocalStorageUndefined(menuFromCacheJSON) &&
        JSON.parse(menuFromCacheJSON);
      const isCachedMenuInMenus = menus.some((menu) => menu.id.toString() === menuFromCache?.value);
      setSelectedMenu(
        menuFromCacheJSON && isCachedMenuInMenus ? menuFromCache : dropdownOptions[0]
      );
      !(menuFromCacheJSON && isCachedMenuInMenus) &&
        localStorage.setItem(
          `${suggestionWidgetMenuCacheKey}_${site.id}`,
          JSON.stringify(dropdownOptions[0])
        );
    }
  }, [site.id, menus, dropdownOptions, errorFetchingMenus]);

  const {
    data: response,
    isLoading: isLoadingSuggestions,
    isError: errorFetchingSuggestions,
  } = useGetSuggestionsQuery({
    siteId: site.id,
    dateTime: `${selectedDate}T${momentjs().format(TIME_FORMAT)}`,
    useErrorBoundary: false,
  });

  const suggestionsWithAvailableMenus = useMemo(() => {
    const suggestions = response?.suggestions ?? [];
    return suggestions.filter((x) => menus.some((menu) => menu.id === x.menuId));
  }, [menus, response?.suggestions]);

  const menuBasedSuggestions = useMenuBasedSuggestions(
    selectedMenu?.value,
    suggestionsWithAvailableMenus,
    label
  );
  const suggestionsToDisplay = useSuggestionsToDisplay(menuBasedSuggestions, lastDisplayedIndex);

  const getNextThreeSuggestions = useCallback(() => {
    setLastDisplayedIndex((lastDisplayedIndex + 3) % menuBasedSuggestions.length);
  }, [menuBasedSuggestions, lastDisplayedIndex]);

  const scannerComponent = useScannerComponent({
    scanSelected,
    menus,
    menuDate: selectedDate,
    site,
    currentLanguageCode,
    createOrderDraft,
    setScanSelected,
  });

  const isDateToday = selectedDate === momentjs().format(DATE_FORMAT);

  return {
    currentLanguageCode,
    promotionalDiscounts,
    isDateToday,
    selectedDate,
    setSelectedDate,
    menus,
    selectedMenu,
    setSelectedMenu,
    isLoading: isLoadingMenus || isLoadingSuggestions,
    setLastDisplayedIndex,
    setScanSelected,
    isCalendarModalOpen,
    openCalendarModal,
    closeCalendarModal,
    dropdownOptions,
    menuBasedSuggestions,
    getNextThreeSuggestions,
    suggestionsToDisplay,
    scannerComponent,
    fetchingError: errorFetchingMenus || errorFetchingSuggestions,
  };
};
