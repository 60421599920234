import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import classNames from 'classnames';
import { get } from 'lodash';
import momentjs from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ReceptionIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import { Alert } from '../../../../components/molecules/Alert';
import Card from '../../../../components/molecules/Card/Card';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import List from '../../../../components/organisms/List/List';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { IN_APP_BROWSER_FRAMELESS } from '../../../../constants';
import { formatDate } from '../../../../helpers/misc';
import { pagePaths as corePagePaths } from '../../../Core/config';
import { useGetStayDetailsQuery, usePostCustomActionMutation } from '../../api';
import StayStatus from '../../components/StayStatus';
import { pagePaths } from '../../config';
import { useAccomodationTranslation } from '../../hooks/useAccomodationTranslation';
import { isDetailedStay } from '../../utils';

import { State } from '@/types/state.types';

const StayDetails = () => {
  const [alertFlag, setAlertFlag] = useState<boolean | string>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [loader, setLoader] = useState('');
  const { label } = useAccomodationTranslation(__filename);

  const history = useHistory();
  const { id: stayId } = useParams<{ id: string }>();
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const {
    data: stay,
    isFetching: lock,
    refetch,
  } = useGetStayDetailsQuery({
    stayId,
    preferredLanguage: currentLanguageCode,
  });

  const [postCustomAction, { isLoading }] = usePostCustomActionMutation();

  const isDetailed = isDetailedStay(stay!);

  const keyValuePairs = [
    {
      id: 'reservationNumber',
      label: label('Reservation number'),
      value: stay?.reservationNumber,
      'data-testid': 'reservation-number-item',
    },
    {
      id: 'arrivalDateLocal',
      label: label('Arrival date'),
      value: formatDate(new Date(stay?.arrivalDateLocal!), currentLanguageCode),
      'data-testid': 'arrival-date-item',
    },
    {
      id: 'departureDateLocal',
      label: label('Departure date'),
      value: formatDate(new Date(stay?.departureDateLocal!), currentLanguageCode),
      'data-testid': 'departure-date-item',
    },
    {
      id: 'room',
      label: label('Room number'),
      value: isDetailed ? stay.room : label('to be confirmed'),
      'data-testid': 'room-item',
    },
  ];

  if (isDetailed) {
    for (const key of Object.keys(stay.customProperties || {})) {
      keyValuePairs.push({
        id: key,
        label: label(key.toLowerCase()),
        value: get(stay, ['customProperties', key]),
        'data-testid': `stay-item-${key.toLowerCase()}`,
      });
    }
  }

  const alerts: Array<React.ReactNode> = [];
  const actions = !isDetailed
    ? null
    : stay.action?.map((stayAction) => {
        let handleClick = undefined;
        const constructedActionUrl = stayAction.url.replace('{stayId}', stay.id); // TODO: check scenario when {stayId} is part of URL
        const actionLabel = label(stayAction.type);

        const actionObject = {
          loading: constructedActionUrl === loader && isLoading,
        };

        const actionFunc = async () => {
          if (stayAction.isUserRedirected) {
            const start = momentjs();

            const browserRef = InAppBrowser.create(
              stayAction.url,
              '_blank',
              IN_APP_BROWSER_FRAMELESS
            );
            browserRef.on('exit')?.subscribe((_) => refetch());
            browserRef.on('message')?.subscribe((message) => {
              if (message.data.action === 'cancel' || message.data.action === 'complete') {
                browserRef.close();
              }
              const end = momentjs();
              const duration = momentjs.duration(end.diff(start));
              const seconds = duration.asSeconds();
              if (seconds >= 60) {
                setDisableButton(true);
              }
              refetch();
            });
          } else {
            setLoader(constructedActionUrl);
            const result = await postCustomAction({ url: constructedActionUrl, stayId: stay.id });
            setLoader('');

            const { error } = result as { error: { status: string } };

            if (error?.status) {
              history.push(corePagePaths.GenericErrorPage);
              return;
            }

            return history.push(pagePaths.Success);
          }
        };

        if (stayAction.msgCode) {
          //go through an alert
          handleClick = () => {
            setAlertFlag(stayAction.type);
          };
          alerts.push(
            <Alert
              isOpen={alertFlag === stayAction.type}
              onDismiss={() => setAlertFlag(false)}
              className="popup-warning"
              header={label(stayAction.type)}
              message={label(stayAction.msgCode, { replace: stayAction.msgParams })}
              data-testid={`stay-details-action-${stayAction.type}-alert`}
              buttons={
                stayAction.isActive
                  ? [
                      'Cancel',
                      {
                        text: 'Ok',
                        handler: actionFunc,
                        'data-testid': `stay-details-action-${stayAction.type}-ok`,
                      },
                    ]
                  : ['Ok']
              }
            />
          );
        } else if (!isLoading) {
          //trigger directly on button click
          handleClick = actionFunc;
        }

        return (
          <Button
            onClick={handleClick}
            key={stayAction.type}
            {...actionObject}
            data-testid={`stay-details-action-${stayAction.type}`}
            disabled={!stayAction.isActive || disableButton}
          >
            {actionLabel}
          </Button>
        );
      });

  const title = (
    <div>
      <Title
        tag={TITLE_TAG.H2}
        size={TITLE_SIZE.HEADLINES}
        className={classNames('mb-S')}
        data-testid="title"
      >
        {stay?.siteName}
      </Title>
      <StayStatus status={stay?.status!} />
    </div>
  );

  return (
    <SimpleFormPage withNavBar={false} title={label('Ref: Page title')}>
      <Container>
        <Column.Main>
          {title}
          <Card>
            {lock || !stay ? (
              <>
                <TileSkeleton
                  withoutActions
                  className={classNames('mb-M')}
                  data-testid="skeleton-placeholder1"
                />
                <TileSkeleton
                  withoutActions
                  className={classNames('mb-M')}
                  data-testid="skeleton-placeholder2"
                />
              </>
            ) : (
              <List data-testid="stay-details-list" items={keyValuePairs} />
            )}
          </Card>
          {alerts.map((alert) => alert)}
        </Column.Main>
        <Column.Complementary>
          <ReceptionIllustration />
        </Column.Complementary>
        {actions && actions.length > 0 ? <ActionsBar inMainColumn>{actions}</ActionsBar> : null}
      </Container>
    </SimpleFormPage>
  );
};

export default StayDetails;
