import React from 'react';
import { ReactReduxContext } from 'react-redux';

const withSite = (WrappedComponent) => {
  return (props) => {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return <WrappedComponent site={store.getState().Core?.context?.site} {...props} />;
        }}
      </ReactReduxContext.Consumer>
    );
  };
};

export default withSite;
