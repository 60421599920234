import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import classNames from 'classnames';

import { INPUT_TYPE } from '../../../constants';
import MM from '../../../services/ModulesManager';
import { SiteProps } from '../../../types/common.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import { LabelFunc } from '@/context/withLang';
import { getCustomSetupOption } from '@/helpers/getSetupOption';
import { SERVICE } from '@/modules/config';
import { ApiType } from '@/modules/Core/api';
import { getAccountPageConfig, pagePaths } from '@/modules/Core/config';
import { checkIfServiceAvailable } from '@/modules/services';
import { User } from '@/types/user.types';
export const hidePhoneNumber = () => {
  const setupOptions = (
    MM<ApiType>().getApi('Core', 'getSetupOptions') as ApiType['getSetupOptions']
  )('MyAccount', 'serviceType');
  return getCustomSetupOption('hidePhoneNumber', setupOptions).isActive;
};

export const canDisplayFilterPreference = (isGuest: boolean, site: SiteProps) =>
  !isGuest &&
  site?.id &&
  (checkIfServiceAvailable(SERVICE.FOOD_MENU) || checkIfServiceAvailable(SERVICE.FOOD_ORDER));

export const generateMyInfo = (
  userContext: User | undefined,
  label: LabelFunc,
  isGuest: boolean,
  siteName: string | undefined,
  updateUserContext: MutationTrigger<any>
) => {
  const info = [];
  info.push({
    id: 'FullName',
    label: label('Ref: Full name'),
    value: `${userContext?.firstName} ${userContext?.lastName}`,
    hide: isGuest,
    'data-testid': 'full-name-item',
  });

  info.push({
    id: 'Email',
    label: label('Ref: Email'),
    value: userContext?.email,
    hide: isGuest,
    valueClassName: classNames('lowercase'),
    'data-testid': 'email-item',
  });

  info.push({
    id: 'CurrentSite',
    label: label('Ref: Current site'),
    value: siteName || label('Ref: Choose site'),
    path: pagePaths['Sites'],
    'data-testid': 'surrent-site-item',
  });

  if (!hidePhoneNumber()) {
    info.push({
      id: 'Mobile',
      label: label('Mobile number'),
      value: userContext?.mobile,
      edit: {
        name: label('Mobile number'),
        inputType: INPUT_TYPE.TEL,
        autocomplete: 'tel',
        validate: (val: string) => val.match(/^[0-9+]{6,}$/),
        handleSubmit: (mobile?: string) => {
          updateUserContext({ mobile });
        },
      },
      hide: isGuest,
      'data-testid': 'mobile-number-item',
    });
  }

  return info;
};

export const getVersionInfo = (): string => {
  const env = process.env.REACT_APP_PORTAL_ENV_CODE;
  const environmentDisplayName = env?.toLowerCase() !== 'prod' ? env?.toUpperCase() : '';
  return (
    process.env.REACT_APP_APP_NAME +
    ' ' +
    environmentDisplayName +
    ' ' +
    process.env.REACT_APP_BUILD_NUMBER
  );
};

export const generateActions = (
  user: User | undefined,
  isGuest: boolean,
  label: LabelFunc,
  shouldDisplayTermsOfSalesLink: boolean,
  logoutUser: () => void,
  goToRegistration: () => void
) => {
  const { supportCTA } = getAccountPageConfig({ shouldDisplayTermsOfSalesLink });
  let actions = [];
  const onSupportButtonClick = () => {
    window.location.href = `mailto:${supportCTA.contact}`;
  };
  if (user) {
    actions.push(
      <Button data-testid="account-page-logout" key="logout_button" onClick={logoutUser}>
        {label('logout', { textTransform: 'capitalize' })}
      </Button>
    );
  }

  if (isGuest) {
    actions.push(
      <Button
        key="registerLogin_button"
        data-testid="account-page-guest-register"
        onClick={goToRegistration}
        look={BUTTON_LOOK.SECONDARY}
      >
        {label('Ref: Register or login')}
      </Button>
    );
  }

  if (supportCTA.display) {
    actions.push(
      <Button
        key="supportCTA_button"
        data-testid="account-page-contact-support"
        onClick={onSupportButtonClick}
      >
        {label('Ref: Support')}
      </Button>
    );
  }

  return actions;
};
