const labels = {
  PrivacyPolicy: 'PrivacyPolicy',
  TermsOfSale: 'TermsOfSale',
  TermsOfUse: 'TermsOfUse',
  home: 'home',
  anytime: 'anytime',
  anywhere: 'anywhere',
  Download: 'Download',
  scrollRightButton: 'scrollRightButton',
  scrollLeftButton: 'scrollLeftButton',
  calendar: 'calendar',
  'See more': 'See more',
  'See less': 'See less',
  'from (followed by a date, or date and time)': 'from (followed by a date, or date and time)',
  'to (followed by a date, or date and time)': 'to (followed by a date, or date and time)',
  'on (followed by a date)': 'on (followed by a date)',
  'at (followed by time)': 'at (followed by time)',
  'from (followed by a location)': 'from (followed by a location)',
  'to (followed by a location)': 'to (followed by a location)',
  'on (followed by a site name)': 'on (followed by a site name)',
  'from (followed by a person)': 'from (followed by a person)',
  previous: 'previous',
  next: 'next',
  success: 'success',
  failure: 'failure',
  today: 'today',
  tomorrow: 'tomorrow',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  everyday: 'everyday',
  'open all day': 'open all day',
  'opening hours': 'opening hours',
  status: 'status',
  'to be confirmed': 'to be confirmed',
  'only (as in filter only this)': 'only (as in filter only this)',
  edit: 'edit',
  submit: 'submit',
  cancel: 'cancel',
  confirm: 'confirm',
  reset: 'reset',
  Delete: 'Delete',
  select: 'select',
  switch: 'switch',
  close: 'close',
  clear: 'clear',
  remove: 'remove',
  logout: 'logout',
  go: 'go',
  'see (as in consult this page or document)': 'see (as in consult this page or document)',
  'see all (as in see all items)': 'see all (as in see all items)',
  'see more (as in see more details for this item)':
    'see more (as in see more details for this item)',
  retry: 'retry',
  'Ref: ok': 'Ref: ok',
  'yes (as in yes I accept)': 'yes (as in yes I accept)',
  'no (as in no I do not accept)': 'no (as in no I do not accept)',
  you: 'you',
  'thank you': 'thank you',
  'sorry (standalone)': 'sorry (standalone)',
  'to home (as in navigate to the homepage)': 'to home (as in navigate to the homepage)',
  map: 'map',
  list: 'list',
  'Loading...': 'Loading...',
  'Refreshing...': 'Refreshing...',
  'Please select': 'Please select',
  'Switch site': 'Switch site',
  'File size cannot exceed {max file size}': 'File size cannot exceed {max file size}',
  Filters: 'Filters',
  'Add attachment': 'Add attachment',
  Search: 'Search',
  'More (as in: see more info)': 'More (as in: see more info)',
  'You can attach a maximum of {max files number} files.':
    'You can attach a maximum of {max files number} files.',
  'There are no messages to display.': 'There are no messages to display.',
  'Your selection did not return any result.': 'Your selection did not return any result.',
  'Ref: Popup header for missing required fields in a form':
    'Ref: Popup header for missing required fields in a form',
  'Ref: Required field': 'Ref: Required field',
  email: 'email',
  phone: 'phone',
  Account: 'Account',
  Contacts: 'Contacts',
  Content: 'Content',
  Events: 'Events',
  Facilities: 'Facilities',
  Shop: 'Shop',
  Menu: 'Menu',
  Menus: 'Menus',
  Order: 'Order',
  Orders: 'Orders',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'Feedback',
  Requests: 'Requests',
  'Request Helpdesk': 'Request Helpdesk',
  Location: 'Location',
  Safety: 'Safety',
  FreeCoffee: 'FreeCoffee',
  Newsfeed: 'Newsfeed',
  MyOrders: 'MyOrders',
  LoyaltyStamps: 'LoyaltyStamps',
  Stamps: 'Stamps',
  Stays: 'Stays',
  Suppliers: 'Suppliers',
  Surveys: 'Surveys',
  notifications: 'notifications',
  cart: 'cart',
  'Ref: Reset your order': 'Ref: Reset your order',
  'Ref: My language': 'Ref: My language',
  Validate: 'Validate',
  'contact us': 'contact us',
  'Ref: Start typing': 'Ref: Start typing',
  Occupancy: 'Occupancy',
  'see less': 'see less',
  'see more': 'see more',
  contacts: 'contacts',
  Imprint: 'Imprint',
  'Ref: camera access request title': 'Ref: camera access request title',
  'Ref: camera access request body': 'Ref: camera access request body',
  or: 'or',
  'Ref: Input required': 'Ref: Input required',
  'Enter a valid email address': 'Enter a valid email address',
  'Ref: Input error E-mail already exists': 'Ref: Input error E-mail already exists',
  'Password must contain': 'Password must contain',
  'Ref: Input password error strength': 'Ref: Input password error strength',
  Password: 'Password',
  'New password': 'New password',
  'Confirm password': 'Confirm password',
  'Ref: Password does not match': 'Ref: Password does not match',
  'Ref: Old password does not match': 'Ref: Old password does not match',
  'Ref: Password validation min8Characters': 'Ref: Password validation min8Characters',
  'Ref: Password validation min1Number': 'Ref: Password validation min1Number',
  'Ref: Password validation min1Letter': 'Ref: Password validation min1Letter',
  'Ref: Password validation min1SpecialChar': 'Ref: Password validation min1SpecialChar',
  Continue: 'Continue',
  'Enter a valid phone number': 'Enter a valid phone number',
  'Phone is required': 'Phone is required',
  'Go to home': 'Go to home',
  'Something went wrong': 'Something went wrong',
  'Something went wrong, data not loaded': 'Something went wrong, data not loaded',
  'Change password': 'Change password',
  'remove one': 'remove one',
  'add one': 'add one',
  'Confirm Filters': 'Confirm Filters',
  'Reset Filters': 'Reset Filters',
  '{selectedNum} active filters': '{selectedNum} active filters',
  'Wrong input icon': 'Wrong input icon',
  'Correct input icon': 'Correct input icon',
  'Show input text icon': 'Show input text icon',
  'Hide input text icon': 'Hide input text icon',
  'Required fields info': 'Required fields info',
  'Ref: Select site on the map': 'Ref: Select site on the map',
  'Ref: Geolocation disabled': 'Ref: Geolocation disabled',
  'Ref: Reaction - LIKE': 'Ref: Reaction - LIKE',
  'Ref: Reaction - LOVE': 'Ref: Reaction - LOVE',
  'Ref: Reaction - INTERESTING': 'Ref: Reaction - INTERESTING',
  'Ref: Reaction - CONGRATE': 'Ref: Reaction - CONGRATE',
  'Ref: Share': 'Ref: Share',
  'Ref: Send by Email': 'Ref: Send by Email',
  'Ref: Copy the link': 'Ref: Copy the link',
  'Ref: Link was copied': 'Ref: Link was copied',
  'Ref: unable to copy url': 'Ref: unable to copy url',
  'Ref: External link': 'Ref: External link',
  'Ref: Step': 'Ref: Step',
  'input field': 'input field',
  'Ref: Zoom': 'Ref: Zoom',
  'Increase hours': 'Increase hours',
  'Decrease hours': 'Decrease hours',
  Hours: 'Hours',
  Minutes: 'Minutes',
  'Ref: filter label: favorites': 'Ref: filter label: favorites',
  'Ref: filter label: max calories': 'Ref: filter label: max calories',
  'Ref: filter label: dishes': 'Ref: filter label: dishes',
  'Ref: filter label: allergens': 'Ref: filter label: allergens',
  'Ref: filter label: highlight': 'Ref: filter label: highlight',
  'Ref: Highlight': 'Ref: Highlight',
  'Ref: Hide': 'Ref: Hide',
  'Ref: Vegan': 'Ref: Vegan',
  'Ref: Vegeterian': 'Ref: Vegeterian',
  'Ref: Mindful': 'Ref: Mindful',
  'Ref: Highlight text': 'Ref: Highlight text',
  'Ref: Just now': 'Ref: Just now',
  'Ref: {amountMinutes} minutes ago': 'Ref: {amountMinutes} minutes ago',
  'Ref: 1 hour ago': 'Ref: 1 hour ago',
  'Ref: {amountHours} hours ago': 'Ref: {amountHours} hours ago',
  'Ref: Yesterday at {time}': 'Ref: Yesterday at {time}',
  'Ref: {date} at {time}': 'Ref: {date} at {time}',
  'Ref: filter label: date': 'Ref: filter label: date',
  'Ref: expand menu': 'Ref: expand menu',
  'Ref: collapse menu': 'Ref: collapse menu',
  'Ref: My site': 'Ref: My site',
  'Ref: Recent': 'Ref: Recent',
  'Ref: Services': 'Ref: Services',
  'Ref: products in cart': 'Ref: products in cart',
  'Ref: no product in cart': 'Ref: no product in cart',
  'Ref: Survey': 'Ref: Survey',
  'Access to camera was denied. Please allow access and try again':
    'Access to camera was denied. Please allow access and try again',
  'Add an attachment': 'Add an attachment',
  'Take a picture': 'Take a picture',
  'Add a file from your device': 'Add a file from your device',
  'Ref: Loading page content, please wait': 'Ref: Loading page content, please wait',
  Star: 'Star',
  Stars: 'Stars',
  'Accommodation Request': 'Accommodation Request',
  'Ref: Guest Accomodation Request': 'Ref: Guest Accomodation Request',
  'Ref: Search a menu, product or facility': 'Ref: Search a menu, product or facility',
  ConnectionLost: 'ConnectionLost',
  ConnectionRestored: 'ConnectionRestored',
  'Ref: Skip to main content': 'Ref: Skip to main content',
  'Ref: No options': 'Ref: No options',
  'Ref: Global search modal': 'Ref: Global search modal',
  'Ref: Search a facility': 'Ref: Search a facility',
  'Ref: Search a menu or product': 'Ref: Search a menu or product',
  'Ref: Reaction': 'Ref: Reaction',
  'Ref: times': 'Ref: times',
  'Ref: reaction. Change reaction': 'Ref: reaction. Change reaction',
  'Ref: No reaction. React': 'Ref: No reaction. React',
  'Ref: LIKE': 'Ref: LIKE',
  'Ref: LOVE': 'Ref: LOVE',
  'Ref: INTERESTING': 'Ref: INTERESTING',
  'Ref: CONGRATE': 'Ref: CONGRATE',
  'Ref: Open search': 'Ref: Open search',
  'Ref: Close search': 'Ref: Close search',
  'Ref: Total file size msg': 'Ref: Total file size msg',
  'Ref: Oops Title': 'Ref: Oops Title',
  'Ref: Well Done': 'Ref: Well Done',
  'Ref: Navigation arrow - Continue': 'Ref: Navigation arrow - Continue',
  'Ref: Reload': 'Ref: Reload',
  'Ref: All Dates': 'Ref: All Dates',
  'Ref: Today': 'Ref: Today',
  'Ref: Validate': 'Ref: Validate',
  'Ref: toast error title': 'Ref: toast error title',
  'Ref: toast error subtitle': 'Ref: toast error subtitle',
  'Ref: Calendar': 'Ref: Calendar',
  'Ref: Selected': 'Ref: Selected',
  'Ref: Previous Month': 'Ref: Previous Month',
  'Ref: Next Month': 'Ref: Next Month',
  'This module has been retired': 'This module has been retired',
};
export default labels;
