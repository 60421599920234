import * as H from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { State } from '../../../types/state.types';
import { getIdpAccessToken } from '../actions';
import IdpLoginPage from '../components/IdpLoginPage';

const mapStateToProps = (state: State, ownProps: { history: H.History<unknown> }) => {
  const {
    language: { currentLanguageCode },
  } = state.Shared;

  const { history } = ownProps;

  return {
    currentLanguageCode,
    history,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getIdpAccessToken: (
      username: string,
      geocode: string,
      type: string,
      currentLanguageCode: string
    ) => dispatch(getIdpAccessToken({ username, geocode, type, currentLanguageCode })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdpLoginPage));
