import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';

import { reactPlugin } from '../../../appInsights';
import { breakPointsInPixels } from '../../../config';
import { APP_NAME } from '../../../constants';
import useMsTeamsContext from '../../../helpers/hooks/useMsTeamsContext/useMsTeamsContext';
import { useLanguageUpdateListener } from '../../../localization/hooks/useLanguageUpdateListener';
import { State } from '../../../types/state.types';
import useKioskSessionNotification from '../../Order/hooks/useKioskSessionNotification/useKioskSessionNotification';
import { useGetGeographiesQuery } from '../api/geolocations/geolocationsApi';
import NotificationsList from '../components/NotificationsList';
import { pagePaths, getNotificationInitInfo } from '../config';
import AccountValidation from '../containers/AccountValidation';
import ErrorBoundary from '../containers/ErrorBoundary';
import GuestRegistrationForm from '../containers/GuestRegistrationForm';
import GuestRegistrationSuccess from '../containers/GuestRegistrationSuccess';
import IdpLoginPage from '../containers/IdpLoginPage';
import PasswordChange from '../containers/PasswordChange';
import { applyTheme } from '../helpers/helpers';
import { initNotifications } from '../helpers/notificationsHelper';
import useTrackSiteVisit from '../helpers/trackSiteVisit.helper';
import useNetworkStatusListener from '../helpers/useNetworkStatusListener';
import useAppListener from '../helpers/useUrlListener';
import useErrorRedirection from '../hooks/ErrorManagement/useErrorRedirection';
import useCheckAppVersion from '../hooks/useCheckAppUpdate';
import useDataTracking from '../hooks/useDataTracking';
import useMetaViewport from '../hooks/useMetaViewport';
import { AppProps } from '../types/App.types';

import DeletionFailure from './Account/DeletionFailure/DeletionFailure.container';
import AppUpdatePage from './AppUpdatePage/AppUpdatePage.container';
import Child from './Child';
import ExternalContentPage from './ExternalContentPage/ExternalContentPage';
import GenericErrorPage from './GenericErrorPage/GenericErrorPage';
import GenericFailurePage from './GenericFailurePage';
import LegalDocsPage from './LegalDocsPage';
import LoginPage from './LoginPage';
import LogoutButton from './LogoutButton';
import ModuleRedirectPage from './ModuleRedirectPage';
import { AccountInformationContainer } from './Onboarding/AccountInformation';
import OnboardingTunnel from './Onboarding/OnboardingTunnel.container';
import PrivateRoute from './PrivateRoute';
import PublicLegalDocPage from './PublicLegalDocPage/index';
import PasswordForgotten from './ResetForgottenPassword/PasswordForgotten/PasswordForgotten.container';
import ResetEmailSent from './ResetForgottenPassword/ResetEmailSent/ResetEmailSent.container';
import ResetPassword from './ResetForgottenPassword/ResetPassword/ResetPassword.container';
import ResetPasswordFailure from './ResetForgottenPassword/ResetPassword/ResetPasswordFailure';
import ResetPasswordSuccess from './ResetForgottenPassword/ResetPassword/ResetPasswordSuccess';
import ServicesMenuPage from './ServicesMenuPage';
import SharePage from './SharePage';
import { TroubleLoggingInPageContainer as TroubleLoggingInPage } from './TroubleLoggingIn';
import TroubleLoggingInFormSuccessPage from './TroubleLoggingIn/TroubleLoggingInFormSuccessPage';
import WelcomePage from './Welcome/WelcomePage';
import WorkplaceLogin from './WorkplaceLogin/WorkplaceLogin';

import TooltipWrapper from '@/components/molecules/TooltipWrapper';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import { SidebarContextProvider } from '@/components/templates/SideBarWrapper/useSidebarContext';
import { startBackgroundCheckForLegalDocsChange } from '@/helpers/checkLegalDocs';
import { endBackgroundCheckForLegalDocsChange } from '@/helpers/checkLegalDocsScheduler';
import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';
import { getAppName, isKiosk } from '@/helpers/misc';
import { useAuditSynchronisation } from '@/modules/Audits/hooks/useAuditSynchronisation';
import QrLandingPage from '@/modules/ServiceRequest/components/QrLandingPage/QrLandingPage';

import '../cssImports';

import '../../../helpers/toast.css';
import 'flag-icons/css/flag-icons.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// Params are placeholders in the URL that begin
// with a colon, like the `:id` param defined in
// the route in this example. A similar convention
// is used for matching dynamic segments in other
// popular web frameworks like Rails and Express.

const App: FC<AppProps> = (props) => {
  const {
    services,
    themeSettings,
    registerPushNotification,
    contactId,
    errors,
    contract,
    site,
    label,
  } = props;
  const { shouldRegisterAppInNotificationHub } = getNotificationInitInfo();
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState<boolean>(
    window.matchMedia('(max-width: ' + breakPointsInPixels['M'] + 'px)').matches
  );
  const [isNavigationVisible, setIsNavigationVisible] = useState<boolean>(false);
  const { list: geographies } = useSelector((state: State) => state.Shared.geographies);
  const { isFetching: isGeographiesLoading } = useGetGeographiesQuery(undefined, {
    skip: geographies?.length > 0,
  });

  useTrackPageView({ isGlobal: true });
  useNetworkStatusListener(label);
  useAppListener();
  useLanguageUpdateListener();

  useErrorRedirection(errors);
  useDataTracking();
  useTrackSiteVisit(props);
  useMetaViewport();
  //tech debt. todo: apply only when needed and reset to default when no settings
  if (themeSettings) applyTheme(themeSettings);
  useCheckAppVersion({ site, contract });
  useKioskSessionNotification();
  useAuditSynchronisation(label, contactId);
  useMsTeamsContext();

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    if (!isKiosk) {
      if (contactId) {
        startBackgroundCheckForLegalDocsChange();
      } else {
        endBackgroundCheckForLegalDocsChange();
      }
    }
  }, [contactId]);

  const registerPustNotification = useCallback(async () => {
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform !== 'web' && shouldRegisterAppInNotificationHub) {
      initNotifications(registerPushNotification, contactId);
    }
  }, [contactId, registerPushNotification, shouldRegisterAppInNotificationHub]);

  useEffect(() => {
    contactId && registerPustNotification();
  }, [contactId, registerPustNotification]);

  const startScreenPath = getAppName() === APP_NAME.BITEKIOSK ? '/welcome' : '/home';

  const routes = (
    <Switch>
      <Route path={pagePaths['AppUpdate']} children={<AppUpdatePage />} />
      <Route path={pagePaths['ExternalContent']} children={<ExternalContentPage />} />
      <Route
        path={pagePaths['Login']}
        children={
          <>
            <TooltipWrapper />
            <LoginPage />
          </>
        }
      />
      <Route path={pagePaths['Idp']} children={<IdpLoginPage />} />
      <Route path={pagePaths['ResetPasswordSuccess']} children={<ResetPasswordSuccess />} />
      <Route path={pagePaths['ResetPasswordFailure']} children={<ResetPasswordFailure />} />
      <Route path={pagePaths['ResetPassword']} children={<ResetPassword />} />
      <Route path={pagePaths['ResetEmailSent']} children={<ResetEmailSent />} />
      <Route path={pagePaths['PasswordForgotten']} children={<PasswordForgotten />} />
      <Route path={pagePaths['PasswordChange']} children={<PasswordChange />} />
      <Route path={pagePaths['Validation']} children={<AccountValidation />} />
      <Route path={pagePaths['TroubleLoggingIn']} children={<TroubleLoggingInPage />} />
      <Route
        path={pagePaths['TroubleLoggingInFormSuccessPage']}
        children={<TroubleLoggingInFormSuccessPage />}
      />
      <Route path={pagePaths['GenericFailurePage']} children={<GenericFailurePage />} />
      <Route path={pagePaths['GenericErrorPage']} children={<GenericErrorPage errors={errors} />} />
      <Route path="/access/legal" children={<LegalDocsPage />} />
      <Route path={pagePaths['PublicLegalDoc']} children={<PublicLegalDocPage />} />
      <Route path="/services" children={<ServicesMenuPage services={services} />} />
      <PrivateRoute path={pagePaths['Notifications']} children={<NotificationsList />} />
      <Route path={pagePaths['Logout']} children={<LogoutButton />} />
      <Route path={pagePaths['AccountDeletionFailure']} children={<DeletionFailure />} />
      <Route path={pagePaths['Onboarding']} children={<OnboardingTunnel />} />
      <Route
        path={pagePaths['LetsGo']}
        children={
          <>
            <TooltipWrapper />
            <AccountInformationContainer />
          </>
        }
      />
      <Route path={pagePaths['Share']} children={<SharePage />} />
      <Route path={pagePaths.ModuleRedirect} children={<ModuleRedirectPage />} />
      <Route path={'/service_request/qr/:siteId/:locationId'} children={<QrLandingPage />} />
      <Route path={'/workplace/auth'} children={<WorkplaceLogin />} />
      <PrivateRoute path={pagePaths['Welcome']} children={<WelcomePage />} />
      <PrivateRoute path={pagePaths.GuestRegistration} children={<GuestRegistrationForm />} />
      <PrivateRoute
        path={pagePaths.GuestRegistrationSuccess}
        children={<GuestRegistrationSuccess />}
      />
      <PrivateRoute
        path="/:serviceRoot"
        children={
          <>
            <TooltipWrapper />
            <Child services={services} />
          </>
        }
      />
      {/* redirect to home when at root, to apply all checks */}
      <Route path="/" render={() => <Redirect to={{ pathname: startScreenPath }} />} />
    </Switch>
  );

  let content;
  if (isGeographiesLoading) content = <LoadingPage />;
  else if (process.env.PUBLIC_URL)
    content = <BrowserRouter basename={process.env.PUBLIC_URL}>{routes}</BrowserRouter>;
  else content = routes;

  return (
    <ErrorBoundary appInsights={reactPlugin}>
      <SidebarContextProvider
        value={{
          isSideBarCollapsed,
          setIsSideBarCollapsed,
          isNavigationVisible,
          setIsNavigationVisible,
        }}
      >
        {content}
      </SidebarContextProvider>
    </ErrorBoundary>
  );
};

export default React.memo(App, (props, nextProps) => isEqual(props, nextProps));
