import { LabelFunc } from '../../../context/withLang';
import { isSmallViewport } from '../../../helpers/windowSize';
import Dropdown from '../../molecules/Dropdown';
import MultiCardSelect from '../MultiCardSelect/MultiCardSelect';
import Range from '../Range/Range';

import CalendarFilter from './CalendarFilter';
import CheckboxGroupFilter from './CheckboxGroupFilter';
import { DefaultFilter } from './DefaultFilter';
import { ExpandedFilter } from './ExpandedFilter';
import {
  Filter,
  Filtering,
  FilterOption,
  FilterPosition,
  FilterType,
  FormattedFilters,
} from './Filters.types';
import FilterWrapper from './FilterWrapper';
import SingleCheckboxFilter from './SingleCheckboxFilter';
import { TabFilter } from './TabFilter';

export const getFilterApplicableOptions = (filter: Filter, filtering: Filtering) =>
  filter.getApplicableOptions
    ? filter.getApplicableOptions(filtering, filter.options || [])
    : filter.options || [];

export const getFilterValue = (filter: Filter, filtering: Filtering) => {
  const { id } = filter;
  const { [id]: value = {} } = filtering;
  return Object.keys(value)[0];
};

export const getFiltersValue = (filter: Filter, filtering: Filtering) => {
  const { id } = filter;
  const { [id]: value = {} } = filtering;

  return Object.entries(value)
    .filter(([, value]) => !!value)
    .map(([key]) => key);
};

export const isFilterInModal = ({
  position = FilterPosition.NOT_IN_MODAL,
  isSmallScreen,
}: {
  position?: string;
  isSmallScreen: boolean;
}) =>
  position === FilterPosition.ALWAYS_IN_MODAL ||
  (position === FilterPosition.MODAL_ON_MOBILE && isSmallScreen);

export const isFilterVisible = ({
  position = FilterPosition.NOT_IN_MODAL,
  isSmallScreen,
}: {
  position?: string;
  isSmallScreen: boolean;
}) =>
  position === FilterPosition.NOT_IN_MODAL ||
  (position === FilterPosition.MODAL_ON_MOBILE && !isSmallScreen);

export const getFilterComponentFromFilterConfig = ({
  filter,
  filtering,
  label,
  languageCode,
  id,
  applicableOptions,
  isInModal,
  handleChange,
  handleCalendarChange,
  testId,
  sectionRefs,
  activeSectionIndex
}: {
  filter: Filter;
  filtering: Filtering;
  label: (text: string) => string;
  languageCode: string;
  id: string;
  applicableOptions: FilterOption[];
  isInModal: boolean;
  handleChange: (name: string, value: string, checked?: boolean) => void;
  handleCalendarChange?: (filterId: string, strDate: string) => void;
  testId: string;
  sectionRefs?: React.MutableRefObject<React.RefObject<HTMLSpanElement>[]>;
  activeSectionIndex?: number;
}) => {
  switch (filter.displayType) {
    case FilterType.EXPANDED:
      return (
        <ExpandedFilter
          filter={filter}
          filtering={filtering}
          onChange={handleChange}
          data-testid={testId}
          label={label}
          sectionRefs={sectionRefs}
          activeSectionIndex={activeSectionIndex}
        />
      );
      case FilterType.TAB:
        return (
          <TabFilter
            filter={filter}
            filtering={filtering}
            onChange={handleChange}
            data-testid={testId}
          />
        );
    case FilterType.CALENDAR: //must have an option with the value "calendar", and may have other options
      if (!handleCalendarChange) return null;
      return (
        <CalendarFilter
          filter={filter}
          filtering={filtering}
          label={label}
          onChange={handleCalendarChange}
          languageCode={languageCode}
          data-testid={`${testId}-filter-${filter.id}`}
        />
      );
    case FilterType.RANGE:
      const rangeValue = getFilterValue(filter, filtering);
      return (
        <Range
          min={filter.min}
          max={filter.max}
          customMaxLabel={filter.customMaxLabel}
          customMinLabel={filter.customMinLabel}
          showLabel={false}
          value={rangeValue ? Number(rangeValue) : filter.defaultValue}
          // TODO Fix / Dual Range values are not allowed here. Only number
          onChange={(value) => handleChange(id, value.toString())}
          aria-label={filter.name}
          data-testid={`${testId}-filter-${filter.id}`}
        />
      );
    case FilterType.MULTIPLE_SELECT:
      const multiSelectValues = getFiltersValue(filter, filtering);
      return (
        <MultiCardSelect
          isMultiple={filter.isMultipleSelect}
          columns={filter.columns}
          columnWidth={filter.columnWidth}
          options={filter.options}
          label={label}
          name={filter.name}
          onChange={({ value, isSelected }) => handleChange(id, value, isSelected)}
          values={multiSelectValues}
          labelSeeLess={filter.seeLessLabel}
          labelSeeMore={filter.seeMoreLabel}
          preventUnselect={filter.preventUnselect}
          data-testid={`${testId}-filter-${filter.id}`}
        />
      );
    case FilterType.CHECKBOX_SINGLE:
      const singleCheckboxValues = getFiltersValue(filter, filtering);

      return (
        <SingleCheckboxFilter
          id={id}
          value={applicableOptions[0].value}
          checked={singleCheckboxValues.length > 0}
          label={filter.name as string}
          onClick={(_, { value, checked }) => handleChange(id, value as string, !!checked)}
          data-testid={`${testId}-filter-${filter.id}`}
        />
      );
    case FilterType.CHECKBOX_GROUP:
      const checkboxGroupValues = getFiltersValue(filter, filtering);

      return (
        <CheckboxGroupFilter
          values={checkboxGroupValues}
          label={label}
          options={applicableOptions}
          data-testid={`${testId}-filter-${filter.id}`}
          onChange={(value: string, checked: boolean) => handleChange(id, value, checked)}
        />
      );
    case FilterType.DROPDOWN:
      const [dropdownValue] = getFiltersValue(filter, filtering);

      return (
        <Dropdown
          labelText={filter.name}
          value={dropdownValue}
          id={`dropdown-filter-${filter.id}`}
          options={applicableOptions}
          onChange={({ value }) => handleChange(id, value)}
          data-testid={`${testId}-filter-${filter.id}`}
          buttonLook={filter.look}
        />
      );

    default:
      //includes: condensed
      //todo: multiple selection not supported yet
      return (
        <DefaultFilter
          onChange={handleChange}
          applicableOptions={applicableOptions}
          id={id}
          filtering={filtering}
          useLegacySelect
          label={label}
          isInModal={isInModal}
          data-testid={`${testId}-filter-${filter.id}`}
        />
      );
  }
};

export const formatLabelValue = (filter: Filter, value: string) => {
  if (filter.displayType === FilterType.RANGE) {
    const formattedValue = value || filter.defaultValue;
    return `${formattedValue} ${filter?.unit}`;
  }
  return value;
};

export const getExpandedView = ({
  languageCode,
  filters,
  filtering,
  label,
  handleChange,
  handleCalendarChange,
  testId,
}: {
  languageCode: string;
  filters: Filter[];
  filtering: Filtering;
  label: LabelFunc;
  handleChange: (name: string, value: string, checked?: boolean) => void;
  handleCalendarChange: (filterId: string, strDate: string) => void;
  testId: string;
}): FormattedFilters => {
  const filtersWithoutOptions = [FilterType.RANGE, undefined];
  const filtersWithValueInLabel = [FilterType.RANGE];
  const isSmallScreen = isSmallViewport();

  return filters.reduce<FormattedFilters>(
    (obj: FormattedFilters, filter: Filter): FormattedFilters => {
      const { id, name, displayType, position, text } = filter;

      const isInModal = isFilterInModal({ position, isSmallScreen });
      const applicableOptions = getFilterApplicableOptions(filter, filtering);
      const filterHasApplicableOptions = applicableOptions?.length > 0;
      const isFilterWithoutOptions = filtersWithoutOptions.includes(displayType);

      //if no options available, stop there
      if (!filterHasApplicableOptions && !isFilterWithoutOptions) return obj;

      const displayedFilter = getFilterComponentFromFilterConfig({
        filter,
        filtering,
        label,
        languageCode,
        id,
        applicableOptions,
        isInModal,
        handleChange,
        handleCalendarChange,
        testId,
      });

      const formattedValue = formatLabelValue(filter, Object.keys(filtering[filter.id] || {})[0]);
      const view = (
        <FilterWrapper
          isInModal={isInModal}
          name={name}
          key={filter.id}
          withLabelValue={displayType && filtersWithValueInLabel.includes(displayType)}
          value={formattedValue}
          text={text}
          data-testid={`${testId}-filter-${filter.id}`}
        >
          {displayedFilter}
        </FilterWrapper>
      );

      if (position === FilterPosition.INVISIBLE) {
        obj.invisible.push(view);
        obj.invisibleFilters.push(filter);
      } else if (!isInModal) {
        obj.visible.push(view);
        obj.visibleFilters.push(filter);
      } else {
        obj.modal.push(view);
        obj.modalFilters.push(filter);
      }
      return obj;
    },
    {
      visible: [],
      visibleFilters: [],
      invisible: [],
      invisibleFilters: [],
      modal: [],
      modalFilters: [],
    }
  );
};

export function getSelectedOptionsNum(
  filters: Filter[],
  isSmallScreen: boolean,
  filtering: Filtering
) {
  return filters.reduce((count, filter) => {
    const isInModal = isFilterInModal({ position: filter.position, isSmallScreen });
    if (!isInModal) {
      return count;
    }

    const applicableOptions = getFilterApplicableOptions(filter, filtering);

    if (filter.displayType === FilterType.RANGE) {
      const rangeValue = Number(Object.keys(filtering[filter.id] || {})[0]) || filter.defaultValue;
      const rangeFilterIsNotDefaultValue = rangeValue !== filter.defaultValue;

      if (rangeFilterIsNotDefaultValue) {
        count++;
      }
      return count;
    }
    for (const filterOption of applicableOptions) {
      if (
        filtering[filter.id]?.[filterOption.value] &&
        !filterOption.default &&
        !filter.excludeCount
      ) {
        count++;
      }
    }
    return count;
  }, 0);
}

export function hasFilterInModal(filters: Filter[], isSmallScreen: boolean) {
  return filters?.some((filter) => isFilterInModal({ position: filter.position, isSmallScreen }));
}
export function hasFilterVisible(filters: Filter[], isSmallScreen: boolean) {
  return filters?.some((filter) => isFilterVisible({ position: filter.position, isSmallScreen }));
}

export const getFilteringStateAfterReset = (filters: Filter[]) => {
  const newFilteringState: Filtering = {};
  const isSmallScreen = isSmallViewport();

  filters.forEach((filter: Filter) => {
    if (isFilterInModal({ position: filter.position, isSmallScreen })) {
      if ('defaultValue' in filter) {
        newFilteringState[filter.id] = { [filter.defaultValue]: true };
      } else if ('options' in filter) {
        const defaultOption = filter.options?.find((option) => option.default);
        if (defaultOption) {
          newFilteringState[filter.id] = { [defaultOption.value]: true };
        } else if ('preventUnselect' in filter) {
          newFilteringState[filter.id] = { [filter.options[0].value]: true };
        } else {
          newFilteringState[filter.id] = {};
        }
      }
    }
  });

  return newFilteringState;
};
