import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { FC, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router';

import { DownloadIcon } from '../../../../assets/icons';
import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import { HtmlContent } from '../../../../components/atoms/RenderContent';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { SIZE, LOCAL_STORAGE_KEYS } from '../../../../constants';
import useGeoCode from '../../../../helpers/hooks/useGeoCode';
import { saveHtmlAsPdf } from '../../../../helpers/misc';
import { State } from '../../../../types/state.types';
import { getPublicLegalDocument, setGeography } from '../../actions';
import { legalDocumentsConfig, legalDocType } from '../../config';
import withLang from '../../context/withLang';
import { getLegalDocLabel } from '../../helpers/helpers';
import GeographySelector from '../GeographySelector/GeographySelector';

import {
  PublicLegalDocPageProps,
  publicLegalDocTypeNamesToCodeMap,
} from './PublicLegalDocPage.types';

import { useLazyGetGeographiesQuery } from '@/modules/Core/api/geolocations/geolocationsApi';

import styles from './PublicLegalDocPage.module.css';

const PublicLegalDocPage: FC<PublicLegalDocPageProps> = ({ label }) => {
  const {
    params: { document_type: type },
  } = useRouteMatch<any>();

  const {
    legalDocs: { detailed: documents },
  } = useSelector((state: State) => state.Core);
  const {
    geographies: { list: geographies },
  } = useSelector((state: State) => state.Shared);
  const dispatch = useDispatch();
  const [getGeographies] = useLazyGetGeographiesQuery();
  const [hasValidGeography, setHasValidGeography] = useState<boolean | null>(null);

  const legalDocument = documents[publicLegalDocTypeNamesToCodeMap[type] || ''];
  const documentId = publicLegalDocTypeNamesToCodeMap[type].toString() || '';
  const { shouldDisplayDownloadButton } = legalDocumentsConfig();

  const { currentGeoCode, defaultGeoCode } = useGeoCode();
  const currentRegionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE);

  const showGeographies =
    type === legalDocType.privacy_policy && (!currentGeoCode || !currentRegionCode);

  useEffect(
    function loadGeographies() {
      if (!geographies || geographies?.length === 0) {
        getGeographies();
      }
    },
    [geographies, getGeographies]
  );

  useEffect(
    function setCurrentGeography() {
      if (!geographies || geographies.length < 1) {
        return;
      }

      const isInList = !!geographies.find(({ code }) => code === currentGeoCode);
      if (isInList) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, currentGeoCode);
      }
      setHasValidGeography(isInList);
    },
    [currentGeoCode, geographies]
  );

  useEffect(() => {
    if (!legalDocument && !showGeographies) {
      dispatch(getPublicLegalDocument({ id: documentId, geoCode: currentGeoCode }));

      if (defaultGeoCode) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, defaultGeoCode);
      }
      // if ion-modal is opened on page load without user interaction. it creates 2 modals ad screen has a backdrop
      // force remove them with JS
      document.querySelectorAll('#geography_modal').forEach((modalElement) => {
        modalElement.remove();
      });
    }
  }, [documentId, currentGeoCode, legalDocument, dispatch, showGeographies, defaultGeoCode]);

  const handleGeographyChange = useCallback(
    (code: string) => {
      if (code) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, code);
        dispatch(setGeography({ geoCode: code }));
      }
    },
    [dispatch]
  );

  if ((!showGeographies && !legalDocument) || hasValidGeography === null) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      withNavBar={false}
      hasBackLink={false}
      hideAllWidgets={true}
      title={getLegalDocLabel(type, label)}
    >
      <Container>
        <Column.Main>
          {showGeographies ? (
            <div className={classNames(styles.geographiesWrapper)}>
              <Card className={classNames(styles.card)}>
                <span>{label('Ref: To view Privacy Policy please select your country.')}</span>
              </Card>
              <Card className={classNames(styles.card)}>
                <GeographySelector
                  geographies={geographies}
                  label={label}
                  handleGeographySubmit={handleGeographyChange}
                  defaultOpenModal={true}
                />
              </Card>
            </div>
          ) : (
            <Card
              overTitle={{
                tag: TITLE_TAG.H1,
                size: TITLE_SIZE.HEADLINES,
                children: getLegalDocLabel(type, label),
                className: styles.legalTitle,
                ...(shouldDisplayDownloadButton
                  ? {
                      suffix: (
                        <Button
                          look="tertiary"
                          onClick={() => saveHtmlAsPdf(Base64.decode(legalDocument))}
                          size={SIZE.SMALL}
                          inheritStyle={styles.titleSuffix}
                          data-testid="legal-docs-download"
                        >
                          <DownloadIcon className={classNames(styles.titleIcon)} />
                        </Button>
                      ),
                    }
                  : {}),
              }}
            >
              <div
                id="legalDocumentContainer"
                data-cy="legal-document-container"
                className={classNames(styles.wrapper)}
              >
                <HtmlContent data-testid="public-legal-doc-page-html-content">
                  {Base64.decode(legalDocument)}
                </HtmlContent>
              </div>
            </Card>
          )}
        </Column.Main>
        <Column.Complementary>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default withRouter(withLang([__filename])(PublicLegalDocPage));
