import { DaySection } from '../../../constants';
import { LabelFunc } from '../../../context/withLang';

export const getWelcomeData = (
  label: LabelFunc
): { welcomeMessage: string; daySection: DaySection } => {
  let welcomeMessage = '';
  let daySection: DaySection;

  const hours = new Date().getHours();
  switch (true) {
    case hours < 6:
      welcomeMessage = label('Ref: Good evening after midnight');
      daySection = 'GOOD_EVENING_AFTER_MIDNIGHT';
      break;
    case hours < 12:
      welcomeMessage = label('Ref: Good morning');
      daySection = 'GOOD_MORNING';
      break;
    case hours < 18:
      welcomeMessage = label('Ref: Good afternoon');
      daySection = 'GOOD_AFTERNOON';
      break;
    default:
      welcomeMessage = label('Ref: Good evening before midnight');
      daySection = 'GOOD_EVENING_BEFORE_MIDNIGHT';
      break;
  }

  return { welcomeMessage, daySection };
};
