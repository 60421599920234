import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import withLang from '../context/withLang';
import { IUrlLocation } from '../types/guestRegistrationForm.types';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const GuestRegistrationSuccess = ({ history, label }: any) => {
  const location: IUrlLocation = useLocation();
  const { state } = location;

  return (
    <NoticePage
      withNavBar={false}
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('Ref: Header', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Ref: Enter'),
          action: () => {
            history.push(state?.from.pathname || '/');
          },
        },
      ]}
    />
  );
};

export default withRouter(withLang([__filename])(GuestRegistrationSuccess));
