import { connect } from 'react-redux';

import { logout } from '../actions';
import ErrorBoundary from '../components/ErrorBoundary';

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
