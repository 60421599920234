import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ThankYouLocalIllustration } from '../../../../assets/illustrations';
import { BUTTON_LOOK } from '../../../../components/atoms/Button';
import LanguageTileList from '../../../../components/organisms/LanguageTileList/LanguageTileList';
import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { getCurrentLanguageCode } from '../../../../helpers/misc';
import { pagePaths } from '../../../Order/config';
import { applyLanguage } from '../../actions';
import { useUpdateUserContextMutation } from '../../api/account/userContextApi';
import withLang from '../../context/withLang';
import useSite from '../../hooks/useSite';

import { WithLangProps } from '@/context/withLang';


function WelcomePage({ label }: WithLangProps) {
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const dispatch = useDispatch();
  const [updateUserContext, { isLoading }] = useUpdateUserContextMutation();
  const history = useHistory();
  const [selectedLanguage, setSelectedLanguage] = useState<{
    code: string;
    backLanguageId?: string;
  }>({ code: getCurrentLanguageCode() });

  const beginHandler = async () => {
    await updateUserContext({ preferredLanguageCode: selectedLanguage.code });
    dispatch(applyLanguage({ code: selectedLanguage.code, isUserSelected: true }));

    history.push(pagePaths.Module);
  };
  return (
    <NoticePage
      withNavBar={false}
      withTitleBar={false}
      pageTitle={null}
      img={<ThankYouLocalIllustration />}
      title={label('Ref: Welcome to', {
        replace: { site_name: site.name },
      })}
      children={
        <LanguageTileList setLanguage={setSelectedLanguage} language={selectedLanguage.code} />
      }
      actions={[
        {
          label: label('Ref: Begin'),
          action: beginHandler,
          look: BUTTON_LOOK.PRIMARY,
          loading: isLoading,
        },
      ]}
    />
  );
}

export default withLang([__filename])(WelcomePage);
