import { DeviceInfo } from '@capacitor/device';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from '../../../types/state.types';
import { registerPushNotification } from '../actions';
import App from '../components/App';
import withLang from '../context/withLang';

const mapStateToProps = (state: State) => {
  const {
    context,
    services,
    user: { contactId = '', contract },
    locks,
  } = state.Core;

  const { language } = state.Shared;

  return {
    site: context.site,
    contactId,
    contract,
    services: services.list,
    themeSettings: context.theme?.settings,
    locks: { tokenRefresh: locks.tokenRefresh, getUserContext: locks.getUserContext },
    language: language.currentLanguageCode,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    registerPushNotification: ({
      notificationToken,
      deviceInfo,
      deviceUniqId,
      contactId,
    }: {
      notificationToken: string;
      deviceInfo: DeviceInfo;
      deviceUniqId: string;
      contactId: string;
    }) =>
      dispatch(
        registerPushNotification({
          contactId,
          notificationToken,
          deviceInfo,
          deviceUniqId,
        })
      ),
  };
};

export default withLang([__filename])(connect(mapStateToProps, mapDispatchToProps)(App));
