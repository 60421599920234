import classNames from 'classnames';
import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../components/atoms/Input';
import Select from '../../../components/atoms/Select';
import Textarea from '../../../components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import List from '../../../components/organisms/List/List';
import { CONTROL_TYPE } from '../../../components/organisms/List/List.types';
import { INPUT_TYPE } from '../../../constants';
import { formatTime, toLocalDate } from '../../../helpers/misc';
import {
  getPickupLocations,
  getPickupTimeSlots,
  setFulfillmentType,
  setPickupInformation,
} from '../actions';
import { orderConfig } from '../config';
import { getDefaultFulfillmentTypeId } from '../helpers/cart.helper';
import { getFulfillmentTypeLabel } from '../helpers/order.info.helper';
import { getTimeSlotDate } from '../helpers/orderDraft.helper';
import {
  checkIsLocationSelectionHidden,
  getFulFillmentTypeIcon,
  getFullfilmentTypeId,
} from '../helpers/pickupInformationForm.helper';
import { useIsTimeSelectionVisible } from '../hooks/useIsTimeSelectionVisible/useIsTimeSelectionVisible';
import {
  DeliveryOptionType,
  PaymentModality,
  PickupInformationFormProps,
} from '../types/cart.types';
import {
  FulfillmentType,
  PickupInformation,
  PickupLocation,
  PickupTimeSlot,
  StateWithOrder,
} from '../types/orderState.types';

import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';
import useSite from '@/modules/Core/hooks/useSite';

import styles from '../components/Cart.module.css';

export const PickupInformationForm = ({
  label,
  menu,
  paymentOptions,
  setPaymentMethod,
  isGuestOrdering,
}: PickupInformationFormProps) => {
  const { isCartPhoneInputEnabled, isCartPaymentFormEnabled } = orderConfig();

  const site = useSite()!;
  const {
    user: { mobile },
  } = useLoginStatus();

  const dispatch = useDispatch();
  const {
    cart,
    locks: orderLocks,
    tableNumber,
  } = useSelector((state: StateWithOrder) => state.Order);
  const {
    createOrderDraft: orderDraftLock,
    getPickupLocations: isPickupLocationsLocked,
    getPickupTimeSlots: isPickupTimeSlotsLocked,
  } = orderLocks;

  const locations = useMemo(() => cart?.pickupLocations ?? [], [cart?.pickupLocations]);
  const timeSlots = useMemo(() => cart?.pickupTimeSlots ?? [], [cart?.pickupTimeSlots]);
  const fulfillmentTypes = useMemo(() => menu?.fulfillmentTypes || [], [menu]);

  const pickupInformation = cart?.pickupInformation;
  const selectedFulfillmentType = cart?.selectedFulfillmentType;

  const isLocationsLocked =
    isPickupLocationsLocked ||
    (!!fulfillmentTypes && fulfillmentTypes?.length > 0 && !selectedFulfillmentType);
  const isTimeSlotsLocked =
    isPickupTimeSlotsLocked || !pickupInformation?.pickupSpotId || orderDraftLock;

  const table = tableNumber ?? undefined;
  const isScanAndGo = menu?.isScanAndGo ?? false;

  const refreshPickupLocations = useCallback(
    async (
      chosenFulfillmentType: FulfillmentType | null
    ): Promise<PickupLocation[] | undefined> => {
      if (menu?.id && !isPickupLocationsLocked) {
        if (!!chosenFulfillmentType) {
          const result = await dispatch(
            getPickupLocations(
              {
                menuId: menu?.id,
                siteId: site.id,
                deliveryOptionId: chosenFulfillmentType.id,
              },
              { useErrorBoundary: false }
            )
          );
          return result.ok ? result.responseData.pickupSpotsContent : undefined;
        }
      }
    },
    [dispatch, isPickupLocationsLocked, menu?.id, site.id]
  );

  const refreshPickupTimeSlots = useCallback(
    async (
      pickupSpotId: number,
      deliveryOptionId?: number | undefined
    ): Promise<PickupTimeSlot[] | undefined> => {
      if (menu?.id && pickupSpotId && !isPickupTimeSlotsLocked) {
        const timeSlotDate = getTimeSlotDate(cart?.date);
        const result = await dispatch(
          getPickupTimeSlots(
            {
              menuId: menu.id,
              pickupSpotId,
              siteId: site.id,
              timeSlotDate,
              deliveryOptionId:
                deliveryOptionId ??
                selectedFulfillmentType?.id ??
                getDefaultFulfillmentTypeId(fulfillmentTypes, table),
            },
            { useErrorBoundary: false }
          )
        );
        return result?.ok ? result.responseData.timeSlotsContent : undefined;
      }
    },
    [
      menu?.id,
      isPickupTimeSlotsLocked,
      cart?.date,
      site.id,
      selectedFulfillmentType?.id,
      fulfillmentTypes,
      table,
      dispatch,
    ]
  );

  const setPickupInformationFunction = useCallback(
    (values: PickupInformation) => setPickupInformation({ pickupInformation: values })(dispatch),
    [dispatch]
  );

  useEffect(() => {
    const setScanAngGoFullfilmentType = () => {
      if (isScanAndGo) {
        const fulfillmentType = fulfillmentTypes?.find(
          (fullfilment) => fullfilment.type === DeliveryOptionType.ScanAndGo
        );
        if (!!fulfillmentType) {
          dispatch(setFulfillmentType({ fulfillmentType }));
        }
      }
    };

    setScanAngGoFullfilmentType();
  }, [fulfillmentTypes, isScanAndGo, dispatch]);

  useEffect(() => {
    if (mobile) setPickupInformationFunction({ pickupPhoneNumber: mobile });
  }, [mobile, setPickupInformationFunction]);

  const handlePhoneNumberKeyDown = useCallback((e: KeyboardEvent) => {
    const validKeys = ['+', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab'];
    const format = /[\d]+/;
    if (!format.test(e.key) && !validKeys.includes(e.key)) e.preventDefault();
  }, []);

  const isTimeSelectionVisible = useIsTimeSelectionVisible();

  const handleLocationChange = useCallback(
    async (
      option: { value: string; label?: string | null; deliveryOptionId?: number },
      selectedOptionType = selectedFulfillmentType?.type
    ) => {
      const pickupSpotId = Number(option.value);
      const timeSlotsResponse = await refreshPickupTimeSlots(pickupSpotId, option.deliveryOptionId);
      const isDinein = selectedOptionType === DeliveryOptionType.DineIn;

      if (
        (!isTimeSelectionVisible || isDinein) &&
        timeSlotsResponse &&
        timeSlotsResponse.length > 0
      ) {
        const selectedTimeSlot = timeSlotsResponse[0];

        await setPickupInformationFunction({
          pickupSpotId,
          pickupSpotName: option?.label,
          pickupTimeSlotId: selectedTimeSlot.timeSlotId.toString(),
          pickupTime: selectedTimeSlot.time,
        });
      } else {
        await setPickupInformationFunction({
          pickupSpotId,
          pickupSpotName: option?.label,
          pickupTimeSlotId: null,
          pickupTime: null,
        });
      }
    },
    [
      refreshPickupTimeSlots,
      isTimeSelectionVisible,
      selectedFulfillmentType,
      setPickupInformationFunction,
    ]
  );

  const handleInputChange = useCallback(
    (value: string, field: string) => {
      if (field) {
        setPickupInformationFunction({ [field]: value });
      }
    },
    [setPickupInformationFunction]
  );

  const pickTheOnlyLocationWhenDineIn = useCallback(
    async (
      pickupLocation: PickupLocation,
      selectedOption: { id: number; type: DeliveryOptionType }
    ) => {
      if (!!pickupLocation.pickupSpotId && !!pickupLocation.name)
        await handleLocationChange(
          {
            value: String(pickupLocation.pickupSpotId),
            label: pickupLocation.name,
            deliveryOptionId: selectedOption.id,
          },
          selectedOption.type
        );
    },
    [handleLocationChange]
  );

  const showTableInformation = table && selectedFulfillmentType?.type === DeliveryOptionType.DineIn;

  const onFulfillmentTypeSelectedRadio = useCallback(
    async (id: string) => {
      const selectedOption = fulfillmentTypes.find((x) => x.id.toString() === id);
      if (!!selectedOption && selectedOption.id !== selectedFulfillmentType?.id) {
        dispatch(setFulfillmentType({ fulfillmentType: selectedOption }));

        const refreshedLocations = await refreshPickupLocations(selectedOption);
        const hideLocationPicker = checkIsLocationSelectionHidden({
          locations: refreshedLocations,
          fullFilmentType: selectedOption.type,
        });
        if (hideLocationPicker) {
          await pickTheOnlyLocationWhenDineIn(refreshedLocations![0], selectedOption);
        } else {
          if (id !== selectedFulfillmentType?.id.toString()) {
            await setPickupInformationFunction({
              pickupSpotId: null,
              pickupSpotName: null,
              pickupTimeSlotId: null,
              pickupTime: null,
            });
          }
        }
      }
    },
    [
      fulfillmentTypes,
      refreshPickupLocations,
      pickTheOnlyLocationWhenDineIn,
      selectedFulfillmentType,
      setPickupInformationFunction,
      dispatch,
    ]
  );

  //Here we filter out other fulfillmentTypes as they are not handled
  const fullFilmentTypeId = getFullfilmentTypeId(selectedFulfillmentType, table, fulfillmentTypes);
  const fulfillmentsForRadio =
    fulfillmentTypes
      ?.filter(
        (x) =>
          x.type === DeliveryOptionType.DineIn ||
          x.type === DeliveryOptionType.PickupOption ||
          x.type === DeliveryOptionType.Delivery
      )
      .map((x) => {
        return {
          key: x.id.toString(),
          id: x.id.toString(),
          label: label(getFulfillmentTypeLabel(x.type)),
          icons: [{ icon: getFulFillmentTypeIcon(x.type) }],
          'data-testid': `fulfillment-type-radio-${x.id}`,
          control: {
            type: CONTROL_TYPE.RADIO,
            props: {
              id: x.id.toString(),
              checked: x.id === fullFilmentTypeId,
              'data-testid': `${x.id}-radio-button`,
            },
          },
        };
      }) || [];

  const payNow = paymentOptions.find((x) => x.id === PaymentModality.PayNow);
  const payOnPickup = paymentOptions.find((x) => x.id === PaymentModality.PayOnPickup);
  const defaultPaymentMethod =
    payNow && isCartPaymentFormEnabled ? payNow.id : payOnPickup?.id || paymentOptions[0]?.id;

  useEffect(() => {
    defaultPaymentMethod && setPaymentMethod(defaultPaymentMethod);
  }, [defaultPaymentMethod, setPaymentMethod]);

  useEffect(() => {
    if (orderDraftLock) {
      return;
    }

    if (!selectedFulfillmentType && fullFilmentTypeId) {
      // when user enters cart and there is no selected fullfilment type, select one
      onFulfillmentTypeSelectedRadio(fullFilmentTypeId.toString());
    }

    if (!isTimeSelectionVisible && pickupInformation?.pickupSpotId) {
      //when user enters cart and time is not shown ensure the latest timeslots are fetched
      handleLocationChange({
        label: pickupInformation.pickupSpotName,
        value: String(pickupInformation.pickupSpotId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDraftLock]);

  const [showLocationPicker, setShowLocationPicker] = useState<boolean>(true);
  useEffect(() => {
    const isLocationSelectionHidden = checkIsLocationSelectionHidden({
      locations,
      fullFilmentType: selectedFulfillmentType?.type,
    });

    setShowLocationPicker(!isLocationSelectionHidden);
  }, [locations, selectedFulfillmentType]);

  return (
    <div className={styles.pickupForm}>
      {!isScanAndGo && (
        <>
          <div className={classNames('mb-M')}>
            <div className={classNames(styles.sectionTitleWrapper, 'mb-S')}>
              <Title tag={TITLE_TAG.PARAGRAPH} size={TITLE_SIZE.BODYSBOLD} id="fulfillmentTypeID">
                {`${label('Ref: Fulfillment Type')}*`}
              </Title>
            </div>
            <List
              data-testid="fulfillment-type-list"
              items={fulfillmentsForRadio}
              onChange={(_, id) => onFulfillmentTypeSelectedRadio(id)}
              aria-labelledby="fulfillmentTypeID"
            />
          </div>
          <hr className={classNames(styles.borderOfFulfillments)}></hr>
          <div className={styles.locationAndTimeSelectsWrapper}>
            {showLocationPicker && (
              <div className={styles.formSelectWrapper}>
                <Select
                  label={`${label('Ref: Location')} *`}
                  placeholder={label('Ref: Please select')}
                  value={
                    pickupInformation?.pickupSpotId
                      ? pickupInformation.pickupSpotId.toString()
                      : null
                  }
                  options={locations.map(({ pickupSpotId, name }) => ({
                    value: `${pickupSpotId}`,
                    label: name,
                  }))}
                  disabled={isLocationsLocked}
                  onChange={handleLocationChange}
                  data-testid="location-picker-select"
                />
              </div>
            )}
            {isTimeSelectionVisible && (
              <div className={styles.formSelectWrapper}>
                <Select
                  label={`${label('Ref: Time')} *`}
                  placeholder={label('Ref: Please select')}
                  value={pickupInformation?.pickupTimeSlotId}
                  options={timeSlots.map(({ timeSlotId, time }) => ({
                    value: `${timeSlotId}`,
                    label: formatTime(toLocalDate(time, true)),
                  }))}
                  disabled={isTimeSlotsLocked}
                  onChange={(option) => {
                    setPickupInformationFunction({
                      pickupTimeSlotId: option?.value,
                      pickupTime: timeSlots.find((x) => x.timeSlotId.toString() === option?.value)
                        ?.time,
                    });
                  }}
                  data-testid="time-picker-select"
                />
              </div>
            )}
          </div>
        </>
      )}
      {showTableInformation && (
        <Select
          label={label('Ref: Table')}
          value={table}
          options={[{ value: table, label: table }]}
          disabled={true}
          onChange={(_) => {}}
          data-testid="table-select"
        />
      )}

      <>
        {isCartPhoneInputEnabled && (
          <div>
            {/* TODO: on submit order, update user context with new phone number if it was changed */}
            <Input
              data-testid="pickup-info-phone"
              inputLabel={label('Ref: Phone')}
              id="phone"
              type={INPUT_TYPE.TEL}
              autocomplete={INPUT_TYPE.TEL}
              value={pickupInformation?.pickupPhoneNumber}
              maxlength={20}
              onKeyDown={handlePhoneNumberKeyDown}
              onInputChange={(val) => handleInputChange(val || '', 'pickupPhoneNumber')}
            />
          </div>
        )}
        {isGuestOrdering && (
          <div>
            <Input
              inputLabel={label('Ref: Email address')}
              data-testid='pickup-info-email'
              id="email"
              type={INPUT_TYPE.EMAIL}
              autocomplete={INPUT_TYPE.EMAIL}
              value={pickupInformation?.email}
              maxlength={100}
              onInputChange={(val) => handleInputChange(val || '', 'email')}
              required={true}
            />
          </div>
        )}
        {!isScanAndGo && (
          <Textarea
            label={label('Ref: Instructions')}
            id="instructions"
            placeholder={label('Ref: Enter special requests')}
            value={pickupInformation?.pickupInstructions || ''}
            onChange={(e) => handleInputChange(e.detail?.value || '', 'pickupInstructions')}
          />
        )}
        {isCartPaymentFormEnabled && paymentOptions.length > 1 && (
          <>
            <div className={classNames(styles.sectionTitleWrapper)}>
              <Title tag={TITLE_TAG.SPAN} size={TITLE_SIZE.BODYSBOLD}>
                {label('Ref: Payment modality')}
              </Title>
            </div>
            <List
              data-testid="payment-modality-list"
              items={paymentOptions}
              onChange={(_, id) => setPaymentMethod(id)}
              role="group"
              aria-labelledby={label('Ref: Payment modality')}
            />
          </>
        )}
        {!isScanAndGo && (
          <div className={classNames(styles.requiredStyle)}>* {label('Ref: Required fields')}</div>
        )}
      </>
    </div>
  );
};
