import { v4 as uuid } from 'uuid';

import { toLocalDate } from '../../../helpers/misc';
import { DeliveryOptionType } from '../types/cart.types';
import { MenuType } from '../types/menuSelector.types';
import { MenuActionType } from '../types/orderActions.types';
import {
  Cart,
  CartMenuItem,
  FacilityMenu,
  LastOrder,
  OrderDetailsItem,
  OrderDraft,
  OrderState,
} from '../types/orderState.types';

export const defaultState: OrderState = {
  menus: [],
  allergens: [],
  discountTiersStatus: {
    mustSetPricingTier: undefined,
  },
  discountTiers: {
    pricingTiers: undefined,
  },
  locks: {
    getMenusForFacility: false,
    getAllergens: false,
    getMenuDownloadUrl: false,
    getTiersStatus: false,
    getDiscountTiers: false,
  },
  menuDownloadUrl: undefined,
  hasSiteChanged: false,
  hasSiteChangeModalClosed: false,
  menusType: MenuType.All,
};

const ordersReducer = (state = defaultState, action: MenuActionType): OrderState => {
  switch (action.type) {
    case 'RESET_STATE':
      return defaultState;
    case 'FETCHING_MENUS_STARTED':
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchingSiteId: false,
        },
        locks: {
          ...state.locks,
          getMenusForFacility: true,
        },
      };
    case 'FACILITY_MENUS_FETCHING_FINISHED':
      return {
        ...state,
        menus: action.menus,
        menusType: action.menuType,
        errors: {
          ...state.errors,
          fetchingSiteId: false,
        },
        locks: {
          ...state.locks,
          getMenusForFacility: false,
        },
      };
    case 'FETCHING_PRESELECTED_FACILITY_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPreselectedFacilityId: true,
        },
      };
    case 'FETCHING_PRESELECTED_FACILITY_FINISHED':
      return {
        ...state,
        preselectedFacilityId: action.facilityId,
        locks: {
          ...state.locks,
          getPreselectedFacilityId: false,
        },
      };
    case 'FETCHING_PRESELECTED_SITE_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPreselectedSiteId: true,
        },
      };
    case 'FETCHING_PRESELECTED_SITE_FINISHED':
      return {
        ...state,
        preselectedSiteId: action.siteId,
        preselectedLocationId: action.locationId,
        errors: {
          ...state.errors,
          fetchingSiteId: false,
        },
        locks: {
          ...state.locks,
          getPreselectedSiteId: false,
        },
      };
    case 'FETCHED_EMPTY_PRESELECTED_SITE':
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchingSiteId: true,
        },
        locks: {
          ...state.locks,
          getPreselectedSiteId: false,
        },
      };
    case 'FETCHING_ALLERGENS_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAllergens: true,
        },
      };
    case 'FETCHED_ALLERGENS':
      return {
        ...state,
        allergens: action.allergens,
        locks: {
          ...state.locks,
          getAllergens: false,
        },
      };
    case 'CART_INITIALIZED':
      const trackingId = uuid();
      return {
        ...state,
        cart: {
          date: action.cart.date,
          facilityId: action.cart.facilityId,
          submissionTrackingId: trackingId,
          menuId: action.cart.menuId,
          siteId: action.cart.siteId,
          menuPortionItems: action.cart.menuPortionItems || [],
          moment: action.cart.moment,
        },
      };
    case 'CART_CLEANED':
      return {
        ...state,
        cart: undefined,
        draft: undefined,
      };
    case 'CART_ITEM_ADDED':
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          menuPortionItems: state.cart?.menuPortionItems
            ? [...state.cart?.menuPortionItems, { ...action.menuItem }]
            : [{ ...action.menuItem }],
        },
      };
    case 'CART_ITEMS_REMOVED':
      const newMenuItems = state.cart?.menuPortionItems?.filter(
        (x) => !action.menuItemsIds.includes(x.id)
      );
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          menuPortionItems: newMenuItems ? [...newMenuItems] : [],
        },
      };
    case 'TRACKING_ID_SYNCED':
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
        },
      };
    case 'CART_ITEM_UPDATED':
      const cartItemToUpdateIndex = state.cart?.menuPortionItems?.findIndex(
        (x) => x.id === action.menuItem.id
      );
      const newMenuPortionItems = [...(state.cart?.menuPortionItems || [])];

      if (cartItemToUpdateIndex !== undefined && cartItemToUpdateIndex !== -1)
        newMenuPortionItems[cartItemToUpdateIndex] = action.menuItem;
      else return { ...state };

      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          menuPortionItems: newMenuPortionItems ? [...newMenuPortionItems] : [],
        },
      };
    case 'CART_ITEM_QUANTITY_INCREASED':
      const menuItemsWithoutIncreased = state.cart?.menuPortionItems?.filter(
        (x) => x.id !== action.menuItemId
      );
      const increasedMenuItem = state.cart?.menuPortionItems?.find(
        (x) => x.id === action.menuItemId
      ) as CartMenuItem;
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          menuPortionItems: menuItemsWithoutIncreased
            ? [
                ...menuItemsWithoutIncreased,
                { ...increasedMenuItem, quantity: increasedMenuItem.quantity + 1 },
              ]
            : [{ ...increasedMenuItem, quantity: increasedMenuItem.quantity + 1 }],
        },
      };
    case 'CART_ITEM_QUANTITY_DECREASED':
      const menuItemsWithoutDecreased = state.cart?.menuPortionItems?.filter(
        (x) => x.id !== action.menuItemId
      );
      const decreasedMenuItem = state.cart?.menuPortionItems?.find(
        (x) => x.id === action.menuItemId
      ) as CartMenuItem;
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          menuPortionItems: menuItemsWithoutDecreased
            ? [
                ...menuItemsWithoutDecreased,
                { ...decreasedMenuItem, quantity: decreasedMenuItem.quantity - 1 },
              ]
            : [{ ...decreasedMenuItem, quantity: decreasedMenuItem.quantity - 1 }],
        },
      };
    case 'FETCHING_PICKUP_LOCATIONS_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPickupLocations: true,
        },
      };
    case 'FETCHING_PICKUP_LOCATIONS_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPickupLocations: false,
        },
        cart: {
          ...(state.cart as Cart),
          pickupLocations: action.pickupLocations,
        },
      };
    case 'FETCHING_PICKUP_TIME_SLOTS_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPickupTimeSlots: true,
        },
      };
    case 'FETCHING_PICKUP_TIME_SLOTS_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPickupTimeSlots: false,
        },
        cart: {
          ...(state.cart as Cart),
          pickupTimeSlots: action.timeSlots,
        },
      };
    case 'SET_PICKUP_INFORMATION':
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          pickupInformation: {
            ...state.cart?.pickupInformation,
            ...action.pickupInformation,
          },
        },
      };
    case 'ORDER_DRAFT_REQUEST_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          createOrderDraft: true,
        },
      };
    case 'ORDER_DRAFT_REQUEST_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          createOrderDraft: false,
        },
      };
    case 'ORDER_DRAFT_PERSISTED':
      return {
        ...state,
        draft: action.orderDraft,
        locks: {
          ...state.locks,
          createOrderDraft: false,
        },
      };
    case 'PENDING_ORDERS_REQUEST_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPendingOrders: true,
        },
      };
    case 'PENDING_ORDERS_REQUEST_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPendingOrders: false,
        },
      };
    case 'PENDING_ORDERS_FETCHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPendingOrders: false,
        },
        pendingOrders: action.orders,
      };
    case 'ORDER_REQUEST_STARTED':
      return {
        ...state,
        errors: {
          ...state.errors,
          payForOrder: false,
        },
        locks: {
          ...state.locks,
          payForOrder: true,
        },
      };
    case 'ORDER_REQUEST_FINISHED':
      return {
        ...state,
        lastOrder: mapLastOrder(state.draft, state.cart),
        errors: {
          ...state.errors,
          payForOrder: action.payForOrderError,
        },
        locks: {
          ...state.locks,
          payForOrder: false,
        },
      };
    case 'FETCHING_MENU_URL_STARTED':
      return {
        ...state,
        menuDownloadUrl: undefined,
        locks: {
          ...state.locks,
          getMenuDownloadUrl: true,
        },
      };
    case 'FETCHING_MENU_URL_FINISHED':
      return {
        ...state,
        menuDownloadUrl: action.url,
        locks: {
          ...state.locks,
          getMenuDownloadUrl: false,
        },
      };
    case 'FETCHING_TIERS_STATUS_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getTiersStatus: true,
        },
      };
    case 'TIERS_STATUS_FETCHING_FINISHED':
      return {
        ...state,
        discountTiersStatus: action.status,
        locks: {
          ...state.locks,
          getTiersStatus: false,
        },
      };
    case 'FETCHING_DISCOUNT_TIERS_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getDiscountTiers: true,
        },
      };
    case 'DISCOUNT_TIERS_FETCHING_FINISHED':
      return {
        ...state,
        discountTiers: action.discountTiers,
        locks: {
          ...state.locks,
          getDiscountTiers: false,
        },
      };
    case 'SITE_CHANGED':
      return {
        ...state,
        hasSiteChanged: true,
      };
    case 'SITE_CHANGE_MODAL_CLOSED':
      return {
        ...state,
        hasSiteChanged: false,
      };
    case 'CLEAR_TIMESLOT': {
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          pickupInformation: {
            ...state.cart?.pickupInformation,
            pickupTimeSlotId: undefined,
          },
        },
      };
    }
    case 'TABLE_SET':
      const tableQRScanTime = action.tableNumber ? Date.now() : null;
      return {
        ...state,
        tableNumber: action.tableNumber,
        tableQRScanTime,
      };
    case 'CHECK_IN_AT_TABLE_FINISHED':
      const orderIndex = state.pendingOrders?.findIndex(
        (x) => x.orderNumber === action.orderNumber
      );

      const pendingOrders = [...(state.pendingOrders || [])];
      if (orderIndex !== undefined) {
        pendingOrders[orderIndex].tableNumber = action.tableNumber!;
        pendingOrders[orderIndex].isCheckedIn = true;
      } else return { ...state };

      return { ...state, pendingOrders };
    default:
      return state;
    case 'FULFILLMENT_TYPE_SET':
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          selectedFulfillmentType: {
            ...state.cart?.selectedFulfillmentType,
            ...action.fulfillmentType,
          },
        },
      };
    case 'LAST_ORDER_SET':
      return {
        ...state,
        lastOrder: mapLastOrder(state.draft, state.cart),
      };
    case 'SUBMISSION_TRACKING_ID_SET':
      return {
        ...state,
        cart: {
          ...(state.cart as Cart),
          submissionTrackingId: action.submissionTrackingId,
        },
      };
    case 'CART_ORDER_SESSION_RETRIEVED_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          retrieveOrderSession: true,
        },
      };
    case 'CART_ORDER_SESSION_RETRIEVED_FAILED':
      return {
        ...state,
        locks: {
          ...state.locks,
          retrieveOrderSession: false,
        },
      };
    case 'CART_ORDER_SESSION_RETRIEVED':
      const firstElement = getProductById(
        state.menus,
        action.orderDraft.menuId,
        action.orderDraft.orderList[0].menuItemId
      );
      return {
        ...state,
        cart: {
          ...state.cart,
          siteId: action.orderDraft.siteId,
          facilityId: action.orderDraft.facilityId,
          submissionTrackingId: action.orderDraft.trackingId,
          menuId: action.orderDraft.menuId,
          date: toLocalDate(new Date()),
          moment: firstElement?.mealName ?? '',
          selectedFulfillmentType: {
            id: action.orderDraft.deliveryOptionId ?? 0,
            type: action.orderDraft.deliveryOptionType ?? DeliveryOptionType.None,
          },
          pickupInformation: {
            pickupInstructions: action.orderDraft.instructions,
            pickupPhoneNumber: action.orderDraft.contactPhoneNumber,
            pickupSpotId: action.orderDraft.pickupSpot?.id,
            pickupSpotName: action.orderDraft.pickupSpot?.name,
            pickupTime: action.orderDraft.pickupDate,
            pickupTimeSlotId: action.orderDraft.timeSlotId.toString(),
          },
          menuPortionItems: action.orderDraft.orderList.map((item) => {
            const menuProduct = getProductById(
              state.menus,
              action.orderDraft.menuId,
              item.menuItemId
            );
            return {
              id: item.cartMenuItemId,
              menuItemId: item.menuItemId,
              uomId: item.uomId,
              foodItemId: item.foodItemId,
              img: menuProduct?.listImage ?? '',
              name: menuProduct?.name ?? '',
              price: menuProduct?.price ?? 0,
              quantity: item.quantity,
              description: menuProduct?.description ?? '',
              isVegan: menuProduct?.productPortions[0].isVegan ?? false,
              isVegetarian: menuProduct?.productPortions[0].isVegetarian ?? false,
              genericCategory: menuProduct?.genericCategory ?? '',
              modifiersDisplayText: item.modifiersDisplayText,
              modifiers: item.modifiers.map((modifier) => {
                const cartModifier = {
                  modifierId: modifier.modifierId,
                  displayText: modifier.displayText,
                  values: (modifier.values || []).map((value) => {
                    const modifierValue = {
                      valueId: value.valueId,
                      price: value.price || 0,
                      tax: value.tax || 0,
                      quantity: value.quantity,
                    };
                    return modifierValue;
                  }),
                };

                return cartModifier;
              }),
            };
          }),
        },
        locks: {
          ...state.locks,
          retrieveOrderSession: false,
        },
      };
    case 'FAVORITE_PRODUCT_ADDED':
      return {
        ...state,
        menus: state.menus.map((menu) => ({
          ...menu,
          menuItems: menu.menuItems.map((item) => ({
            ...item,
            productPortions: item.productPortions.map((portion) =>
              portion.uomId === action.uomId ? { ...portion, isFavorite: true } : portion
            ),
          })),
        })),
      };
    case 'FAVORITE_PRODUCT_REMOVED':
      return {
        ...state,
        menus: state.menus.map((menu) => ({
          ...menu,
          menuItems: menu.menuItems.map((item) => ({
            ...item,
            productPortions: item.productPortions.map((portion) =>
              portion.uomId === action.uomId ? { ...portion, isFavorite: false } : portion
            ),
          })),
        })),
      };
  }
};

const getProductById = (menus: FacilityMenu[], menuId: number, menuItemId: number) =>
  menus
    .find((menu) => menu.id === menuId)
    ?.menuItems.find((menuItem) => menuItem.menuItemId === menuItemId);

const mapLastOrder = (draft?: OrderDraft, cart?: Cart): LastOrder | undefined => {
  if (!draft || !cart || !cart.selectedFulfillmentType?.type) return;

  const orderedItems: OrderDetailsItem[] | undefined = cart.menuPortionItems?.map((item) => {
    //todo once we will use new RR on all instances, such as UAT, we should check only UomId and do not bother with following fallback to foodItemId
    const discount = draft.promotionalDiscountForItems?.find((x) =>
      item.uomId !== 0 ? x.uomId === item.uomId : x.foodItemId === item.foodItemId
    )?.discount;

    return {
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      promotionDiscount: discount ? discount * item.quantity : 0,
      isVegan: item.isVegan,
      isVegetarian: item.isVegetarian,
    };
  });

  return {
    orderId: draft.orderId,
    facilityId: cart.facilityId,
    pickupInformation: {
      ...cart.pickupInformation,
    },
    deliveryOptionType: cart.selectedFulfillmentType.type,
    orderItems: orderedItems ?? [],
    total: draft.total,
    subTotal: draft.subtotal,
    tax: draft.tax,
    subsidy: draft.subsidy,
    delivery: draft.delivery,
    promotionDiscount: draft.promotionDiscount,
    promotionsApplied: draft.promotionsApplied,
  };
};

export default ordersReducer;
