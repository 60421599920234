import classNames from 'classnames';
import { useMemo } from 'react';
import { useHistory } from 'react-router';

import { CheckIcon } from '../../../../assets/icons';
import { Discount } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import { TILE_VARIANT, Tile } from '../../../../components/molecules/Tile';
import { SIZE } from '../../../../constants';
import { LabelFunc } from '../../../../context/withLang';
import { getProductRedirectUrl } from '../../helpers/promotions.helper';
import { PromotionInfoItem } from '../../types/promotions.types';

import styles from './PromotionsTile.module.css';

interface TileProps {
  name: string;
  description: string;
  id: number;
  items?: PromotionInfoItem[];
  isActive: boolean;
  label: LabelFunc;
}

export const PromotionsTile = ({ name, description, id, items, isActive, label }: TileProps) => {
  const productRedirectUrl = useMemo(() => getProductRedirectUrl(items), [items]);
  const history = useHistory();

  return (
    <Tile
      key={id}
      title={name}
      description={description}
      variant={TILE_VARIANT.STANDARD}
      className={classNames(styles.promotionTile)}
      image={<Discount />}
      dark
    >
      {items?.length === 1 && items[0].menuId && productRedirectUrl && !isActive && (
        <Button
          look="secondary"
          size={SIZE.SMALL}
          className={classNames(styles.promotionButton)}
          onClick={() => history.push(productRedirectUrl)}
          data-testid='promotion-tile-redirect-action'
        >
          {label('Ref: See item')}
        </Button>
      )}
      {isActive && (
        <div className={classNames(styles.promotionActive)}>
          <CheckIcon width="20px" height="20px" />
          <div className={classNames(styles.promotionActiveTitle)}>Activated</div>
        </div>
      )}
    </Tile>
  );
};
