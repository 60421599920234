import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { FC, useEffect } from 'react';

import { DownloadIcon } from '../../../../assets/icons';
import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import { HtmlContent } from '../../../../components/atoms/RenderContent';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { SIZE } from '../../../../constants';
import withLoginStatus from '../../../../context/withLoginStatus';
import { saveHtmlAsPdf, updateRelativePathUrl } from '../../../../helpers/misc';
import { legalDocumentsConfig, legalDocTypeNamesToCodeMap } from '../../config';
import withLang from '../../context/withLang';
import { getLegalDocLabel } from '../../helpers/helpers';

import { LegalDocPageProps } from './LegalDocPage.types';

import styles from './LegalDocPage.module.css';

const LegalDocPage: FC<LegalDocPageProps> = ({
  isLoggedIn,
  geoCode,
  languageCode,
  documentId,
  legalDocument,
  getAllLegalDocuments,
  getOneLegalDocument,
  type,
  version,
  history,
  shouldAcknowledge,
  isAcknowledgeAfterLegalDocModification,
  acknowledgeLegalDoc,
  label,
}) => {
  useEffect(() => {
    if (!isLoggedIn) history.replace('/');
    const fetchLegalDoc = async () => {
      let applicableDocumentId = documentId;
      if (!applicableDocumentId && version === 'current') {
        const typeCode = legalDocTypeNamesToCodeMap[type];
        const allLegalDocsResult = await getAllLegalDocuments(geoCode, languageCode);
        applicableDocumentId =
          allLegalDocsResult.responseData?.documents.find(
            (document) => document.type?.value === typeCode
          )?.id || '';
      }
      if (!legalDocument) getOneLegalDocument(applicableDocumentId);
    };
    fetchLegalDoc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { shouldDisplayDownloadButton } = legalDocumentsConfig();

  if (!legalDocument) {
    return <LoadingPage />;
  }

  const notificationContent = (
    <Notification
      look={NOTIFICATION_LOOK.INFO}
      dismissable
      title={label('Ref: Legal changed notification title', {
        replace: { legal_doc_type: getLegalDocLabel(type, label) },
      })}
      inheritStyle={classNames(styles.notificationWrapper)}
    >
      {label('Ref: Legal changed notification content')}
    </Notification>
  );

  return (
    <SimpleFormPage
      hasBackLink={true}
      hideAccountWidget={shouldAcknowledge}
      title={getLegalDocLabel(type, label)}
    >
      <Container>
        <Column.Main>
          {isAcknowledgeAfterLegalDocModification && notificationContent}
          <Card
            overTitle={{
              tag: TITLE_TAG.H1,
              size: TITLE_SIZE.HEADLINES,
              children: getLegalDocLabel(type, label),
              className: styles.legalTitle,
              ...(shouldDisplayDownloadButton
                ? {
                    suffix: (
                      <Button
                        data-testid="legal-docs-save-as-pdf"
                        look="tertiary"
                        onClick={() => saveHtmlAsPdf(Base64.decode(legalDocument))}
                        size={SIZE.SMALL}
                        inheritStyle={styles.titleSuffix}
                      >
                        <DownloadIcon className={classNames(styles.titleIcon)} />
                      </Button>
                    ),
                  }
                : {}),
            }}
          >
            <div
              id="legalDocumentContainer"
              data-cy="legal-document-container"
              className={classNames(styles.wrapper)}
            >
              <HtmlContent data-testid="legal-doc-page-html-content">
                {updateRelativePathUrl(Base64.decode(legalDocument))}
              </HtmlContent>
            </div>
          </Card>
        </Column.Main>
        <Column.Complementary>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
        {shouldAcknowledge ? (
          <ActionsBar>
            <Button
              data-testid="legal-docs-acknowledge"
              onClick={async () => {
                await acknowledgeLegalDoc(documentId, legalDocTypeNamesToCodeMap[type]);
                history.replace('/');
              }}
            >
              {label('Acknowledge')}
            </Button>
          </ActionsBar>
        ) : null}
      </Container>
    </SimpleFormPage>
  );
};

export default withLoginStatus(withLang([__filename])(LegalDocPage));
