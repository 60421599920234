import { useState } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';
import { StaysListFiltering } from '../types/routes.types';

import StayDetails from './StayDetails';
import StaysList from './StaysList';
import StaysSuccess from './StaysSuccess';

const Accomodation = () => {
  const [staysListSearchString, setStaysListSearchString] = useState('');
  const [staysListFiltering, setStaysListFiltering] = useState<StaysListFiltering>();

  const handleStaysListSearchChange = (searchString: string) => {
    setStaysListSearchString(searchString);
  };

  const handleStaysListFilteringChange = (filtering: StaysListFiltering) => {
    setStaysListFiltering(filtering);
  };

  return (
    <div>
      <Switch>
        <Route path={pagePaths['Success']} children={<StaysSuccess />} />
        <Route path={pagePaths['StayDetails']} children={<StayDetails />} />
        <Route
          path={pagePaths['Module']}
          children={
            <StaysList
              parentSearch={{
                searchString: staysListSearchString,
                handleSearchChange: handleStaysListSearchChange,
              }}
              parentFilter={{
                filtering: staysListFiltering,
                handleFilteringChange: handleStaysListFilteringChange,
              }}
            />
          }
        />
      </Switch>
    </div>
  );
};

export default withRouter(Accomodation);
