import { WithLangProps } from '../../../context/withLang';
import { ISite } from '../../Sites/types/sites.types';

import { DeliveryOptionType } from './cart.types';

export type KioskOrderProps = WithLangProps & {
  variant: KioskOrderVariant.SUCCESS | KioskOrderVariant.FAIL;
  deliveryOption?: DeliveryOptionType;
  orderId?: number;
  site: ISite;
};

export enum KioskOrderVariant {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type EmailReceipt = {
  emailAddress: string;
};

export enum GetReceiptType {
  Print = 'print',
  Mail = 'mail',
}

export type PrintArgs = {
  siteId: string;
  orderId: string;
};

export type EmailArgs = {
  email: string;
} & PrintArgs;
