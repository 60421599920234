import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useGetAccommodationRequestDetailsQuery } from '../../api';
import RequestStatus from '../../components/RequestStatus/RequestStatus';
import { pagePaths } from '../../config/default';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';

import Button from '@/components/atoms/Button';
import { ChatMessageProps } from '@/components/atoms/ChatMessage/ChatMessage';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Chat from '@/components/organisms/Chat/Chat';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { getAttachments } from '@/helpers/attachments';
import { formatTime, formatDate, formatDateTime } from '@/helpers/misc';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import styles from '@/modules/ServiceRequest/components/Details/Details.module.css';
import { Message } from '@/modules/ServiceRequest/types/types';

const Details = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);
  const { id } = useParams<{ id: string }>();
  const { currentLanguageCode } = useLanguage();
  const { data, isLoading } = useGetAccommodationRequestDetailsQuery({ id });
  const [messages, setMessages] = useState<ChatMessageProps[]>();
  const goToUpdateUs = (): void => history.push(pagePaths.UpdateUs.replace(':id', id));

  let keyValuePairs: {
    id: string;
    label: string;
    value: any;
    wrapLine?: boolean;
    'data-testid': string;
  }[] = [
    {
      id: 'siteName',
      label: label('Ref: site'),
      value: data?.siteName,
      'data-testid': 'site-name',
    },
    {
      id: 'phoneNumber',
      label: label('Ref: phoneNumber'),
      value: data?.phoneNumber,
      'data-testid': 'phone-number',
    },
    {
      id: 'raisedFor',
      label: label('Ref: request raised for'),
      value: data?.affectedPerson ?? label('Ref: Me'),
      'data-testid': 'raised-for',
    },
    {
      id: 'arrivalDateTime',
      label: label('Ref: arrivalDate'),
      value: data?.arrivalDateTime
        ? `${formatDate(new Date(data?.arrivalDateTime), currentLanguageCode)} -
        ${formatTime(new Date(data?.arrivalDateTime))}`
        : '',
      'data-testid': 'arrival-date-time',
    },
    {
      id: 'departureDateTime',
      label: label('Ref: departureDate'),
      value: data?.departureDateTime
        ? `${formatDate(new Date(data?.departureDateTime), currentLanguageCode)} -
        ${formatTime(new Date(data?.departureDateTime))}`
        : '',
      'data-testid': 'departure-date-time',
    },
    {
      id: 'description',
      label: label('Ref: description'),
      value: data?.description,
      'data-testid': 'description',
    },
  ];

  const detailsTitle = useMemo(
    () =>
      data &&
      `${data.referenceNumber} ${'•'} ${label('Ref: Arrival')}: ${formatDate(
        new Date(data.arrivalDateTime),
        currentLanguageCode
      )} - ${formatTime(new Date(data.arrivalDateTime))}`,
    [data, label, currentLanguageCode]
  );

  const isMessageSentBySodexo = (message: Message) => {
    return !message.isCreatedByUser && message.createdBy.toLowerCase().includes('sodexo');
  };

  const getMessages = useCallback(async () => {
    if (!data?.comments) return;
    const messagesArray = data?.comments.map(async (candidateMessage: Message) => {
      return {
        author: candidateMessage.createdBy,
        message: candidateMessage.description,
        date: formatDateTime(new Date(candidateMessage.createdOn), currentLanguageCode),
        'data-testid': `service-request-details-message`,
        actions: candidateMessage?.attachments && candidateMessage.attachments.length > 0 && (
          <div className={classNames(styles.wrapper)}>
            <Attachments
              readOnly
              attachments={await getAttachments(candidateMessage?.attachments)}
              closeButtonNotVisible
              displaySize={false}
              data-testid={`attachments-candidate-response`}
            />
          </div>
        ),
        inheritStyle: isMessageSentBySodexo(candidateMessage)
          ? 'toUserChatMessage'
          : 'fromUserChatMessage',
      };
    });

    const messages = await Promise.all(messagesArray);
    setMessages(messages);
  }, [data, currentLanguageCode]);

  useEffect(() => {
    getMessages();
  }, [data, getMessages]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      title={label('Ref: AccommodationRequestDetails')}
      tabTitle={label('Ref: AccommodationRequestDetails')}
      hasBackLink
      data-testid="accommodation-request-detais-page"
    >
      <Container>
        <Column.Side>
          <div>
            <Title tag={TITLE_TAG.H3} size={TITLE_SIZE.HEADLINES} className="mb-M">
              {detailsTitle}
            </Title>
            <div className="mb-S">{<RequestStatus status={data?.status} />}</div>
            <Card>
              <List data-testid="accommodation-request-detais" items={keyValuePairs} />
            </Card>
          </div>
        </Column.Side>
        <Column.Main>
          <div>
            <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className="mb-M">
              {label('Ref: Timeline')}
            </Title>
            <Chat
              messages={messages}
              title={label('Ref: No updates placeholder')}
              data-testid="accommodation-request-chat"
            />
          </div>
        </Column.Main>
        <ActionsBar inMainColumn>
          <Button
            onClick={goToUpdateUs}
            key={'actionUpdateUs_button'}
            data-testid="accommodation-request-update-action"
          >
            {label('Ref: Update us')}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Details;
