import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import DateRange, { DateRangeValue } from '../../../../components/molecules/DateRange';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { pagePaths } from '../../../ServiceRequestLight/config';
import { ServiceRequestLightHelpdeskObject } from '../../api/api.types';
import { pagePaths as helpdeskPagePaths } from '../../config';
import { useServiceRequestLightHelpdeskTranslation } from '../../hooks/useServiceRequestLightHelpdeskTranslation';

import useDownloadSRLHelpdeskExport from './hooks/useDownloadSRLHelpdeskExport';
import { ListProps } from './List.types';

import { DownloadIcon, RefreshIcon } from '@/assets/icons';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import SearchBar from '@/components/atoms/SearchBar/SearchBar';
import Table from '@/components/atoms/Table/Table';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import { formatDate, formatTime, stripTimeZoneFromDateTime } from '@/helpers/misc';
import SwitchSite from '@/modules/Sites/components/SwitchSite';
import { State } from '@/types/state.types';

import styles from './List.module.css';

const List = ({
  listData,
  isLoading,
  isRefreshing,
  isFetchingNextPage,
  onRefresh,
  setPageNumber,
}: ListProps) => {
  const { id: siteId, name: siteName } = useSelector(
    (state: State) => state.Core.context.site || { id: '', name: '' }
  );
  const { label } = useServiceRequestLightHelpdeskTranslation(__filename);

  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const history = useHistory();
  const location = useLocation();

  const searchQueryParams = new URLSearchParams(location.search);

  const { searchTerm, startDate, endDate } = Object.fromEntries(searchQueryParams);

  const [records, setRecords] = useState<ServiceRequestLightHelpdeskObject[]>([]);

  useEffect(() => {
    if (listData?.data && (listData?.metadata?.pageNumber || 1) > 1) {
      // If we are on a page >1, combine the data with the existing records - infinite scroll
      setRecords((prevRecords) => [...prevRecords, ...(listData?.data ?? [])]);
    } else {
      setRecords(listData?.data ?? []);
    }
  }, [listData]);

  const { downloadExport, exportIsLoading } = useDownloadSRLHelpdeskExport({
    siteId,
    searchTerm,
    startDate,
    endDate,
  });

  const onInfiniteScroll = () => {
    const newPageNumber = (listData?.metadata?.pageNumber || 0) + 1;
    setPageNumber(newPageNumber);
  };

  const handleSearchTermChange = (searchTerm?: string) => {
    searchTerm
      ? searchQueryParams.set('searchTerm', searchTerm)
      : searchQueryParams.delete('searchTerm');
    searchQueryParams.delete('pageNumber');

    history.push({
      search: searchQueryParams.toString(),
    });
  };

  const handleOnDateRangeChange = (selectedRange: DateRangeValue | undefined) => {
    const [start, end] = selectedRange ?? [null, null];
    searchQueryParams.delete('pageNumber');
    start
      ? searchQueryParams.set('startDate', stripTimeZoneFromDateTime(start))
      : searchQueryParams.delete('startDate');
    end
      ? searchQueryParams.set('endDate', stripTimeZoneFromDateTime(end, true))
      : searchQueryParams.delete('endDate');

    history.push({
      search: searchQueryParams.toString(),
    });
  };

  const dateRangeButton = (
    <DateRange
      id={'date-range-filter'}
      modalTitle={label('Ref: Dates')}
      languageCode={currentLanguageCode}
      label={label}
      currentDateRangeIso={[decodeURIComponent(startDate), decodeURIComponent(endDate)]}
      onChange={handleOnDateRangeChange}
    />
  );

  const downloadAndRefreshButtons = (
    <div className={styles.buttons}>
      <Button
        look={BUTTON_LOOK.TERTIARY}
        onClick={downloadExport}
        data-testid="service-request-light-helpdesk-list-download"
        srOnlyText={label('Ref: Export')}
        loading={exportIsLoading}
      >
        <DownloadIcon />
      </Button>
      <Button
        look={BUTTON_LOOK.TERTIARY}
        onClick={onRefresh}
        data-testid="service-request-light-helpdesk-list-refresh"
        srOnlyText={label('Ref: SRL list refresh')}
      >
        <RefreshIcon className={classNames({ [styles.loading]: isRefreshing })} />
      </Button>
    </div>
  );

  const tableColumns = [
    {
      header: label('Ref: Name'),
      accessorKey: label('name'),
    },
    {
      header: label('Ref: Date'),
      accessorKey: 'date',
    },
    {
      header: label('Ref: Category'),
      accessorKey: 'category',
    },
    {
      header: label('Ref: User'),
      accessorKey: 'user',
    },
    {
      header: label('Ref: Location'),
      accessorKey: 'location',
    },
  ];

  const tableData = records?.map((item: ServiceRequestLightHelpdeskObject) => {
    return {
      id: item.id,
      'data-testid': `service-request-light-helpdesk-table-row-item-${item.id}`,
      name: `${item.serviceRequestNumber} - ${item.title}`,
      date:
        item.createDateTime &&
        `${formatDate(new Date(item.createDateTime), currentLanguageCode)} - ${formatTime(
          new Date(item.createDateTime)
        )}`,
      category: item.category ? item.category : label('Ref: Empty/not filled'),
      user: item.affectedPersonFullName,
      location:
        item.roomText || item.buildingText
          ? `${item.buildingText} ${item.roomText && item.buildingText && '/'} ${item.roomText}`
          : label('Ref: Empty/not filled'),
    };
  });

  const onRowClick = (rowData: any) => {
    history.push(pagePaths['Details'].replace(':id', rowData.id));
  };

  return (
    <SimpleFormPage title={label('Ref: ServiceRequestLightHelpdeskTitle')} hasBackLink={false}>
      <Container className={styles.container}>
        <Column.Complementary className={styles.complementaryColumn}>{[]}</Column.Complementary>
        <Column.Main className={styles.mainColumn}>
          <SwitchSite
            sentence={label(
              'Ref: You are administrating service requests on the site {siteName}.',
              {
                replace: { siteName },
              }
            )}
            redirectTo={helpdeskPagePaths.Module}
          />
          <div className={styles.helpdeskSearchBar}>
            <SearchBar
              data-testid="service-request-light-helpdesk-search-bar"
              right={[dateRangeButton, downloadAndRefreshButtons]}
              handleChange={handleSearchTermChange}
              debounce={700}
              searchString={searchTerm ?? ''}
              collapsible
              withCardWrapper
            />
          </div>
          <Table
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            data={tableData}
            columns={tableColumns}
            onRowClick={onRowClick}
            onInfiniteScroll={onInfiniteScroll}
            hasMoreItems={!!listData?.metadata?.hasNextPage}
          />
        </Column.Main>
      </Container>
    </SimpleFormPage>
  );
};

export default List;
