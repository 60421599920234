import { useHistory } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { WithLangProps } from '../../../../context/withLang';
import { pagePaths } from '../../config';
import withLang from '../../context/withLang';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const AccountPasswordChangeFailure = ({ label }: WithLangProps) => {
  const history = useHistory();
  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.ERROR}
      withNavBar={false}
      title={label('Something went wrong', { textTransform: 'capitalize' })}
      content={label('Ref: Error message', {
        textTransform: 'capitalize',
      })}
      actions={[
        {
          label: label('Change password', { textTransform: 'capitalize' }),
          action: () => history.push(pagePaths.AccountPasswordChange),
        },
        {
          label: label('Go to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
};

export default withLang([__filename])(AccountPasswordChangeFailure);
