import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Subtract } from 'utility-types';

import { languages } from '../modules/Core/config';

type Translations = Record<string, string>;

type StringOptions = {
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  start?: 'whitespace' | 'comma' | 'colon' | 'semi-colon';
  end?: 'whitespace' | 'comma' | 'colon' | 'semi-colon';
};

export type LabelOptions = StringOptions & {
  isString?: boolean;
  replace?: Record<string, string>;
};

export type LabelFunc = (key: string, options?: LabelOptions) => string;

export type WithLangProps = {
  label: LabelFunc;
};

//preload cross-module translations
const crossModuleTranslations: Record<string, Translations> = {};
for (const language of languages) {
  crossModuleTranslations[language.code] = require('../languages/' + language.code).default;
}

//creates the label function used at component level
const labelFuncCreator =
  (applicableLangFileKeys: string[], labels: Record<string, Translations>): LabelFunc =>
  (key: string, options?: LabelOptions) => {
    for (const shortHandKey of applicableLangFileKeys) {
      let result = labels[shortHandKey]?.[key];
      if (!result) continue;

      //placeholder for replacements are within brakets in the translation strings, e.g. {this should be replaced}
      for (const [replaced, replacement] of Object.entries(options?.replace || {})) {
        if (options?.isString !== false) {
          result = result.replace(new RegExp('{' + replaced + '}', 'g'), replacement);
        } else {
          let splitLabel = result.split('{' + replaced + '}', 2);
          if (splitLabel.length !== 2) return result;
          return `${splitLabel[0]} ${replacement} ${splitLabel[1]}`;
        }
      }

      if (options?.isString !== false) result = applyStringOptions(result, options);

      return result;
    }

    const arrKey = key.split('.');

    if (arrKey.length === 1) {
      //app-level translations (not overridden by module-level one)
      if (typeof labels[arrKey[0]] === 'string')
        // LUCAS : this should not be happening
        throw new Error('Invalid translation format');
      // return applyStringOptions(labels[arrKey[0]], options);
      else return applyStringOptions(key, options); //worst case scenario: label not found, apply options. this is to format strings returned by the back
    }

    if (labels[arrKey[0]] && labels[arrKey[0]][arrKey[1]]) return labels[arrKey[0]][arrKey[1]];

    return applyStringOptions(key, options); //worst case scenario: label not found, apply options. this is to format strings returned by the back
  };

export function applyStringOptions(txt: string, options?: StringOptions): string {
  let result = txt;
  // TODO refactor to use newer textTransform helper. Since this requires more refactoring to use enums instead of string literals
  switch (options?.textTransform) {
    case 'uppercase':
      result = result.toUpperCase();
      break;
    case 'lowercase':
      result = result.toLowerCase();
      break;
    case 'capitalize':
      result = result.charAt(0).toUpperCase() + result.slice(1);
      break;
    default:
  }
  switch (
    options?.start //todo: support asian languages
  ) {
    case 'whitespace':
      result = ' ' + result;
      break;
    case 'comma':
      result = ', ' + result;
      break;
    case 'colon':
      result = ': ' + result;
      break;
    case 'semi-colon':
      result = '; ' + result;
      break;
    default:
  }
  switch (
    options?.end //todo: support asian languages
  ) {
    case 'whitespace':
      result = result + ' ';
      break;
    case 'comma':
      result = result + ', ';
      break;
    case 'colon':
      result = result + ': ';
      break;
    case 'semi-colon':
      result = result + '; ';
      break;
    default:
  }

  return result;
}

function withLang(moduleId?: string, langFileKeys: string[] = []) {
  return <T extends WithLangProps>(WrappedComponent: React.ComponentType<T>) => {
    type PartialType = Subtract<T, WithLangProps>;
    type WithLangComponentProps = {
      partialProps: PartialType;
      languageCode: string;
    };

    const WithLang = ({ partialProps, languageCode }: WithLangComponentProps) => {
      const isMounted = useRef(false);
      const [labelFunc, setLabelFunc] = useState<LabelFunc>();

      const getLabelFunc = useCallback(async () => {
        const WrappedComponentName = WrappedComponent.name || WrappedComponent.displayName || '';
        const cleanedLangFileKeys = (langFileKeys || []).map((langFileKey) => {
          const matchFileExtensionJSorTS_X = langFileKey.match(/\.[jt]sx?$/);
          const isIndexFile = langFileKey.startsWith('/index');
          if (matchFileExtensionJSorTS_X && !isIndexFile) {
            const stdSepKey = langFileKey.replace(/\\/g, '/');

            return stdSepKey.slice(
              stdSepKey.lastIndexOf('/') + 1,
              -matchFileExtensionJSorTS_X[0].length
            );
          }
          if (isIndexFile) {
            return WrappedComponentName;
          }
          return langFileKey;
        });

        let moduleTranslations: Record<string, Record<string, Translations>> = {};
        try {
          const translationModule = await import(
            '../modules/' + moduleId + '/languages/' + languageCode
          );
          moduleTranslations[languageCode] = translationModule.default;
        } catch (e) {
          /** It's acceptable not to have a translation file for this language code */
          moduleTranslations[languageCode] = {};
        }

        /** Always add en-US as a fallback (if not already loaded) */
        let enModule;
        if (languageCode !== 'en-US') {
          try {
            enModule = await import('../modules/' + moduleId + '/languages/en-US');
            moduleTranslations['en-US'] = enModule.default;
          } catch (e) {
            /**
             * It's acceptable not to have a default translation file for the module:
             * We may rely on shared translations
             */
            moduleTranslations['en-US'] = {};
          }
        }

        let applicableLangFileKeys = cleanedLangFileKeys;
        if (!applicableLangFileKeys) {
          /** By default, the applicable key is the component name */
          applicableLangFileKeys = [WrappedComponentName as string];
        }
        /**
         * Specific keyword, always added. it may have been added already
         * in which case it will be skipped
         */
        applicableLangFileKeys.push('All');
        /**
         * Translations available in a module are the concatenation of module-specific translations
         * (with a fallback on English) and cross-module translations (with a fallback to English,
         * and overridden by module-specific translations when applicable)
         */
        const labels = {
          ...(moduleTranslations['en-US'] || {}),
          ...(moduleTranslations[languageCode] || {}),
        };

        labels.All = {
          ...crossModuleTranslations['en-US'],
          ...(crossModuleTranslations[languageCode] || {}),
          ...labels.All,
        };

        const labelFunction = labelFuncCreator(applicableLangFileKeys, labels);

        /** Put into state if the component is still mounted */
        if (isMounted.current) {
          setLabelFunc(() => {
            return labelFunction;
          });
        }
      }, [languageCode]);

      useEffect(() => {
        isMounted.current = true;
        return () => {
          isMounted.current = false;
        };
      }, []);

      useEffect(() => {
        if (!labelFunc) getLabelFunc();
      }, [getLabelFunc, labelFunc]);

      const finalProps: PartialType = useMemo(
        () => ({
          ...partialProps,
          languageCode,
          label: labelFunc,
        }),
        [labelFunc, languageCode, partialProps]
      );

      if (!labelFunc) {
        /** Cannot return Loading, or we would have an infinite loop */
        return <></>;
      }
      return <WrappedComponent {...(finalProps as T)} />;
    };

    return (props: PartialType) => {
      const languageCode = useSelector((state: any) => state.Shared.language.currentLanguageCode);

      return <WithLang partialProps={props} languageCode={languageCode} />;
    };
  };
}

export default withLang;
