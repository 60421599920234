import { screen, cleanup } from '@testing-library/react';
import { withRouter } from 'react-router';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import withLang from '../../../context/withLang';

import AccountInformation from './AccountInformation';

jest.mock('../../../helpers/onboardingHelpers', () => {
  return {
    getUserIdentity: jest.fn(),
    isSSOUser: jest.fn(),
    invokeSSORedirect: jest.fn(),
  };
});

const AccountInformationComponent = withRouter(withLang([__filename])(AccountInformation));
describe('AccountInformation', () => {
  afterEach(() => cleanup());

  describe('on first render', () => {
    renderComponent(AccountInformationComponent, {
      languageId: 'en-US',
      siteWithContract: { contractId: '9e282c5d-9e48-4aa8-89b7-e6fd0e109f2' },
      geographies: [{ label: 'Europe', value: 'EU' }],
      isLoggedIn: false,
      locks: { getGeographies: jest.fn() },
      dispatchRegister: jest.fn(),
      dispatchRegisterGuestUser: jest.fn(),
      goToNext: jest.fn(),
      dispatchGetGeographies: jest.fn(),
      dispatchSetGeography: jest.fn(),
      dispatchCheckEmailExists: jest.fn(),
    });
    beforeEach(() => {
      expect(screen.getByTestId('form')).toBeTruthy();
      expect(screen.getByTestId('form-submit')).toBeTruthy();
    });

    it('should have displayed geography, email inputs', () => {
      expect(screen.getByTestId('geography-selector')).toBeTruthy();
      expect(screen.getByTestId('account-info-email-hook-input-field')).toBeTruthy();
    });
  });
});
