import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Button from '../../../../components/atoms/Button';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import { useGetServiceRequestsQuery } from '../../api/index';
import { pagePaths, statusMap } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import ServiceRequestStatus from '../Status/Status';
import { whatsNewLastUpdate } from '../Tutorials/Tutorials';

import { HomeProps, HomeListItemProps, ServiceRequestTile } from './Home.types';

import { NavArrowRightIcon } from '@/assets/icons';
import { ServiceRequestIllustration } from '@/assets/illustrations';
import { Filter, FilterType } from '@/components/atoms/Filters/Filters.types';
import { Tile, TileSkeleton } from '@/components/molecules/Tile';
import { formatDateTime } from '@/helpers/misc';
import { getSRTutorial } from '@/modules/ServiceRequest/helpers/helpers';
import { ServiceRequestProps } from '@/modules/ServiceRequest/types/types';
import { State } from '@/types/state.types';

import '../ServiceRequest/ServiceRequest.css';

const Home = ({ parentSearch, parentFilter }: HomeProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const tutorials = useMemo(() => getSRTutorial(), []);
  const currentLanguageCode = useSelector(
    (state: State) => state.Shared.language.currentLanguageCode
  );

  const { data: serviceRequests = [], isFetching } = useGetServiceRequestsQuery(
    {},
    // Pull new changes from server every 4 minutes
    { pollingInterval: 4 * 60 * 1000 }
  );

  useEffect(() => {
    if (!tutorials?.lastDisplayed || tutorials.lastDisplayed < whatsNewLastUpdate) {
      history.push(pagePaths['Tutorials'] + '?redirectTo=' + pagePaths['Home']);
    }
  }, [tutorials, history]);

  const items = useMemo(() => {
    const itemsResult: HomeListItemProps[] = [];
    serviceRequests?.forEach((serviceRequest: ServiceRequestProps) => {
      const status = serviceRequest?.currentStatus
        ? // @ts-ignore FIXED ME
          statusMap[serviceRequest.currentStatus.toLowerCase()]
        : statusMap.pending;
      const path = pagePaths['Details'].replace(':id', serviceRequest.id);

      itemsResult.push({
        key: serviceRequest.id,
        id: serviceRequest.id ? serviceRequest.id : serviceRequest.tmpId || '',
        addendum: (
          <ServiceRequestStatus status={status} displayStatus={label(`status: ${status}`)} />
        ),
        title: serviceRequest.title || '',
        description: formatDateTime(new Date(serviceRequest.createdOn), currentLanguageCode),
        linkPath: path,
        'data-testid': `service-request-${serviceRequest.id}`,
      });
    });
    return itemsResult;
  }, [label, currentLanguageCode, serviceRequests]);

  const filters: Filter[] = [
    {
      id: 'filter_sr_status',
      options: [
        {
          value: 'all',
          label: label('status: all'),
          default: true,
        },
        {
          value: 'open',
          label: label('status: open'),
        },
        {
          value: 'pending',
          label: label('status: pending'),
        },
        {
          value: 'closed',
          label: label('status: closed'),
        },
      ],
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const filteredServiceRequests = selectedValues.length
          ? serviceRequests.filter(
              (serviceRequest: ServiceRequestProps) =>
                selectedValues.indexOf('all') === 0 ||
                (serviceRequest?.currentStatus &&
                  // @ts-ignore FIXED ME
                  selectedValues.indexOf(statusMap[serviceRequest.currentStatus.toLowerCase()]) >
                    -1)
            )
          : serviceRequests;
        return filteredServiceRequests.map((sr) => sr.id);
      },
    },
  ];

  const filter = {
    filters,
    initialFiltering: parentFilter.filtering,
    handleFilteringChange: parentFilter.handleFilteringChange,
  };

  const search = {
    searchableKeys: ['title', 'description'],
    searchString: parentSearch.searchString,
    handleSearchChange: parentSearch.handleSearchChange,
  };

  return (
    <ListPage
      data-testid="service-request-home-list"
      hasBackLink={false}
      title={label('Ref: Page title')}
      items={items}
      filter={filter}
      isLoading={isFetching}
      search={search}
      hideFilterTitle={true}
      asideFirst
      aside={
        <Column.Complementary>
          <ServiceRequestIllustration />
        </Column.Complementary>
      }
      actions={
        <ActionsBar>
          <Button
            onClick={() => {
              history.push(pagePaths['Create']);
            }}
            data-testid="service-request-home-request-service-create"
          >
            {label('request service')}
          </Button>
        </ActionsBar>
      }
      itemRenderer={(item: ServiceRequestTile) => {
        return (
          <Tile
            id={item.id}
            key={`ServiceRequestTile-${item.id}`}
            title={item.title}
            description={item.description}
            tags={[{ name: item.addendum }]}
            onClick={() => history?.push(item.linkPath)}
            data-testid={`ServiceRequestTile-${item.id}`}
            actions={[
              {
                name: 'NavArrowRightIcon',
                icon: <NavArrowRightIcon />,
                onClick: () => history?.push(item.linkPath),
                'data-testid': `service-request-tile-${item.id}-right`,
              },
            ]}
          />
        );
      }}
    >
      {isFetching &&
        Array.apply(null, Array(10)).map((_, index) => (
          <TileSkeleton key={`${index}-skeleton`} withoutActions withoutImage />
        ))}
    </ListPage>
  );
};

export default Home;
