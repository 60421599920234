import { LabelFunc } from '../../../../context/withLang';

export type FacilityContact = {
  phone?: string;
  email?: string;
};

export type FacilityContactProps = {
  facility: FacilityContact;
  label: LabelFunc;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
