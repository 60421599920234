import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';

import { StepperCreateProps } from './StepperCreate.types';

import Stepper from '@/components/atoms/Stepper/Stepper';

const StepperCreate = ({ horizontal, stepIndex }: StepperCreateProps) => {
  const { label } = useAccommodationRequestTranslation(__filename);

  const steps = [
    {
      index: 0,
      title: label('Ref: Your request'),
      current: stepIndex === 0,
      path: 'create-form',
      label,
    },
    {
      index: 1,
      title: label('Ref: Summary'),
      current: stepIndex === 1,
      path: 'create-summary',
      label,
    },
  ];

  return <Stepper horizontalOnly={horizontal} steps={steps} />;
};

export default StepperCreate;
