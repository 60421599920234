import { SelectInterface } from '@ionic/core';
import { IonSelect, IonSelectOption } from '@ionic/react';
import classNames from 'classnames';

import { ChevronDownIcon } from '../../../assets/icons';
import { WithLangProps } from '../../../context/withLang';
import CustomSelect, { SelectOption } from '../Select';

import { Filtering, FilterOption } from './Filters.types';

import styles from './Filters.module.css';

import { TestingPropsV2 } from 'src/types';

export const DefaultFilter = ({
  filtering,
  applicableOptions,
  id,
  onChange,
  useLegacySelect,
  label,
  isInModal,
  'data-testid': testId,
}: {
  filtering: Filtering;
  applicableOptions: FilterOption[];
  id: string;
  onChange: (filterId: string, strDate: string) => void;
  useLegacySelect?: boolean;
  isInModal: boolean;
} & WithLangProps &
  TestingPropsV2) => {
  let selectedValues = Object.entries(filtering[id] || {});
  if (!selectedValues.length) {
    const defaultOption = applicableOptions.find((el: FilterOption) => el.default);
    if (defaultOption !== undefined) selectedValues = [[defaultOption.value, defaultOption.label]];
  }

  const options = applicableOptions.map(
    (option: FilterOption): SelectOption => ({
      value: option.value,
      label: option.label,
    })
  );
  const handleSelectChange = (id: string, option: SelectOption | undefined) => {
    if (option) {
      onChange(id, option.value);
    }
  };

  const value = selectedValues.reduce((selectedValue: SelectOption | undefined, [key, val]):
    | SelectOption
    | undefined => {
    if (selectedValue) return selectedValue;

    if (key) {
      const opt = applicableOptions.find((opt) => opt.value === key);
      if (opt) return opt;
    }

    const defaultOption = applicableOptions.find((el: FilterOption) => el.default);
    if (defaultOption) return options.find((opt) => opt.value === defaultOption.value);

    return undefined;
  }, undefined);

  // Legacy code : used only for the facility switch in the product list of the shop
  // TODO : remove this from the filters
  if (useLegacySelect) {
    return (
      <div
        className={classNames(styles.selectWrapper, {
          [styles.selectWrapperModal]: isInModal,
        })}
        data-testid={`${testId}-default-filter-legacy-select`}
      >
        <IonSelect
          className={classNames(styles.default_select, {
            [styles.customSelectWidth]: !isInModal,
            [styles.customSelectHeight]: !isInModal,
          })}
          id={id}
          name={id}
          data-cy={'custom-select'}
          interface={'overlay' as SelectInterface}
          interfaceOptions={{ header: label('Please select') }}
          placeholder={label('Please select')}
          onIonChange={(e) => {
            // Needed to prevent the event from firing twice
            if (typeof e.detail.value === 'string') {
              onChange(id, e.detail.value);
            }
          }}
          value={value?.value}
          data-testid={`${testId}-default-filter-legacy-select-input`}
        >
          {applicableOptions.map((option) => (
            <IonSelectOption key={option.value} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        <div className={styles.chevron}>
          <ChevronDownIcon />
        </div>
      </div>
    );
  }

  return (
    <CustomSelect
      className={classNames(styles.customSelect, {
        [styles.customSelectWidth]: !isInModal,
      })}
      onChange={(option) => {
        handleSelectChange(id, option);
      }}
      value={value}
      options={options}
      data-testid={`${testId}-default-filter-custom-select`}
    />
  );
};
