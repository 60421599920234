import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import withLoginStatus from '../../../../context/withLoginStatus';
import {
  applyLanguage,
  login,
  checkEmailExists,
  migrateOldBiteUser,
  setGeography,
  setUserRegionCode,
  setUsername,
} from '../../actions';

import LoginPage from './LoginPage';

import { State } from '@/types/state.types';

function mapStateToProps(state: State) {
  const { context, user, identity } = state.Core;

  const {
    language: { currentLanguageCode, isUserSelected },
    geographies: { list: geographies, defaultGeoCode },
  } = state.Shared;

  let currentGeoCode: string | undefined = context.currentGeoCode || defaultGeoCode;
  const currentRegionCode = geographies.find((el) => el.code === currentGeoCode)?.regionCode;
  if (currentGeoCode && currentRegionCode) {
    //just to be extra sure, reset the region code in localstorage
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE, currentRegionCode);
  } else {
    currentGeoCode = undefined;
  }
  const preferendLanguageId = state.Core?.user?.preferredLanguage?.id;

  return {
    currentGeoCode,
    currentLanguageCode,
    currentRegionCode,
    geographies,
    identity,
    isLanguageUserSelected: isUserSelected,
    username: user.username,
    preferendLanguageId,
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => {
  return {
    applyLanguage: (languageCode: string) =>
      dispatch(applyLanguage({ code: languageCode, isUserSelected: false })),
    setGeography: (geoCode: string) => dispatch(setGeography({ geoCode })),
    setUsername: (username: string) => dispatch(setUsername(username)),
    setUserRegionCode: (
      username: string,
      regionCode: string,
      callbackUrl: string,
      isMobile: boolean
    ) => dispatch(setUserRegionCode({ username, regionCode, callbackUrl, isMobile })),
    login: (geoCode: string, username: string, psw: string, currentLanguageCode: string) =>
      dispatch(login({ geoCode, username, psw, currentLanguageCode, scope: 'offline_access' })), //this is local login, so request a refresh token
    checkEmailExists: (email: { email: string }) => dispatch(checkEmailExists(email)),
    migrateOldBiteUser: (email: string, password: string, languageId: string) =>
      dispatch(migrateOldBiteUser({ email, password, languageId })),
  };
};

export default withLoginStatus(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
