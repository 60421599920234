const labels = {
  PrivacyPolicy: 'Tietosuojakäytäntö',
  TermsOfSale: 'Myyntiehdot',
  TermsOfUse: 'käyttöehdot',
  home: 'Koti',
  anytime: 'Milloin vain',
  anywhere: 'missä vain',
  Download: 'ladata',
  scrollRightButton: 'Vieritä luokkia oikealle',
  scrollLeftButton: 'Vieritä luokkia vasemmalle',
  calendar: 'Kalenteri',
  'See more': 'Katso lisää',
  'See less': 'Katso vähemmän',
  'from (followed by a date, or date and time)': 'alkaen',
  'to (followed by a date, or date and time)': 'to',
  'on (followed by a date)': 'Päällä',
  'at (followed by time)': 'at',
  'from (followed by a location)': 'alkaen',
  'to (followed by a location)': 'to',
  'on (followed by a site name)': 'Päällä',
  'from (followed by a person)': 'alkaen',
  previous: 'Edellinen',
  next: 'Seuraava',
  success: 'menestys',
  failure: 'epäonnistuminen',
  today: 'Tänään',
  tomorrow: 'Huomenna',
  Monday: 'maanantai',
  Tuesday: 'tiistai',
  Wednesday: 'keskiviikko',
  Thursday: 'torstai',
  Friday: 'perjantai',
  Saturday: 'Lauantai',
  Sunday: 'sunnuntai',
  everyday: 'Joka päivä',
  'open all day': 'Avoinna koko päivän',
  'opening hours': 'Aukioloajat',
  status: 'Tila',
  'to be confirmed': 'Vahvistettava',
  'only (as in filter only this)': 'vain',
  edit: 'muokata',
  submit: 'Lähetä',
  cancel: 'Peruuttaa',
  confirm: 'Vahvistaa',
  reset: 'Nollata',
  Delete: 'Poistaa',
  select: 'valitse',
  switch: 'vaihtaa',
  close: 'kiinni',
  clear: 'asia selvä',
  remove: 'Poista',
  logout: 'kirjaudu ulos',
  go: 'mennä',
  'see (as in consult this page or document)': 'katso',
  'see all (as in see all items)': 'Näytä kaikki',
  'see more (as in see more details for this item)': 'Katso lisää',
  retry: 'yritä uudelleen',
  'Ref: ok': 'Hyvä',
  'yes (as in yes I accept)': 'Kyllä',
  'no (as in no I do not accept)': 'Ei',
  you: 'sinä',
  'thank you': 'Kiitos',
  'sorry (standalone)': 'anteeksi',
  'to home (as in navigate to the homepage)': 'kotiin',
  map: 'kartta',
  list: 'lista',
  'Loading...': 'Ladataan...',
  'Refreshing...': 'Päivitetään...',
  'Please select': 'Ole hyvä ja valitse',
  'Switch site': 'Vaihda sivusto täällä',
  'File size cannot exceed {max file size}': 'Tiedoston koko ei voi ylittää {max file size}',
  Filters: 'Suodattimet',
  'Add attachment': 'Lisää LIITE',
  Search: 'Hae',
  'More (as in: see more info)': 'Lisää',
  'You can attach a maximum of {max files number} files.':
    'Voit liittää enintään {max files number} tiedostoa.',
  'There are no messages to display.': 'Näytettäviä viestejä ei ole.',
  'Your selection did not return any result.': 'Valintasi ei tuottanut tulosta.',
  'Ref: Popup header for missing required fields in a form': 'Anna lisätietoja',
  'Ref: Required field': '{ref} on pakollinen kenttä.',
  email: 'Sähköposti',
  phone: 'Matkapuhelinnumero',
  Account: 'Tili',
  Contacts: 'Yhteystiedot',
  Content: 'Sisältö',
  Events: 'Tapahtumat',
  Facilities: 'Tilat',
  Shop: 'Myymälä',
  Menu: 'Valikko',
  Menus: 'Valikot',
  Order: 'Tilaus',
  Orders: 'Tilaus',
  Info: 'Tiedot',
  FAQ: 'Faq',
  Feedback: 'Palaute',
  Review: 'Arvostelu',
  Requests: 'Pyynnöt',
  'Request Helpdesk': 'Pyydä avustaa',
  Location: 'Sijainti',
  Safety: 'Turvallisuus',
  FreeCoffee: 'Ilmainen kahvi',
  Newsfeed: 'Uutisvirta',
  MyOrders: 'Tilaukseni',
  LoyaltyStamps: 'Kanta -asiakasleimat',
  Stamps: 'Leimat',
  Stays: 'Pysyy',
  Suppliers: 'Toimittajat',
  Surveys: 'Tutkimukset',
  notifications: 'Ilmoitukset',
  cart: 'kori',
  'Ref: Reset your order': 'Nollaa tilauksesi',
  'Ref: My language': 'Minun kieleni',
  Validate: 'Validoida',
  'contact us': 'ota meihin yhteyttä',
  'Ref: Start typing': 'Aloita kirjoittaminen',
  Occupancy: 'Käyttöaste',
  'see less': 'Nähdä vähemmän',
  'see more': 'Katso lisää',
  contacts: 'Kontaktit',
  Imprint: 'Jälki',
  'Ref: camera access request title': 'Salli kameran käyttäminen',
  'Ref: camera access request body':
    'Sinun on otettava käyttöön kamera tällä sivustolla QR -koodi skannerin käyttämiseksi',
  or: 'tai',
  'Ref: Input required': '{input_name} vaaditaan',
  'Enter a valid email address': 'syötä voimassa oleva sähköpostiosoite',
  'Ref: Input error E-mail already exists':
    'Antamallasi sähköpostiosoitteella on jo tili. Kirjaudu sen sijaan.',
  'Password must contain': 'Salasanan on oltava:',
  'Ref: Input password error strength':
    'Salasanan on sisällettävä vähintään 8 merkkiä, 1 numero, 1 kirjain ja 1 erikoismerkki',
  Password: 'Salasana',
  'New password': 'Uusi salasana',
  'Confirm password': 'Vahvista salasana',
  'Ref: Password does not match': 'Salasana ja vahvista salasana eivät vastaa',
  'Ref: Old password does not match': 'Vanha salasana ei vastaa',
  'Ref: Password validation min8Characters': 'Vähintään 8 merkkiä',
  'Ref: Password validation min1Number': 'Vähintään yksi numero',
  'Ref: Password validation min1Letter': 'Vähintään yksi kirjain',
  'Ref: Password validation min1SpecialChar': 'Ainakin yksi erikoismerkki',
  Continue: 'Jatka',
  'Enter a valid phone number': 'Kirjoita kelvollinen puhelinnumero',
  'Phone is required': 'Puhelinnumero vaaditaan',
  'Go to home': 'Mene kotiin',
  'Something went wrong': 'Jotain meni pieleen',
  'Something went wrong, data not loaded': 'Jokin meni pieleen, tietoja ei ladattu',
  'Change password': 'Vaihda salasana',
  'remove one': 'poista yksi',
  'add one': 'lisätä yksi',
  'Confirm Filters': 'Vahvista suodattimet',
  'Reset Filters': 'Nollaa suodattimet',
  '{selectedNum} active filters': '{selectedNum} aktiiviset suodattimet',
  'Wrong input icon': 'Väärä syöttökuvake',
  'Correct input icon': 'Oikea syöttökuvake',
  'Show input text icon': 'Näytä syöttötekstikuvake',
  'Hide input text icon': 'Piilota syöttötekstikuvake',
  'Required fields info': '* Pakolliset kentät',
  'Ref: Select site on the map': 'Valitse sivustosi kartalla',
  'Ref: Geolocation disabled':
    'Geolocation on poistettu käytöstä. Jos haluat nähdä lähellä olevat sivustot, ota se käyttöön.',
  'Ref: Reaction - LIKE': 'Kuten',
  'Ref: Reaction - LOVE': 'Rakkaus',
  'Ref: Reaction - INTERESTING': 'Mielenkiintoista',
  'Ref: Reaction - CONGRATE': 'Onnitella',
  'Ref: Share': 'Jaa',
  'Ref: Send by Email': 'Lähetä sähköpostitse',
  'Ref: Copy the link': 'Kopioi linkki',
  'Ref: Link was copied': 'Linkki kopioitiin',
  'Ref: unable to copy url': 'URL -osoitetta ei voi kopioida',
  'Ref: External link': 'Ulkoinen linkki',
  'Ref: Step': 'Askel',
  'input field': 'syöttökenttä',
  'Ref: Zoom': 'Zoomaus',
  'Increase hours': 'Pidentää tunteja',
  'Decrease hours': 'Vähentää tunteja',
  Hours: 'Tuntia',
  Minutes: 'Pöytäkirja',
  'Ref: filter label: favorites': 'Näytä vain suosikkini',
  'Ref: filter label: max calories': 'Max-kalorit',
  'Ref: filter label: dishes': 'Astiat',
  'Ref: filter label: allergens': 'Allergeenit',
  'Ref: filter label: highlight': 'Korosta tai piilota suodattimet astioilla',
  'Ref: Highlight': 'Kohokohta',
  'Ref: Hide': 'Piilottaa',
  'Ref: Vegan': 'Vegaani',
  'Ref: Vegeterian': 'Kasvissyöjä',
  'Ref: Mindful': 'Tietoinen',
  'Ref: Highlight text':
    'Täällä voit valita hightlight tai piilottaa ruokia valikossa, joka sisältää tiettyjä allergeeneja',
  'Ref: Just now': 'Juuri nyt',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minuutti sitten',
  'Ref: 1 hour ago': '1 tunti sitten',
  'Ref: {amountHours} hours ago': '{amountHours} tuntia sitten',
  'Ref: Yesterday at {time}': 'Eilen {time}',
  'Ref: {date} at {time}': '{date} {time}',
  'Ref: filter label: date': 'Päivämäärä',
  'Ref: expand menu': 'laajentaa valikkoa',
  'Ref: collapse menu': 'romahdusvalikko',
  'Ref: My site': 'Sivuni',
  'Ref: Recent': 'Äskettäinen',
  'Ref: Services': 'Palvelut',
  'Ref: products in cart': 'Tuotteiden lukumäärä kärryssä',
  'Ref: no product in cart': 'Ei tuotetta kärryssä',
  'Ref: Survey': 'Tutkimus',
  'Access to camera was denied. Please allow access and try again':
    'Kameran pääsy hylättiin. Salli pääsy ja yritä uudelleen',
  'Add an attachment': 'Lisää liite',
  'Take a picture': 'Ottaa kuva',
  'Add a file from your device': 'Lisää tiedosto laitteesta',
  'Ref: Loading page content, please wait': 'Sivun sisällön lataaminen, odota',
  Star: 'Tähti',
  Stars: 'Tähdet',
  'Accommodation Request': 'Majoituspyyntö',
  'Ref: Guest Accomodation Request': 'Vieraspyyntö',
  'Ref: Search a menu, product or facility': 'Etsi valikko, tuote tai laitos',
  ConnectionLost: 'Verkkoyhteys kadonnut',
  ConnectionRestored: 'Verkkoyhteys kunnostettu',
  'Ref: Skip to main content': 'Siirry pääsisältöön',
  'Ref: No options': 'Ei vaihtoehtoja',
  'Ref: Global search modal': 'Globaali haku modaali',
  'Ref: Search a facility': 'Etsiä tilaa',
  'Ref: Search a menu or product': 'Etsi valikko tai tuote',
  'Ref: Reaction': 'Reaktio',
  'Ref: times': 'ajat',
  'Ref: reaction. Change reaction': 'reaktio. Muutosreaktio',
  'Ref: No reaction. React': 'Ei reaktiota. Reagoida',
  'Ref: LIKE': 'Kuten',
  'Ref: LOVE': 'Rakkaus',
  'Ref: INTERESTING': 'Mielenkiintoista',
  'Ref: CONGRATE': 'Onnea',
  'Ref: Open search': 'Avaa hakukenttä',
  'Ref: Close search': 'Sulje hakukenttä',
  'Ref: Total file size msg': 'Tiedoston kokonaiskoko ei voi ylittää {max file size}',
  'Ref: Oops Title': 'Oho!',
  'Ref: Well Done': 'Hyvin tehty {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigointikieli - Jatka',
  'Ref: Reload': 'Ladata uudelleen',
  'Ref: All Dates': 'Kaikki päivämäärät',
  'Ref: Today': 'Tänään',
  'Ref: Validate': 'Validoida',
  'Ref: toast error title': 'Oho!',
  'Ref: toast error subtitle': 'Tapahtui virhe. Ole hyvä ja yritä uudelleen.',
  'Ref: Calendar': 'Kalenteri',
  'Ref: Selected': 'Valittu',
  'Ref: Previous Month': 'Edellisen kuukauden',
  'Ref: Next Month': 'Ensikuussa',
  'This module has been retired': 'Tämä moduuli on poistettu käytöstä',
};
export default labels;
