import classNames from 'classnames';
import momentjs from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Restaurant, Time, InformationIcon, BarcodeIcon } from '../../../../assets/icons';
import { MenuIllustration } from '../../../../assets/illustrations';
import Button, {
  BADGE_PLACEMENT,
  BADGE_POSITION,
  BUTTON_LOOK,
} from '../../../../components/atoms/Button';
import {
  ButtonModalFilters,
  FilterComponent,
  useFilters,
} from '../../../../components/atoms/Filters';
import SearchBar from '../../../../components/atoms/SearchBar/SearchBar';
import Title from '../../../../components/atoms/Title/Title';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title/Title.types';
import { ContentList } from '../../../../components/molecules/ContentList';
import SecondaryActions from '../../../../components/molecules/SecondaryActions/SecondaryActions';
import ShowMore from '../../../../components/molecules/ShowMore';
import { TILE_VARIANT } from '../../../../components/molecules/Tile';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import { useFiltering } from '../../../../components/templates/ListPage';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { DATE_FORMAT, SETUP_OPTIONS } from '../../../../constants';
import useToggle from '../../../../helpers/hooks/useToggle';
import { formatDate, isKiosk, toLocalDate } from '../../../../helpers/misc';
import { TestingPropsV2 } from '../../../../types';
import { useGetFilterPreferencesQuery } from '../../../Core/api/account/accountPreferencesApi';
import { mapResponseToFilters } from '../../../Core/components/AccountPage/filterPreferences.helper';
import { getAllergens, getMenus, setSiteModalClosed } from '../../actions';
import { menuModuleId, menuModulePath, moduleId, orderConfig, pagePaths } from '../../config';
import ProductTile from '../../containers/ProductTile';
import { getAllergensFromCache } from '../../helpers/allergens.helper';
import { isFFAppSharingEnabled } from '../../helpers/feature.flags.helper';
import { useCheckCanOrder } from '../../hooks/useCheckCanOrder/useCheckCanOrder';
import useMenusVisits from '../../hooks/useMenusVisits/useMenusVisits';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import useResetTableAfterOneHour from '../../hooks/useResetTableAfterOneHour';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useCheckNoSuggestionsToDisplay } from '../../hooks/useSuggestions/useCheckNoSuggestionsToDisplay';
import { useFetchSuggestions } from '../../hooks/useSuggestions/useFetchSuggestions';
import { useMomentBasedSuggestions } from '../../hooks/useSuggestions/useMomentBasedSuggestions';
import { useSuggestionsMobilePopup } from '../../hooks/useSuggestions/useSuggestionsMobilePopup';
import { PageType } from '../../types/menuCartActions.types';
import { MenuType } from '../../types/menuSelector.types';
import { FacilityMenu, FacilityMenuSource } from '../../types/orderState.types';
import {
  HightlightAllergenFilterValues,
  ProductListItem,
  ProductListFiltering,
} from '../../types/productList.types';
import { SuggestPlace } from '../../widgets/SuggestionsWidget/SuggestionsWidget.types';
import { useIsSuggestionsEnabled } from '../../widgets/SuggestionsWidget/useIsSuggestionsEnabled';
import FacilityDetails from '../FacilityDetails';
import ProductScanner from '../ProductScanner';
import SuggestionContainer from '../SuggestionContainer/SuggestionContainer';
import SuggestionSlider from '../SuggestionSlider/SuggestionSlider';

import {
  buildConfig,
  buildFilteringState,
  buildSections,
  cacheSelectedFilters,
  getCartInfo,
  getDefaultFiltersFromCache,
  getViewCartAction,
  mapToListModel,
  siteChangeNotification,
  cutOffDateNotification,
  mapSuggestionItemToProductItem,
  getFilteredSuggestions,
} from './productList.helper';
import { getFilters } from './productListFilters.helper';

import { Share20Icon } from '@/assets/index';
import useGeoCode from '@/helpers/hooks/useGeoCode';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import useShareModal from '@/helpers/hooks/useShareModal';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import { getRedirectionPathWithSiteIdentifier } from '@/modules/Core/components/ModuleRedirectPage/ModuleRedirectPage.helper';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';
import useSite from '@/modules/Core/hooks/useSite';
import { ISite } from '@/modules/Sites/types/sites.types';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

import styles from './ProductsList.module.css';

const ProductsList = () => {
  const { contentRef, saveScrollPosition } = useScrollPosition();
  const { areSuggestionsEnabled, isKioskTitleBar } = orderConfig();
  const { label } = useOrderTranslation(__filename);

  const {
    facilityId,
    menuId,
    date: selectedDate,
    barcode,
  } = useParams<{
    facilityId: string;
    menuId: string;
    date: string;
    barcode: string | undefined;
  }>();

  const site = useSite() as ISite;
  const history = useHistory();

  const state = useSelector((state: State) => state);
  const dispatch = useDispatch();
  const { isGuest } = useLoginStatus();
  const facility = state.Facilities?.data[facilityId] || {};
  const allergens = useMemo(() => state.Order?.allergens || [], [state.Order?.allergens]);

  const {
    Order: {
      cart,
      draft: orderDraft,
      tableNumber,
      tableQRScanTime,
      hasSiteChanged,
      locks: {
        createOrderDraft: orderDraftLock,
        getMenusForFacility: isLocked,
        getAllergens: isAllergensLocked,
      },
      menusType: menuType,
      menus,
    },
  } = useSelector((state: State) => state);

  const { currentLanguageCode: languageCode } = useLanguage();

  const setSiteModalClosedFunction = useCallback(() => dispatch(setSiteModalClosed()), [dispatch]);
  const getAllergensFunction = (siteId: string) =>
    dispatch(getAllergens({ siteId }, { useErrorBoundary: false, withAccessToken: false }));
  const getMenusFunction = (siteId: string, date?: string, menuType?: MenuType) =>
    dispatch(getMenus({ siteId, date, useCache: true, menuType }));

  const selectedMenu = menus.find((x) => x.id.toString() === menuId) as FacilityMenu;

  const moments = useMemo(() => {
    const momentsArray: string[] = [];
    selectedMenu.menuItems.forEach((menuItem) => {
      if (!momentsArray.find((x) => x === menuItem.dayPart) && menuItem.dayPart !== null) {
        momentsArray.push(menuItem.dayPart);
      }
    });
    return momentsArray;
  }, [selectedMenu.menuItems]);

  const hideBackBtn = menus.length === 1 && !menus[0].isFutureOrdering;

  const isRrMenu = useMemo(
    () => selectedMenu?.source === FacilityMenuSource.Rr,
    [selectedMenu?.source]
  );

  const { data: filterPreferences, isLoading: isLoadingPreferences } = useGetFilterPreferencesQuery(
    {
      siteId: site.id,
      useErrorBoundary: false,
    }
  );

  const { logUserSteps } = useUserStepsInsightsLogging();

  const isSuggestionsOnProductList = useIsSuggestionsEnabled(SuggestPlace.PRODUCT_LIST);

  const { suggestions, isLoading } = useFetchSuggestions(
    site.id,
    selectedDate,
    selectedMenu?.id,
    isRrMenu,
    selectedMenu?.isOrderable
  );

  const {
    remainingSeconds,
    showSuggestionPopup,
    showProgressBar,
    openPopupAndCountDown,
    isFirstItemScanned,
    setIsFirstItemScanned,
    closePopupResetTime,
    stopCountdown,
  } = useSuggestionsMobilePopup(isRrMenu, selectedMenu?.isOrderable);

  const [isScanOpen, setIsScanOpen] = useState<boolean>(false);
  const { increaseMenuVisits } = useMenusVisits(site.id);

  const enableSharingFeature = isFFAppSharingEnabled();

  const shareSiteIdentifier = useSetupOption(
    SETUP_OPTIONS.RETAIL.shareSiteIdentifier,
    SERVICE.FOOD_MENU
  ).value;

  const { currentGeoCode } = useGeoCode();

  const shareableLink: string = useMemo(() => {
    let link: string = '';
    if (shareSiteIdentifier) {
      const url = window.location.href;
      const isMenuModule =
        menuType === MenuType.NonOrderable ||
        (menuType === MenuType.All && url.includes(`${menuModulePath}/`));

      const menuRouteParams = {
        menuId: (selectedMenu?.id || 'no-menu').toString(),
        menuDate: selectedDate,
      };

      const redirectionPathForProductList = getRedirectionPathWithSiteIdentifier(
        isMenuModule ? menuModuleId : moduleId,
        shareSiteIdentifier,
        currentGeoCode,
        menuRouteParams
      );

      link = redirectionPathForProductList;
    }
    return link;
  }, [selectedDate, shareSiteIdentifier, menuType, currentGeoCode, selectedMenu?.id]);

  const { shareOptionsModal, triggerShareModal } = useShareModal({ label });

  const handleShare = () =>
    triggerShareModal(`${facility.title} - ${selectedMenu.name}`, shareableLink);

  const refreshMenu = useCallback(() => {
    increaseMenuVisits(selectedMenu?.id);
    if (!isLocked && site?.id && selectedDate) getMenusFunction(site.id, selectedDate, menuType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMenus, site.id, selectedDate]);

  const {
    state: isFacilityModalOpen,
    toggleOn: openFacilityDetails,
    toggleOff: closeFacilityDetailsModal,
  } = useToggle(false);

  const refreshAllergens = useCallback(() => {
    if (!isAllergensLocked) getAllergensFunction(site.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllergens]);

  const { canOrder, canOrderLoading } = useCheckCanOrder({
    siteId: site.id,
    orderPickupDate: selectedDate,
    selectedMenu: selectedMenu,
  });

  useResetTableAfterOneHour({ tableQRScanTime });

  useEffect(() => {
    refreshMenu();
  }, [refreshMenu]);

  useEffect(() => {
    refreshAllergens();
  }, [refreshAllergens]);

  useEffect(() => {
    setIsScanOpen(selectedMenu?.isScanAndGo);
  }, [selectedMenu?.isScanAndGo]);

  useEffect(() => {
    if (!!facilityId && !!facility.title) {
      logUserSteps({
        event: UserSteps.ProductList,
        facilityId,
        facilityName: facility.title,
      });
    }
  }, [facility.title, facilityId, logUserSteps]);

  const defaultFilters = useMemo(() => {
    const cachedFilters = getDefaultFiltersFromCache(site.id, selectedMenu?.name, selectedDate);
    if (!!cachedFilters) {
      return cachedFilters;
    } else {
      if (!!filterPreferences && !isLoadingPreferences) {
        return mapResponseToFilters(filterPreferences) as ProductListFiltering;
      }
      return null;
    }
  }, [filterPreferences, isLoadingPreferences, selectedDate, selectedMenu?.name, site]);

  const [hideAllergens, setHideAllergens] = useState(
    defaultFilters?.filter_allergens_highlight
      ? defaultFilters.filter_allergens_highlight[HightlightAllergenFilterValues.Hide]
      : false
  );

  const [selectedAllergens, setSelectedAllergens] = useState(
    getAllergensFromCache(filterPreferences) || []
  );
  const [selectedMoment, setSelectedMoment] = useState(moments[0]);

  const momentBasedSuggestions = useMomentBasedSuggestions(suggestions, selectedMoment, moments);

  const { suggestionsLimitMobile, suggestionsLimitDesktop } =
    useCheckNoSuggestionsToDisplay(momentBasedSuggestions);

  const listItems: ProductListItem[] = useMemo(
    () =>
      selectedMenu
        ? mapToListModel({
            menu: selectedMenu,
            highlightAllergens: !hideAllergens,
            selectedAllergens: selectedAllergens || [],
            selectedMoment,
          })
        : [],
    [hideAllergens, selectedAllergens, selectedMenu, selectedMoment]
  );
  const sections = useMemo(() => (listItems?.length ? buildSections(listItems) : []), [listItems]);

  const listConfig = useMemo(
    () => (sections?.length ? buildConfig(sections?.length) : {}),
    [sections?.length]
  );

  const cartInfo = getCartInfo(cart, orderDraft);

  const getFilteringValue = useCallback(() => {
    return buildFilteringState({ moments, selectedAllergens, hideAllergens, defaultFilters });
  }, [defaultFilters, hideAllergens, moments, selectedAllergens]);

  const [basefiltering, setBaseFiltering] = useState<ProductListFiltering | null>(null);

  const [initialfiltering, setInitialFiltering] = useState<ProductListFiltering>({});

  useEffect(() => {
    if (!isLoadingPreferences) {
      setInitialFiltering(getFilteringValue());
    }
  }, [getFilteringValue, isLoadingPreferences]);

  const redirectToCart = () => history.push(pagePaths.Cart);

  const listFilters = useMemo(
    () => ({
      filters: getFilters({
        label,
        menuItemsList: listItems,
        sections,
        moments,
        allergens,
        hideAllergens,
        isGuest,
        setHideAllergens,
        selectedAllergens,
        setSelectedAllergens,
        setSelectedMoment,
        menuSource: selectedMenu?.source,
      }),
      initialFiltering: basefiltering ?? initialfiltering,
      handleFilteringChange: (newFiltering: ProductListFiltering) => {
        setBaseFiltering(newFiltering);
        cacheSelectedFilters(newFiltering, site.id, selectedMenu?.name, selectedDate);
      },
    }),
    [
      allergens,
      basefiltering,
      hideAllergens,
      initialfiltering,
      label,
      listItems,
      moments,
      sections,
      selectedAllergens,
      selectedDate,
      selectedMenu?.name,
      selectedMenu?.source,
      site,
      isGuest,
    ]
  );

  const search = useMemo(
    () => ({
      searchableKeys: ['title'],
      placeholder: label('Ref: Search products list'),
    }),
    [label]
  );

  const displaySelectedDate = () => {
    if (selectedDate === momentjs().format(DATE_FORMAT))
      return label('Ref: today', { textTransform: 'capitalize' });
    if (selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT))
      return label('Ref: tomorrow', { textTransform: 'capitalize' });
    return formatDate(toLocalDate(selectedDate), languageCode);
  };

  const topContentSiteChangeNotification = useMemo(
    () =>
      siteChangeNotification({
        hasSiteChanged,
        setSiteModalClosed: setSiteModalClosedFunction,
        siteName: site.name,
        labelFn: label,
      }),
    [hasSiteChanged, label, setSiteModalClosedFunction, site]
  );

  const topContentCutOffDateNotification = useMemo(
    () =>
      cutOffDateNotification({
        isOrderingPossible: canOrder,
        isOrderingPossibleLoading: canOrderLoading,
        labelFn: label,
      }),
    [canOrder, canOrderLoading, label]
  );

  const topContentNotifications = (topContentSiteChangeNotification ||
    topContentCutOffDateNotification) && (
    <div className={classNames(styles.notificationsWrapper, 'mb-L')}>
      {topContentSiteChangeNotification}
      {topContentCutOffDateNotification}
    </div>
  );

  const listItemsWithTestId = useMemo(() => {
    return listItems.map((item) => ({
      ...item,
      'data-testid': `product-list-item-${item.id}`,
    }));
  }, [listItems]);

  const {
    filterItems,
    hasFilters,
    selectedNum,
    hasAtLeastOneFilterInModal,
    searchString,
    filtering,
    handleSearchChange,
    handleFilteringChange,
  } = useFiltering({ items: listItemsWithTestId, filter: listFilters, search });

  const {
    selectedFilters,
    visibleFilters,
    modalFilters,
    onCommitFilter,
    resetModalFilters,
    onDismissModal,
    handleFilterChange,
    handleCalendarChange,
  } = useFilters({
    filters: listFilters.filters,
    filtering,
    handleChange: handleFilteringChange,
    label,
    languageCode,
    menuId: selectedMenu?.id,
    testId: 'product-list-filters',
  });

  const filterMoment = useMemo(() => {
    const momentFilter = visibleFilters.find((obj) => {
      return obj.id === 'filter_moment';
    });

    //If none of the moment is saved in filters then we always pick first moment (if multiple exist)

    const hasFilteredMoment: boolean =
      !!filtering['filter_moment'] && Object.keys(filtering['filter_moment']).length > 0;

    return momentFilter ? (
      <FilterComponent
        key={momentFilter.id}
        filter={momentFilter}
        filtering={
          hasFilteredMoment
            ? filtering
            : { ...filtering, filter_moment: { [moments[0]?.toLowerCase()]: true } }
        }
        label={label}
        languageCode={languageCode}
        handleChange={handleFilterChange}
        handleCalendarChange={handleCalendarChange}
        data-testid="product-list-filter-moment"
        className={styles.dayParts}
      />
    ) : null;
  }, [
    filtering,
    handleCalendarChange,
    handleFilterChange,
    label,
    languageCode,
    moments,
    visibleFilters,
  ]);

  const filterCategory = useMemo(() => {
    const categoryFilter = visibleFilters.find((obj) => {
      return obj.id === 'filter_category';
    });

    return categoryFilter ? (
      <FilterComponent
        key={categoryFilter.id}
        filter={categoryFilter}
        filtering={filtering}
        label={label}
        languageCode={languageCode}
        handleChange={handleFilterChange}
        handleCalendarChange={handleCalendarChange}
        data-testid="product-list-filter-category"
      />
    ) : null;
  }, [filtering, handleCalendarChange, handleFilterChange, label, languageCode, visibleFilters]);

  if (isLocked || isLoadingPreferences || !selectedMenu)
    return <LoadingPage data-testid="loading-page-products-list" />;

  const hasTableAssigned = !!tableNumber;

  const onQRTileClick = () => {
    setIsScanOpen(true);
  };

  const onAddFirstItemToCart = {
    ...(suggestions.length > 0 && (!cart || cart?.menuPortionItems?.length === 0)
      ? { onAddFirstItemToCart: openPopupAndCountDown }
      : {}),
  };

  const onScanFirstItemToCart = {
    ...(!cart || cart?.menuPortionItems?.length === 0
      ? { onAddFirstItemToCart: setIsFirstItemScanned }
      : {}),
  };

  const productScanner = selectedMenu?.isScanAndGo && listItems?.length && (
    <ProductScanner
      className={styles.scan}
      menuItems={selectedMenu.menuItems}
      facilityId={facilityId}
      date={toLocalDate(selectedDate)}
      menuId={selectedMenu.id}
      site={site}
      languageCode={languageCode}
      cartModificationDisabled={orderDraftLock}
      isScanOpen={isScanOpen}
      {...onScanFirstItemToCart}
      onDismiss={() => {
        setIsScanOpen(false);
        suggestions.length > 0 && isFirstItemScanned && openPopupAndCountDown();
      }}
      barCode={barcode}
    />
  );

  const listActions = [
    getViewCartAction({
      label,
      cartInfo,
      languageCode,
      isOrderableMenu: selectedMenu.isOrderable,
      redirectToCart,
      siteId: site.id,
      productScanner: productScanner || undefined,
    }),
  ].filter((x) => !!x);

  const facilityTitle = (
    <div className={styles.facilityTitle}>
      <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
        {facility.title}
      </Title>
    </div>
  );

  const description = selectedMenu?.message ? (
    <ShowMore data-testid="product-list-description" text={selectedMenu.message} label={label} />
  ) : null;

  const listTopContent = (
    <div>
      {topContentNotifications}
      <div className={styles.pageHeader}>
        <div className={styles.facility}>
          <div>{facilityTitle}</div>
        </div>
        <div className={styles.moment} data-testid={'page-header-moment'}>
          <p className={styles.dateString}>
            <span className={styles.dateIcon}>
              <Time />
            </span>
            {displaySelectedDate()}
          </p>
          {selectedMenu?.isScanAndGo ? (
            <p className={styles.dateString} data-testid={'page-header-scan-and-go'}>
              <span className={styles.dateIcon}>
                <BarcodeIcon />
              </span>
              {label('Ref: Scan&Go')}
            </p>
          ) : null}
        </div>
        {hasTableAssigned && (
          <div className={styles.moment} data-testid={'page-header-table'}>
            <p className={styles.dateString}>
              <span className={styles.dateIcon}>
                <Restaurant />
              </span>
              {`${label('Ref: Table')}: ${tableNumber}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );

  const filterButton = hasAtLeastOneFilterInModal ? (
    <ButtonModalFilters
      label={allergens.length > 0 ? label('Ref: Filters') : label('Filters')}
      srOnlyLabel={label('{selectedNum} active filters', {
        replace: { selectedNum: selectedNum.toString() },
      })}
      hasFilters={hasFilters}
      filters={modalFilters}
      filtering={filtering}
      hideFilterTitle={true}
      handleFilteringChange={handleFilterChange}
      selectedNum={selectedNum}
      badgePosition={BADGE_POSITION.RIGHT}
      badgePlacement={BADGE_PLACEMENT.INNER}
      look={BUTTON_LOOK.TERTIARY}
      className={styles.filterButton}
      languageCode={languageCode}
      uncommitedFiltering={selectedFilters}
      onCommitFilter={onCommitFilter}
      onDismissModal={onDismissModal}
      onResetModalFilters={resetModalFilters}
      data-testid="product-list-filter-button-modal"
    />
  ) : null;

  const secondaryActions = (
    <SecondaryActions>
      <div>
        <Button
          data-testid="product-list-open-facility-details"
          look={BUTTON_LOOK.TERTIARY}
          onClick={openFacilityDetails}
          affix={InformationIcon}
        >
          {label('Ref: Facility details')}
        </Button>
        <FacilityDetails
          label={label}
          isFacilityModalOpen={isFacilityModalOpen}
          onFacilityModalDismiss={closeFacilityDetailsModal}
          facility={facility}
        />
      </div>
      {filterButton}
      {shareSiteIdentifier && !isKiosk && enableSharingFeature && (
        <Button
          data-testid="share-product-list-link"
          look={BUTTON_LOOK.TERTIARY}
          onClick={handleShare}
          affix={Share20Icon}
        >
          {label('Ref: Share')}
        </Button>
      )}
    </SecondaryActions>
  );

  const filtersAndSearchBar =
    listFilters.filters || search ? (
      <SearchBar
        {...search}
        withCardWrapper
        collapsible={filterCategory ? true : false}
        right={filterCategory ? [filterCategory] : []}
        searchString={searchString}
        handleChange={handleSearchChange}
        data-testid="products-list-search-bar"
      />
    ) : null;

  return (
    <SimpleFormPage
      hasBackLink={!hideBackBtn}
      title={label('Ref: Page title')}
      contentRef={contentRef}
      hideAccountWidget={isKioskTitleBar}
      hideNotificationsWidget={isKioskTitleBar}
    >
      <Container>
        <Column.Main>
          {listTopContent}
          {filterMoment}
          {secondaryActions}
          {description}
          {filtersAndSearchBar}
          <ContentList
            items={filterItems}
            config={listConfig}
            sections={sections}
            twoTilesColumns
            noResults={{ title: label('Your selection did not return any result.') }}
            itemRenderer={(item: ProductListItem & TestingPropsV2) => {
              const props = {
                ...item,
                facilityId: facilityId,
                date: selectedDate,
                menu: selectedMenu,
                saveScrollPosition,
                isOrderableMenu: selectedMenu.isOrderable,
                isKiosk: isKiosk,
                isGuest: isGuest,
                canAddToFavorite: true,
              };
              return (
                <ProductTile
                  key={item.id}
                  {...props}
                  onScanButtonClick={onQRTileClick}
                  {...onAddFirstItemToCart}
                />
              );
            }}
            tileVariant={TILE_VARIANT.BIG}
          />
          {isSuggestionsOnProductList ? (
            <SuggestionSlider
              id={'suggestion_slider_mobile'}
              data-testid={'slider_mobile'}
              rows={1}
              isForMobile={true}
              showSuggestionPopup={showSuggestionPopup}
              closePopupResetTime={closePopupResetTime}
              showProgressBar={showProgressBar}
              remainingSeconds={remainingSeconds}
              stopCountdown={stopCountdown}
            >
              {[...getFilteredSuggestions(momentBasedSuggestions, selectedAllergens, hideAllergens)]
                ?.slice(0, suggestionsLimitMobile)
                .map((item) => {
                  const productItem = mapSuggestionItemToProductItem(
                    item,
                    !hideAllergens,
                    selectedAllergens
                  );

                  const props = {
                    ...productItem,
                    date: selectedDate,
                    menu: selectedMenu,
                    variant: TILE_VARIANT.STANDARD,
                    oneLine: false,
                    canAddToFavorite: true,
                  };

                  return (
                    <div className={styles.containerWithPadding} key={props.id}>
                      <ProductTile
                        {...props}
                        onAddOrQuantityButtonClick={stopCountdown}
                        onScanButtonClick={() => {
                          stopCountdown();
                          onQRTileClick();
                        }}
                      />
                    </div>
                  );
                })}
            </SuggestionSlider>
          ) : null}
        </Column.Main>
        <Column.Complementary>
          {areSuggestionsEnabled &&
          isRrMenu &&
          selectedMenu?.isOrderable &&
          isSuggestionsOnProductList ? (
            <SuggestionContainer
              id={'suggestion_product_list'}
              pageType={PageType.productsList}
              isLoading={isLoading}
              suggestions={[
                ...getFilteredSuggestions(momentBasedSuggestions, selectedAllergens, hideAllergens),
              ]?.slice(0, suggestionsLimitDesktop)}
              date={selectedDate}
              menus={[selectedMenu]}
              highlightAllergens={!hideAllergens}
              selectedAllergens={selectedAllergens}
              onScanButtonClick={onQRTileClick}
              label={label}
            />
          ) : (
            <MenuIllustration />
          )}
        </Column.Complementary>
        {listActions.length ? (
          <ActionsBar className={classNames({ [styles.hideActionsBar]: showSuggestionPopup })}>
            {listActions}
          </ActionsBar>
        ) : null}
      </Container>
      {shareOptionsModal}
    </SimpleFormPage>
  );
};

export default ProductsList;
