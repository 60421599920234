import { useHistory, useLocation } from 'react-router';

import { ManSitIllustration } from '../../../assets/illustrations/index';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../config';
import { AccountValidationProps, REDIRECTED_FROM } from '../types/accountValidation.types';

const AccountValidation = ({ firstName, lastName, label }: AccountValidationProps) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectedFrom = queryParams.get('redirectedFrom');
  const message =
    redirectedFrom === REDIRECTED_FROM.LOGIN
      ? `${label('Welcome')} ${firstName} ${lastName}! ${label('Ref: Welcome message')}`
      : `${label('Ref: Password changed message')}`;

  if (!firstName || !lastName) return <LoadingPage />;

  return (
    <NoticePage
      img={<ManSitIllustration width={340} height={340} />}
      withNavBar={false}
      title={message}
      actions={[
        {
          label: label('Continue', { textTransform: 'capitalize' }),
          action: () => history.replace(pagePaths.Home),
        },
      ]}
    />
  );
};
export default AccountValidation;
