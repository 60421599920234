import { LabelFunc } from '../context/withLang';
import { REACTIONS, ReactionType } from '../types/reactions.types';

const reactionsWithLabels = (label: LabelFunc, reactionsData?: Required<ReactionType[]>) =>
  (Object.keys(REACTIONS) as Array<keyof typeof REACTIONS>).map((reaction) => ({
    type: reaction as REACTIONS,
    label: label('Ref: Reaction - ' + reaction),
    count: reactionsData?.find((r) => r.type === (reaction as REACTIONS))?.count || 0,
  }));

export { reactionsWithLabels };
