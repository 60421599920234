import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TranslateIcon20 } from '../../../../../assets';
import LangSelector from '../../../../../components/molecules/LangSelector/LangSelector';
import TitleBar from '../../../../../components/organisms/TitleBar';
import withLang, { WithLangProps } from '../../../../../context/withLang';
import { getCurrentLanguageCode } from '../../../../../helpers/misc';
import { applyLanguage } from '../../../../Core/actions';
import { languages } from '../../../../Core/config';
import { orderConfig } from '../../../config';

const TranslationWidget = ({ label }: WithLangProps) => {
  const languageCode = getCurrentLanguageCode();
  const { isTranslationWidgetShownInTitleBar } = orderConfig();

  const [otherModal, setOtherModal] = useState(false);

  const handleClick = () => {
    setOtherModal(!otherModal);
  };

  const dispatch = useDispatch();

  const handleLanguageSubmit = (modalCode: string) => {
    setOtherModal(false);
    dispatch(applyLanguage({ code: modalCode, isUserSelected: true }));
    window.location.reload();
  };

  if (!isTranslationWidgetShownInTitleBar) return <></>;

  const languageName = languages.find((x) => x.code === languageCode)?.name ?? '';

  return (
    <>
      <TitleBar.Widget
        label={label}
        labelKey={languageName.replace(/\s*\([^)]*\)\s*/g, '')}
        Icon={TranslateIcon20}
        onClick={handleClick}
        forceToDisplayLabel
        iconOnTheLeft
      />
      <LangSelector
        label={label}
        open={otherModal}
        setModalOpen={setOtherModal}
        handleLanguageSubmit={(modalCode) => handleLanguageSubmit(modalCode)}
      />
    </>
  );
};

export default withLang(__filename)(TranslationWidget);
