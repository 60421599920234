import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

import { State } from '../../../../types/state.types';
import { updateCommunicationPreferences, updateUserContext } from '../../actions';
import { moduleId } from '../../config';

import CommunicationPreferencesPage from './CommunicationPreferencesPage';

const mapStateToProps = (state: State) => {
  const { allowContent, allowOffers } = state[moduleId].user.communicationPreferences || {};

  return {
    allowContent,
    allowOffers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateCommunicationPreferences: async (allowContent: string, allowOffers: string) => {
      await dispatch(updateCommunicationPreferences({ allowContent, allowOffers }));
      return await dispatch(updateUserContext({ allowContent, allowOffers }));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunicationPreferencesPage)
);
