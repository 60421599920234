import { useEffect } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router';

import { AppNames, APP_HERO_WIDGET_IMAGE } from '../../../../constants';
import withLoginStatus from '../../../../context/withLoginStatus';
import CartWidget from '../../../Order/components/TitleBarWidgets/CartWidget';
import { ISite } from '../../../Sites/types/sites.types';
import { pagePaths } from '../../config';
import withLang from '../../context/withLang';
import { getWelcomeData } from '../../helpers/homePage.helper';
import useSiteWelcomeImage from '../../hooks/useSiteWelcomeImage';
import { useWidgets } from '../../hooks/useWidgets';
import { HomePageProps } from '../../types/homePage.types';
import AccountWidget from '../TitleBarWidgets/AccountWidget';
import NotificationsWidget from '../TitleBarWidgets/NotificationsWidget';

import WidgetHero from '@/components/atoms/WidgetHero';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container/Container';
import MiniWidgetContainer from '@/components/organisms/MiniWidgetContainer/MiniWidgetContainer';
import TitleBar from '@/components/organisms/TitleBar';
import { TITLEBAR_CAPTION_TYPE } from '@/components/organisms/TitleBar/TitleBar.types';
import DefaultPage from '@/components/templates/DefaultPage/DefaultPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { useGetAccountUserContextQuery } from '@/modules/Core/api/account/userContextApi';
import GlobalSearchWidget from '@/modules/GlobalSearch/widgets/Widget/GlobalSearchWidget';
import TransactionalFeedbackModalWrapper from '@/modules/Order/components/TransactionalFeedbackModal/TransactionalFeedbackModalWrapper';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

import styles from './HomePage.module.css';

const HomePage = (props: HomePageProps) => {
  const { label, isGuest } = props;
  const site = useSelector((state: State) => state.Core.context.site);
  const services = useSelector((state: State) => state.Core.services || { list: [] }).list;
  const { data: user = { firstName: '' } } = useGetAccountUserContextQuery({});
  const history = useHistory();
  const { logUserSteps } = useUserStepsInsightsLogging();

  useEffect(() => {
    logUserSteps({ event: UserSteps.HomePage });
  }, [logUserSteps]);

  const location = useLocation();

  const { welcomeWidgetImage } = useSiteWelcomeImage(site as ISite);
  const {
    widgets,
    miniWidgets,
    hasGlobalSearch: showGlobalSearch,
  } = useWidgets({ services, isGuest });

  if (!site) return <LoadingPage />;

  const miniWidgetsComponents = miniWidgets.map(({ id, component: MiniWidget }) => (
    <MiniWidget key={id} />
  ));

  const welcomeData = getWelcomeData(label);
  const welcomeMessage = user.firstName ? welcomeData.welcomeMessage : '';
  const bgImage =
    welcomeWidgetImage ??
    APP_HERO_WIDGET_IMAGE[
      (process?.env?.REACT_APP_APP_NAME?.toLowerCase() as AppNames) ?? 'default'
    ][welcomeData.daySection];

  const notificationContent = (
    <React.Fragment key="homepage_notification">
      {isGuest && (
        <Notification
          look={NOTIFICATION_LOOK.INFO}
          title={label('Ref: You are logged in as a guest user')}
          inheritStyle={styles.notificationWrapper}
          actions={[
            {
              children: label('Register'),
              'data-testid': 'home-register-guest',
              onClick: () =>
                history.push({
                  pathname: pagePaths.GuestRegistration,
                  state: { from: location },
                }),
            },
          ]}
        />
      )}
    </React.Fragment>
  );
  return (
    <DefaultPage
      titleBar={
        <TitleBar>
          <TitleBar.Navigation
            text={site.name}
            caption={{
              hideBackButton: true,
              title: label('Ref: Tabtitle'),
              captionType: TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE,
            }}
            tabTitle={label('Ref: Tabtitle')}
          />
          <TitleBar.Widgets>
            <NotificationsWidget />
            <CartWidget />
            <AccountWidget />
          </TitleBar.Widgets>
        </TitleBar>
      }
    >
      <Container.WithNotification notificationContent={notificationContent}>
        <Column.Side>
          {showGlobalSearch && <GlobalSearchWidget label={label} />}
          <MiniWidgetContainer id={'mini-widget-container'}>
            <WidgetHero message={welcomeMessage} username={user.firstName} background={bgImage} />
            {miniWidgetsComponents}
          </MiniWidgetContainer>
        </Column.Side>
        <Column.Main>
          <div className={styles.widgetsContainer}>
            {widgets.map(({ component: Widget }, index) => (
              <Widget key={index} />
            ))}
          </div>
        </Column.Main>
      </Container.WithNotification>
      <TransactionalFeedbackModalWrapper site={site} />
    </DefaultPage>
  );
};

export default withLoginStatus(withRouter(withLang([__filename])(HomePage)));
