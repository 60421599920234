import { connect } from 'react-redux';

import { State } from '../../../../types/state.types';
import { sendSupportEmail, setGeography } from '../../actions';
import { CreateSupportEmailRequestType, SetGeographyRequestType } from '../../types';

import TroubleLoggingInPage from './TroubleLoggingInPage';

function mapStateToProps(state: State) {
  const {
    user: { username, preferredLanguage },
    context: { currentGeoCode },
  } = state.Core;

  const {
    geographies: { list: geographies },
  } = state.Shared;

  return {
    languageCode: preferredLanguage?.languageCode || 'en-US',
    username,
    geographies,
    currentGeoCode,
  };
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchSetGeography: (request: SetGeographyRequestType) =>
      dispatch(setGeography({ geoCode: request.geoCode })),
    dispatchCreateSupportEmail: (request: CreateSupportEmailRequestType) =>
      dispatch(
        sendSupportEmail({
          email: request.email,
          fullName: request.fullName,
          employer: request.employer,
          village: request.whatVillage,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleLoggingInPage);
