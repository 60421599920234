import classNames from 'classnames';
import momentjs from 'moment';
import { ReactNode, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ShoppingBagIcon, WarningOutlinedIcon } from '../../../../assets/icons';
import { TileSkeleton, TILE_VARIANT } from '../../../../components/molecules/Tile';
import MiniWidget from '../../../../components/organisms/MiniWidget';
import withSite from '../../../../context/withSite';
import { useGetMenusQuery, useGetReorderPropositionQuery } from '../../api';
import { pagePaths } from '../../config';
import ProductTile from '../../containers/ProductTile';
import { mapReorderMenuItemToProductItem } from '../../helpers/reorderWidget.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { PageType } from '../../types/menuCartActions.types';
import { MenuType } from '../../types/menuSelector.types';
import { ScannableMenuItem, SharedState, StateWithOrder } from '../../types/orderState.types';
import { ReorderWidgetProps } from '../../types/reorderWidget.types';

import { useScannerComponent } from './useScannerComponent';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';

import styles from './ReorderWidget.module.css';

const tileVariant = TILE_VARIANT.STANDARD;

const ReorderWidget = ({ site }: ReorderWidgetProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);
  const menuDate = momentjs().format('YYYY-MM-DD');
  const createOrderDraft = useSelector(
    (state: StateWithOrder) => state.Order?.locks?.createOrderDraft
  );
  const currentLanguageCode = useSelector(
    (state: { Shared: SharedState }) => state.Shared?.language?.currentLanguageCode
  );

  const [scanSelected, setScanSelected] = useState<ScannableMenuItem | undefined>();

  const { logError } = useUserStepsInsightsLogging();

  const {
    data: menus = [],
    isLoading: isLoadingMenus,
    isError: errorFetchingMenus,
  } = useGetMenusQuery({
    siteId: site.id,
    useCache: true,
    date: menuDate,
    menuType: MenuType.Orderable,
    useErrorBoundary: false,
  });

  const {
    data: reorderItems = [],
    isLoading: isLoadingReorderItems,
    error: errorFetchingReorderProposition,
  } = useGetReorderPropositionQuery({ siteId: site.id, date: menuDate, useErrorBoundary: false });

  const fetchingError = errorFetchingMenus || errorFetchingReorderProposition;

  fetchingError && logError(new Error('Error occured while fetching Menus or Reorder proposition'));

  const reorderProductTiles = useMemo(() => {
    const productTiles = reorderItems.map((reorderItem) => {
      const menu = menus.find((menu) => menu.id === reorderItem.menuId);
      if (!menu) return null;

      const productItem = mapReorderMenuItemToProductItem(reorderItem);
      if (!productItem) return null;

      const props = {
        ...productItem,
        dark: true,
        date: menuDate,
        menu: menu,
        variant: tileVariant,
        oneLine: true,
      };
      return (
        <ProductTile
          key={props.id}
          pageType={PageType.reorderWidget}
          {...props}
          onScanButtonClick={() => setScanSelected(reorderItem)}
        />
      );
    });
    return productTiles.filter((item) => !!item);
  }, [reorderItems, menus, menuDate]);

  const widgetItems = useMemo(() => {
    const items: ReactNode[] = [];
    if (isLoadingReorderItems || isLoadingMenus) {
      items.push(
        <TileSkeleton
          data-testid="loader"
          key="loader"
          variant={tileVariant}
          withoutChildren={false}
        />
      );
    } else if (reorderProductTiles.length > 0) {
      items.push(...reorderProductTiles);
    } else {
      const errorOrNoItemsMessage = fetchingError
        ? label('Something went wrong, data not loaded')
        : label('Ref: No items');

      items.push(
        <div
          key="noItems"
          data-testid="reorder-noItems"
          className={classNames(styles.noItemsPlaceholder)}
          onClick={() => history.push(pagePaths.Module)}
          role="button"
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              history.push('/order');
            }
          }}
          tabIndex={0}
          aria-label={errorOrNoItemsMessage}
        >
          {fetchingError ? <WarningOutlinedIcon /> : <ShoppingBagIcon width={32} />}
          <p>{errorOrNoItemsMessage}</p>
        </div>
      );
    }

    return items;
  }, [isLoadingReorderItems, isLoadingMenus, reorderProductTiles, fetchingError, label, history]);

  const scannerComponent = useScannerComponent({
    scanSelected,
    menus,
    menuDate,
    site,
    currentLanguageCode,
    createOrderDraft,
    setScanSelected,
  });

  return (
    <>
      {scannerComponent}
      <MiniWidget id="reorderWidget" data-testid="reorderWidget" title={label('Ref: Title')}>
        {widgetItems}
      </MiniWidget>
    </>
  );
};

export default withSite(ReorderWidget);
