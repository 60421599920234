import { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import List from '../../../components/organisms/List/List';
import { formatDateTime, toLocalDate } from '../../../helpers/misc';
import { getOrderInfo } from '../helpers/order.info.helper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import { OrderInfoCardProps } from '../types/order.summary.types';

const OrderInfoCard = ({ currentLanguageCode, ...orderInfo }: OrderInfoCardProps) => {
  const { label } = useOrderTranslation(__filename);

  const orderItems = getOrderInfo(label, orderInfo);
  const pickupDate = orderInfo.pickupDate
    ? formatDateTime(toLocalDate(orderInfo.pickupDate, true), currentLanguageCode)
    : undefined;

  const title = !!pickupDate
    ? {
        tag: TITLE_TAG.H2,
        children: `${label('Ref: Header')} ${pickupDate}`,
        size: TITLE_SIZE.HEADLINEXS,
      }
    : undefined;

  return (
    <>
      <Card title={title}>
        <List data-testid="order-info-card-order-items-list" items={orderItems} />
      </Card>
    </>
  );
};

export default OrderInfoCard;
