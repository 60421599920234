const labels = {
  PrivacyPolicy: 'Polityka prywatności',
  TermsOfSale: 'Warunki sprzedaży',
  TermsOfUse: 'Zasady i Warunki',
  home: 'Strona główna',
  anytime: 'zawsze',
  anywhere: 'gdziekolwiek',
  Download: 'Pobierz',
  scrollRightButton: 'Przewiń kategorie po prawej stronie',
  scrollLeftButton: 'Przewiń kategorie w lewo',
  calendar: 'Kalendarz',
  'See more': 'Zobacz więcej',
  'See less': 'Zobacz mniej',
  'from (followed by a date, or date and time)': 'od',
  'to (followed by a date, or date and time)': 'do',
  'on (followed by a date)': 'Na',
  'at (followed by time)': 'Na',
  'from (followed by a location)': 'od',
  'to (followed by a location)': 'do',
  'on (followed by a site name)': 'Na',
  'from (followed by a person)': 'od',
  previous: 'Poprzedni',
  next: 'Następny',
  success: 'powodzenie',
  failure: 'niepowodzenie',
  today: 'Dziś',
  tomorrow: 'Jutro',
  Monday: 'Poniedziałek',
  Tuesday: 'Wtorek',
  Wednesday: 'Środa',
  Thursday: 'Czwartek',
  Friday: 'Piątek',
  Saturday: 'Sobota',
  Sunday: 'Niedziela',
  everyday: 'Codziennie',
  'open all day': ' Otwarte przez cały dzień',
  'opening hours': 'Godziny otwarcia',
  status: 'status',
  'to be confirmed': 'Do potwierdzenia',
  'only (as in filter only this)': 'tylko',
  edit: 'edytuj',
  submit: 'Zatwierdź',
  cancel: 'Anuluj',
  confirm: 'Potwierdź',
  reset: 'Zresetuj',
  Delete: 'Usuń',
  select: 'Wybierz',
  switch: 'przełącz',
  close: 'Zamknij',
  clear: 'wyczyść',
  remove: 'usuń',
  logout: 'wyloguj',
  go: 'idź',
  'see (as in consult this page or document)': 'zobacz',
  'see all (as in see all items)': 'Zobacz wszystko',
  'see more (as in see more details for this item)': 'Zobacz więcej',
  retry: 'ponów',
  'Ref: ok': 'Ok',
  'yes (as in yes I accept)': 'Tak',
  'no (as in no I do not accept)': 'Nie',
  you: 'Ty',
  'thank you': 'Dziękujemy',
  'sorry (standalone)': 'przepraszamy',
  'to home (as in navigate to the homepage)': 'do strony głównej',
  map: 'mapa',
  list: 'lista',
  'Loading...': 'Ładowanie…',
  'Refreshing...': 'Odświeżam...',
  'Please select': 'Proszę wybrać',
  'Switch site': 'Przełącz stronę tutaj',
  'File size cannot exceed {max file size}': 'Rozmiar pliku nie może przekraczać {max file size}',
  Filters: 'Filtry',
  'Add attachment': 'Dodaj załącznik',
  Search: 'Szukaj',
  'More (as in: see more info)': 'Więcej',
  'You can attach a maximum of {max files number} files.':
    'Możesz dołączyć maksymalnie {max files number} plików.',
  'There are no messages to display.': 'Brak wiadomości do wyświetlenia.',
  'Your selection did not return any result.': 'Twój wybór nie zwrócił żadnego wyniku.',
  'Ref: Popup header for missing required fields in a form': 'Proszę podać dodatkowe informacje',
  'Ref: Required field': '{ref} to pole wymagane.',
  email: 'E-mail',
  phone: 'Numer telefonu',
  Account: 'Moje konto',
  Contacts: 'Kontakty',
  Content: 'Treści',
  Events: 'Wydarzenia',
  Facilities: 'Obiekty',
  Shop: 'Sklep',
  Menu: 'Menu',
  Menus: 'Menu',
  Order: 'Zamów',
  Orders: 'Historia zamówień',
  Info: 'Informacje',
  FAQ: 'FAQ',
  Feedback: 'Komentarze',
  Review: 'Recenzja',
  Requests: 'Zlecenia',
  'Request Helpdesk': 'Poproś o pomoc',
  Location: 'Lokalizacja',
  Safety: 'Bezpieczeństwo',
  FreeCoffee: 'Darmowa kawa',
  Newsfeed: 'Aktualności',
  MyOrders: 'Moje zamówienia',
  LoyaltyStamps: 'Pieczątki lojalnościowe',
  Stamps: 'Pieczątki',
  Stays: 'Pobyt',
  Suppliers: 'Dostawcy',
  Surveys: 'Ankiety',
  notifications: 'powiadomienia',
  cart: 'koszyk',
  'Ref: Reset your order': 'Zresetuj zamówienie',
  'Ref: My language': 'Wybrany język',
  Validate: 'Zweryfikuj',
  'contact us': 'Skontaktuj się z nami',
  'Ref: Start typing': 'Zacznij pisać',
  Occupancy: 'Obłożenie',
  'see less': 'Zobacz mniej',
  'see more': 'Zobacz więcej',
  contacts: 'Kontakty',
  Imprint: 'Nadruk',
  'Ref: camera access request title': 'Zezwól na dostęp do aparatu',
  'Ref: camera access request body':
    'Musisz włączyć udostępnienie aparatu na tej stronie, aby użyć skanera kodu QR',
  or: 'lub',
  'Ref: Input required': '{input_name} jest wymagany',
  'Enter a valid email address': 'Wprowadź poprawny adres e -mail',
  'Ref: Input error E-mail already exists':
    'Istnieje już konto z wprowadzonym e-mailem. Użyj go by się zalogować.',
  'Password must contain': 'Hasło musi zawierać:',
  'Ref: Input password error strength':
    'Hasło musi zawierać co najmniej 8 znaków, 1 liczbę, 1 literę i 1 znak specjalny',
  Password: 'Hasło',
  'New password': 'Nowe hasło',
  'Confirm password': 'Potwierdź hasło',
  'Ref: Password does not match': 'Hasło i potwierdzenie hasła nie pasują',
  'Ref: Old password does not match': 'Stare hasło nie pasuje',
  'Ref: Password validation min8Characters': 'Co najmniej 8 znaków',
  'Ref: Password validation min1Number': 'Co najmniej 1 cyfra',
  'Ref: Password validation min1Letter': 'Co najmniej 1 litera',
  'Ref: Password validation min1SpecialChar': 'Co najmniej 1 znak specjalny',
  Continue: 'Kontynuuj',
  'Enter a valid phone number': 'Wprowadź poprawny numer telefonu',
  'Phone is required': 'Wymagany jest numer telefonu',
  'Go to home': 'Idź do strony głównej',
  'Something went wrong': 'Coś poszło nie tak',
  'Something went wrong, data not loaded': 'Coś poszło nie tak, dane nie załadowane',
  'Change password': 'Zmień hasło',
  'remove one': 'Usuń jeden',
  'add one': 'Dodaj jeden',
  'Confirm Filters': 'Potwierdź filtry',
  'Reset Filters': 'Zresetuj filtry',
  '{selectedNum} active filters': '{selectedNum} aktywnych filtrów',
  'Wrong input icon': 'Niewłaściwa ikona wejściowa',
  'Correct input icon': 'Prawidłowa ikona wejściowa',
  'Show input text icon': 'Pokaż ikonę tekstu wejściowego',
  'Hide input text icon': 'Ukryj ikonę tekstu wejściowego',
  'Required fields info': '* Wymagane pola',
  'Ref: Select site on the map': 'Wybierz swoją lokalizację na mapie',
  'Ref: Geolocation disabled':
    'Geolokalizacja jest wyłączona. Włącz, aby zobaczyć pobliskie lokalizacje',
  'Ref: Reaction - LIKE': 'Polubienie',
  'Ref: Reaction - LOVE': 'Pokochanie',
  'Ref: Reaction - INTERESTING': 'Uznanie interesującym',
  'Ref: Reaction - CONGRATE': 'Pogratulowanie',
  'Ref: Share': 'Udostępnij',
  'Ref: Send by Email': 'Wyślij e-mailem',
  'Ref: Copy the link': 'Skopiuj link',
  'Ref: Link was copied': 'Link został skopiowany',
  'Ref: unable to copy url': 'Nie można skopiować adresu URL',
  'Ref: External link': 'Link zewnętrzny',
  'Ref: Step': 'Krok',
  'input field': 'pole wejściowe',
  'Ref: Zoom': 'Powiększ',
  'Increase hours': 'Dodaj godziny',
  'Decrease hours': 'Odejmij godziny',
  Hours: 'godziny',
  Minutes: 'Minuty',
  'Ref: filter label: favorites': 'Pokaż tylko moje ulubione',
  'Ref: filter label: max calories': 'Max ilość kalorii',
  'Ref: filter label: dishes': 'Dania',
  'Ref: filter label: allergens': 'Alergeny',
  'Ref: filter label: highlight': 'Wyróżnij lub ukryj produkty na podstawie wybranych filtrów',
  'Ref: Highlight': 'Wyróżnij',
  'Ref: Hide': 'Ukryj',
  'Ref: Vegan': 'Wegańskie',
  'Ref: Vegeterian': 'Wegetariańskie',
  'Ref: Mindful': 'Świadome',
  'Ref: Highlight text':
    'Możesz wyróżnić lub ukryć posiłki w menu, które zawierają wybrane alergeny',
  'Ref: Just now': 'Teraz',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} minut temu',
  'Ref: 1 hour ago': '1 godzinę temu',
  'Ref: {amountHours} hours ago': '{amountHours} godzin temu',
  'Ref: Yesterday at {time}': 'Wczoraj o {time}',
  'Ref: {date} at {time}': '{date} o {time}',
  'Ref: filter label: date': 'Data',
  'Ref: expand menu': 'Rozwiń menu',
  'Ref: collapse menu': 'Menu zwinięte',
  'Ref: My site': 'Moja lokalizacja',
  'Ref: Recent': 'Ostatni',
  'Ref: Services': 'Usługi',
  'Ref: products in cart': 'Liczba produktów w koszyku',
  'Ref: no product in cart': 'Brak produktu w koszyku',
  'Ref: Survey': 'Ankieta',
  'Access to camera was denied. Please allow access and try again':
    'Dostęp do aparatu został odrzucony. Udostępnij dostęp i spróbuj ponownie',
  'Add an attachment': 'Dodaj załącznik',
  'Take a picture': 'Zrób zdjęcie',
  'Add a file from your device': 'Dodaj plik z urządzenia',
  'Ref: Loading page content, please wait': 'Ładowanie treści strony, poczekaj',
  Star: 'Gwiazda',
  Stars: 'Gwiazdy',
  'Accommodation Request': 'Żądanie zakwaterowania',
  'Ref: Guest Accomodation Request': 'Prośba gościa',
  'Ref: Search a menu, product or facility': 'Wyszukaj menu, produkt lub obiekt',
  ConnectionLost: 'Utracone połączenie sieciowe',
  ConnectionRestored: 'Przywrócone połączenie sieciowe',
  'Ref: Skip to main content': 'Przejdź do głównej zawartości',
  'Ref: No options': 'Brak opcji',
  'Ref: Global search modal': 'Okienko wyszukiwania w całej aplikacji',
  'Ref: Search a facility': 'Wyszukaj obiekt',
  'Ref: Search a menu or product': 'Wyszukaj menu lub produkt',
  'Ref: Reaction': 'Reakcja',
  'Ref: times': 'razy',
  'Ref: reaction. Change reaction': 'reakcja. Zareaguj inaczej',
  'Ref: No reaction. React': 'Brak reakcji. Zareaguj',
  'Ref: LIKE': 'Tak jak',
  'Ref: LOVE': 'Miłość',
  'Ref: INTERESTING': 'Ciekawy',
  'Ref: CONGRATE': 'ZAKREDUJ',
  'Ref: Open search': 'Otwórz wyszukiwarkę',
  'Ref: Close search': 'Zamknij wyszukiwarkę',
  'Ref: Total file size msg': 'Całkowity rozmiar pliku nie może przekraczać {max file size}',
  'Ref: Oops Title': 'Ups!',
  'Ref: Well Done': 'Dobra robota {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Strzałka nawigacyjna - Kontynuuj',
  'Ref: Reload': 'Odśwież',
  'Ref: All Dates': 'Wszystkie daty',
  'Ref: Today': 'Dzisiaj',
  'Ref: Validate': 'Uprawomocnić',
  'Ref: toast error title': 'Ups!',
  'Ref: toast error subtitle': 'Wystąpił błąd. Proszę spróbować ponownie.',
  'Ref: Calendar': 'Kalendarz',
  'Ref: Selected': 'Wybrany',
  'Ref: Previous Month': 'Poprzedni miesiac',
  'Ref: Next Month': 'W następnym miesiącu',
  'This module has been retired': 'Ten moduł został wycofany',
};
export default labels;
