import * as H from 'history';
import { withRouter, RouteComponentProps, useLocation } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import {
  Action,
  NOTICEPAGE_TYPE,
} from '../../../../components/templates/NoticePage/NoticePage.types';
import { LabelFunc, WithLangProps } from '../../../../context/withLang';
import { isKiosk } from '../../../../helpers/misc';
import KioskOrder from '../../../Order/components/KioskOrder';
import { KioskOrderVariant } from '../../../Order/types/KioskOrder.types';
import withLang from '../../context/withLang';
import { getErrors } from '../../helpers/helpers';

export type GenericErrorProps = WithLangProps &
  RouteComponentProps & {
    history: H.History<unknown>;
    label: LabelFunc;
    title?: string;
    errors?: string[];
    actions?: Action[];
    withNavbar?: boolean;
  };

const GenericErrorPage = ({
  title,
  history,
  label,
  errors,
  actions,
  withNavbar,
}: GenericErrorProps) => {
  const location =
    useLocation<{
      errorBody: string;
    }>();
  const errorContent = errors?.length
    ? getErrors(errors, label)
    : location.state?.errorBody || label('Ref: Generic Error Body');
  if (isKiosk) {
    return <KioskOrder variant={KioskOrderVariant.FAIL} label={label} />;
  }
  return (
    <NoticePage
      withNavBar={withNavbar}
      id="generic-error"
      hideAllWidgets
      type={NOTICEPAGE_TYPE.ERROR}
      title={title ?? label('Ref: Generic Error Title', { textTransform: 'capitalize' })}
      content={errorContent}
      actions={
        actions ?? [
          {
            label: label('Go to home'),
            action: () => history.push('/'),
            look: 'secondary',
          },
        ]
      }
      tabTitle={label('Ref: TabTitle')}
    />
  );
};
export default withRouter(withLang([__filename])(GenericErrorPage));
