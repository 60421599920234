const labels = {
  PrivacyPolicy: 'Política de privacidad',
  TermsOfSale: 'Condiciones de ventas',
  TermsOfUse: 'Términos y condiciones',
  home: 'Inicio',
  anytime: 'En cualquier momento',
  anywhere: 'en cualquier sitio',
  Download: 'Descargar',
  scrollRightButton: 'Desplazar categorías a la derecha',
  scrollLeftButton: 'Desplazar categorías a la izquierda',
  calendar: 'Calendario',
  'See more': 'Ver más',
  'See less': 'Ver menos',
  'from (followed by a date, or date and time)': 'desde',
  'to (followed by a date, or date and time)': 'a',
  'on (followed by a date)': 'En',
  'at (followed by time)': 'en',
  'from (followed by a location)': 'en',
  'to (followed by a location)': 'a',
  'on (followed by a site name)': 'En',
  'from (followed by a person)': 'de',
  previous: 'Anterior',
  next: 'Siguiente',
  success: 'éxito',
  failure: 'fallo',
  today: 'Hoy',
  tomorrow: 'Mañana',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  everyday: 'Todos los días',
  'open all day': 'Abierto todo el día',
  'opening hours': 'Horario de apertura',
  status: 'estado',
  'to be confirmed': 'Por confirmar',
  'only (as in filter only this)': 'solamente',
  edit: 'editar',
  submit: 'Enviar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  reset: 'Reiniciar',
  Delete: 'Borrar',
  select: 'Seleccione',
  switch: 'cambiar',
  close: 'Cerrar',
  clear: 'claro',
  remove: 'Eliminar',
  logout: 'cerrar sesión',
  go: 'Ir',
  'see (as in consult this page or document)': 'ver',
  'see all (as in see all items)': 'Ver todo',
  'see more (as in see more details for this item)': 'Ver más',
  retry: 'reintentar',
  'Ref: ok': 'De acuerdo',
  'yes (as in yes I accept)': 'Si',
  'no (as in no I do not accept)': 'No',
  you: 'usted',
  'thank you': 'Gracias',
  'sorry (standalone)': 'Lo siento',
  'to home (as in navigate to the homepage)': 'A la página de inicio',
  map: 'mapa',
  list: 'lista',
  'Loading...': 'Cargando...',
  'Refreshing...': 'Actualizando...',
  'Please select': 'Por favor seleccione',
  'Switch site': 'Cambiar el sitio aquí',
  'File size cannot exceed {max file size}':
    'El tamaño del archivo no puede exceder el {max file size}',
  Filters: 'Filtrar',
  'Add attachment': 'Añadir un adjunto',
  Search: 'Búsqueda',
  'More (as in: see more info)': 'Más',
  'You can attach a maximum of {max files number} files.':
    'Puede adjuntar un máximo de {max files number} archivos.',
  'There are no messages to display.': 'No hay mensajes para mostrar.',
  'Your selection did not return any result.': 'No se encuentra ningún resultado.',
  'Ref: Popup header for missing required fields in a form': 'Proporcione información adicional',
  'Ref: Required field': '{ref} es un campo obligatorio.',
  email: 'Correo electrónico',
  phone: 'Número de teléfono',
  Account: 'Cuenta',
  Contacts: 'Contactos',
  Content: 'Contenido',
  Events: 'Eventos',
  Facilities: 'Instalaciones',
  Shop: 'Tienda',
  Menu: 'Menú',
  Menus: 'Menús',
  Order: 'Pedido',
  Orders: 'Historial de pedidos',
  Info: 'Información',
  FAQ: 'Preguntas más frecuentes',
  Feedback: 'Comentarios',
  Review: 'Revisar',
  Requests: 'Solicitudes',
  'Request Helpdesk': 'Solicitar ayuda de ayuda',
  Location: 'Ubicación',
  Safety: 'Seguridad',
  FreeCoffee: 'Café gratis',
  Newsfeed: 'Noticias',
  MyOrders: 'Mis ordenes',
  LoyaltyStamps: 'Sellos de lealtad',
  Stamps: 'Sellos',
  Stays: 'Permanece',
  Suppliers: 'Proveedor',
  Surveys: 'Encuestas',
  notifications: 'notificaciones',
  cart: 'carro',
  'Ref: Reset your order': 'Restablecer su pedido',
  'Ref: My language': 'Mi idioma',
  Validate: 'Validar',
  'contact us': 'Contáctenos',
  'Ref: Start typing': 'Empezar a escribir',
  Occupancy: 'Ocupación',
  'see less': 'Ver menos',
  'see more': 'Ver más',
  contacts: 'Contactos',
  Imprint: 'Imprimir',
  'Ref: camera access request title': 'Permitir acceso a la cámara',
  'Ref: camera access request body':
    'Debe habilitar el uso de una cámara en este centro para usar el escáner de código QR',
  or: 'o',
  'Ref: Input required': '{input_name} es necesario',
  'Enter a valid email address': 'Introduzca una dirección de correo electrónico válida',
  'Ref: Input error E-mail already exists':
    'Ya hay una cuenta con este correo electrónico. Inicie sesión con ella.',
  'Password must contain': 'La contraseña debe contener:',
  'Ref: Input password error strength':
    'La contraseña debe contener al menos 8 caracteres, 1 número, 1 letra y 1 caracter especial (*, !, @, ?...)',
  Password: 'Contraseña',
  'New password': 'Nueva contraseña',
  'Confirm password': 'Confirmar contraseña',
  'Ref: Password does not match': 'La contraseña y la contraseña que ha confirmado no coincide',
  'Ref: Old password does not match': 'La contraseña antigua no coincide',
  'Ref: Password validation min8Characters': 'Al menos 8 carácteres',
  'Ref: Password validation min1Number': 'Al menos 1 dígito',
  'Ref: Password validation min1Letter': 'Al menos 1 letra',
  'Ref: Password validation min1SpecialChar': 'Al menos 1 caracter especial',
  Continue: 'Continuar',
  'Enter a valid phone number': 'Introduzca un número de teléfono válido',
  'Phone is required': 'Se requiere teléfono',
  'Go to home': 'Ir a la página de inicio',
  'Something went wrong': 'Algo salió mal',
  'Something went wrong, data not loaded': 'Algo salió mal, datos no cargados',
  'Change password': 'Cambie la contraseña',
  'remove one': 'eliminar uno',
  'add one': 'Agrega uno',
  'Confirm Filters': 'Confirmar filtros',
  'Reset Filters': 'Restablecer filtros',
  '{selectedNum} active filters': '{selectedNum} filtros activos',
  'Wrong input icon': 'Icono de entrada incorrecto',
  'Correct input icon': 'Icono de entrada correcto',
  'Show input text icon': 'Mostrar icono de texto de entrada',
  'Hide input text icon': 'Ocultar icono de texto de entrada',
  'Required fields info': '* Campos requeridos',
  'Ref: Select site on the map': 'Seleccione su centro en el mapa',
  'Ref: Geolocation disabled':
    'La geolocalización está deshabilitada. Para ver sitios cercanos, habilítelo.',
  'Ref: Reaction - LIKE': 'Me gusta',
  'Ref: Reaction - LOVE': 'Amar',
  'Ref: Reaction - INTERESTING': 'Interesante',
  'Ref: Reaction - CONGRATE': 'Felicitar',
  'Ref: Share': 'Compartir',
  'Ref: Send by Email': 'Enviar por correo electrónico',
  'Ref: Copy the link': 'Copiar el enlace',
  'Ref: Link was copied': 'El enlace fue copiado',
  'Ref: unable to copy url': 'Incapaz de copiar URL',
  'Ref: External link': 'Enlace externo',
  'Ref: Step': 'Paso',
  'input field': 'campo de entrada',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Aumentar las horas',
  'Decrease hours': 'Disminución de las horas',
  Hours: 'Horas',
  Minutes: 'Minutos',
  'Ref: filter label: favorites': 'Muestre solo mis favoritos',
  'Ref: filter label: max calories': 'Calorías max',
  'Ref: filter label: dishes': 'Platos',
  'Ref: filter label: allergens': 'Alérgenos',
  'Ref: filter label: highlight': 'Resaltar u ocultar filtros con platos.',
  'Ref: Highlight': 'Resaltar',
  'Ref: Hide': 'Esconder',
  'Ref: Vegan': 'Vegano',
  'Ref: Vegeterian': 'Vegetariano',
  'Ref: Mindful': 'Consciente de',
  'Ref: Highlight text':
    'Aquí puede optar por la lucha contra la luz o ocultar platos en el menú que contienen ciertos alérgenos.',
  'Ref: Just now': 'En este momento',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} hace minutos',
  'Ref: 1 hour ago': '1 hora antes',
  'Ref: {amountHours} hours ago': '{amountHours} Hace Houres',
  'Ref: Yesterday at {time}': 'Ayer en {time}',
  'Ref: {date} at {time}': '{date} en {time}',
  'Ref: filter label: date': 'Fecha',
  'Ref: expand menu': 'expandir el menú',
  'Ref: collapse menu': 'menú de colapso',
  'Ref: My site': 'Mi sitio',
  'Ref: Recent': 'Reciente',
  'Ref: Services': 'Servicios',
  'Ref: products in cart': 'Número de productos en el carrito',
  'Ref: no product in cart': 'No hay producto en el carrito',
  'Ref: Survey': 'Encuesta',
  'Access to camera was denied. Please allow access and try again':
    'Se negó el acceso a la cámara. Permita el acceso y vuelva a intentarlo',
  'Add an attachment': 'Agregar un archivo adjunto',
  'Take a picture': 'Toma una foto',
  'Add a file from your device': 'Agregue un archivo desde su dispositivo',
  'Ref: Loading page content, please wait': 'Cargando contenido de la página, espere',
  Star: 'Estrella',
  Stars: 'Estrellas',
  'Accommodation Request': 'Solicitud de alojamiento',
  'Ref: Guest Accomodation Request': 'Solicitud de invitado',
  'Ref: Search a menu, product or facility': 'Busque en un menú, producto o instalación',
  ConnectionLost: 'Conexión de red perdida',
  ConnectionRestored: 'Conexión de red restaurada',
  'Ref: Skip to main content': 'Saltar al contenido principal',
  'Ref: No options': 'Sin opciones',
  'Ref: Global search modal': 'Modal de búsqueda global',
  'Ref: Search a facility': 'Buscar una instalación',
  'Ref: Search a menu or product': 'Buscar un menú o producto',
  'Ref: Reaction': 'Reacción',
  'Ref: times': 'veces',
  'Ref: reaction. Change reaction': 'reacción. Reacción de cambio',
  'Ref: No reaction. React': 'Sin reacción. Reaccionar',
  'Ref: LIKE': 'Como',
  'Ref: LOVE': 'Amar',
  'Ref: INTERESTING': 'Interesante',
  'Ref: CONGRATE': 'Congradar',
  'Ref: Open search': 'Abra el cuadro de búsqueda',
  'Ref: Close search': 'Cierre el cuadro de búsqueda',
  'Ref: Total file size msg': 'El tamaño total del archivo no puede exceder {max file size}',
  'Ref: Oops Title': '¡Ups!',
  'Ref: Well Done': 'Bien hecho {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Arrow de navegación - Continuar',
  'Ref: Reload': 'Recargar',
  'Ref: All Dates': 'Todas las fechas',
  'Ref: Today': 'Hoy',
  'Ref: Validate': 'Validar',
  'Ref: toast error title': '¡Ups!',
  'Ref: toast error subtitle': 'Ha ocurrido un error. Por favor intente otra vez.',
  'Ref: Calendar': 'Calendario',
  'Ref: Selected': 'Seleccionado',
  'Ref: Previous Month': 'Mes anterior',
  'Ref: Next Month': 'Próximo mes',
  'This module has been retired': 'Este módulo ha sido retirado.',
};
export default labels;
