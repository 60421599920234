import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Calendar2Icon,
  CloseIcon,
  StatusCheckCircleIcon,
  StatusErrorIcon,
  StatusPendingIcon,
  TravelLocationIcon,
} from '../../../../assets/icons';
import { TAG_COLOR, Tile, TileAction } from '../../../../components/molecules/Tile';
import { formatDate } from '../../../../helpers/misc';
import { useUpdateAuditInviteStateMutation } from '../../api';
import { pagePaths } from '../../config';
import { AuditStateCacheService } from '../../services/AuditStateCacheService';
import { AuditStatus } from '../../types';

import { AuditTileProps } from './AuditTile.types';

import toast from '@/components/atoms/ModalToast';
import { Alert } from '@/components/molecules/Alert';
import { POPOVER_POSITION } from '@/components/molecules/Tile/Tile.types';
import useToggle from '@/helpers/hooks/useToggle';
import { State } from '@/types/state.types';

import styles from './AuditTile.module.css';

const AuditTile = ({
  id,
  auditName,
  auditStreamType,
  dueDate,
  status,
  workOrderNumber,
  workOrderLocation,
  locationId,
  isUnsynchronised,
  label,
  handleSyncStatus,
  refreshLocalData,
}: AuditTileProps) => {
  const history = useHistory();
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const [updateAuditInvite, { isLoading }] = useUpdateAuditInviteStateMutation();
  const stateCache = AuditStateCacheService.getInstance();

  const [auditState, setAuditState] = useState(status);

  const {
    state: isCancelModalOpen,
    toggleOn: openCancelModal,
    toggleOff: closeCancelModal,
  } = useToggle(false);

  const stickers = [
    { name: formatDate(new Date(dueDate), currentLanguageCode), icon: <Calendar2Icon /> },
    { name: auditState.label, ...getStickerProps(auditState.value) },
    isUnsynchronised
      ? {
          name: label('Ref: Audit unsynchronised'),
          icon: (
            <div
              className={classNames(styles.synchronisedStatusIcon, styles.statusUnsynchronised)}
            />
          ),
        }
      : {
          name: label('Ref: Audit synchronised'),
          icon: (
            <div className={classNames(styles.synchronisedStatusIcon, styles.statusSynchronised)} />
          ),
        },
  ];
  const tags = [{ name: workOrderLocation, icon: <TravelLocationIcon /> }];
  const description = `${workOrderNumber}${
    workOrderNumber && auditStreamType ? ' • ' : ''
  }${auditStreamType}`;

  const onClickTileAction = async () => {
    try {
      const oldStatus = status;
      setAuditState({
        label: label(`Ref: status${AuditStatus.CANCELLED}`),
        value: AuditStatus.CANCELLED,
      });
      const response = await updateAuditInvite({ id: id, state: AuditStatus.CANCELLED });
      const { error } = response as { error: { status: string } };

      if (error?.status) {
        setAuditState(oldStatus);
        toast.error(label('Ref: Error audit cancel'), {
          position: 'top-center',
          closeOnClick: true,
          draggable: false,
          closeButton: false,
        });
        return;
      }

      toast.success(label('Ref: Audit cancelled successfully'), {
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: false,
      });
      stateCache.remove(id);

      refreshLocalData();
    } catch (error) {}
  };

  let actions: TileAction[] = [];

  if (status.value === AuditStatus.OPEN) {
    actions = [
      {
        name: label('Ref: Cancel audit invite'),
        icon: <CloseIcon />,
        'data-testid': 'cancel-audit',
        onClick: () => openCancelModal(),
      },
    ];
  }

  const onClick = () =>
    status.value !== AuditStatus.CANCELLED &&
    handleSyncStatus(() => history.push(pagePaths.AuditResponseForm.replace(':id', id)));

  return (
    <>
      <Tile
        isLoading={isLoading}
        title={auditName}
        stickers={stickers}
        description={description}
        tags={tags}
        actions={actions}
        actionsCollapseAfter={0}
        popoverPosition={POPOVER_POSITION.bottom}
        onClick={onClick}
      />
      <Alert
        isOpen={isCancelModalOpen}
        data-testid="audit-tile-cancel-modal"
        onDismiss={closeCancelModal}
        header={label('Ref: Cancel audit')}
        message={label('Ref: Cancel audit confirmation')}
        buttons={[
          {
            text: label('Ref: Cancel audit invite'),
            handler: closeCancelModal,
            'data-testid': 'audit-tile-cancel-modal-cancel',
          },
          {
            text: label('Ref: Confirm'),
            handler: onClickTileAction,
            'data-testid': 'audit-tile-cancel-modal-confirm',
          },
        ]}
      />
    </>
  );
};

export const getStickerProps = (status: number) => {
  switch (status) {
    default:
    case AuditStatus.OPEN:
      return { icon: <StatusPendingIcon /> };
    case AuditStatus.COMPLETED:
      return { icon: <StatusCheckCircleIcon />, color: TAG_COLOR.SUCCESS };
    case AuditStatus.CANCELLED:
      return { icon: <StatusErrorIcon />, color: TAG_COLOR.DANGER };
  }
};

export default AuditTile;
