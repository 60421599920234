import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { pagePaths } from '../config';
import { IdpLoginProps } from '../types/IdpLogin.types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

const IdpLoginPage = ({ currentLanguageCode, getIdpAccessToken, history }: IdpLoginProps) => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.has('username') && queryParams.has('geocode')) {
      idpLogin(queryParams);
    }
  });

  const idpLogin = async (queryParams: URLSearchParams) => {
    await getIdpAccessToken(
      queryParams.get('username')!,
      queryParams.get('geocode')!,
      'SAML',
      currentLanguageCode
    );
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, queryParams.get('username')!);

    const siteIdFromShare = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
    dispatch(baseApi.util.resetApiState());
    dispatch(dspApi.util.resetApiState());

    if (siteIdFromShare) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
      history.replace(`${pagePaths.Share.replace(':siteCode', siteIdFromShare)}`);
    } else {
      history.replace('/home');
    }
  };

  return <LoadingPage />;
};

export default IdpLoginPage;
