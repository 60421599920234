import classNames from 'classnames';

import { ArrowRightIcon } from '../../../assets';
import { ISite } from '../../Sites/types/sites.types';

export function getMarkersFromSites({
  sites,
  selectedSiteId,
  inactiveMarkerIcon,
  activeMarkerIcon,
}: {
  sites: ISite[];
  selectedSiteId?: string;
  inactiveMarkerIcon: string;
  activeMarkerIcon: string;
}) {
  return (sites || [])
    .filter((site) => Boolean(site.geoCoordinates))
    .map((site) => ({
      id: site.id,
      icon: site.id === selectedSiteId ? activeMarkerIcon : inactiveMarkerIcon,
      coordinates: {
        longitude: site.geoCoordinates!.longitude,
        latitude: site.geoCoordinates!.latitude,
      },
      data: { site },
    }));
}

export function formatSiteToListItem({
  site,
  sitesImages = {},
  label,
  onSiteSelection,
}: {
  site: ISite;
  sitesImages?: Record<string, string>;
  label: (text: string) => string;
  onSiteSelection: (site: ISite) => void;
}) {
  const addressParts = [site?.address?.line1, site?.address?.line2].filter(Boolean);
  const strAddress = addressParts.join(',');

  return {
    id: site.id,
    data: site,
    imgInfo: sitesImages[site.id]
      ? {
          src: sitesImages[site.id],
          alt: site.name,
        }
      : undefined,
    title: site.name,
    description: strAddress,
    tileAction: () => {
      onSiteSelection(site);
    },
    highlightedActions: [
      {
        element: (
          <span className={classNames('action_element')} data-cy="action-element">
            <ArrowRightIcon title={label('Select site')} />
          </span>
        ),
        action: () => onSiteSelection(site),
      },
    ],
  };
}

export function sortSitesByDistance(
  sites: ISite[],
  userLocation: { latitude: number; longitude: number } | undefined | null
): ISite[] {
  if (!userLocation) {
    return sites;
  }
  const sitesToSort = [...sites];
  return sitesToSort.sort((siteA, siteB) => {
    const siteAHasCoordinates = Boolean(siteA.geoCoordinates);
    const siteBHasCoordinates = Boolean(siteB.geoCoordinates);

    if (siteAHasCoordinates && !siteBHasCoordinates) {
      return -1;
    } else if (!siteAHasCoordinates && siteBHasCoordinates) {
      return 1;
    } else if (!siteAHasCoordinates && !siteBHasCoordinates) {
      return 0;
    }
    const aDistance = getDistanceFromLatLonInKm(
      userLocation.latitude,
      userLocation.longitude,
      siteA.geoCoordinates!.latitude,
      siteA.geoCoordinates!.longitude
    );
    const bDistance = getDistanceFromLatLonInKm(
      userLocation.latitude,
      userLocation.longitude,
      siteB.geoCoordinates!.latitude,
      siteB.geoCoordinates!.longitude
    );
    return aDistance - bDistance;
  });
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadius = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = earthRadius * c; // Distance in km
  return d;
}
