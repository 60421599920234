import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { useGetStampsQuery, useRedeemStampsMutation, useScanQrCodeMutation } from '../../api/api';

import StampsWidget from './Widget';

const mockDispatch = jest.fn();

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
    logError: jest.fn(),
  }),
}));

jest.mock('@/helpers/hooks/Analytics/useAnalytics', () => ({
  __esModule: true,
  default: () => ({
    trackButtonClickEvent: jest.fn(),
  }),
}));

const mockSelector = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector),
  useDispatch: () => mockDispatch,
}));

const mockUseScanQrCodeMutation = jest.fn();
const mockUseRedeemStampsMutation = jest.fn();

jest.mock('@/modules/Stamps/api/api', () => ({
  useGetStampsQuery: jest.fn(),
  useScanQrCodeMutation: jest.fn(),
  useRedeemStampsMutation: jest.fn(),
}));

jest.mock('../../../../context/withSite', () => (WrappedComponent: any) => {
  return (props: any) => {
    return <WrappedComponent site={{ id: 'test' }} {...props} />;
  };
});

jest.mock('../../../../components/molecules/QrCode/QrCodeReader', () => () => (
  <div data-testid="qr-scanner" />
));

const UseGetStampsDataNotAllStampsCollected = {
  stampsCollected: 1,
  stampsToCollectReward: 5,
  isRewardAvailable: false,
};
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    siteId: 'id1',
  }),
}));

const UseGetStampsDataAllStampsCollected = {
  stampsCollected: 5,
  stampsToCollectReward: 5,
  isRewardAvailable: true,
};
const state = {
  Core: {
    context: {
      site: {
        name: ' Village',
        id: '1234',
      },
    },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
};
describe('StampsWidget', () => {
  describe('render with not all stamps collected', () => {
    afterAll(() => cleanup());
    beforeEach(() => {
      mockSelector.mockReturnValue(state);
      (useGetStampsQuery as jest.Mock).mockReturnValue({
        data: UseGetStampsDataNotAllStampsCollected,
        isLoading: false,
      });
      (useScanQrCodeMutation as jest.Mock).mockReturnValue([mockUseScanQrCodeMutation, {}]);
      (useRedeemStampsMutation as jest.Mock).mockReturnValue([mockUseRedeemStampsMutation, {}]);
    });

    renderComponent(StampsWidget, {}, state);

    it('should display content', () => {
      expect(screen.getByText('Ref: Get free coffee')).toBeTruthy();
      expect(screen.getByText('Ref: See All')).toBeTruthy();
      expect(screen.getByText('Ref: Get free coffee')).toBeTruthy();
      expect(screen.getByText('1/5')).toBeTruthy();
      expect(screen.getByTestId('widget-body')).toBeTruthy();
      expect(screen.getByTestId('qr-scanner')).toBeTruthy();
    });
  });
  describe('render with all stamps collected', () => {
    afterAll(() => cleanup());
    beforeEach(() => {
      mockSelector.mockReturnValue(state);
      (useGetStampsQuery as jest.Mock).mockReturnValue({
        data: UseGetStampsDataAllStampsCollected,
        isLoading: false,
      });
    });

    beforeEach(async () => {
      (useScanQrCodeMutation as jest.Mock).mockReturnValue([mockUseScanQrCodeMutation, {}]);
      (useRedeemStampsMutation as jest.Mock).mockReturnValue([mockUseRedeemStampsMutation, {}]);
    });
    renderComponent(StampsWidget, {}, state);

    it('should display content', () => {
      expect(screen.getByText('Free coffee!')).toBeTruthy();
      expect(screen.getByText('See All')).toBeTruthy();
      expect(screen.getByText('Collect reward')).toBeTruthy();
      expect(screen.getByText('5/5')).toBeTruthy();
      expect(screen.getByTestId('widget-body')).toBeTruthy();
      expect(screen.getByTestId('redeem-button')).toBeTruthy();
    });
  });

  describe('on fetching error', () => {
    afterAll(() => cleanup());
    beforeEach(() => {
      mockSelector.mockReturnValue(state);
      (useGetStampsQuery as jest.Mock).mockReturnValue({
        isLoading: false,
        isError: true,
      });
    });

    beforeEach(async () => {
      (useScanQrCodeMutation as jest.Mock).mockReturnValue([mockUseScanQrCodeMutation, {}]);
      (useRedeemStampsMutation as jest.Mock).mockReturnValue([mockUseRedeemStampsMutation, {}]);
    });
    renderComponent(StampsWidget, {}, state);

    it('should render placeholder with Something went wrong message', async () => {
      const widgetPlaceholder = screen.getAllByTestId('widget-placeholder');
      expect(widgetPlaceholder).toBeTruthy();
      expect(widgetPlaceholder[0]).toHaveTextContent('Something went wrong, data not loaded');
    });
  });
});
