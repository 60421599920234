import { connect } from 'react-redux';

import { State } from '../../../../../types/state.types';
import { resetEmail } from '../../../actions';
import ResetPassword from '../ResetPassword';

import { ResetEmailRequestType } from './ResetPassword.types';

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchResetPassword: (request: ResetEmailRequestType) => {
      return dispatch(resetEmail(request));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
