import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  LocalNotifications,
  ActionPerformed as LocalActionPerformed,
} from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';

import { IN_APP_BROWSER_WITH_LOCATION } from '../../../constants';
import history from '../../../history';
import { modulePath as accomodationPath } from '../../Accomodation/config';
import { modulePath as auditsPath } from '../../Audits/config';
import { pagePaths as pagePathContent } from '../../Content/config';
import { pagePaths as pagePathEvents } from '../../Events/config';
import { modulePath as surveysPath } from '../../Surveys/config';
import { RegisterPushNotificationType } from '../types/PushNotificationTypes';

import { AndroidPostNotificationsPermission } from 'capacitor-plugin-android-post-notifications-permission';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

const entityRoutes: { [entity: string]: { path: string; objectIdRequired: boolean } } = {
  Audit: { path: auditsPath, objectIdRequired: false },
  Survey: { path: surveysPath, objectIdRequired: false },
  stay: { path: accomodationPath + '/:id/details', objectIdRequired: true },
  Article: { path: pagePathContent.ContentDetails, objectIdRequired: true },
  Event: { path: pagePathEvents.EventDetails, objectIdRequired: true },
};

async function registerOnHubNotification(
  registerPushNotification: RegisterPushNotificationType,
  contactId: string
) {
  if (isPushNotificationsAvailable) {
    const [deviceInfo, deviceUniqId] = await Promise.all([Device.getInfo(), Device.getId()]);
    await PushNotifications.addListener('registration', (token) => {
      registerPushNotification({
        notificationToken: token.value,
        deviceInfo: deviceInfo,
        deviceUniqId: deviceUniqId.identifier,
        contactId,
      });
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        const platform = Capacitor.getPlatform();

        if (platform === 'android') {
          const title = notification?.title || notification?.data?.title || '';
          const body = notification?.body || notification?.data?.body || '';

          const regardingEntity = notification?.data?.regardingEntity || '';
          const objectId = notification?.data?.objectId || '';
          const url = notification?.data?.url || '';

          // Can't use id to search for message because of https://github.com/zo0r/react-native-push-notification/issues/1947

          const deliveredNotifications = await PushNotifications.getDeliveredNotifications();
          const notificationsToDelete = deliveredNotifications.notifications.filter(
            (item) => item.title === notification.title
          );
          await PushNotifications.removeDeliveredNotifications({
            notifications: notificationsToDelete,
          });

          // There are 3 "types" of notification in android:
          // 1. app is not running
          // 2. app is running but it's in background
          // 3. app is running and it's active
          // notifications of type 3 are not clickable the trick is to remove the native one
          // and replace it with local which is clickable

          await LocalNotifications.schedule({
            notifications: [
              {
                id: Math.round(+new Date() / 1000), //we need a 32bit integer here (unix timestamp)
                title,
                body,
                extra: { regardingEntity, objectId, url },
              },
            ],
          });
        }
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        redirectToModule(notification);
      }
    );

    await LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notification: LocalActionPerformed) => {
        redirectToModule(notification);
      }
    );

    await PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on Push registration: ' + JSON.stringify(error));
    });

    await PushNotifications.register();
  }
}

export async function initNotifications(
  registerPushNotification: RegisterPushNotificationType,
  contactId: string
) {
  const platform = Capacitor.getPlatform();
  if (platform === 'android') {
    // TODO this is temp workoround for details check https://azeuvs1aze987.visualstudio.com/IFM/_workitems/edit/51169
    let permissionStatus = await AndroidPostNotificationsPermission.checkPermissions();
    if (permissionStatus.postNotifications === 'prompt') {
      permissionStatus = await AndroidPostNotificationsPermission.requestPermissions({
        permissions: ['postNotifications'],
      });
    }

    if (permissionStatus.postNotifications === 'granted') {
      await registerOnHubNotification(registerPushNotification, contactId);
    }
  } else {
    const grantNotificationResponse = await PushNotifications.checkPermissions();
    if (grantNotificationResponse.receive !== 'granted') {
      const permission = await PushNotifications.requestPermissions();
      if (permission.receive !== 'denied') {
        await registerOnHubNotification(registerPushNotification, contactId);
      }
    } else {
      await registerOnHubNotification(registerPushNotification, contactId);
    }
  }
}

function redirectToModule(notification: any) {
  const { regardingEntity, objectId, payloadUrl } = extractDataFromNotification(notification);

  if (regardingEntity) {
    const entityRouteInfo = entityRoutes[regardingEntity];
    if (entityRouteInfo) {
      let entityRoute = entityRouteInfo.path;
      if (objectId) {
        entityRoute = entityRoute.replace(':id', objectId);
      }

      if (!entityRouteInfo.objectIdRequired || (entityRouteInfo.objectIdRequired && !!objectId))
        history.push(entityRoute);
    }
  }

  if (regardingEntity === 'Inmoment-Survey' && payloadUrl) {
    InAppBrowser.create(payloadUrl, '_blank', IN_APP_BROWSER_WITH_LOCATION);
  }
}

function extractDataFromNotification(notification: any) {
  const { data, extra } = notification?.notification;
  const payload = data || extra;

  const regardingEntity = payload?.regardingEntity || payload?.aps?.alert.regardingEntity || '';
  const objectId = payload?.objectId || payload?.aps?.alert.objectId;
  const payloadUrl = payload?.url || payload?.aps?.alert.url;

  return {
    regardingEntity,
    objectId,
    payloadUrl,
  };
}
