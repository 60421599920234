import { RouteComponentProps } from 'react-router';

export interface Action {
  label: string;
  action: () => void;
  look?: string;
  loading?: boolean;
}

export interface INoticePageProps extends RouteComponentProps<never> {
  id?: string;
  hasBackLink?: boolean;
  withNavBar?: boolean;
  withTitleBar?: boolean;
  pageTitle?: any;
  title?: string;
  tabTitle?: string;
  content?: string | string[];
  subtitle?: string;
  img?: any;
  actions?: Action[];
  children?: any;
  note?: any;
  next?: () => void;
  topContent?: React.ReactNode;
  hideNotificationsWidget?: boolean;
  hideAccountWidget?: boolean;
  hideAllWidgets?: boolean;
  type?: NOTICEPAGE_TYPE;
}

export enum NOTICEPAGE_TYPE {
  SUCCESS = 'SuccessNotice',
  ERROR = 'ErrorNotice',
}
