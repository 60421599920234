const labels = {
  PrivacyPolicy: 'Politique de confidentialité',
  TermsOfSale: 'Conditions générales de vente',
  TermsOfUse: "Conditions d'utilisations",
  home: 'Accueil',
  anytime: "N'importe quand",
  anywhere: 'partout',
  Download: 'Télécharger',
  scrollRightButton: 'Faites défiler les catégories à droite',
  scrollLeftButton: 'Catégories de défilement vers la gauche',
  calendar: 'Calendrier',
  'See more': 'Plus',
  'See less': 'Voir moins',
  'from (followed by a date, or date and time)': 'de',
  'to (followed by a date, or date and time)': 'à',
  'on (followed by a date)': 'Le',
  'at (followed by time)': 'à',
  'from (followed by a location)': 'depuis',
  'to (followed by a location)': 'à',
  'on (followed by a site name)': 'Le',
  'from (followed by a person)': 'depuis',
  previous: 'précédent',
  next: 'Suivant',
  success: 'Succès',
  failure: 'échec',
  today: "Aujourd'hui",
  tomorrow: 'Demain',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
  everyday: 'Tous les jours',
  'open all day': 'Ouvert toute la journée',
  'opening hours': "Horaires d'ouvertures",
  status: 'statut',
  'to be confirmed': 'A confirmer',
  'only (as in filter only this)': 'seulement',
  edit: 'Éditer',
  submit: 'Envoyer',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  reset: 'Réinitialiser',
  Delete: 'Effacer',
  select: 'sélectionner',
  switch: 'changer',
  close: 'Fermer',
  clear: 'effacer',
  remove: 'déplacer',
  logout: 'Se déconnecter',
  go: 'aller',
  'see (as in consult this page or document)': 'voir',
  'see all (as in see all items)': 'Voir tout',
  'see more (as in see more details for this item)': 'Plus',
  retry: 'essayer à nouveau',
  'Ref: ok': "D'accord",
  'yes (as in yes I accept)': 'Oui',
  'no (as in no I do not accept)': 'Non',
  you: 'vous',
  'thank you': 'Merci',
  'sorry (standalone)': 'Désolé',
  'to home (as in navigate to the homepage)': "à la page d'accueil",
  map: 'carte',
  list: 'liste',
  'Loading...': 'Chargement...',
  'Refreshing...': 'Réactualiser',
  'Please select': 'Veuillez sélectionner',
  'Switch site': 'Changer de site ici',
  'File size cannot exceed {max file size}':
    'La taille du fichier ne peut pas dépasser {max file size}',
  Filters: 'Filtrer',
  'Add attachment': 'Ajouter une pièce jointe',
  Search: 'Recherche',
  'More (as in: see more info)': 'Plus',
  'You can attach a maximum of {max files number} files.':
    'Vous pouvez joindre un maximum de {max files number} fichiers.',
  'There are no messages to display.': "Il n'y a aucun message à afficher.",
  'Your selection did not return any result.': "Votre sélection n'a renvoyé aucun résultat.",
  'Ref: Popup header for missing required fields in a form':
    'Veuillez fournir des informations supplémentaires',
  'Ref: Required field': '{ref} est un champ obligatoire.',
  email: 'E-mail',
  phone: 'téléphone',
  Account: 'Compte',
  Contacts: 'Contacts',
  Content: 'Contenu',
  Events: 'Évènements',
  Facilities: 'Installations',
  Shop: 'Boutique',
  Menu: 'Menu',
  Menus: 'Menus',
  Order: 'Commande',
  Orders: 'Commandes',
  Info: 'Info',
  FAQ: 'FAQ',
  Feedback: 'Votre avis',
  Review: 'La revue',
  Requests: 'Demandes',
  'Request Helpdesk': "Demander un service d'assistance",
  Location: 'Emplacement',
  Safety: 'Sécurité',
  FreeCoffee: 'Café gratuit',
  Newsfeed: "Fil d'actualité",
  MyOrders: 'Mes commandes',
  LoyaltyStamps: 'Timbres de fidélité',
  Stamps: 'Timbres',
  Stays: 'Séjours',
  Suppliers: 'Fournisseurs',
  Surveys: 'Enquêtes',
  notifications: 'notifications',
  cart: 'panier',
  'Ref: Reset your order': 'Réinitialisez votre commande',
  'Ref: My language': 'Ma langue',
  Validate: 'Valider',
  'contact us': 'Nous contacter',
  'Ref: Start typing': 'Commencer à écrire',
  Occupancy: 'Occupation',
  'see less': 'Voir moins',
  'see more': 'Voir plus',
  contacts: 'Contacts',
  Imprint: 'Imprimer',
  'Ref: camera access request title': "Autoriser l'accès à la caméra",
  'Ref: camera access request body':
    "Vous devez activer l'utilisation d'une caméra sur ce site afin d'utiliser le scanner de code QR",
  or: 'ou',
  'Ref: Input required': '{input_name} est requis',
  'Enter a valid email address': 'Entrez une adresse mail valide',
  'Ref: Input error E-mail already exists':
    'Il y a déjà un compte avec cet e-mail que vous avez saisi.',
  'Password must contain': 'Le mot de passe doit contenir:',
  'Ref: Input password error strength':
    'Le mot de passe doit contenir au moins 8 caractères, 1 numéro, 1 lettre et 1 caractère spécial',
  Password: 'Mot de passe',
  'New password': 'Nouveau mot de passe',
  'Confirm password': 'Confirmez le mot de passe',
  'Ref: Password does not match': 'Le mot de passe ne correspond pas',
  'Ref: Old password does not match': "L'ancien mot de passe ne correspond pas",
  'Ref: Password validation min8Characters': 'Au moins 8 caractères',
  'Ref: Password validation min1Number': 'Au moins 1 chiffre',
  'Ref: Password validation min1Letter': 'Au moins 1 lettre',
  'Ref: Password validation min1SpecialChar': 'Au moins 1 caractère spécial',
  Continue: 'Continuer',
  'Enter a valid phone number': 'Entrez un numéro de téléphone valide',
  'Phone is required': 'Le numéro de téléphone est requis',
  'Go to home': "Aller à l'accueil",
  'Something went wrong': "Quelque chose s'est mal passé",
  'Something went wrong, data not loaded':
    "Quelque chose s'est mal passé, les données non chargées",
  'Change password': 'Changer le mot de passe',
  'remove one': 'enlever un',
  'add one': 'ajoute un',
  'Confirm Filters': 'Confirmer les filtres',
  'Reset Filters': 'Réinitialiser les filtres',
  '{selectedNum} active filters': '{selectedNum} filtres actifs',
  'Wrong input icon': 'Icône incorrect',
  'Correct input icon': "Icône d'entrée correcte",
  'Show input text icon': "Afficher l'icône du texte",
  'Hide input text icon': "Masquer l'icône du texte",
  'Required fields info': '* Champs obligatoires',
  'Ref: Select site on the map': 'Sélectionnez votre site sur la carte',
  'Ref: Geolocation disabled':
    "La géolocalisation est désactivée. Pour voir les sites à proximité, veuillez l'activer.",
  'Ref: Reaction - LIKE': "J'aime",
  'Ref: Reaction - LOVE': "J'adore",
  'Ref: Reaction - INTERESTING': 'Intéressant',
  'Ref: Reaction - CONGRATE': 'Félicitations',
  'Ref: Share': 'Partager',
  'Ref: Send by Email': 'Envoyer par email',
  'Ref: Copy the link': 'Copier le lien',
  'Ref: Link was copied': 'Link a été copié',
  'Ref: unable to copy url': "Impossible de copier l'URL",
  'Ref: External link': 'Lien externe',
  'Ref: Step': 'Étape',
  'input field': 'champ de saisie',
  'Ref: Zoom': 'Zoom',
  'Increase hours': 'Augmenter les heures',
  'Decrease hours': 'Diminuer les heures',
  Hours: 'Heures',
  Minutes: 'Minutes',
  'Ref: filter label: favorites': 'Montrez mes favoris uniquement',
  'Ref: filter label: max calories': 'Calories maximales',
  'Ref: filter label: dishes': 'Vaisselle',
  'Ref: filter label: allergens': 'Allergènes',
  'Ref: filter label: highlight': 'Mettez en surbrillance ou masquez les filtres avec des plats',
  'Ref: Highlight': 'Souligner',
  'Ref: Hide': 'Cacher',
  'Ref: Vegan': 'Végétalien',
  'Ref: Vegeterian': 'Végétarien',
  'Ref: Mindful': 'Conscient',
  'Ref: Highlight text':
    'Ici, vous pouvez choisir de mettre en surbrillance ou de masquer les plats du menu qui contiennent certains allergènes',
  'Ref: Just now': 'Juste maintenant',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} il y a quelques minutes',
  'Ref: 1 hour ago': 'Il ya 1 heure',
  'Ref: {amountHours} hours ago': '{amountHours} il y a des heures',
  'Ref: Yesterday at {time}': 'Hier à {time}',
  'Ref: {date} at {time}': '{date} à {time}',
  'Ref: filter label: date': 'Date',
  'Ref: expand menu': 'étendre le menu',
  'Ref: collapse menu': "menu d'effondrement",
  'Ref: My site': 'Mon site',
  'Ref: Recent': 'Récent',
  'Ref: Services': 'Services',
  'Ref: products in cart': 'Nombre de produits dans le panier',
  'Ref: no product in cart': 'Pas de produit dans le panier',
  'Ref: Survey': 'Enquête',
  'Access to camera was denied. Please allow access and try again':
    "L'accès à la caméra a été refusé. Veuillez autoriser l'accès et réessayer",
  'Add an attachment': 'Ajouter une pièce jointe',
  'Take a picture': 'Prendre une photo',
  'Add a file from your device': 'Ajoutez un fichier de votre appareil',
  'Ref: Loading page content, please wait': 'Chargement du contenu de la page, veuillez attendre',
  Star: 'Étoile',
  Stars: 'Étoiles',
  'Accommodation Request': "Demande d'hébergement",
  'Ref: Guest Accomodation Request': "Demande d'invité",
  'Ref: Search a menu, product or facility': 'Recherchez un menu, un produit ou une installation',
  ConnectionLost: 'Connexion du réseau perdu',
  ConnectionRestored: 'Connexion réseau restaurée',
  'Ref: Skip to main content': 'Passez au contenu principal',
  'Ref: No options': 'Aucune option',
  'Ref: Global search modal': 'Modal de recherche globale',
  'Ref: Search a facility': 'Rechercher une installation',
  'Ref: Search a menu or product': 'Rechercher un menu ou un produit',
  'Ref: Reaction': 'Réaction',
  'Ref: times': 'fois',
  'Ref: reaction. Change reaction': 'réaction. Changer la réaction',
  'Ref: No reaction. React': 'Pas de réaction. Réagir',
  'Ref: LIKE': 'Comme',
  'Ref: LOVE': 'Amour',
  'Ref: INTERESTING': 'Intéressant',
  'Ref: CONGRATE': 'Féliciter',
  'Ref: Open search': 'Ouvrez la zone de recherche',
  'Ref: Close search': 'Fermez la zone de recherche',
  'Ref: Total file size msg': 'La taille totale du fichier ne peut pas dépasser {max file size}',
  'Ref: Oops Title': 'Oops!',
  'Ref: Well Done': 'Bien joué {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Arrow de navigation - Continuez',
  'Ref: Reload': 'Recharger',
  'Ref: All Dates': 'Toutes les dates',
  'Ref: Today': "Aujourd'hui",
  'Ref: Validate': 'Valider',
  'Ref: toast error title': 'Oops!',
  'Ref: toast error subtitle': "Une erreur s'est produite, veuillez réessayer.",
  'Ref: Calendar': 'Calendrier',
  'Ref: Selected': 'Choisi',
  'Ref: Previous Month': 'Le mois précédent',
  'Ref: Next Month': 'Le mois prochain',
  'This module has been retired': 'Ce module a été retiré',
};
export default labels;
