import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { State } from '../../../../types/state.types';
import { refreshAccessToken } from '../../actions';

import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state: State) => {
  const {
    isFirstLogin,
    privacyPolicyToAcknowledge,
    termsOfUseToAcknowledge,
    shouldSelectSiteContext,
  } = state.Core.access;

  const contractId = state.Core.user?.contract?.id;
  const { locks } = state.Core;

  return {
    isFirstLogin,
    isTokenRefreshing: locks.tokenRefresh,
    privacyPolicyToAcknowledge: privacyPolicyToAcknowledge === false ? false : true,
    termsOfUseToAcknowledge: termsOfUseToAcknowledge === false ? false : true,
    shouldSelectSiteContext,
    isUserContextReturned: locks.getUserContext,
    contractId,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  refreshAccessToken: (refreshToken: string) => dispatch(refreshAccessToken(refreshToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
