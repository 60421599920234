import { LabelFunc } from '../../../context/withLang';
import { getCustomSetupOption } from '../../../helpers/getSetupOption';
import { isServiceType } from '../../../helpers/misc';
import { SERVICE, serviceTypes, servicesHiddenInNav } from '../../../modules/config';
import { ServiceDefinition } from '../../../modules/defaultServices';

const getServicePath = (service: ServiceDefinition): string => {
  const { value: url } = getCustomSetupOption('externalUrl', service.setupOptions);
  const { value: emmbededURL } = getCustomSetupOption('emmbededURL', service.setupOptions);

  if (emmbededURL) {
    return `/externalContent?url=${encodeURIComponent(emmbededURL)}&title=${encodeURI(
      service.navigation.name
    )}`;
  } else if (url) {
    return url;
  }

  let path = serviceTypes[service.name].path;
  if (isServiceType(service, SERVICE.CONTENT_PAGE)) {
    path += '/' + service.navigation.name;
  }

  return path;
};

const getServicesWithNav = (services: ServiceDefinition[], isLoggedIn: boolean) => {
  const servicesWithNav = services
    .filter(
      (service: ServiceDefinition) =>
        service.navigation &&
        service.navigation.isEnabled &&
        !servicesHiddenInNav.includes(service.name as SERVICE)
    )
    .filter((service: ServiceDefinition) =>
      Object.keys(serviceTypes).find(
        (el) =>
          el === service.name &&
          !getCustomSetupOption('navItemHidden', service.setupOptions).isActive
      )
    );

  //sort by nav item order index
  servicesWithNav.sort((a, b) => a.navigation.orderIndex - b.navigation.orderIndex);

  return servicesWithNav;
};

const getItemLabel = (service: ServiceDefinition, label: LabelFunc) => {
  if (isServiceType(service, [SERVICE.CONTENT_PAGE, SERVICE.EXTERNAL_WEBSITE])) {
    return label(service.navigation.name);
  }

  return label(serviceTypes[service.name].label, {
    textTransform: 'capitalize',
  });
};

export { getServicePath, getServicesWithNav, getItemLabel };
