import { useSelector } from 'react-redux';

import { LOCAL_STORAGE_KEYS } from '../../constants';
import { State } from '../../types/state.types';

function useGeoCode(): { defaultGeoCode: string; currentGeoCode: string } {
  const defaultGeoCode = useSelector((state: State) => state.Shared.geographies?.defaultGeoCode);
  const cachedGeoCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE);
  const currentGeoCode = cachedGeoCode ?? defaultGeoCode;

  return { defaultGeoCode, currentGeoCode };
}

export default useGeoCode;
