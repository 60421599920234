import classNames from 'classnames';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { AccountIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import FormLine from '../../../../components/atoms/FormLine/FormLine';
import Select from '../../../../components/atoms/Select';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { applyLanguage } from '../../actions';
import { useUpdateUserContextMutation } from '../../api/account/userContextApi';
import { pagePaths, languages } from '../../config';
import withLang from '../../context/withLang';
import useLanguage from '../../hooks/useLanguage';

import { WithLangProps } from '@/context/withLang';

import styles from './LanguagePage.module.css';

const LanguagePage: FC<WithLangProps> = ({ label }) => {
  const dispatch = useDispatch();
  const { currentLanguageCode } = useLanguage();
  const history = useHistory();
  const [updateUserContext, { isLoading }] = useUpdateUserContextMutation();

  const [selectedLanguage, setSelectedLanguage] = useState<{
    code: string;
    backLanguageId?: string;
  }>({ code: currentLanguageCode });

  const applyLanguageAndRedirect = async () => {
    await updateUserContext({ preferredLanguageCode: selectedLanguage.code });
    dispatch(applyLanguage({ code: selectedLanguage.code, isUserSelected: true }));

    history.push(pagePaths['Account']);
  };

  const handleChangeLanguage = (language: { value: string }) => {
    const code = language.value;
    const backLanguageId = languages.find((l) => l.code === code)?.backLanguageId;
    setSelectedLanguage({
      code,
      backLanguageId,
    });
  };

  const title = (
    <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
      {label('Ref: Page title')}
    </Title>
  );

  const selectLanguage = (
    <Card
      overTitle={{
        tag: TITLE_TAG.H3,
        size: TITLE_SIZE.BODYMDEFAULT,
        children: label('Select in which language you would like to read our app'),
      }}
      className={classNames(styles.selectLanguageCard)}
    >
      <FormLine data-testid="select-language">
        <Select
          menuPortalTarget={document.body}
          name="languages"
          data-cy="language-select"
          data-testid="language-page-select"
          label={label('My language')}
          noOptionsMessage={() => label('Ref: No options')}
          value={selectedLanguage.code}
          options={languages.map((language) => ({
            label: language.name,
            value: language.code,
          }))}
          onChange={handleChangeLanguage}
        />
      </FormLine>
    </Card>
  );

  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink={true}>
      <Container>
        <Column.Main>
          {title}
          {selectLanguage}
        </Column.Main>
        <Column.Complementary>
          <AccountIllustration />
        </Column.Complementary>
        <ActionsBar.Static inMainColumn>
          <Button
            data-testid="language-apply-and-redirect"
            loading={isLoading}
            onClick={applyLanguageAndRedirect}
          >
            {label('apply', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar.Static>
      </Container>
    </SimpleFormPage>
  );
};

export default withLang([__filename])(LanguagePage);
