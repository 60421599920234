import { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import withLoginStatus from '../../../context/withLoginStatus';
import { Result } from '../../../types/common.types';
import { useGetPublicSitesQuery } from '../../Sites/api/api';
import LocationListPage from '../../Sites/components/LocationListPage/LocationListPage';
import { ISite } from '../../Sites/types/sites.types';
import { pagePaths } from '../config';
import withLang from '../context/withLang';
import {
  IAccountOnboardingLocationProps,
  ISiteWithContract,
} from '../types/accountOnboardingLocation.types';

export enum CodeErrors {
  UNAUTHORIZED = 'Ref: Unauthorized error',
  FORBIDDEN = 'Ref: Forbidden',
  NOT_FOUND = 'Ref: Not found',
  GENERIC_ERROR = 'Ref: Generic error',
}

const AccountOnboardingLocation = ({
  isGetSiteLocked,
  isOnboarding,
  getSiteByCode,
  updateSiteAndContract,
  label,
  history,
  contractId,
}: IAccountOnboardingLocationProps) => {
  const [codeError, setCodeError] = useState<CodeErrors | undefined>();
  const { data: sites = [], isFetching: isSitesLocked } = useGetPublicSitesQuery({});
  const checkIfOnboarded = useCallback(() => {
    if (!!contractId && !isOnboarding) history.replace(pagePaths.Home);
  }, [history, contractId, isOnboarding]);

  const siteIdFromShare = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);

  useEffect(() => {
    if (siteIdFromShare) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
      history.replace(`${pagePaths.Share.replace(':siteCode', siteIdFromShare)}`);
    }
  }, [history, siteIdFromShare]);

  useEffect(() => {
    checkIfOnboarded();
  }, [checkIfOnboarded]);

  const handleSiteSelection = useCallback(
    (site: ISite) => {
      updateSiteAndContract(site.id);
    },
    [updateSiteAndContract]
  );

  const handleValidateCode = useCallback(
    async (siteCode: string, closeModal: Function) => {
      const result: Result<ISiteWithContract> = await getSiteByCode(siteCode);
      const { responseStatus, responseData } = result;

      switch (responseStatus) {
        case 200:
          closeModal();
          await updateSiteAndContract(responseData.site.id);
          break;
        case 401:
          setCodeError(CodeErrors.UNAUTHORIZED);
          break;
        case 403:
          setCodeError(CodeErrors.FORBIDDEN);
          break;
        case 404:
          setCodeError(CodeErrors.NOT_FOUND);
          break;
        default:
          setCodeError(CodeErrors.GENERIC_ERROR);
      }
    },
    [getSiteByCode, updateSiteAndContract]
  );

  const handleScanCode = useCallback(
    async ({ response }: QRCodeReaderResponse, openErrorModal: () => void) => {
      if (response) {
        const result: Result<ISiteWithContract> = await getSiteByCode(response);
        const { responseStatus, responseData } = result;

        switch (responseStatus) {
          case 200:
            await updateSiteAndContract(responseData.site.id);
            break;
          case 401:
            setCodeError(CodeErrors.UNAUTHORIZED);
            openErrorModal();
            break;
          case 403:
            setCodeError(CodeErrors.FORBIDDEN);
            openErrorModal();
            break;
          case 404:
            setCodeError(CodeErrors.NOT_FOUND);
            openErrorModal();
            break;
          default:
            setCodeError(CodeErrors.GENERIC_ERROR);
        }
      }
    },
    [getSiteByCode, updateSiteAndContract]
  );

  if (isSitesLocked || isGetSiteLocked || isOnboarding || siteIdFromShare) {
    return <LoadingPage />;
  }

  return (
    <LocationListPage
      withNavBar={false}
      hideAllWidgets={true}
      title={label('Ref: Page title')}
      sites={sites}
      siteCodeError={codeError}
      pagePaths={pagePaths}
      label={label}
      layoutOptions={{ withStepper: true, withNavBar: false }}
      isSiteCodeLocked={isGetSiteLocked}
      onSiteSelection={handleSiteSelection}
      onValidateSiteCode={handleValidateCode}
      onScanSiteCode={handleScanCode}
      handleCodeError={setCodeError}
      enableUserLocation
    />
  );
};

export default withLoginStatus(withRouter(withLang([__filename])(AccountOnboardingLocation)));
