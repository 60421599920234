import React from 'react';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';

import withSetupOptions from '../../../context/withSetupOptions';
import { moduleId, pagePaths } from '../config';

import FacilityPicker from './FacilityPicker';
import ReviewForm from './ReviewForm';
import SuccessPage from './SuccessPage';

interface IReviewProps extends RouteComponentProps<never> {
  children: React.ReactNode;
}

const Review = ({ children }: IReviewProps) => {
  if (children) {
    return <>{children}</>;
  }

  return (
    <Switch>
      <Route exact path={pagePaths.Module} component={FacilityPicker} />
      <Route exact path={pagePaths.ReviewForm} component={ReviewForm} />
      <Route exact path={pagePaths.SubmitSuccess} component={SuccessPage} />
      <Route exact path={pagePaths.FacilityReview} component={FacilityPicker} />
    </Switch>
  );
};

export default withSetupOptions(moduleId, withRouter(Review));
