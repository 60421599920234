import classNames from 'classnames';

import { Tag } from '../../../components/molecules/Tile/Tag';
import { TAG_VARIANT } from '../../../components/molecules/Tile/Tile.types';
import { ListItemProps } from '../../../components/organisms/List/List.types';
import { LabelFunc } from '../../../context/withLang';
import {
  Allergen,
  AllergenType,
  FacilityMenu,
  ICartModifier,
  MenuItem,
  Modifier,
  NutritionFacts,
  ProductPortion,
} from '../types/orderState.types';
import { ModifierSelections } from '../types/productDetails.types';

import { displayAllergen } from './allergens.helper';
import { calculateNutritionSum } from './nutritions.helper';
import { getPrice } from './order.helper';
import { buildAllergenChip } from './productTile.helpers';

import styles from '../components/ProductDetails.module.css';

export const buildAdditives = (portion: ProductPortion, label: LabelFunc): string => {
  if (portion.additives) return portion.additives;
  const additives: string[] = [];
  if (portion.eggsAdditive) additives.push(label('Ref: Additives eggs'));
  if (portion.fishAdditive) additives.push(label('Ref: Additives fish'));
  if (portion.milkAdditive) additives.push(label('Ref: Additives milk'));
  if (portion.nutsAdditive) additives.push(label('Ref: Additives nuts'));
  if (portion.wheatAdditive) additives.push(label('Ref: Additives wheat'));
  if (portion.peanutsAdditive) additives.push(label('Ref: Additives peanuts'));
  if (portion.soyBeansAdditive) additives.push(label('Ref: Additives soy beans'));
  if (portion.sesameSeedsAdditive) additives.push(label('Ref: Additives sesame seeds'));

  return additives.join(', ');
};

export const buildNutritionList = (
  portion: ProductPortion,
  label: LabelFunc,
  modifiersNutritionsSum: NutritionFacts | null,
  divisor: number
): ListItemProps[] => {
  const nutritions: ListItemProps[] = [];

  nutritions.push({
    id: 'calories',
    label: `${label('Ref: Nutrition Calories')}`,
    'data-testid': 'calories',
    value: `${calculateTotalNutritions(
      portion.nutritions.kilojoules,
      modifiersNutritionsSum?.kilojoules,
      'kJ',
      divisor
    )} /
  ${calculateTotalNutritions(
    portion.nutritions.calories,
    modifiersNutritionsSum?.calories,
    'kcal',
    divisor
  )}`,
  });

  nutritions.push({
    id: 'fat',
    label: `${label('Ref: Nutrition Fat')}`,
    'data-testid': 'fat',
    value: `${calculateTotalNutritions(
      portion.nutritions.fat,
      modifiersNutritionsSum?.fat,
      'g',
      divisor
    )}`,
  });

  nutritions.push({
    id: 'saturated',
    label: `${label('Ref: Nutrition Saturated Fat')}`,
    'data-testid': 'saturated-fat',
    value: `${calculateTotalNutritions(
      portion.nutritions.saturatedFat,
      modifiersNutritionsSum?.saturatedFat,
      'g',
      divisor
    )}`,
  });

  nutritions.push({
    id: 'carbohydrates',
    label: `${label('Ref: Nutrition Carbohydrates')}`,
    'data-testid': 'carbohydrates',
    value: `${calculateTotalNutritions(
      portion.nutritions.carbohydrates,
      modifiersNutritionsSum?.carbohydrates,
      'g',
      divisor
    )}`,
  });

  nutritions.push({
    id: 'sugar',
    label: `${label('Ref: Nutrition Sugar')}`,
    'data-testid': 'sugar',
    value: `${calculateTotalNutritions(
      portion.nutritions.sugar,
      modifiersNutritionsSum?.sugar,
      'g',
      divisor
    )}`,
  });

  nutritions.push({
    id: 'protein',
    label: `${label('Ref: Nutrition Protein')}`,
    'data-testid': 'protein',
    value: `${calculateTotalNutritions(
      portion.nutritions.protein,
      modifiersNutritionsSum?.protein,
      'g',
      divisor
    )}`,
  });

  nutritions.push({
    id: 'sodium',
    label: `${label('Ref: Nutrition Sodium')}`,
    'data-testid': 'sodium',
    value: `${calculateTotalNutritions(
      portion.nutritions.sodium,
      modifiersNutritionsSum?.sodium,
      'g',
      divisor
    )}`,
  });

  return nutritions;
};

const calculateTotalNutritions = (
  portionNutritionValue: number,
  modifiersNutritionValue: number | undefined,
  unit: string = '',
  divisor?: number
): string => {
  const result = ((portionNutritionValue || 0) + (modifiersNutritionValue || 0)) * (divisor || 1);
  return `${Number.isInteger(result) ? result : result.toFixed(2)} ${unit}`;
};

export const buildDetailsNutritionList = (
  portion: ProductPortion,
  label: LabelFunc,
  modifiersNutritionsSum: NutritionFacts | null,
  divisor?: number
): ListItemProps[] => {
  const nutritions: ListItemProps[] = [];
  if (portion.nutritions.calcium)
    nutritions.push({
      id: 'calcium',
      label: `${label('Ref: Nutrition Calcium')}`,
      'data-testid': 'calcium',
      value: calculateTotalNutritions(
        portion.nutritions.calcium,
        modifiersNutritionsSum?.calcium,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.cholesterol)
    nutritions.push({
      id: 'cholesterol',
      label: `${label('Ref: Nutrition cholesterol')}`,
      'data-testid': 'cholesterol',
      value: calculateTotalNutritions(
        portion.nutritions.cholesterol,
        modifiersNutritionsSum?.cholesterol,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.dietaryFiber)
    nutritions.push({
      id: 'dietaryFiber',
      label: `${label('Ref: Nutrition dietaryFiber')}`,
      'data-testid': 'fiber',
      value: calculateTotalNutritions(
        portion.nutritions.dietaryFiber,
        modifiersNutritionsSum?.dietaryFiber,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.iron)
    nutritions.push({
      id: 'iron',
      label: `${label('Ref: Nutrition iron')}`,
      'data-testid': 'iron',
      value: calculateTotalNutritions(
        portion.nutritions.iron,
        modifiersNutritionsSum?.iron,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.potassium)
    nutritions.push({
      id: 'potassium',
      label: `${label('Ref: Nutrition potassium')}`,
      'data-testid': 'potassium',
      value: calculateTotalNutritions(
        portion.nutritions.potassium,
        modifiersNutritionsSum?.potassium,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.vitaminA)
    nutritions.push({
      id: 'vitaminA',
      label: `${label('Ref: Nutrition vitaminA')}`,
      'data-testid': 'vitaminA',
      value: calculateTotalNutritions(
        portion.nutritions.vitaminA,
        modifiersNutritionsSum?.vitaminA,
        'mg',
        divisor
      ),
    });

  if (portion.nutritions.vitaminC)
    nutritions.push({
      id: 'vitaminC',
      label: `${label('Ref: Nutrition vitaminC')}`,
      'data-testid': 'vitaminC',
      value: calculateTotalNutritions(
        portion.nutritions.vitaminC,
        modifiersNutritionsSum?.vitaminC,
        'mg',
        divisor
      ),
    });

  return nutritions;
};

export const getCompleteNutritionList = (
  portion: ProductPortion,
  label: LabelFunc,
  modifiersNutritionsSum: NutritionFacts | null,
  divisor: number
): ListItemProps[] => {
  const nutritions = buildNutritionList(portion, label, modifiersNutritionsSum, divisor);
  const detailedNutritions = buildDetailsNutritionList(
    portion,
    label,
    modifiersNutritionsSum,
    divisor
  );
  return [...nutritions, ...detailedNutritions];
};

export const calculateAllNutritionValues = (
  portion: ProductPortion,
  modifiersNutritionsSum: NutritionFacts | null
) => {
  const nutritions = [];

  nutritions.push({
    id: 'calories',
    value: calculateTotalNutritions(portion.nutritions.calories, modifiersNutritionsSum?.calories),
  });

  nutritions.push({
    id: 'totalFat',
    value: calculateTotalNutritions(portion.nutritions.fat, modifiersNutritionsSum?.fat),
  });

  nutritions.push({
    id: 'saturatedFat',
    value: calculateTotalNutritions(
      portion.nutritions.saturatedFat,
      modifiersNutritionsSum?.saturatedFat
    ),
  });

  nutritions.push({
    id: 'totalCarbohydrate',
    value: calculateTotalNutritions(
      portion.nutritions.carbohydrates,
      modifiersNutritionsSum?.carbohydrates
    ),
  });

  nutritions.push({
    id: 'sugars',
    value: calculateTotalNutritions(portion.nutritions.sugar, modifiersNutritionsSum?.sugar),
  });

  nutritions.push({
    id: 'protein',
    value: calculateTotalNutritions(portion.nutritions.protein, modifiersNutritionsSum?.protein),
  });

  nutritions.push({
    id: 'sodium',
    value: calculateTotalNutritions(portion.nutritions.sodium, modifiersNutritionsSum?.sodium),
  });

  if (portion.nutritions.calcium)
    nutritions.push({
      id: 'calcium',
      value: calculateTotalNutritions(portion.nutritions.calcium, modifiersNutritionsSum?.calcium),
    });

  if (portion.nutritions.cholesterol)
    nutritions.push({
      id: 'cholesterol',
      value: calculateTotalNutritions(
        portion.nutritions.cholesterol,
        modifiersNutritionsSum?.cholesterol
      ),
    });

  if (portion.nutritions.dietaryFiber)
    nutritions.push({
      id: 'dietaryFiber',
      value: calculateTotalNutritions(
        portion.nutritions.dietaryFiber,
        modifiersNutritionsSum?.dietaryFiber
      ),
    });

  if (portion.nutritions.iron)
    nutritions.push({
      id: 'iron',
      value: calculateTotalNutritions(portion.nutritions.iron, modifiersNutritionsSum?.iron),
    });

  if (portion.nutritions.potassium)
    nutritions.push({
      id: 'potassium',
      value: calculateTotalNutritions(
        portion.nutritions.potassium,
        modifiersNutritionsSum?.potassium
      ),
    });

  if (portion.nutritions.vitaminA)
    nutritions.push({
      id: 'vitaminA',
      value: convertMgToInternationalUnit(
        Number(
          calculateTotalNutritions(portion.nutritions.vitaminA, modifiersNutritionsSum?.vitaminA)
        )
      ).toString(),
    });

  if (portion.nutritions.vitaminC)
    nutritions.push({
      id: 'vitaminC',
      value: convertMgToInternationalUnit(
        Number(
          calculateTotalNutritions(portion.nutritions.vitaminC, modifiersNutritionsSum?.vitaminC)
        )
      ).toString(),
    });

  return nutritions;
};

export const buildModifiersNutritionList = (
  label: LabelFunc,
  nutritionsFacts: NutritionFacts | null
): ListItemProps[] => {
  const nutritions: ListItemProps[] = [];

  nutritions.push({
    id: 'calories',
    label: `${label('Ref: Nutrition Calories')}`,
    'data-testid': 'modifiers-calories',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.calories, 'kJ')} /
    ${calculateTotalNutritions(0, nutritionsFacts?.calories, 'kcal')}`,
  });

  nutritions.push({
    id: 'fat',
    label: `${label('Ref: Nutrition Fat')}`,
    'data-testid': 'modifiers-fat',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.fat, 'g')}`,
  });

  nutritions.push({
    id: 'saturated',
    label: `${label('Ref: Nutrition Saturated Fat')}`,
    'data-testid': 'modifiers-saturated-fat',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.saturatedFat, 'g')}`,
  });

  nutritions.push({
    id: 'carbohydrates',
    label: `${label('Ref: Nutrition Carbohydrates')}`,
    'data-testid': 'modifiers-carbohydrates',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.carbohydrates, 'g')}`,
  });

  nutritions.push({
    id: 'sugar',
    label: `${label('Ref: Nutrition Sugar')}`,
    'data-testid': 'modifiers-sugar',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.sugar, 'g')}`,
  });

  nutritions.push({
    id: 'protein',
    label: `${label('Ref: Nutrition Protein')}`,
    'data-testid': 'modifiers-protein',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.protein, 'g')}`,
  });

  nutritions.push({
    id: 'sodium',
    label: `${label('Ref: Nutrition Sodium')}`,
    'data-testid': 'modifiers-sodium',
    value: `${calculateTotalNutritions(0, nutritionsFacts?.sodium, 'g')}`,
  });

  return nutritions;
};

const convertMgToInternationalUnit = (value: number) => Number((value * 3) / 2).toFixed(2);

export const portionPrice = (
  portion: ProductPortion,
  languageCode: string,
  siteCurrencyIsoCode: string,
  numberOfFreeItems: number,
  label: LabelFunc
) => {
  if (numberOfFreeItems > 0) return label('Ref: Free');

  return portion.price
    ? getPrice(portion?.price, languageCode, siteCurrencyIsoCode)
    : getPrice(0, languageCode, siteCurrencyIsoCode);
};

export const buildAllergensList = (allergens: Allergen[], selectedAllergens: AllergenType[]) => {
  const iconSize = 24;
  return allergens.map((allergen) => {
    const hasAllergen = selectedAllergens.includes(allergen.id);
    return displayAllergen(
      allergen.id,
      iconSize,
      iconSize,
      hasAllergen ? styles.warningAllergenIcon : styles.nonWarningAllergenIcon
    );
  });
};

export const displayAllergensTag = (
  allergens: Allergen[],
  selectedAllergens: AllergenType[],
  label: LabelFunc
) => {
  const hasAllergen = allergens.some((allergen) => selectedAllergens.includes(allergen.id));

  const allergensChip = buildAllergenChip({
    showAllergensWarning: hasAllergen,
    label: label('Ref: Allergens'),
    ariaLabel: label('Ref: Presence of allergen'),
  });

  const tags = allergensChip ? (
    <div className={classNames(styles.tagsContainer)}>
      <Tag {...allergensChip!} variant={TAG_VARIANT.CHIP} />
    </div>
  ) : null;

  return tags;
};

export const getMenuItemById = (
  menuItemId: number,
  menus: (FacilityMenu | undefined)[]
): MenuItem | undefined => {
  const validMenus = menus?.filter((item) => !!item) as FacilityMenu[];

  if (validMenus) {
    for (const menu of validMenus) {
      const menuItem = menu.menuItems.find((item) => item.menuItemId === menuItemId);
      if (menuItem) {
        return menuItem;
      }
    }
  }
  return undefined;
};

export const getModifiersFromCart = (cartModifiers?: ICartModifier[]): ModifierSelections[] => {
  if (!cartModifiers) return [];
  return cartModifiers?.map((cartModifier) => {
    const itemQuantities =
      cartModifier?.values?.reduce(
        (acc, curr) => ({ ...acc, [curr.valueId.toString()]: curr.quantity }),
        {}
      ) || [];

    return {
      modifierId: cartModifier.modifierId,
      itemIds: cartModifier?.values?.map((modifierValue) => modifierValue.valueId.toString()) || [],
      itemQuantities: itemQuantities,
      displayText: cartModifier.displayText,
    };
  });
};

export const getInitialModifiers = (
  modifiers: Modifier[] | undefined
): ModifierSelections[] | undefined => {
  const initialModifiers = modifiers?.reduce((acc, modifier) => {
    const initialItems = modifier.modifierItems.filter(({ min }) => min > 0);

    if (!initialItems.length) return acc;

    const displayText = getModifierDisplayText({
      modifierName: modifier.name,
      selectedItems: initialItems.map((item) => {
        return { name: item.name, quantity: item.min };
      }),
    });

    const modifierSelection = {
      modifierId: modifier.id,
      itemIds: initialItems.map(({ id }) => `${id}`),
      itemQuantities: initialItems.reduce((acc, { id, min }) => ({ ...acc, [id]: min }), {}),
      displayText: displayText,
    };

    return [...acc, modifierSelection];
  }, [] as ModifierSelections[]);

  return initialModifiers;
};

export const getAllNutritionFacts = ({
  portion,
  selectedModifiers,
}: {
  portion: ProductPortion | null;
  selectedModifiers: ModifierSelections[];
}) => {
  const modifiersNutritionsSum = calculateNutritionSum(selectedModifiers, portion?.modifiers);

  if (!portion) return [];
  return calculateAllNutritionValues(portion, modifiersNutritionsSum);
};

export const getModifierDisplayText = ({
  modifierName,
  selectedItems,
}: {
  modifierName: string;
  selectedItems: Array<{ name: string; quantity: number }>;
}) => {
  const initialItemsText = selectedItems
    .filter((item) => item.quantity > 0)
    .reduce((prev, item) => `${prev} ${item.name}: ${item.quantity}`, '');

  return `${modifierName}: ${initialItemsText}`;
};

export const calculateDivisor = (gramWeight: string | undefined): number | undefined => {
  if (!!gramWeight) {
    const weight = Number.parseInt(gramWeight, 10);
    if (weight !== 0) {
      return 100 / weight;
    }
    return 0;
  }
  return undefined;
};
