import { ionFireEvent } from '@ionic/react-test-utils';
import { screen, cleanup, fireEvent, act } from '@testing-library/react';
import { useReducer } from 'react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import CreateFlow from '../CreateFlow';

jest.mock(
  '../../../../../context/withModuleContext',
  () => () => (Component: any) => (props: any) => <Component {...props} />
);

// Calendar component is mocked because I was having trouble
// setting the value of react-calendar in the test. So I just
// mocked it with an input element simply to be able to set the
// state value
jest.mock('../../../../../components/molecules/Calendar', () => (props: any) => {
  return <input onChange={(e) => props.onChange(e.target.value)} data-testid="date-input" />;
});

jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useReducer: jest.fn(),
}));

const mockGetFeedbackTypes = jest.fn();
const mockGetServiceTypes = jest.fn();
const mockGetAreaTypes = jest.fn();
const mockGetSubjectTypes = jest.fn();
const mockCreateFeedbackMutation = jest.fn();

jest.mock('../../../api/api', () => ({
  useLazyGetFeedbackTypesQuery: () => [mockGetFeedbackTypes],
  useLazyGetServiceTypesQuery: () => [mockGetServiceTypes],
  useLazyGetAreaTypesQuery: () => [mockGetAreaTypes],
  useLazyGetSubjectTypesQuery: () => [mockGetSubjectTypes],
  useCreateFeedbackMutation: () => [mockCreateFeedbackMutation],
}));

const componentProps = {
  label: (s: string) => s,
  steps: [
    {
      id: 'feedbackTypeId',
      prefix: 'I would like to provide a',
      getOptions: () => {
        mockGetFeedbackTypes();
      },
    },
    {
      id: 'serviceTypeId',
      prefix: 'in regards to',
      getOptions: () => {
        mockGetServiceTypes();
      },
    },
    {
      id: 'areaTypeId',
      prefix: '&',
      getOptions: () => {
        mockGetAreaTypes();
      },
    },
    {
      id: 'date',
      prefix: 'on the',
    },
  ],
};

const state = {
  feedbackTypeId: {
    id: 'feedbackTypeId',
    index: 0,
    isActive: true,
    noOptions: undefined,
    prefix: 'Ref: I would like to provide a',
    data: [
      { label: 'General Feedback', value: '224960002', data: {} },
      { label: 'Compliment', value: '224960000', data: {} },
      { label: 'Complaint', value: '224960001', data: {} },
    ],
    filtered: [
      { label: 'General Feedback', value: '224960002', data: {} },
      { label: 'Compliment', value: '224960000', data: {} },
      { label: 'Complaint', value: '224960001', data: {} },
    ],
  },
  serviceTypeId: {
    id: 'serviceTypeId',
    index: 1,
    isActive: true,
    noOptions: undefined,
    prefix: 'Ref: in regards to',
  },
  areaTypeId: {
    id: 'areaTypeId',
    index: 2,
    isActive: true,
    noOptions: undefined,
    prefix: 'Ref: &',
  },
  date: { id: 'date', index: 3, isActive: true, noOptions: true, prefix: 'Ref: on the' },
};
const mockDispatch = jest.fn();

describe('CreateFlow', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    beforeEach(() => {
      mockGetFeedbackTypes.mockResolvedValue({
        data: {
          feedbackTypes: [
            { name: 'General Feedback', id: '224960002' },
            { name: 'Compliment', id: '224960000' },
            { name: 'Complaint', id: '224960001' },
          ],
        },
        isSuccess: true,
      });
      (useReducer as jest.Mock).mockReturnValue([state, mockDispatch]);
    });

    renderComponent(CreateFlow, componentProps);

    it('should display first step', () => {
      const firstStepSentence = screen.getByText(/I would like to provide/i);
      expect(firstStepSentence).toBeTruthy();
    });

    it('should not display second step', () => {
      const secondStepSentence = screen.queryByText(/in regards to/i);
      expect(secondStepSentence).not.toBeInTheDocument();
    });

    it('should display the options', () => {
      const firstOption = screen.getByText(/General feedback/i);
      const secondOption = screen.getByText(/Compliment/i);
      const thirdOption = screen.getByText(/Complaint/i);
      expect(firstOption).toBeTruthy();
      expect(secondOption).toBeTruthy();
      expect(thirdOption).toBeTruthy();
    });
  });

  describe('on selecting options', () => {
    describe('when there is a getOptions property in one of the steps', () => {
      beforeEach(() => {
        mockGetFeedbackTypes.mockResolvedValue({
          data: {
            feedbackTypes: [
              { name: 'General Feedback', id: '224960002' },
              { name: 'Compliment', id: '224960000' },
              { name: 'Complaint', id: '224960001' },
            ],
          },
          isSuccess: true,
        });
        mockGetServiceTypes.mockResolvedValue({
          data: {
            serviceRequestTypes: [
              { id: '3e083a54-5908-e611-80db-c4346bc5b718', name: 'Town Services' },
              { id: '32083a54-5908-e611-80db-c4346bc5b718', name: 'Village Services' },
              { id: '42083a54-5908-e611-80db-c4346bc5b718', name: 'Building Maintenance' },
            ],
          },
          isSuccess: true,
        });
        (useReducer as jest.Mock).mockReturnValue([state, mockDispatch]);
      });

      renderComponent(CreateFlow, componentProps);

      beforeEach(async () => {
        const firstStepOption = screen.getByText(/General feedback/i);

        await act(async () => {
          fireEvent.click(firstStepOption);
        });
      });

      it('should fetch the options', () => {
        expect(mockGetServiceTypes).toHaveBeenCalled();
      });

      it('should display the step', async () => {
        const secondStepSentence = screen.queryByText(/in regards to/i);
        expect(secondStepSentence).toBeTruthy();
      });
    });
  });

  describe('when getOptions does not retrieve any options', () => {
    beforeEach(() => {
      mockGetFeedbackTypes.mockResolvedValue({
        data: {
          feedbackTypes: [
            { name: 'General Feedback', id: '224960002' },
            { name: 'Compliment', id: '224960000' },
            { name: 'Complaint', id: '224960001' },
          ],
        },
        isSuccess: true,
      });
      mockGetServiceTypes.mockResolvedValue({
        data: {
          serviceRequestTypes: null,
        },
        isSuccess: true,
      });

      (useReducer as jest.Mock).mockReturnValue([
        {
          ...state,
          serviceTypeId: {
            ...state.serviceTypeId,
            data: [],
            filtered: [],
            isActive: false,
          },
          areaTypeId: {
            ...state.areaTypeId,
            data: [
              {
                label: 'Electricity Consumption Enquiry',
                value: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
                data: {},
              },
              {
                label: 'Community Engagement',
                value: '0dfc1855-a8a5-ec11-983f-00224811d35c',
                data: {},
              },
              {
                label: 'Accommodation Site safety',
                value: '1fbcf515-88c5-e811-819c-e0071b681aa1',
                data: {},
              },
            ],
            filtered: [
              {
                label: 'Electricity Consumption Enquiry',
                value: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
                data: {},
              },
              {
                label: 'Community Engagement',
                value: '0dfc1855-a8a5-ec11-983f-00224811d35c',
                data: {},
              },
              {
                label: 'Accommodation Site safety',
                value: '1fbcf515-88c5-e811-819c-e0071b681aa1',
                data: {},
              },
            ],
          },
        },
        mockDispatch,
      ]);
    });

    renderComponent(CreateFlow, componentProps);

    beforeEach(async () => {
      const firstStepOption = screen.getByText(/General Feedback/i);

      await act(async () => {
        fireEvent.click(firstStepOption);
      });
    });

    it('should skip step', () => {
      const secondStepSentence = screen.queryByText(/in regards to/i);
      expect(secondStepSentence).toBeFalsy();
      const thirdStepSentence = screen.queryByText(/&/i);
      expect(thirdStepSentence).toBeTruthy();
    });
  });

  describe('when the user reaches the last step', () => {
    beforeEach(() => {
      mockGetFeedbackTypes.mockResolvedValue({
        data: {
          feedbackTypes: [
            { name: 'General Feedback', id: '224960002' },
            { name: 'Compliment', id: '224960000' },
            { name: 'Complaint', id: '224960001' },
          ],
        },
        isSuccess: true,
      });
      mockGetServiceTypes.mockResolvedValue({
        data: {
          serviceRequestTypes: [
            { id: '3e083a54-5908-e611-80db-c4346bc5b718', name: 'Town Services' },
            { id: '32083a54-5908-e611-80db-c4346bc5b718', name: 'Village Services' },
            { id: '42083a54-5908-e611-80db-c4346bc5b718', name: 'Building Maintenance' },
          ],
        },
        isSuccess: true,
      });
      mockGetAreaTypes.mockResolvedValue({
        data: {
          areaTypes: [
            {
              serviceTypeId: '42083a54-5908-e611-80db-c4346bc5b718',
              id: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
              name: 'Electricity Consumption Enquiry',
            },
            {
              serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
              id: '0dfc1855-a8a5-ec11-983f-00224811d35c',
              name: 'Community Engagement',
            },
            {
              serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
              id: '1fbcf515-88c5-e811-819c-e0071b681aa1',
              name: 'Accommodation Site safety',
            },
          ],
        },
        isSuccess: true,
      });
      mockCreateFeedbackMutation.mockResolvedValue({ isSuccess: true });
      (useReducer as jest.Mock).mockReturnValue([
        {
          ...state,
          feedbackTypeId: {
            ...state.feedbackTypeId,
            value: '224960002',
          },
          serviceTypeId: {
            ...state.serviceTypeId,
            data: [
              { value: '3e083a54-5908-e611-80db-c4346bc5b718', label: 'Town Services', data: {} },
              {
                value: '32083a54-5908-e611-80db-c4346bc5b718',
                label: 'Village Services',
                data: {},
              },
              {
                value: '42083a54-5908-e611-80db-c4346bc5b718',
                label: 'Building Maintenance',
                data: {},
              },
            ],
            filtered: [
              { value: '3e083a54-5908-e611-80db-c4346bc5b718', label: 'Town Services', data: {} },
              {
                value: '32083a54-5908-e611-80db-c4346bc5b718',
                label: 'Village Services',
                data: {},
              },
              {
                value: '42083a54-5908-e611-80db-c4346bc5b718',
                label: 'Building Maintenance',
                data: {},
              },
            ],
            value: '3e083a54-5908-e611-80db-c4346bc5b718',
          },
          areaTypeId: {
            ...state.areaTypeId,
            data: [
              {
                label: 'Electricity Consumption Enquiry',
                value: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
                data: {},
              },
              {
                label: 'Community Engagement',
                value: '0dfc1855-a8a5-ec11-983f-00224811d35c',
                data: {},
              },
              {
                label: 'Accommodation Site safety',
                value: '1fbcf515-88c5-e811-819c-e0071b681aa1',
                data: {},
              },
            ],
            filtered: [
              {
                label: 'Electricity Consumption Enquiry',
                value: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
                data: {},
              },
              {
                label: 'Community Engagement',
                value: '0dfc1855-a8a5-ec11-983f-00224811d35c',
                data: {},
              },
              {
                label: 'Accommodation Site safety',
                value: '1fbcf515-88c5-e811-819c-e0071b681aa1',
                data: {},
              },
            ],
            value: '0dfc1855-a8a5-ec11-983f-00224811d35c',
          },
        },
        mockDispatch,
      ]);
    });

    let descriptionForm: HTMLElement,
      descriptionInput: HTMLElement,
      calendarButton: HTMLElement,
      dateInput: HTMLElement,
      submit: HTMLElement;

    renderComponent(CreateFlow, componentProps);

    beforeEach(async () => {
      const firstStepOption = screen.getAllByTestId('fill-in-blanks-option');

      await act(async () => {
        fireEvent.click(firstStepOption[0]);
      });

      const secondStepOption = screen.getAllByTestId('fill-in-blanks-option');

      await act(async () => {
        fireEvent.click(secondStepOption[0]);
      });

      const thirdStepOption = screen.getAllByTestId('fill-in-blanks-option');

      await act(async () => {
        fireEvent.click(thirdStepOption[1]);
      });

      descriptionForm = screen.getByText(/Please provide a date and description/i);

      descriptionInput = screen.getByTestId('description-input');
      ionFireEvent.ionChange(descriptionInput, 'Feedback description');

      calendarButton = screen.getByText('Calendar');
      fireEvent.click(calendarButton);

      dateInput = screen.getByTestId('date-input');
      fireEvent.change(dateInput, { target: { value: '2022-11-08' } });

      act(() => {
        submit = screen.getByText(/submit/i);
        fireEvent.click(submit);
      });
    });

    it('should display description form', () => {
      expect(descriptionForm).toBeTruthy();
      expect(descriptionInput).toBeTruthy();
      expect(calendarButton).toBeTruthy();
    });

    it('should submit the feedback', () => {
      act(() => {
        expect(mockCreateFeedbackMutation).toHaveBeenCalledWith({
          feedbackTypeId: '224960002',
          serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
          areaTypeId: '0dfc1855-a8a5-ec11-983f-00224811d35c',
          feedbackDate: '2022-11-08',
          description: 'Feedback description',
          withAccessToken: true,
        });
      });
    });
  });
});
