const labels = {
  PrivacyPolicy: 'Adatvédelmi irányelvek',
  TermsOfSale: 'Az eladási feltételek',
  TermsOfUse: 'Felhasználási feltételek',
  home: 'Otthon',
  anytime: 'Bármikor',
  anywhere: 'bárhol',
  Download: 'Letöltés',
  scrollRightButton: 'Görgessen a kategóriák jobbra',
  scrollLeftButton: 'Görgetje a kategóriákat balra',
  calendar: 'Naptár',
  'See more': 'Mutass többet',
  'See less': 'Kevesebbet látni',
  'from (followed by a date, or date and time)': 'tól től',
  'to (followed by a date, or date and time)': 'nak nek',
  'on (followed by a date)': 'Tovább',
  'at (followed by time)': 'nál nél',
  'from (followed by a location)': 'tól től',
  'to (followed by a location)': 'nak nek',
  'on (followed by a site name)': 'Tovább',
  'from (followed by a person)': 'tól től',
  previous: 'Előző',
  next: 'Következő',
  success: 'siker',
  failure: 'kudarc',
  today: 'Ma',
  tomorrow: 'Holnap',
  Monday: 'Hétfő',
  Tuesday: 'kedd',
  Wednesday: 'szerda',
  Thursday: 'csütörtök',
  Friday: 'péntek',
  Saturday: 'szombat',
  Sunday: 'vasárnap',
  everyday: 'Minden nap',
  'open all day': 'Egész nap nyitva',
  'opening hours': 'Nyitvatartási idő',
  status: 'állapot',
  'to be confirmed': 'Megerősítésre váró',
  'only (as in filter only this)': 'csak',
  edit: 'szerkeszteni',
  submit: 'Beküldés',
  cancel: 'Töröl',
  confirm: 'Megerősítés',
  reset: 'Visszaállítás',
  Delete: 'Töröl',
  select: 'válassza ki',
  switch: 'kapcsoló',
  close: 'Bezárás',
  clear: 'egyértelmű',
  remove: 'távolítsa el',
  logout: 'Kijelentkezés',
  go: 'megy',
  'see (as in consult this page or document)': 'lát',
  'see all (as in see all items)': 'Az összes megtekintése',
  'see more (as in see more details for this item)': 'Mutass többet',
  retry: 'próbálja újra',
  'Ref: ok': 'Rendben',
  'yes (as in yes I accept)': 'Igen',
  'no (as in no I do not accept)': 'Nem',
  you: 'te',
  'thank you': 'Köszönöm',
  'sorry (standalone)': 'sajnálom',
  'to home (as in navigate to the homepage)': 'a kezdőlapra',
  map: 'térkép',
  list: 'lista',
  'Loading...': 'Betöltés...',
  'Refreshing...': 'Frissítés...',
  'Please select': 'Kérlek, válassz',
  'Switch site': 'Kapcsolja itt a webhelyet',
  'File size cannot exceed {max file size}':
    'A fájl mérete nem haladhatja meg a {max file size} értéket',
  Filters: 'Szűrők',
  'Add attachment': 'Melléklet hozzáadása',
  Search: 'Keresés',
  'More (as in: see more info)': 'Több',
  'You can attach a maximum of {max files number} files.':
    'Maximum {max files number} fájlt csatolhat.',
  'There are no messages to display.': 'Nincsenek megjelenítendő üzenetek.',
  'Your selection did not return any result.': 'Kiválasztása nem hozott eredményt.',
  'Ref: Popup header for missing required fields in a form':
    'Kérjük, adjon meg további információkat',
  'Ref: Required field': 'A {ref} kötelező mező.',
  email: 'Email',
  phone: 'Mobil szám',
  Account: 'Fiok. Számla',
  Contacts: 'Kapcsolatok',
  Content: 'Tartalom',
  Events: 'Események',
  Facilities: 'Létesítmények',
  Shop: 'Üzlet',
  Menu: 'Menü',
  Menus: 'Menük',
  Order: 'Rendelés',
  Orders: 'Rendelések',
  Info: 'Információ',
  FAQ: 'GYIK',
  Feedback: 'Visszajelzés',
  Review: 'Felülvizsgálat',
  Requests: 'Kérelmek',
  'Request Helpdesk': 'Kérjen ügyelót',
  Location: 'Helyszin',
  Safety: 'Biztonság',
  FreeCoffee: 'Ingyenes kávé',
  Newsfeed: 'Hírcsatorna',
  MyOrders: 'Rendeléseim',
  LoyaltyStamps: 'Hűségbélyegek',
  Stamps: 'Bélyeg',
  Stays: 'Tartózkodások',
  Suppliers: 'Beszállítók',
  Surveys: 'Felmérések',
  notifications: 'értesítések',
  cart: 'kosár',
  'Ref: Reset your order': 'Állítsa vissza megrendelését',
  'Ref: My language': 'A nyelvem',
  Validate: 'Érvényesít',
  'contact us': 'lépjen kapcsolatba velünk',
  'Ref: Start typing': 'Kezdje el a gépelést',
  Occupancy: 'Kihasználtság',
  'see less': 'Lásd kevesebbet',
  'see more': 'Többet látni',
  contacts: 'Kapcsolattartó',
  Imprint: 'Lenyomat',
  'Ref: camera access request title': 'Engedje meg a hozzáférést a kamerához',
  'Ref: camera access request body':
    'A QR -kód szkenner használatához engedélyeznie kell egy kamera használatát ezen a webhelyen',
  or: 'vagy',
  'Ref: Input required': '{input_name} szükséges',
  'Enter a valid email address': 'Adjon meg egy érvényes email címet',
  'Ref: Input error E-mail already exists':
    'Van már egy fiók ezzel a megadott e -mailben. Kérjük, jelentkezzen be ehelyett.',
  'Password must contain': 'A jelszónak tartalmaznia kell:',
  'Ref: Input password error strength':
    'A jelszónak legalább 8 karaktert, 1 számot, 1 betűt és 1 speciális karaktert tartalmaznia kell',
  Password: 'Jelszó',
  'New password': 'Új jelszó',
  'Confirm password': 'Jelszó megerősítése',
  'Ref: Password does not match': 'A jelszó és a jelszó megerősítése nem egyezik',
  'Ref: Old password does not match': 'A régi jelszó nem egyezik',
  'Ref: Password validation min8Characters': 'Legalább 8 karakter',
  'Ref: Password validation min1Number': 'Legalább 1 számjegy',
  'Ref: Password validation min1Letter': 'Legalább 1 betű',
  'Ref: Password validation min1SpecialChar': 'Legalább 1 különleges karakter',
  Continue: 'Folytatni',
  'Enter a valid phone number': 'Írjon be egy érvényes telefonszámot',
  'Phone is required': 'Telefonra van szükség',
  'Go to home': 'Menj haza',
  'Something went wrong': 'Valami elromlott',
  'Something went wrong, data not loaded': 'Valami rosszul ment, az adatok nem betöltöttek',
  'Change password': 'Jelszó módosítása',
  'remove one': 'Távolítson el egyet',
  'add one': 'hozzáadni egyet',
  'Confirm Filters': 'Erősítse meg a szűrőket',
  'Reset Filters': 'A szűrők visszaállítása',
  '{selectedNum} active filters': '{selectedNum} aktív szűrők',
  'Wrong input icon': 'Rossz bemeneti ikon',
  'Correct input icon': 'Helyes bemeneti ikon',
  'Show input text icon': 'Mutassa be a bemeneti szöveg ikonját',
  'Hide input text icon': 'Elrejtse a bemeneti szöveg ikonját',
  'Required fields info': '* Szükséges Mezők',
  'Ref: Select site on the map': 'Válassza ki a webhelyét a térképen',
  'Ref: Geolocation disabled':
    'A földrajzi helyzet le van tiltva. A közeli webhelyek megtekintéséhez kérjük, engedélyezze.',
  'Ref: Reaction - LIKE': 'Tetszik',
  'Ref: Reaction - LOVE': 'Szerelem',
  'Ref: Reaction - INTERESTING': 'Érdekes',
  'Ref: Reaction - CONGRATE': 'Gratulál',
  'Ref: Share': 'Részvény',
  'Ref: Send by Email': 'Küldés e-mailben',
  'Ref: Copy the link': 'Másolja a linket',
  'Ref: Link was copied': 'A linket lemásoltuk',
  'Ref: unable to copy url': 'Képtelen másolni az URL -t',
  'Ref: External link': 'Külső hivatkozás',
  'Ref: Step': 'Lépés',
  'input field': 'bemeneti mező',
  'Ref: Zoom': 'Zoomolás',
  'Increase hours': 'Növeli az órákat',
  'Decrease hours': 'Csökkentési órák',
  Hours: 'Órák',
  Minutes: 'Percek',
  'Ref: filter label: favorites': 'Csak a kedvenceimet mutasd meg',
  'Ref: filter label: max calories': 'Maximális kalória',
  'Ref: filter label: dishes': 'Edények',
  'Ref: filter label: allergens': 'Allergének',
  'Ref: filter label: highlight': 'Kiemelje vagy elrejtse az edényekkel ellátott szűrőket',
  'Ref: Highlight': 'Kiemel',
  'Ref: Hide': 'Elrejt',
  'Ref: Vegan': 'Vegán',
  'Ref: Vegeterian': 'Vegetáriánus',
  'Ref: Mindful': 'Figyelmes',
  'Ref: Highlight text':
    'Itt választhat a menüben lévő ételek elrejtése vagy elrejtése, amely bizonyos allergéneket tartalmaz',
  'Ref: Just now': 'Pont most',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} perccel ezelőtt',
  'Ref: 1 hour ago': '1 órával ezelőtt',
  'Ref: {amountHours} hours ago': '{amountHours} órákkal ezelőtt',
  'Ref: Yesterday at {time}': 'Tegnap a {time}',
  'Ref: {date} at {time}': '{date} a {time}',
  'Ref: filter label: date': 'Dátum',
  'Ref: expand menu': 'Bontsa ki a menüt',
  'Ref: collapse menu': 'összeomlási menü',
  'Ref: My site': 'Az én oldalam',
  'Ref: Recent': 'Friss',
  'Ref: Services': 'Szolgáltatás',
  'Ref: products in cart': 'A kocsiban lévő termékek száma',
  'Ref: no product in cart': 'Nincs termék a kosárban',
  'Ref: Survey': 'Felmérés',
  'Access to camera was denied. Please allow access and try again':
    'A kamerához való hozzáférést megtagadták. Kérjük, engedje meg a hozzáférést, és próbálja újra',
  'Add an attachment': 'Adjon hozzá egy mellékletet',
  'Take a picture': 'Fotózni',
  'Add a file from your device': 'Adjon hozzá egy fájlt az eszközről',
  'Ref: Loading page content, please wait': 'Az oldal tartalmának betöltése, kérjük, várjon',
  Star: 'Csillag',
  Stars: 'Csillagok',
  'Accommodation Request': 'Szálláskérés',
  'Ref: Guest Accomodation Request': 'Vendégkérés',
  'Ref: Search a menu, product or facility': 'Keressen egy menüt, terméket vagy létesítményt',
  ConnectionLost: 'A hálózati kapcsolat elveszett',
  ConnectionRestored: 'A hálózati kapcsolat visszaállítva',
  'Ref: Skip to main content': 'Ugorjon a fő tartalomra',
  'Ref: No options': 'Nincs lehetőség',
  'Ref: Global search modal': 'Globális keresési modális',
  'Ref: Search a facility': 'Keressen egy létesítményt',
  'Ref: Search a menu or product': 'Keressen egy menüt vagy terméket',
  'Ref: Reaction': 'Reakció',
  'Ref: times': 'idő',
  'Ref: reaction. Change reaction': 'reakció. Változási reakció',
  'Ref: No reaction. React': 'Nincs reakció. Reagál',
  'Ref: LIKE': 'Mint',
  'Ref: LOVE': 'Szerelem',
  'Ref: INTERESTING': 'Érdekes',
  'Ref: CONGRATE': 'Elismerő',
  'Ref: Open search': 'Nyissa meg a keresőmezőt',
  'Ref: Close search': 'Zárja be a keresőmezőt',
  'Ref: Total file size msg': 'A teljes fájlméret nem haladhatja meg a {max file size}',
  'Ref: Oops Title': 'Hoppá!',
  'Ref: Well Done': 'Szép munka {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigációs nyíl - Folytassa',
  'Ref: Reload': 'Újratöltés',
  'Ref: All Dates': 'Minden dátum',
  'Ref: Today': 'Ma',
  'Ref: Validate': 'Érvényesít',
  'Ref: toast error title': 'Hoppá!',
  'Ref: toast error subtitle': 'Hiba történt, kérjük, próbálkozzon újra.',
  'Ref: Calendar': 'Naptár',
  'Ref: Selected': 'Kiválasztott',
  'Ref: Previous Month': 'Előző hónap',
  'Ref: Next Month': 'Következő hónap',
  'This module has been retired': 'Ez a modul megszűnt',
};
export default labels;
