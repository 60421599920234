import * as H from 'history';
import { generatePath } from 'react-router';
import { Dispatch } from 'redux';

import { pagePaths as orderPagePaths } from '../../../Order/config';
import { pagePaths } from '../../config';

import { formatDateAsIS08601 } from '@/helpers/misc';
import { getPendingOrders, setTable } from '@/modules/Order/actions';

export const getOrderModuleRedirectionPath = (
  queryParams: URLSearchParams,
  isMenuModel: boolean = false
) => {
  const { menuId, menuDate } = Object.fromEntries([...queryParams]);

  const routeParams = {
    menuId: menuId || 'no-menu',
    date: menuDate || 'no-date',
  };

  return generatePath(
    isMenuModel ? orderPagePaths.ViewableMenuRedirection : orderPagePaths.MenuRedirection,
    routeParams
  );
};

const getValidDate = (date: Date) => {
  if (isNaN(date.valueOf())) return 'no-date';
  let formattedDate = formatDateAsIS08601(date);

  const currentDate = new Date();
  const parsedDate = new Date(formattedDate);

  if (parsedDate < currentDate) {
    formattedDate = formatDateAsIS08601(currentDate);
  }

  return formattedDate;
};

export const getProductDetailsRedirectionPath = (
  modulePath: string,
  queryParams: URLSearchParams
) => {
  const { menuId, date, id, facilityId, cartItem } = Object.fromEntries([...queryParams]);
  const routeParams = {
    menuId: menuId || 'no-menu',
    date: getValidDate(new Date(date)),
    facilityId: facilityId || 'no-facility',
    id: id || 'no-menu-item',
    cartItem: cartItem || 'no-cart',
  };

  return generatePath(
    modulePath === orderPagePaths.Module
      ? orderPagePaths.ProductDetails
      : orderPagePaths.MenuProductDetails,
    routeParams
  );
};

export const handleDineInRedirection = async (
  dispatch: Dispatch,
  history: H.History<unknown>,
  locationSearch: string,
  menuId: string | null,
  outletId: string,
  table: string | null,
  siteId: string,
  facilityId: string
): Promise<void> => {
  await dispatch(setTable({ tableNumber: table }));

  const pendingOrders = await dispatch(getPendingOrders({ siteId, facilityId }));

  if (pendingOrders && pendingOrders.ok && pendingOrders.responseData?.length > 0 && table) {
    history.push(
      `${orderPagePaths.TableCheckInOutlet.replace(':outletId', outletId)}?menuId=${menuId}`
    );
  } else {
    const menuProductsListPath = getOrderModuleRedirectionPath(new URLSearchParams(locationSearch));
    history.push(menuProductsListPath);
  }
};

export const getRedirectionPathWithSiteIdentifier = (
  module: string,
  siteIdentifier: string,
  country: string,
  routeParams: Record<string, string>
) => {
  const guestRedirectPath = generatePath(pagePaths.ModuleRedirect, {
    geoCode: country,
    siteIdentifier: siteIdentifier,
    module: module.toLowerCase(),
  });
  const newQueryParams = new URLSearchParams(routeParams);
  return `${guestRedirectPath}?${newQueryParams.toString()}`;
};
