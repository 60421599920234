import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../../config';

import CartWidget from './CartWidget';

jest.mock('../../../hooks/useCartInfo/useCartInfo', () => {
  return {
    __esModule: true,
    default: () => ({ linkTo: 'Highway to hell', itemsCount: undefined }),
  };
});

const state = {
  Core: {
    services: {
      list: [
        {
          name: SERVICE.FOOD_ORDER,
        },
      ],
    },
  },
  Shared: { language: { currentLanguageCode: 'en' } },
};

const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

jest.mock(
  '../../../../../context/withLang',
  () => () => (WrappedComponent: any) => (props: any) =>
    <WrappedComponent {...props} label={(text: any) => text} />
);

describe('CartWidget component', () => {
  describe('Render with no props', () => {
    afterAll(() => cleanup());
    beforeEach(() => {
      mockSelector.mockReturnValue(state);
    });
    renderComponent(CartWidget, {}, state);

    it('Should display widget', () => {
      const component = screen.getByTestId('title-bar-icon-cart');
      expect(component).toBeTruthy();
    });
  });
});
