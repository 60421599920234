import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import { INPUT_TYPE, EMOJIS, LOCAL_STORAGE_KEYS } from '../../../../constants';
import useToggle from '../../../../helpers/hooks/useToggle';
import { pagePaths, languages, defaultLanguages, useTroubleLoggingIn } from '../../config';
import withLang from '../../context/withLang';
import { isMyWayAndWando } from '../../helpers/helpers';
import {
  getForgottenUrl,
  isKioskUser,
  isUserMigrationFromOldBitesEnabled,
} from '../../helpers/login.helper';
import { removeTokens } from '../../helpers/tokensHelpers';
import useDynamicTabName from '../../hooks/useDynamicTabName';
import useLoginViaGates from '../../hooks/useLoginViaGates';
import { REDIRECTED_FROM } from '../../types/accountValidation.types';
import { MIGRATION_STATUS } from '../../types/passwordChange.types';
import GeographySelector from '../GeographySelector/GeographySelector';

import { LocationState, LoginPageProps } from './LoginPage.types';

import { CoverIllustration } from '@/assets/illustrations';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import FormLine from '@/components/atoms/FormLine/FormLine';
import { HookInput as Input } from '@/components/atoms/Input';
import EmailInput from '@/components/atoms/Input/EmailInput/EmailInput';
import Title, { TITLE_TAG, TITLE_SIZE } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Form from '@/components/molecules/Form';
import { requiredRule } from '@/components/molecules/Form/helpers';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import Modal from '@/components/organisms/Modal/Modal';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { getAppDisplayName, isKiosk } from '@/helpers/misc';
import { useLazyGetGeographiesQuery } from '@/modules/Core/api/geolocations/geolocationsApi';

import styles from './LoginPage.module.css';

type FormFields = {
  login: string;
  password: string;
};

const LoginPage = ({
  isLoggedIn,
  geographies,
  currentGeoCode,
  currentRegionCode,
  applyLanguage,
  isLanguageUserSelected,
  currentLanguageCode,
  username,
  login,
  checkEmailExists,
  migrateOldBiteUser,
  preferendLanguageId,
  setGeography,
  setUsername,
  setUserRegionCode,
  label,
}: LoginPageProps) => {
  const [getGeographies] = useLazyGetGeographiesQuery();
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormFields>({
    mode: 'all',
    defaultValues: {
      login: '',
      password: '',
    },
  });

  useEffect(
    function initializeLoginField() {
      if (username) {
        setValue('login', username);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [username]
  );

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation<LocationState>();
  const history = useHistory();

  const contentRef = useRef<HTMLIonContentElement>(null);
  const hiddenLocationRef = useRef<HTMLInputElement>(null);

  const inputUsernameRef = useRef<HTMLInputElement>();
  const inputPasswordRef = useRef<HTMLInputElement>();

  const [shouldDisplayPasswordField, setShouldDisplayPasswordField] = useState(false);
  const [loginLabel, setLoginLabel] = useState(false);
  const [loginError, setLoginError] = useState<SetStateAction<boolean>>(false);
  const { shouldUseTroubleLoggingIn } = useTroubleLoggingIn();
  const { state: isModalOpen, toggleOn: openModal, toggleOff: hideModal } = useToggle(false);

  const title = label('Ref: Page title', {
    replace: { 'app name': getAppDisplayName() },
  });
  const loginValue = watch('login');
  useEffect(
    function onUsernameChange() {
      setShouldDisplayPasswordField(false);
      setLoginLabel(false);
    },
    [loginValue]
  );

  const passwordValue = watch('password');
  useEffect(
    function onPasswordChange() {
      setLoginLabel((passwordValue.length || 0) > 0);
    },
    [passwordValue]
  );

  useEffect(() => {
    setIsMobile(Capacitor.isNativePlatform());
  }, []);

  const redirect = useCallback(() => {
    const from = location.state?.from || { pathname: '/' };
    history.replace(from);
  }, [history, location]);

  useDynamicTabName(title, true);

  const setInterfaceLanguage = useCallback(() => {
    // Do not update if the language was selected by the user already
    if (isLanguageUserSelected) return;

    const navigatorLanguage = navigator.language;
    let newLanguage;

    if (!newLanguage) {
      if (navigatorLanguage.length === 2) {
        // Language code, no localization
        newLanguage = languages.find((el) => el.code.startsWith(navigatorLanguage) && el.default); // If we have a default language, take it

        if (!newLanguage) {
          newLanguage = languages.find((el) => el.code.startsWith(navigatorLanguage)); // Otherwise just take the 1st language for this code
        }
      } else {
        newLanguage = languages.find((el) => el.code === navigatorLanguage);
      }
    }

    // Not found? select the default language for this geography
    if (!newLanguage) newLanguage = defaultLanguages.find((el) => el.geoCode === currentGeoCode);

    // Still nothing? Then English
    if (!newLanguage) newLanguage = languages.find((el) => el.code === 'en-US');

    if (newLanguage?.code) applyLanguage(newLanguage.code);
  }, [applyLanguage, currentGeoCode, isLanguageUserSelected]);

  const handleGeographyChange = useCallback(
    (code: string) => {
      if (code === currentGeoCode) return;

      // This is synchronous
      setGeography(code);

      // The interface language may need to be updated
      setInterfaceLanguage();
    },
    [currentGeoCode, setGeography, setInterfaceLanguage]
  );

  const loginViaGates = useLoginViaGates(
    loginValue,
    setShouldDisplayPasswordField,
    setLoginError,
    currentGeoCode || '',
    currentRegionCode || '',
    history
  );

  const handleSubmitHookForm: SubmitHandler<FormFields> = async ({
    login: usernameValue,
    password: passwordValue,
  }) => {
    if (!shouldDisplayPasswordField) {
      loginViaGates(usernameValue);
      return;
    }

    let geoCode = currentGeoCode;

    /**
     * Only in password field and only for OneTrust set location to value from OneTrust as
     * OneTrust is not able to handle such complex login form
     */
    if (hiddenLocationRef.current?.value !== undefined && hiddenLocationRef.current?.value !== '') {
      geoCode = hiddenLocationRef.current.value;
      handleGeographyChange(geoCode);
    }

    const loginResult = await login(
      geoCode || '',
      usernameValue,
      passwordValue,
      currentLanguageCode
    );

    if (loginResult.responseStatus === 200) {
      if (!isKiosk) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, usernameValue);
      } else {
        if (!isKioskUser()) {
          setLoginError(true);
          removeTokens();
          return;
        }

        redirect();
      }
    } else if (loginResult.responseStatus === 400) {
      const emailResult = await checkEmailExists({ email: usernameValue });

      if (emailResult.ok === true) {
        setLoginError(true);
        return;
      } else {
        const isOldBiteUserMIgrationEnabled = isUserMigrationFromOldBitesEnabled();

        if (!isOldBiteUserMIgrationEnabled) {
          setLoginError(true);
          return;
        } else {
          const migrationResult = await migrateOldBiteUser(
            usernameValue,
            passwordValue,
            preferendLanguageId
          );

          if (migrationResult.responseStatus === 200) {
            if (
              migrationResult.responseData?.status === MIGRATION_STATUS.INVALID_CREDENTIALS ||
              migrationResult.responseData?.status === MIGRATION_STATUS.MIGRATION_DISABLED
            ) {
              setLoginError(true);
            } else if (migrationResult.responseData?.status === MIGRATION_STATUS.USER_MIGRATED) {
              const secondLoginResult = await login(
                geoCode || '',
                usernameValue,
                passwordValue,
                currentLanguageCode
              );

              if (secondLoginResult.responseStatus === 200) {
                history.push({
                  pathname: pagePaths['Validation'],
                  search: `${new URLSearchParams({
                    redirectedFrom: REDIRECTED_FROM.LOGIN,
                  })}`,
                });
              } else if (secondLoginResult.responseStatus === 400) {
                setLoginError(true);
              }
            } else if (migrationResult.responseData?.status === MIGRATION_STATUS.PASSWORD_WEAK) {
              history.push(pagePaths['PasswordChange']);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      redirect();
    }
  }, [isLoggedIn, redirect]);

  useEffect(() => {
    const localEmail = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
    if (localEmail) {
      setUsername(localEmail);
    }
  }, [setUsername]);

  useEffect(() => {
    const returnUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.RETURN_URL);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.RETURN_URL,
      returnUrl ? returnUrl : location.state?.from?.pathname || '/home'
    );

    if (!geographies.length) {
      getGeographies();
    }
  }, [geographies, getGeographies, location.state?.from?.pathname]);

  useEffect(() => {
    window.setTimeout(() => inputUsernameRef.current?.focus(), 250);
  }, []);

  useEffect(() => {
    if (shouldDisplayPasswordField) {
      window.setTimeout(() => inputPasswordRef.current?.focus(), 100);
    }
  }, [shouldDisplayPasswordField]);

  const onDismiss = () => {
    hideModal();
  };

  if (isLoggedIn || !geographies.length) {
    // Waiting for redirection in App
    return <LoadingPage />;
  }

  const submitIsDisbled =
    Object.keys(errors).length > 0 || loginValue.length === 0 || !currentGeoCode;

  const notification = loginError
    ? {
        look: NOTIFICATION_LOOK.ERROR,
        title: label('Ref: error, incorrect credentials'),
        dismissable: true,
        onDismiss: () => {
          setLoginError(false);
          inputUsernameRef.current?.focus();
        },
      }
    : false;

  // TODO REMOVE THE FEATURE FLAG AFTER IFM BACKEND RELEASE
  const forgottenUrl: string = getForgottenUrl(currentLanguageCode, username);

  const topNotification = location?.state?.notification || notification;

  return (
    <SimpleFormPage
      hasBackLink={false}
      title={title}
      withNavBar={false}
      hideAllWidgets={true}
      contentRef={contentRef}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className={classNames('mb-M')}>
              {label('Ref: Hello', {
                replace: { wave_emoji: String.fromCodePoint(EMOJIS.wave) },
              })}
            </Title>

            {topNotification && (
              <Notification inheritStyle={classNames('mb-M')} {...topNotification} />
            )}
            <Form onSubmit={handleSubmit(handleSubmitHookForm)}>
              <Card className={classNames(styles.loginForm, 'mb-M')}>
                <input
                  type="hidden"
                  id="hLocation"
                  name="hLocation"
                  value=""
                  ref={hiddenLocationRef}
                ></input>
                <FormLine data-testid="login-email">
                  <EmailInput
                    control={control}
                    labelFunc={label}
                    name="login"
                    inputLabel={label('Email', { textTransform: 'capitalize' })}
                    autocomplete="email"
                    data-cy="input-email"
                    data-testid="login-page"
                    id="login"
                    placeholder={label('Ref: Email field placeholder')}
                    inputRef={inputUsernameRef}
                    required
                  />
                </FormLine>
                {shouldDisplayPasswordField ? (
                  <div className={'mb-M'}>
                    <Input
                      control={control}
                      name="password"
                      inputLabel={label('Password', { textTransform: 'capitalize' })}
                      title={label('Ref: Password field title')}
                      autocomplete="current-password"
                      data-cy="input-password"
                      data-testid="login-password"
                      id="password"
                      inputType={INPUT_TYPE.PASSWORD}
                      placeholder="********"
                      inputRef={inputPasswordRef}
                      required
                      rules={requiredRule(label('Password'), label)}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div className={styles.textWrapper}>
                  {shouldDisplayPasswordField && (
                    <>
                      <a href={forgottenUrl}>
                        <Title size={TITLE_SIZE.BODYSBOLD} className={'underlinedText'}>
                          {label('Ref: Forgot password?')}
                        </Title>
                      </a>
                      {isMobile && isMyWayAndWando() && <span>•</span>}
                    </>
                  )}
                  {isMobile && isMyWayAndWando() && (
                    <>
                      <div
                        data-testid={`connection-issue`}
                        onClick={openModal}
                        onKeyDown={(e) => e.key === 'Enter' && openModal()}
                        role="button"
                        tabIndex={0}
                      >
                        <Title size={TITLE_SIZE.BODYSBOLD} className={'underlinedText'}>
                          {label('Ref: Connection issue?')}
                        </Title>
                      </div>
                      <Modal
                        isOpen={isModalOpen}
                        id="connection_issue_modal"
                        data-testid="connection_issue_modal"
                        onDismiss={onDismiss}
                        title={label('Ref: Connection issue?')}
                      >
                        <Title
                          size={TITLE_SIZE.BODYSDEFAULT}
                          className={classNames(styles.connectionMsg, 'mb-M')}
                        >
                          {label('Ref: Connection issue message')}
                        </Title>
                      </Modal>
                    </>
                  )}
                </div>
                <p
                  className={classNames(styles.fieldsDisclaimer, {
                    [styles.topBottomMargins]:
                      shouldDisplayPasswordField && !isMobile && isMyWayAndWando(),
                    [styles.topMargin]:
                      (isMobile && isMyWayAndWando()) ||
                      (shouldDisplayPasswordField && !isMyWayAndWando()),
                  })}
                >
                  {label('Required fields info')}
                </p>
                {!isMobile && isMyWayAndWando() && (
                  <p
                    className={classNames(styles.fieldsDisclaimer, {
                      [styles.topMargin]: !shouldDisplayPasswordField && !isMobile,
                    })}
                  >
                    {label('Ref: Connection issue message')}
                  </p>
                )}
              </Card>
              <GeographySelector
                className={styles.geographySelector}
                geographies={geographies}
                label={label}
                handleGeographySubmit={handleGeographyChange}
              />
              <ActionsBar className={styles.actionButton}>
                <Button
                  data-testid="loginSubmit"
                  data-cy="button-action-primary"
                  look={BUTTON_LOOK.PRIMARY}
                  onClick={handleSubmit(handleSubmitHookForm)}
                  disabled={submitIsDisbled}
                  loading={isSubmitting}
                  isClickDisabled={true}
                >
                  {loginLabel
                    ? label('Ref: Login', { textTransform: 'capitalize' })
                    : label('Continue', { textTransform: 'capitalize' })}
                </Button>
                <Button
                  data-testid="register"
                  data-cy="button-action-secondary"
                  onClick={() => history.push(pagePaths['LetsGo'])}
                >
                  {label('Ref: Register')}
                </Button>
                {shouldUseTroubleLoggingIn && (
                  <Button
                    data-testid="troubleLoggingIn"
                    onClick={() => history.push(pagePaths['TroubleLoggingIn'])}
                  >
                    {label('I have trouble logging in')}
                  </Button>
                )}
              </ActionsBar>
            </Form>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <CoverIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default withLang([__filename])(LoginPage);
