import { connect } from 'react-redux';

import { State } from '../../../../types/state.types';
import { changePassword } from '../../actions';

import AccountPasswordChange from './AccountPasswordChange';
import { ChangePasswordRequestType } from './AccountPasswordChange.types';

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchChangePassword: (request: ChangePasswordRequestType) =>
      dispatch(
        changePassword({ password: request.password, old_password: request.currentPassword })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPasswordChange);
