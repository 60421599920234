import { useSelector } from 'react-redux';

import { EyeIcon } from '../../../../assets/icons';
import { ReceptionIllustration } from '../../../../assets/illustrations';
import { FilterType, NormalFilter } from '../../../../components/atoms/Filters/Filters.types';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { LabelFunc } from '../../../../context/withLang';
import { formatDate } from '../../../../helpers/misc';
import { useGetStaysQuery } from '../../api';
import StayStatus from '../../components/StayStatus';
import { pagePaths } from '../../config';
import { useAccomodationTranslation } from '../../hooks/useAccomodationTranslation';
import { IStay } from '../../types/accomodation.types';

import { StaysListProps } from './StaysLists.types';

import OldTile from '@/components/organisms/Tile';
import { State } from '@/types/state.types';

import './StaysList.css';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(23, 59, 59);

const getStaysItems = (stays: IStay[], label: LabelFunc, currentLanguageCode: string) => {
  return stays.map((stay) => {
    const path = pagePaths['StayDetails'].replace(':id', stay.id);
    return {
      id: stay.id,
      title: `${stay.reservationNumber} - ${stay.siteName}`,
      description: `${label('from (followed by a date, or date and time)', {
        textTransform: 'capitalize',
        end: 'colon',
      })} ${formatDate(new Date(stay.arrivalDateLocal), currentLanguageCode)} ${label(
        'to (followed by a date, or date and time)',
        {
          start: 'whitespace',
          textTransform: 'capitalize',
          end: 'colon',
        }
      )} ${formatDate(new Date(stay.departureDateLocal), currentLanguageCode)}`,
      addendum: <StayStatus status={stay.status} />,
      linkPath: path,
      cornerAction: {
        link: {
          to: path,
        },
        element: <EyeIcon title={label('See more')} />,
      },
      'data-testid': `stays-list-item-${stay.id}`,
    };
  });
};

const StaysList = ({ parentFilter, parentSearch }: StaysListProps) => {
  const { data: stays = [], isFetching: lock } = useGetStaysQuery({});
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const { label } = useAccomodationTranslation(__filename);

  const filters: NormalFilter[] = [
    {
      id: 'filters_period',
      options: [
        {
          value: 'current_future',
          label: label('Current and future stays'),
          default: true,
        },
        {
          value: 'past',
          label: label('Ref: Past', { textTransform: 'capitalize' }),
        },
      ],
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const selectedValue = selectedValues[0];
        return stays
          .filter((item) => {
            const departureDate = new Date(item.departureDateLocal);
            return selectedValue === 'current_future'
              ? departureDate > yesterday
              : departureDate <= yesterday;
          })
          .map((item) => item.id);
      },
    },
  ];

  const filter = {
    filters,
    initialFiltering: parentFilter.filtering,
    handleFilteringChange: parentFilter.handleFilteringChange,
  };

  const search = {
    searchableKeys: ['title'],
    searchString: parentSearch.searchString,
    handleSearchChange: parentSearch.handleSearchChange,
    placeholder: label('Ref: Search'),
  };

  if (lock) return <LoadingPage />;

  return (
    <ListPage
      hasBackLink={false}
      title={label('Ref: Page title')}
      filter={filter}
      items={getStaysItems(stays, label, currentLanguageCode)}
      itemRenderer={(item) => (
        <OldTile
          key={item.id}
          {...item}
          secondTitleLine={item.secondTitleLine ? item.secondTitleLine : undefined}
        />
      )}
      hideFilterTitle={true}
      search={search}
      data-testid="stays-list-page"
      aside={
        <Column.Complementary>
          <ReceptionIllustration />
        </Column.Complementary>
      }
    />
  );
};

export default StaysList;
