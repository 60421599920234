import { useCallback, useRef } from 'react';

import { QrIcon, WarningOutlinedIcon } from '../../../assets/icons';
import Button from '../../../components/atoms/Button';
import { Alert } from '../../../components/molecules/Alert';
import QrCodeReader from '../../../components/molecules/QrCode/QrCodeReader';
import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import useToggle from '../../../helpers/hooks/useToggle';
import { useRedeemStampsMutation, useScanQrCodeMutation } from '../api/api';
import { defaultValues, waitBetweenScansInMs } from '../config';
import { getStampsButtonLabel } from '../helpers/stamps.helper';
import { useStampsTranslation } from '../hooks/useStampsTranslation';
import { IStampActionButtonProps } from '../types/stamps.types';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';
import useSite from '@/modules/Core/hooks/useSite';
import { UserSteps } from '@/types/userSteps.types';

import styles from './StampActionButton.module.css';

const StampActionButton = ({
  isLoadingStamps,
  isRewardAvailable,
  isRedeem,
  setIsRedeem,
  setRedeemTimer,
  componentIdToScroll,
}: IStampActionButtonProps) => {
  const { label } = useStampsTranslation(__filename);
  const { isGuest } = useLoginStatus();
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const [scanQRCode] = useScanQrCodeMutation();
  const [redeemStamps] = useRedeemStampsMutation();

  const { logUserSteps, logError } = useUserStepsInsightsLogging();

  const {
    state: isConfirmRedeemModalOpen,
    toggleOn: openConfirmRedeemModal,
    toggleOff: closeConfirmRedeemModal,
  } = useToggle(false);

  const {
    state: isErrorModalOpen,
    toggleOn: openErrorModal,
    toggleOff: closeErrorModal,
  } = useToggle(false);

  const {
    state: isQrCodeErrorModalOpen,
    toggleOn: openQrCodeErrorModal,
    toggleOff: closeQrCodeErrorModal,
  } = useToggle(false);

  const {
    state: isSuccessModalOpen,
    toggleOn: openSuccessModal,
    toggleOff: closeSuccessModal,
  } = useToggle(false);

  const startRedeemInterval = useCallback(() => {
    let timer = defaultValues.redeemTimeInSeconds;

    const redeemTimerInterval = setInterval(() => {
      timer -= 1;
      setRedeemTimer(timer);

      if (timer === 0) {
        clearInterval(redeemTimerInterval);
        setIsRedeem(false);
        setRedeemTimer(defaultValues.redeemTimeInSeconds);
      }
    }, 1000);

    intervalRef.current = redeemTimerInterval;
  }, [setIsRedeem, setRedeemTimer]);

  const handleError = useCallback(openErrorModal, [openErrorModal]);

  const handleRedeem = useCallback(async () => {
    closeConfirmRedeemModal();
    if (site.id) {
      await redeemStamps({ siteId: site.id, useErrorBoundary: false })
        .unwrap()
        .then((onfulfilled) => {
          if (onfulfilled) {
            logUserSteps({ event: UserSteps.FreeCoffeeRedeemed });
            setIsRedeem(true);
            startRedeemInterval();
          }
        })
        .catch((error) => {
          if (error) {
            logError(new Error('Error when redeeming free coffee'));
            handleError();
          }
        });
    }
  }, [
    closeConfirmRedeemModal,
    handleError,
    logError,
    logUserSteps,
    redeemStamps,
    setIsRedeem,
    site.id,
    startRedeemInterval,
  ]);

  const resetTimer = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setIsRedeem(false);
    setRedeemTimer(defaultValues.redeemTimeInSeconds);
  }, [setIsRedeem, setRedeemTimer]);

  const handleQrCodeError = useCallback(() => {
    logError(
      new Error('Error occured when scanning Qr code to collect a stamp, error modal displayed')
    );
    openQrCodeErrorModal();
  }, [logError, openQrCodeErrorModal]);

  const handleSuccess = useCallback(() => {
    logUserSteps({ event: UserSteps.FreeCoffeeCodeScannedSuccessfully });
    openSuccessModal();
  }, [logUserSteps, openSuccessModal]);

  const handleQRCodeScan = useCallback(
    async ({ response: qrCode }: QRCodeReaderResponse) => {
      if (site?.id && qrCode) {
        logUserSteps({ event: UserSteps.UserScannedQrCode });
        await scanQRCode({ siteId: site.id, qrCode, useErrorBoundary: false })
          .unwrap()
          .then((data) => data.isRewardAvailable && handleSuccess())
          .catch((error) => error && handleQrCodeError());
      }
    },
    [handleQrCodeError, handleSuccess, logUserSteps, scanQRCode, site?.id]
  );

  const ActionButton =
    isRewardAvailable || isRedeem ? (
      <Button
        data-testid="redeem-button"
        look="primary"
        loading={isLoadingStamps}
        inheritStyle={styles.actionButton}
        onClick={isRedeem ? resetTimer : openConfirmRedeemModal}
      >
        {label(getStampsButtonLabel(isRewardAvailable, isRedeem))}
      </Button>
    ) : (
      <QrCodeReader
        label={label}
        buttonLabel={label(getStampsButtonLabel(isRewardAvailable, isRedeem))}
        title={label('Ref: Scan QR Code')}
        buttonProps={{
          look: 'primary',
          loading: isLoadingStamps,
          inheritStyle: styles.actionButton,
          affix: QrIcon,
          contentCenterAlign: true,
          disabled: isGuest,
          'data-testid': 'stamps-scan-qr-code-button',
        }}
        onQrCodeScan={handleQRCodeScan}
        componentIdToScroll={componentIdToScroll}
        timeBetweenScans={waitBetweenScansInMs}
      />
    );

  return (
    <>
      {ActionButton}
      <Alert
        isOpen={isQrCodeErrorModalOpen}
        onDismiss={closeQrCodeErrorModal}
        header={label('Ref: Invalid QR Code')}
        message={label("Ref: The QR Code isn't recognized")}
        data-testid="stamps-error-modal"
        buttons={[
          {
            text: label('Ref: Close', { textTransform: 'uppercase' }),
            handler: () => closeQrCodeErrorModal(),
            'data-testid': 'stamps-error-modal-close-button',
          },
        ]}
      />
      <Alert
        isOpen={isSuccessModalOpen}
        onDismiss={closeSuccessModal}
        header={label('Ref: Congratulations modal title')}
        message={label('Ref: Congratulations modal description')}
        data-testid="stamps-success-modal"
        buttons={[
          {
            text: label('Ref: Close', { textTransform: 'uppercase' }),
            handler: () => closeSuccessModal(),
            'data-testid': 'stamps-success-modal-close-button',
          },
        ]}
      />
      <Alert
        isOpen={isConfirmRedeemModalOpen}
        onDismiss={closeConfirmRedeemModal}
        header={label('Ref: Bite')}
        message={label('Ref: Confirm redeem description')}
        data-testid="stamps-confirm-redeem-modal"
        buttons={[
          {
            text: label('Ref: Cancel', { textTransform: 'uppercase' }),
            handler: () => closeConfirmRedeemModal(),
            'data-testid': 'stamps-confirm-redeem-modal-cancel-button',
          },
          {
            text: label('Ref: Redeem button', { textTransform: 'uppercase' }),
            handler: () => handleRedeem(),
            'data-testid': 'stamps-confirm-redeem-modal-redeem-button',
          },
        ]}
      />
      <Alert
        isOpen={isErrorModalOpen}
        onDismiss={closeErrorModal}
        header={<WarningOutlinedIcon />}
        message={label('Something went wrong, data not loaded')}
        data-testid="stamps-redeeming-error-modal"
        buttons={[
          {
            text: label('Ref: Close', { textTransform: 'uppercase' }),
            handler: () => closeErrorModal(),
            'data-testid': 'stamps-redeeming-error-modal-close-button',
          },
        ]}
      />
    </>
  );
};

export default StampActionButton;
