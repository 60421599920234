import { WithLangProps } from '../../../../context/withLang';

export type SharePageProps = {
  isLoggedIn: boolean;
} & WithLangProps;

export enum ActionType {
  LOADING = 'LOADING',
  NOT_FOUND = 'NOT_FOUND',
  REQUEST_ERROR = 'REQUEST_ERROR',
  GO_TO_LOGIN = 'GO_TO_LOGIN',
}
