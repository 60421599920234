import { WithLangProps } from '../../../../context/withLang';
import { DateFields } from '../../types';

export enum dateTypeValues {
  departure = 'departure',
  arrival = 'arrival',
}

export type DateTypes = dateTypeValues.departure | dateTypeValues.arrival;

export type ScheduleFieldsProps = {
  value: DateFields;
  onChange: (dateFields: DateFields) => void;
} & WithLangProps;
