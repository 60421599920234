import { CheckIcon } from '../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../components/atoms/Button';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import { SIZE } from '../../../constants';
import { formatDateTime } from '../../../helpers/misc';
import { pagePaths } from '../config';
import { MapPendingOrdersProps } from '../types/tableCheckIn.types';

export const mapPendingOrders = ({
  label,
  pendingOrders,
  history,
  checkInAtTableProcess,
  checkingIn,
  tableNumber,
  siteId,
  checkedInOrders,
  isLoadingPendingOrders,
  currentLanguageCode,
}: MapPendingOrdersProps) => {
  const orders = pendingOrders?.map((order) => {
    const dineInTime = formatDateTime(new Date(order.pickupTime), currentLanguageCode);
    const checked = checkedInOrders.includes(Number(order.orderNumber)) || order.isCheckedIn;
    const isLoadingOrder = checkingIn === order.orderNumber;
    const isLoading = isLoadingPendingOrders || isLoadingOrder;
    const title = `${label('Ref #')}${order.orderNumber}`;
    const secondTitleLine = `${label('Table')} ${order.tableNumber}`;
    const screenReaderText = `${label('Order number')} ${order.orderNumber}`;

    const handleOnClick = () => {
      if (!!tableNumber) {
        checkInAtTableProcess(
          siteId,
          Number(order.orderNumber),
          order.deliveryOptionId,
          tableNumber
        );
      }
    };

    return {
      key: order.orderNumber,
      title: title,
      secondTitleLine: order.tableNumber ? secondTitleLine : null,
      addendum: order.orderStatus,
      description: dineInTime,
      highlightedActions: [
        {
          element: (
            <div className="buttonsWrapper">
              {checked ? (
                <Notification
                  title={label('Checked')}
                  look={NOTIFICATION_LOOK.SUCCESS}
                  icon={<CheckIcon />}
                  inheritStyle="smallNotification"
                />
              ) : (
                <Button
                  look={BUTTON_LOOK.PRIMARY}
                  size={SIZE.SMALL}
                  onClick={handleOnClick}
                  loading={isLoading}
                  data-testid="order-check-in-button"
                  srOnlyText={screenReaderText}
                >
                  {label('Check in')}
                </Button>
              )}
              <Button
                look={BUTTON_LOOK.SECONDARY}
                size={SIZE.SMALL}
                onClick={() =>
                  history.push(pagePaths.OrderHistoryDetails.replace(':id', `${order.orderNumber}`))
                }
                data-testid="order-see-details-button"
                srOnlyText={screenReaderText}
              >
                {label('See details')}
              </Button>
            </div>
          ),
        },
      ],
    };
  });
  return orders;
};
