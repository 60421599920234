import isEqual from 'lodash.isequal';
import { memo } from 'react';
import { connect } from 'react-redux';

import { modulesConfig } from '../modules/config';

const withSetupOptions = (moduleId, WrappedComponent) => {
  const serviceType = modulesConfig[moduleId]?.serviceType;
  const mapStateToProps = (state) => {
    const moduleSetupOptions =
      state.Core?.services?.list?.find((el) => el.name === serviceType)?.setupOptions || [];

    const setupOptions = {};
    for (const option of moduleSetupOptions) {
      setupOptions[option.name.toUpperCase()]
        ? setupOptions[option.name.toUpperCase()].push(option)
        : (setupOptions[option.name.toUpperCase()] = Array(1).fill(option));
    }

    return { setupOptions };
  };
  return connect(mapStateToProps)(
    memo(WrappedComponent, (props, nextProps) => isEqual(props, nextProps))
  );
};

export default withSetupOptions;
