import { RedoIcon } from '../../../../../assets';
import TitleBar from '../../../../../components/organisms/TitleBar';
import withLang, { WithLangProps } from '../../../../../context/withLang';
import { orderConfig } from '../../../config';
import useResetKioskSession from '../../../hooks/useClearKioskSession/useResetKioskSession';

const ResetSessionWidget = ({ label }: WithLangProps) => {
  const [resetSession] = useResetKioskSession();
  const { isResetSessionWidgetShownInTitleBar } = orderConfig();

  if (!isResetSessionWidgetShownInTitleBar) return <></>;
  return (
    <TitleBar.Widget
      label={label}
      labelKey="Ref: Reset your order"
      Icon={RedoIcon}
      filled
      onClick={resetSession}
      forceToDisplayLabel
      iconOnTheLeft
    />
  );
};

export default withLang(__filename)(ResetSessionWidget);
