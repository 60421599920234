import { useParams } from 'react-router';

import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { APP_ERRORS } from '../../../../constants';
import { PermissionErrors } from '../../../../types/errors.types';
import GenericErrorPage from '../../../Core/components/GenericErrorPage/GenericErrorPage';
import { useGetEventQuery } from '../../api';
import Details from '../../components/EventDetail/Details';
import { EventType } from '../../types';

import useSite from '@/modules/Core/hooks/useSite';

const EventDetailsWrapper = () => {
  const params = useParams<Pick<EventType, 'id'>>();
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const {
    data: eventResponse = {
      eventDetails: undefined,
      onDifferentSite: false,
      notFound: false,
      permissionDenied: false,
    },
    isLoading,
  } = useGetEventQuery({ id: params.id, siteId });
  const { eventDetails, onDifferentSite, notFound, permissionDenied } = eventResponse;

  if (notFound) {
    return <GenericErrorPage errors={[APP_ERRORS.NOT_FOUND]} />;
  }
  if (permissionDenied) {
    return <GenericErrorPage errors={[permissionDenied as PermissionErrors]} />;
  }
  if (isLoading || !eventDetails) {
    return <LoadingPage />;
  }

  return <Details eventDetails={eventDetails} onDifferentSite={onDifferentSite} />;
};

export default EventDetailsWrapper;
