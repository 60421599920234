import cloneDeepWith from 'lodash/cloneDeepWith';

import { LabelFunc } from '../../../context/withLang';

import { FormField } from './';

const getFieldsDefaultValues = (
  fields: { [k: string]: FormField },
  customValues: { [k: string]: string }
) => {
  const defaultValues: { [k: string]: string } = {};

  for (const [field, options] of Object.entries(fields)) {
    if (field in customValues) {
      defaultValues[field] = customValues[field];
    } else {
      defaultValues[field] = options.defaultValue;
    }
  }

  return defaultValues;
};

const trimFields = <T>(data: T) =>
  cloneDeepWith(data, (p: keyof T) => (typeof p === 'string' ? p.trim() : undefined));

const validateOnlyWhitespace = <T>(value: T, errorMessage: string): true | string =>
  typeof value === 'string' ? value.trim().length > 0 || errorMessage : true;

const requiredRule = (
  inputLabel: string,
  label: LabelFunc
): {
  required: string;
  validate: Record<string, (val: any) => true | string>;
} => {
  const errorMessage = label('Ref: Input required', {
    replace: { input_name: inputLabel },
  });

  return {
    required: errorMessage,
    validate: {
      whitespace: (val: any) => validateOnlyWhitespace(val, errorMessage),
    },
  };
};

export { getFieldsDefaultValues, trimFields, validateOnlyWhitespace, requiredRule };
