import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';

import DefaultErrorPage from './DefaultErrorPage';

describe('DefaultErrorPage', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let firstTitle: HTMLElement,
      secondTitle: HTMLElement,
      content: HTMLElement,
      errorId: HTMLElement,
      button: HTMLElement;

    renderComponent(DefaultErrorPage);

    beforeEach(() => {
      firstTitle = screen.getByTestId('default-error-page-first-title');
      secondTitle = screen.getByTestId('default-error-page-second-title');
      content = screen.getByTestId('default-error-page-body');
      errorId = screen.getByTestId('default-error-page-errorId');
      button = screen.getByTestId('button-action-secondary');
    });

    it('should have displayed generic text', () => {
      expect(firstTitle).toBeTruthy();
      expect(secondTitle).toBeTruthy();
      expect(content).toBeTruthy();
      expect(errorId).toBeTruthy();
      expect(button).toBeTruthy();

      expect(screen.queryByText(/Oops!/)).toBeTruthy();
      expect(screen.getByText('Something went wrong!')).toBeTruthy();
      expect(
        screen.getByText('Try to refresh this page or to contact helpdesk if the problem persists.')
      ).toBeTruthy();
      expect(screen.getByText(/Error ID:/)).toBeTruthy();
      expect(screen.getByText('Back to home')).toBeTruthy();
    });
  });

  describe('on button click', () => {
    let button: HTMLElement;
    renderComponent(DefaultErrorPage);

    beforeEach(() => {
      button = screen.getByTestId('button-action-secondary');
    });

    it('should have redirected to Homepage', () => {
      const history = createMemoryHistory();
      expect(button).toBeTruthy();
      expect(screen.getByText('Back to home')).toBeTruthy();
      fireEvent.click(button);

      expect(history.location.pathname).toBe('/');
    });
  });
});
