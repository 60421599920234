import { useHistory } from 'react-router';

import NoticePage from '../../../../../components/templates/NoticePage/NoticePage';
import { WithLangProps } from '../../../../../context/withLang';
import { pagePaths } from '../../../config';
import withLang from '../../../context/withLang';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const ResetPasswordFailure = ({ label }: WithLangProps) => {
  const history = useHistory();
  return (
    <NoticePage
      hideAllWidgets
      type={NOTICEPAGE_TYPE.ERROR}
      withNavBar={false}
      title={label('Password reset fail')}
      note={label('Ref: Error')}
      actions={[
        {
          label: label('Reset your password'),
          action: () => history.goBack(),
        },
        {
          label: label('Go to login page'),
          action: () => history.push(pagePaths.Home),
        },
      ]}
    />
  );
};

export default withLang([__filename])(ResetPasswordFailure);
