import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import withSetupOptions from '../../../context/withSetupOptions';
import FoodFacilitiesList from '../components/FoodFacilitiesList';
import ProductDetails from '../components/ProductDetails';
import ProductsList from '../components/ProductsList';
import { pagePaths, moduleId } from '../config';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.Module} exact children={<FoodFacilitiesList />} />
      <Route path={pagePaths.ProductsList} children={<ProductsList />} />
      <Route path={pagePaths.ProductDetails} children={<ProductDetails />} />
    </Switch>
  );
};

export default withSetupOptions(moduleId, withRouter(Routes));
