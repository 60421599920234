import { connect } from 'react-redux';

import OnboardingTunnel from './OnboardingTunnel';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTunnel);
