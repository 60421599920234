import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

import Button from '../../../components/atoms/Button';
import Title from '../../../components/atoms/Title';
import { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title/Title.types';
import Card from '../../../components/molecules/Card/Card';
import ContentDetailsPage from '../../../components/templates/ContentDetailsPage';
import { isKiosk } from '../../../helpers/misc';
import { pagePaths as corePagePaths } from '../../Core/config';
import { orderConfig, pagePaths as orderPagePaths } from '../config';
import { getThankYouImage, getThankYouLabel } from '../helpers/successOrderHelper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import { KioskOrderVariant } from '../types/KioskOrder.types';
import { OrderInfo } from '../types/order.summary.types';
import { OrderSummaryInfo } from '../types/orderSummary.types';
import { SuccessOrderProps } from '../types/successOrder.types';

import KioskOrder from './KioskOrder';
import OrderInfoCard from './OrderInfoCard';
import OrderSummary from './OrderSummary/OrderSummary';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';
import { UserSteps } from '@/types/userSteps.types';

const SuccessOrder = ({ lastOrder, facilityName, resetTableNumber }: SuccessOrderProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);
  const { logUserSteps } = useUserStepsInsightsLogging();
  const { currentLanguageCode } = useLanguage();
  const { isGuest } = useLoginStatus();

  const { isKioskTitleBar } = orderConfig();

  const redirectHome = useCallback(() => {
    return history.push(corePagePaths.Home);
  }, [history]);

  const redirectToOrderHistoryDetails = useCallback(() => {
    return history.push(
      orderPagePaths.OrderHistoryDetails.replace(':id', `${lastOrder?.orderId}` ?? '')
    );
  }, [history, lastOrder?.orderId]);

  useEffect(() => {
    if (!lastOrder) {
      redirectHome();
    }
  }, [lastOrder, redirectHome]);

  useEffect(() => {
    if (!!facilityName && !!lastOrder?.facilityId) {
      logUserSteps({
        event: UserSteps.OrderSuccessPage,
        facilityId: lastOrder?.facilityId,
        facilityName,
      });
    }
  }, [facilityName, lastOrder?.facilityId, logUserSteps]);

  useEffect(
    function resetTableNumberOnMount() {
      resetTableNumber && resetTableNumber();
    },
    [resetTableNumber]
  );

  if (!lastOrder) {
    return null;
  }
  const thankYouImage = getThankYouImage(lastOrder!.deliveryOptionType!);
  if (isKiosk) {
    return (
      <KioskOrder
        label={label}
        variant={KioskOrderVariant.SUCCESS}
        deliveryOption={lastOrder!.deliveryOptionType!}
        orderId={lastOrder?.orderId}
      />
    );
  }
  const thankYouLabel = getThankYouLabel(lastOrder!.deliveryOptionType!);
  const orderInfo: OrderInfo = {
    ...lastOrder,
    pickupDate: lastOrder.pickupInformation.pickupTime ?? undefined,
    pickupLocation: lastOrder.pickupInformation.pickupSpotName!,
    phoneNumber: lastOrder.pickupInformation.pickupPhoneNumber,
    instructions: lastOrder.pickupInformation.pickupInstructions ?? undefined,
    facilityName,
  };
  const orderSummary: OrderSummaryInfo = {
    ...lastOrder,
    delivery: lastOrder.delivery,
    promotionDiscount: lastOrder.promotionDiscount,
    appliedPromotions: lastOrder.promotionsApplied,
    productsCount: lastOrder.orderItems.reduce((prev, item) => (prev += item.quantity), 0),
  };

  const header = {
    children: (
      <>
        <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES} className="mb-M">
          {label('Ref: Order confirmed', {
            textTransform: 'capitalize',
          })}
        </Title>
      </>
    ),
    imageNode: thankYouImage,
  };

  const mainColumn = (
    <>
      <p className={classNames('bodySBold', 'textPrimary', 'paragraph')}>
        {label(thankYouLabel, {
          textTransform: 'capitalize',
        })}
      </p>
      <OrderInfoCard currentLanguageCode={currentLanguageCode} {...orderInfo} />
      <Card>
        <OrderSummary {...orderSummary} />
      </Card>
    </>
  );

  const actions = [
    <Button
      data-testid="success-order-go-to-home"
      key="success-order-go-to-home"
      onClick={redirectHome}
    >
      {label('Go to home', { textTransform: 'capitalize' })}
    </Button>,
  ];

  if (!isGuest) {
    actions.unshift(
      <Button
        data-testid="success-order-see-details"
        key="success-order-see-details"
        onClick={redirectToOrderHistoryDetails}
      >
        {label('See details', { textTransform: 'capitalize' })}
      </Button>
    );
  }

  return (
    <ContentDetailsPage
      title={''}
      header={header}
      actions={actions}
      hideBackButton
      hideDefaultTitleBarWidgets={isKioskTitleBar}
      tabTitle={label('Order confirmed')}
    >
      {mainColumn}
    </ContentDetailsPage>
  );
};

export default SuccessOrder;
