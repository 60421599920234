import { ScanResult } from '@capacitor-community/barcode-scanner';
import { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { WithLangProps } from '../../../context/withLang';
import Button from '../../atoms/Button';

import nativeReader from './helpers/NativeReader';
import { QRCodeReaderResponse } from './reader.types';

import styles from './QrCodeReader.module.css';

type NativeQrCodeProps = {
  isScanStarted: boolean;
  stopScan: () => void;
  onQrCodeScan: (response: QRCodeReaderResponse) => void;
  preventCloseAfterScan?: boolean;
  actions: React.ReactNode[];
  timeBetweenScans?: number;
  children?: React.ReactNode;
} & WithLangProps;

const NativeQrCode = ({
  label,
  isScanStarted,
  stopScan,
  onQrCodeScan,
  actions,
  preventCloseAfterScan,
  timeBetweenScans,
  children,
}: NativeQrCodeProps) => {
  const ref = document.getElementById('portalBarCode');
  const shouldShowNativeClose = ref && isScanStarted;
  const lastScanTime = useRef(new Date(1900, 1, 1));

  const onScanned = useCallback(
    (result: ScanResult, err?: any) => {
      const timeDiffBetweenScans = new Date().getTime() - lastScanTime.current.getTime();
      const isNextScanReady =
        (timeBetweenScans && timeDiffBetweenScans > timeBetweenScans) || !timeBetweenScans;

      if (isNextScanReady) {
        onQrCodeScan({ response: result.content });
        lastScanTime.current = new Date();
      }
    },
    [onQrCodeScan, timeBetweenScans]
  );

  useEffect(() => {
    const onScanStart = async () => {
      if (isScanStarted) {
        try {
          if (!preventCloseAfterScan) {
            const result = await nativeReader.startScan();
            stopScan();
            onQrCodeScan({ response: result });
          } else {
            await nativeReader.startScanning(onScanned);
          }
        } catch (e: any) {
          onQrCodeScan({ error: e.message });
          stopScan();
        }
      }
    };

    onScanStart();
  }, [isScanStarted, onQrCodeScan, stopScan, preventCloseAfterScan, onScanned]);

  useEffect(() => {
    return () => {
      nativeReader.stopScan();
    };
  }, []);

  const stopReader = async () => {
    stopScan();
    await nativeReader.stopScan();
  };

  if (shouldShowNativeClose) {
    return ReactDOM.createPortal(
      <>
        {
          <div className={styles.bottomWrapper}>
            {children && <div>{children}</div>}
            <>
              {actions?.length ? <div className={styles.divider} /> : <></>}
              <div className={styles.actionsContainerNative}>
                {actions}
                <Button look="secondary" onClick={stopReader} className={styles.closeBtn} data-testid='native-qr-code-secondary-action'>
                  {label('close')}
                </Button>
              </div>
            </>
          </div>
        }
      </>,
      ref
    );
  }
  return null;
};

export default NativeQrCode;
