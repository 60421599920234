import classNames from 'classnames';

import { ArrowRightIcon } from '../../../../assets';
import { Information } from '../../../../assets/icons';
import { ImageDefaultFoodImg } from '../../../../assets/images';
import { LabelFunc } from '../../../../context/withLang';
import { pagePaths } from '../../config';

import { FoodFacility } from './FoodFacilitiesList.types';

import styles from './FoodFacilitiesList.module.css';

export const convertFacilitiesToList = ({
  facilities,
  facilityImages,
  label,
  viewFacilityDetails,
}: {
  facilities: FoodFacility[];
  facilityImages: { key: string; image: string }[];
  label: LabelFunc;
  viewFacilityDetails: (facility: FoodFacility) => void;
}) => {
  const handleOnClick = (facility: FoodFacility) => () => viewFacilityDetails(facility);

  return facilities.map((facility: FoodFacility) => {
    const addendum = (
      <button
        className={classNames(styles.addendum)}
        onClick={handleOnClick(facility)}
        data-testid="view-details-button"
      >
        <Information /> <span>{label('Ref: View details')}</span>
      </button>
    );

    const linkPath = pagePaths.ProductsList.replace(':facilityId', facility.id);

    const imgInfo = facilityImages?.find((x) => x.key === facility.id)
      ? {
          src: facilityImages?.find((x) => x.key === facility.id)?.image,
          alt: facility.title,
        }
      : undefined;

    const highlightedActions = [
      {
        element: <ArrowRightIcon />,
        linkPath,
      },
    ];

    return {
      id: facility.id,
      title: facility.title,
      imgElement: <ImageDefaultFoodImg />,
      addendum,
      imgInfo,
      highlightedActions,
      'data-testid': `food-facility-${facility.id}`,
    };
  });
};
