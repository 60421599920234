import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useLazyGetLocationsQuery } from '../../api/index';
import { pagePaths } from '../../config';
import { defaultSR } from '../../helpers/helpers';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import Confirm from '../Confirm';
import Describe from '../Describe/Describe';
import Failed from '../Failed/Failed';
import Success from '../Sucess/Success';

import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import Tunnel from '@/components/Tunnel';
import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';
import { ServiceRequestCreationProps, LocationProps } from '@/modules/ServiceRequest/types/types';
import { State } from '@/types/state.types';

const QrCreateTunnel = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const { isLoggedIn } = useLoginStatus();

  const location = useLocation();
  const { siteId, locationId }: { siteId: string; locationId: string } = useParams();
  const [currentSR, setCurrentSR] = useState(defaultSR);
  const { mobile: userPhoneNumber } = useSelector((state: State) => state.Core?.user);
  const [getLocations] = useLazyGetLocationsQuery();
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  useEffect(() => {
    const changed = new URLSearchParams(location.search).get('isSiteChanged') ?? 'false';
    if (changed === 'true') {
      setShowNotification(true);
    }
  }, [location]);

  const fetchData = useCallback(async () => {
    setLocationsLoading(true);
    const { data: result = [] } = await getLocations({
      siteId: siteId,
    });

    setLocations(result);
    setLocationsLoading(false);
  }, [getLocations, siteId]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn, fetchData]);

  useEffect(() => {
    if (!locationsLoading && locations.length > 0) {
      setCurrentSR({
        ...defaultSR,
        myLocation: locations?.length
          ? locations.find((loc) => loc?.id === locationId)!
          : undefined,
        phoneNumber: userPhoneNumber || '',
      });
    }
  }, [locationId, userPhoneNumber, locations, locationsLoading]);

  const handleChange = (createServiceRequest: ServiceRequestCreationProps) => {
    setCurrentSR({ ...currentSR, ...createServiceRequest });
  };

  const {
    myLocation,
    title,
    category,
    description,
    phoneNumber,
    roomText,
    raisedFor,
    templateId,
    attachments,
  } = currentSR;

  const tunnelBaseRoute = pagePaths['QrCreate']
    .replace(':siteId', siteId)
    .replace(':locationId', locationId);

  if (locationsLoading) return <LoadingPage />;

  return (
    <Tunnel
      steps={[
        {
          id: 'describe',
          path: `${tunnelBaseRoute}/describe`,
          title: label('Ref: Your request'),
          component: Describe,
          customProps: {
            hasLocation: !!(myLocation || roomText),
            assetTypeId: myLocation ? myLocation.assetTypeId : undefined, //tmp todo: review when asset type id returned by api
            myLocation,
            title,
            category,
            description,
            phoneNumber,
            roomText,
            raisedFor,
            templateId,
            attachments,
            handleChange,
            withNavBar: false,
            previousPath: '/',
            isSiteChanged: showNotification,
          },
          getApplicability: () => true,
          getNextStepId: () => 'confirm',
        },
        {
          id: 'confirm',
          path: `${tunnelBaseRoute}/confirm`,
          title: label('Ref: Summary'),
          component: Confirm,
          customProps: {
            myLocation,
            title,
            category,
            description,
            phoneNumber,
            roomText,
            raisedFor,
            templateId,
            attachments,
            withNavBar: false,
            pathToLocate: '',
            pathToDescribe: `${tunnelBaseRoute}/describe`,
          },
          getApplicability: () => true,
          getNextStepId: (isSubmittedSuccessfully: boolean) =>
            isSubmittedSuccessfully ? 'success' : 'failed',
        },
        {
          id: 'success',
          path: `${tunnelBaseRoute}/success`,
          component: Success,
          customProps: {
            clearDraft: () => {
              if (currentSR) defaultSR.phoneNumber = currentSR.phoneNumber;
              setCurrentSR(defaultSR);
            },
            withNavBar: false,
          },
          getApplicability: () => true,
          getNextStepId: () => false,
        },
        {
          id: 'failed',
          path: `${tunnelBaseRoute}/failed`,
          component: Failed,
          customProps: {
            withNavBar: false,
          },
          getApplicability: () => true,
          getNextStepId: () => false,
        },
      ]}
      startStepId="describe"
      exitToPath=""
    />
  );
};

export default QrCreateTunnel;
