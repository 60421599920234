import { act, cleanup, fireEvent, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import { hidePhoneNumber } from '../../../helpers/accountPage.helper';
import { defaultState as coreDefaultState } from '../../../reducers/coreReducer';
import AccountPage from '../AccountPage';

import { AllergenType } from '@/modules/Order/types/orderState.types';

const guestUser = {
  email: '7df82716-1cad',
  firstName: '',
  lastName: 'Guest',
  mobile: 'xxxx',
  shouldDisplayImprintLink: true,
  shouldDisplayTermsOfSalesLink: false,
  siteName: 'Hope Downs 1 Village',
};

const guestProps = {
  currentLanguage: {
    name: 'English (United States)',
  },
  identity: {
    client: 'Global',
    type: 'OAuth',
    provider: 'Custom',
    tokenUrl: 'xxx',
  },
  ...guestUser,
};

const user = {
  email: 'apptest1@myvillage.com',
  firstName: 'DSP',
  lastName: 'Testing',
  mobile: '+33987654321',
  shouldDisplayImprintLink: true,
  shouldDisplayTermsOfSalesLink: false,
  siteName: 'Hope Downs 1 Village',
};

const props = {
  currentLanguage: {
    name: 'English (United States)',
  },
  identity: {
    client: 'Global',
    type: 'OAuth',
    provider: 'Custom',
    tokenUrl: 'xxx',
  },
  isAllergensLocked: false,
  allergens: [AllergenType.Milk],
  getAllergens: () => [AllergenType.Milk],
  ...user,
};

jest.mock('../../../../services', () => ({
  checkIfServiceAvailable: () => true,
}));
const mockGetFilters = async () => {
  return {
    data: {},
  };
};
const mockGetIdentity = async () => {
  return {
    data: {
      client: 'Global',
      type: 'OAuth',
      provider: 'Custom',
      tokenUrl: 'xxx',
    },
  };
};

jest.mock('../../../api/account/accountPreferencesApi', () => ({
  useLazyGetFilterPreferencesQuery: () => [mockGetFilters],
  useSaveFilterPreferencesMutation: () => [],
}));

let mockUserContextData = guestUser;
jest.mock('../../../api/account/accountApi', () => ({
  useGetIdentityContextQuery: () => mockGetIdentity,
}));

jest.mock('../../../api/account/userContextApi', () => ({
  useUpdateUserContextMutation: () => [mockGetIdentity],
  useGetAccountUserContextQuery: () => ({
    data: mockUserContextData,
  }),
}));

jest.mock('../../../helpers/accountPage.helper', () => {
  const actualHelper = jest.requireActual('../../../helpers/accountPage.helper');
  return {
    ...actualHelper,
    hidePhoneNumber: jest.fn(),
  };
});

const legalDocs = [
  {
    id: 'f4aff6bd-b518-ed11-b83e-000d3aad9559',
    type: {
      name: 'Terms and Conditions',
      value: 224960000,
    },
    version: 'V1.1',
    publishDate: '2022-08-09T08:00:00Z',
    geolocation: 'EU',
    language: {
      name: 'English',
      code: 'en-US',
    },
  },
  {
    id: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
    type: {
      name: 'Privacy Policy',
      value: 224960001,
    },
    version: 'V2.0',
    publishDate: null,
    geolocation: 'EU',
    language: {
      name: 'English',
      code: 'en-US',
    },
  },
  {
    id: 'f07d31da-7619-ed11-b83e-000d3aad9206',
    type: {
      name: 'Imprint',
      value: 224960003,
    },
    version: '1',
    publishDate: '2022-08-09T08:00:00Z',
    geolocation: 'EU',
    language: {
      name: 'English',
      code: 'en-US',
    },
  },
  {
    id: 'f7e2a61d-19c2-eb11-bacc-000d3ac22e37',
    type: {
      name: 'Terms of Sales',
      value: 224960002,
    },
    version: '1',
    publishDate: '2021-06-01T08:00:00Z',
    geolocation: 'EU',
    language: {
      name: 'English',
      code: 'en-US',
    },
  },
];
const accountPageState = {
  Core: {
    context: {
      site: {
        name: 'Hope Downs 1 Village',
        id: 'aa',
      },
    },
    services: {
      list: [],
    },
    legalDocs: { list: legalDocs },
    user: { acknowledgements: [] },
    access: { shouldSelectSiteContext: false },
  },
};

const accountPageGuestState = {
  Core: {
    ...coreDefaultState,
    legalDocs: { list: legalDocs },
    user: {
      isGuest: true,
      acknowledgements: [],
    },
    context: {
      site: {
        name: 'Hope Downs 1 Village',
        id: 'aa',
      },
    },
  },
};

const mockLogout = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockLogout,
}));

describe('AccountPage', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('when is Bite and guest user', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.BITE,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });

    describe('on initial render', () => {
      let primaryButton: HTMLElement;
      let secondaryButton: HTMLElement;

      renderComponent(AccountPage, { ...guestProps }, { ...accountPageGuestState });

      beforeEach(async () => {
        primaryButton = screen.getByTestId('button-action-primary');
        secondaryButton = screen.getByTestId('button-action-secondary');
      });

      it('should display', () => {
        expect(screen.getByText('My Information')).toBeTruthy();
        expect(screen.getByText('Current site')).toBeTruthy();
        expect(screen.getAllByText(guestProps.siteName)).toBeTruthy();
        expect(screen.getByText('Settings')).toBeTruthy();
        expect(screen.getByText('Language')).toBeTruthy();
        expect(screen.getByText(guestProps.currentLanguage.name)).toBeTruthy();
        expect(screen.getByText('Terms & conditions')).toBeTruthy();
        expect(screen.getByText('Privacy policy')).toBeTruthy();
        expect(screen.getByText('Cookie Settings')).toBeTruthy();
        expect(primaryButton).toBeTruthy();
        expect(screen.getByText('Log out')).toBeTruthy();
        expect(secondaryButton).toBeTruthy();
        expect(screen.getByText('Register or login')).toBeTruthy();
      });

      it('should not display', () => {
        expect(screen.queryByText(`${guestProps.firstName} ${guestProps.lastName}`)).toBeFalsy();
        expect(screen.queryByText(guestProps.email)).toBeFalsy();
        expect(screen.queryByText(guestProps.mobile)).toBeFalsy();
        expect(screen.queryByText('Communication preferences')).toBeFalsy();
        expect(screen.queryByText('Password')).toBeFalsy();
        expect(screen.queryByText('Delete my account')).toBeFalsy();
        expect(screen.queryByText('Filtering Preferences')).toBeFalsy();
      });

      it('should have correct terms link', () => {
        const terms = screen.getByText('Terms & conditions').closest('a');

        expect(terms?.getAttribute('href')).toBe('/legal/terms_of_use/current');
      });
    });

    describe('on clicking primary button', () => {
      let primaryButton: HTMLElement;
      const history = createMemoryHistory();

      renderComponent(AccountPage, { ...guestProps }, { ...accountPageGuestState }, history);

      beforeEach(async () => {
        primaryButton = screen.getByTestId('button-action-primary');

        await act(async () => {
          fireEvent.click(primaryButton);
        });
      });

      it('should logout', async () => {
        expect(mockLogout).toHaveBeenCalled();
        expect(history.location.pathname).toBe('/');
      });
    });

    describe('on clicking secondary button', () => {
      let secondaryButton: HTMLElement;
      const history = createMemoryHistory();

      renderComponent(
        AccountPage,
        { ...guestProps },
        {
          Core: {
            ...coreDefaultState,
            user: {
              isGuest: true,
            },
          },
        },
        history
      );

      beforeEach(async () => {
        secondaryButton = screen.getByTestId('button-action-secondary');

        await act(async () => {
          fireEvent.click(secondaryButton);
        });
      });

      it('should logout', async () => {
        expect(history.location.pathname).toBe('/guest/register');
      });
    });
  });

  describe('on initial render as registered user', () => {
    describe('when is MyVillage', () => {
      mockUserContextData = user;
      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });

      let primaryButton: HTMLElement;

      renderComponent(AccountPage, { ...props }, { ...accountPageState });

      beforeEach(async () => {
        primaryButton = screen.getByTestId('button-action-primary');
      });

      it('should display', () => {
        expect(screen.getByText('My Information')).toBeTruthy();
        expect(screen.getByText('Full name')).toBeTruthy();
        expect(screen.getByText(`${props.firstName} ${props.lastName}`)).toBeTruthy();
        expect(screen.getByText('Email')).toBeTruthy();
        expect(screen.getByText(props.email)).toBeTruthy();
        expect(screen.getByText('Current site')).toBeTruthy();
        expect(screen.getAllByText(props.siteName)).toBeTruthy();
        expect(screen.getByText('Mobile number')).toBeTruthy();
        expect(screen.getByText(props.mobile)).toBeTruthy();

        expect(screen.getByText('Settings')).toBeTruthy();
        expect(screen.getByText('Password')).toBeTruthy();
        expect(screen.getByText('Language')).toBeTruthy();
        expect(screen.getByText(props.currentLanguage.name)).toBeTruthy();
        expect(screen.getByText('Terms & conditions')).toBeTruthy();
        expect(screen.getByText('Privacy policy')).toBeTruthy();
        expect(screen.getByText('Cookie Settings')).toBeTruthy();
        expect(screen.getByText('Imprint')).toBeTruthy();
        expect(screen.getByText('Delete my account')).toBeTruthy();
        expect(screen.getByText('Filtering Preferences')).toBeTruthy();

        expect(primaryButton).toBeTruthy();
        expect(screen.getByText('Log out')).toBeTruthy();
      });
    });
    describe('when is MyWay', () => {
      mockUserContextData = user;

      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });

      let primaryButton: HTMLElement;

      renderComponent(AccountPage, { ...props }, { ...accountPageState });

      beforeEach(async () => {
        primaryButton = screen.getByTestId('button-action-primary');
      });

      it('should display', () => {
        expect(screen.getByText('My Information')).toBeTruthy();
        expect(screen.getByText('Full name')).toBeTruthy();
        expect(screen.getByText(`${props.firstName} ${props.lastName}`)).toBeTruthy();
        expect(screen.getByText('Email')).toBeTruthy();
        expect(screen.getByText(props.email)).toBeTruthy();
        expect(screen.getByText('Current site')).toBeTruthy();
        expect(screen.getAllByText(props.siteName)).toBeTruthy();
        expect(screen.getByText('Mobile number')).toBeTruthy();
        expect(screen.getByText(props.mobile)).toBeTruthy();

        expect(screen.getByText('Settings')).toBeTruthy();
        expect(screen.getByText('Password')).toBeTruthy();
        expect(screen.getByText('Language')).toBeTruthy();
        expect(screen.getByText(props.currentLanguage.name)).toBeTruthy();
        expect(screen.getByText('Terms & conditions')).toBeTruthy();
        expect(screen.getByText('Privacy policy')).toBeTruthy();
        expect(screen.getByText('Cookie Settings')).toBeTruthy();
        expect(screen.getByText('Imprint')).toBeTruthy();
        expect(screen.getByText('Delete my account')).toBeTruthy();
        expect(screen.getByText('Filtering Preferences')).toBeTruthy();
        expect(primaryButton).toBeTruthy();
        expect(screen.getByText('Log out')).toBeTruthy();
      });
    });
    describe('on clicking filtering preferences', () => {
      let filteringPreferences: HTMLElement[];
      let resetButton: HTMLElement;
      let confirmButton: HTMLElement;
      let filteringModal: HTMLElement;

      renderComponent(AccountPage, { ...props }, accountPageState);

      beforeEach(async () => {
        filteringPreferences = screen.getAllByText('Filtering Preferences');

        await act(async () => {
          fireEvent.click(filteringPreferences[0]);
        });
      });

      it('should open filter modal', async () => {
        filteringModal = screen.getByText('Filters', { selector: ':not(.sr-only)' });
        resetButton = screen.getByText('Reset');
        confirmButton = screen.getByText('Confirm');
        expect(filteringModal).toBeTruthy();
        expect(resetButton).toBeTruthy();
        expect(confirmButton).toBeTruthy();
      });
    });
  });

  describe('on clicking mobile number', () => {
    let mobile: HTMLElement;
    let alertCancel: HTMLElement;
    let alertConfirm: HTMLElement;
    let alert: HTMLElement;

    renderComponent(AccountPage, { ...props });

    beforeEach(async () => {
      mobile = screen.getByText('Mobile number');

      await act(async () => {
        fireEvent.click(mobile);
      });
    });

    it('should open alert', async () => {
      alert = screen.getByTestId('alert');
      alertCancel = screen.getByText('Cancel');
      alertConfirm = screen.getByText('Confirm');
      expect(alert).toBeTruthy();
      expect(alertCancel).toBeTruthy();
      expect(alertConfirm).toBeTruthy();
    });
  });

  describe('on clicking communication preferences', () => {
    describe('when is MyVillage', () => {
      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });

      const history = createMemoryHistory();

      renderComponent(AccountPage, { ...props }, undefined, history);

      it('should not exist', async () => {
        expect(screen.queryByText('Communication preferences')).not.toBeInTheDocument();
      });
    });

    describe('when is MyWay', () => {
      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });

      const history = createMemoryHistory();

      renderComponent(AccountPage, { ...props }, undefined, history);

      it('should not exist', async () => {
        expect(screen.queryByText('Communication preferences')).not.toBeInTheDocument();
      });
    });
  });

  describe('on clicking password', () => {
    let button: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(AccountPage, { ...props }, undefined, history);

    beforeEach(async () => {
      button = screen.getByText('Password');

      await act(async () => {
        fireEvent.click(button);
      });
    });

    it('should redirect', async () => {
      expect(history.location.pathname).toBe('/account/password_change');
    });
  });

  describe('on clicking language', () => {
    let button: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(AccountPage, { ...props }, undefined, history);

    beforeEach(async () => {
      button = screen.getByText('Language');

      await act(async () => {
        fireEvent.click(button);
      });
    });

    it('should redirect', async () => {
      expect(history.location.pathname).toBe('/account/language');
    });
  });

  describe('on clicking delete my account', () => {
    const history = createMemoryHistory();

    describe('when is MyVillage', () => {
      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });
      let deleteAccount: HTMLElement;

      renderComponent(AccountPage, { ...props }, undefined, history);

      beforeEach(async () => {
        deleteAccount = screen.getByText('Delete my account');

        await act(async () => {
          fireEvent.click(deleteAccount);
        });
      });

      it('should redirect', async () => {
        expect(history.location.pathname).toBe('/account/delete');
      });
    });

    describe('when is MyWay', () => {
      beforeAll(() => {
        global.process.env = {
          ...env,
          REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY,
          REACT_APP_PORTAL_ENV_CODE: 'prod',
        };
      });

      let deleteAccount: HTMLElement;

      renderComponent(AccountPage, { ...props }, undefined, history);

      beforeEach(async () => {
        deleteAccount = screen.getByText('Delete my account');

        await act(async () => {
          fireEvent.click(deleteAccount);
        });
      });

      it('should redirect', async () => {
        expect(history.location.pathname).toBe('/account/delete');
      });
    });
  });

  describe('When hidePhoneNumber setupOption is not set - default state', () => {
    beforeEach(() => {
      (hidePhoneNumber as jest.Mock).mockReturnValue(false);
    });
    renderComponent(AccountPage, { ...props });

    it('it should display phone number field', () => {
      expect(screen.getByText('Mobile number')).toBeTruthy();
      expect(screen.getByText(props.mobile)).toBeTruthy();
    });
  });
});
