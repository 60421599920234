import { ionFireEvent as fireEvent } from '@ionic/react-test-utils';
import { screen, cleanup, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { APP_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultState as mockCoreDefaultState } from '../../../../modules/Core/reducers/coreReducer';
import { defaultState as mockSharedDefaultState } from '../../../../reducers/sharedReducer';

import TroubleLoggingInPage from './TroubleLoggingInPage';

import { feedbackTypes } from '@/modules/Feedback/config';

const mockDispatch = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) =>
    callback({
      Shared: mockSharedDefaultState,
      Core: mockCoreDefaultState,
    }),
  useDispatch: () => mockDispatch,
}));

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

const mockCreateFeedback = jest.fn();

jest.mock('../../../Feedback/api/api', () => ({
  useCreateFeedbackMutation: () => [mockCreateFeedback],
}));

describe('TroubleLoggingInPage', () => {
  const env = global.process.env;

  describe('when is MyWay', () => {
    const geographies = [{ name: 'Europe', code: 'EU' }];

    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let geography: any, email: any, phone: any, city: any, comment: any, company: any;

      renderComponent(TroubleLoggingInPage, { geographies });

      beforeEach(() => {
        geography = screen.getByTestId('region-select');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        phone = screen.getByTestId('trouble-logging-phone-hook-input-field');
        city = screen.getByTestId('trouble-logging-city-hook-input-field');
        comment = screen.getByTestId('trouble-logging-comment-hook-input-field');
        company = screen.getByTestId('trouble-logging-company-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(geography).toBeTruthy();
        expect(email).toBeTruthy();
        expect(phone).toBeTruthy();
        expect(city).toBeTruthy();
        expect(comment).toBeTruthy();
        expect(company).toBeTruthy();
      });
    });

    describe('on submit form without required fields', () => {
      let button: any;

      renderComponent(TroubleLoggingInPage, {
        geographies,
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expect(screen.getByText(/Email address is required/i)).toBeTruthy();
        expect(screen.getByText(/Mobile phone is required/i)).toBeTruthy();
        expect(screen.getByText(/Company is required/i)).toBeTruthy();
        expect(screen.getByText(/Comment is required/i)).toBeTruthy();

        expect(mockCreateFeedback).not.toHaveBeenCalled();
      });
    });

    describe('on submit form with all fields', () => {
      let geography: any,
        email: any,
        phone: any,
        company: any,
        city: any,
        comment: any,
        button: any;

      const dispatchSetGeography = jest.fn();

      renderComponent(TroubleLoggingInPage, {
        geographies: [{ name: 'Europe', code: 'EU' }],
        dispatchSetGeography,
      });

      beforeEach(async () => {
        geography = screen.getByTestId('region-select');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        phone = screen.getByTestId('trouble-logging-phone-hook-input-field');
        company = screen.getByTestId('trouble-logging-company-hook-input-field');
        city = screen.getByTestId('trouble-logging-city-hook-input-field');
        comment = screen.getByTestId('trouble-logging-comment-hook-input-field');

        await userEvent.selectOptions(geography, ['EU']);

        await userEvent.type(email, 'user@email.com');
        await userEvent.type(phone, '123456');
        await userEvent.type(company, 'Big Corp Inc.');
        await userEvent.type(city, 'Gotham');
        await userEvent.type(comment, 'I cant login');
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockCreateFeedback.mockResolvedValue({
          isSuccess: true,
        });
        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should create feedback', () => {
        const args = {
          comment:
            'Language: en-US, \nGeography: EU, \nE-mail: user@email.com, \nPhone: 123456, \nCompany: Big Corp Inc., \nCity / location name: Gotham, \nWhat went wrong: I cant login',
          title: 'I have trouble logging in',
          feedbackType: {
            id: feedbackTypes['Feedback type'],
            name: 'Feedback type',
          },
          feedbackDate: expect.any(String),
          withAccessToken: false,
        };

        expect(mockCreateFeedback).toHaveBeenCalledWith(expect.objectContaining(args));
      });
    });
  });

  describe('when is MyVillage', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYVILLAGE };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let fullName: any, email: any, whatVillage: any, employer: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(() => {
        fullName = screen.getByTestId('trouble-logging-full-name-hook-input-field');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        whatVillage = screen.getByTestId('trouble-logging-village-hook-input-field');
        employer = screen.getByTestId('trouble-logging-employer-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(fullName).toBeTruthy();
        expect(email).toBeTruthy();
        expect(whatVillage).toBeTruthy();
        expect(employer).toBeTruthy();
      });
    });

    describe('on submitting form without required fields', () => {
      let button: any;

      const createSupportEmail = jest.fn();

      renderComponent(TroubleLoggingInPage, { createSupportEmail });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expect(screen.getByText(/Email address is required/i)).toBeTruthy();
        expect(screen.getByText(/Full name is required/i)).toBeTruthy();

        expect(createSupportEmail).not.toHaveBeenCalled();
      });
    });

    describe('on submitting form with required fields', () => {
      let fullName: any, email: any, whatVillage: any, employer: any, button: any;

      const dispatchCreateSupportEmail = jest.fn();

      renderComponent(TroubleLoggingInPage, { dispatchCreateSupportEmail });

      beforeEach(async () => {
        fullName = screen.getByTestId('trouble-logging-full-name-hook-input-field');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        whatVillage = screen.getByTestId('trouble-logging-village-hook-input-field');
        employer = screen.getByTestId('trouble-logging-employer-hook-input-field');

        await userEvent.type(fullName, 'Clark Kent');
        await userEvent.type(email, 'clark.kent@superman.com');
        await userEvent.type(whatVillage, 'Smallville');
        await userEvent.type(employer, 'Justice League');
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        dispatchCreateSupportEmail.mockReturnValue(() => Promise.resolve({ ok: true }));
        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should create support request', () => {
        expect(dispatchCreateSupportEmail).toHaveBeenCalledWith({
          email: 'clark.kent@superman.com',
          employer: 'Justice League',
          fullName: 'Clark Kent',
          whatVillage: 'Smallville',
        });
      });
    });
  });
});
