import { act } from '@testing-library/react';
import momentjs from 'moment';

import {
  cartWithPortion,
  defaultCart,
  defaultCartMenuItem,
  defaultMenuItem,
  defaultModifier,
  defaultNutritions,
  defaultProductPortion,
  menuItemWithPortions,
  selectedProductPortion,
} from '../__mocks/mock';
import {
  addItemToCart,
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeItemsFromCart,
  updateItemInCart,
  upsertOrderDraft,
} from '../actions';
import { CartDifferentContextTypeEnum } from '../types/menuCartActions.types';
import { ModifierSelections } from '../types/productDetails.types';

import {
  buildCartModifiers,
  buildProductDetailsPath,
  buildModifierDisplayText,
  isCartAnotherContext,
  hasPortionsOrModifiers,
  decrementItem,
  increaseItem,
  redirectWhenPortionsOrModifiers,
  getMenuItemQuantity,
  hasMandatoryModifiers,
  getDefaultPortion,
  getCartMenuItems,
  increaseItemWithModifiersOrPortions,
  updateCartItem,
  buildProductTotalPrice,
} from './menuCartActions.helper';

import { toLocalDate } from '@/helpers/misc';

const selectedModifiers = [
  {
    displayText: 'Vegetable:  carrot: 1 lettuce: 1',
    itemIds: ['40472', '40473'],
    itemQuantities: { 40472: 1, 40473: 1 },
    modifierId: 14583,
  },
];

const modifierItem = {
  allergens: [],
  description: 'carrot',
  firstFree: 1,
  id: 40472,
  isChild: false,
  max: 2,
  maximumQuantity: 0,
  min: 0,
  name: 'carrot',
  nutritions: defaultNutritions,
  price: 4.8,
  priceLabel: '£0.00',
  sortOrder: 1,
};

const allModifiers = [
  {
    description: 'Vegetable',
    firstFree: 2,
    id: 14583,
    isRequired: false,
    max: 5,
    min: 0,
    name: 'Vegetable',
    selectionLimit: 5,
    type: 2,
    modifierItems: [
      modifierItem,
      {
        ...modifierItem,
        description: 'lettuce',
        id: 40473,
        name: 'lettuce',
        sortOrder: 2,
      },
      {
        ...modifierItem,
        description: 'spinach',
        firstFree: 0,
        id: 40475,
        name: 'spinach',
        sortOrder: 3,
      },
    ],
  },
];

const cartModifiers = [
  {
    displayText: 'Vegetable:  carrot: 1 lettuce: 1',
    firstFree: 2,
    modifierId: 14583,
    values: [
      {
        firstFree: 1,
        isFirstSelected: true,
        price: 4.8,
        quantity: 1,
        valueId: 40472,
      },
      {
        firstFree: 1,
        isFirstSelected: false,
        price: 4.8,
        quantity: 1,
        valueId: 40473,
      },
    ],
  },
];

const mockedCart = defaultCart;

jest.mock('../../../store', () => ({
  store: {
    getState: () => ({
      Order: { cart: mockedCart },
      Core: {
        context: {
          site: {
            id: 'xxx-xxx-xxx',
            name: 'Test Site',
          },
        },
      },
    }),
  },
}));

jest.mock('../actions', () => {
  return {
    upsertOrderDraft: jest.fn(),
    removeItemsFromCart: jest.fn(),
    decreaseCartItemQuantity: jest.fn(),
    addItemToCart: jest.fn(),
    increaseCartItemQuantity: jest.fn(),
    updateItemInCart: jest.fn(),
  };
});

const dispatch = jest.fn();

describe('buildCartModifiers', () => {
  it('should return cart modifiers', () => {
    const result = buildCartModifiers(selectedModifiers, allModifiers);
    expect(result).toEqual(cartModifiers);
  });
});

describe('decrementItem', () => {
  beforeEach(() => {
    (upsertOrderDraft as jest.Mock).mockResolvedValue({
      responseStatus: 200,
      responseData: {},
    });
    (removeItemsFromCart as jest.Mock).mockReturnValue({});
    (decreaseCartItemQuantity as jest.Mock).mockReturnValue({});
  });

  it('should remove item from cart, when quantity is 1', async () => {
    const cartMenuItems = [defaultCartMenuItem];

    await act(async () => {
      decrementItem({ cartMenuItems, dispatch });
    });

    expect(removeItemsFromCart).toHaveBeenCalled();
  });

  it('should decrease quantity, when quantity is > 1', async () => {
    const cartMenuItems = [{ ...defaultCartMenuItem, quantity: 3 }];

    await act(async () => {
      decrementItem({ cartMenuItems, dispatch });
    });

    expect(decreaseCartItemQuantity).toHaveBeenCalled();
  });
});

describe('redirectWhenPortionsOrModifiers', () => {
  it('should setPopup, when product is in cart', async () => {
    const props = {
      productQuantity: 5,
      redirectToProductDetails: jest.fn(),
      setPopup: jest.fn(),
      label: (s: string) => s,
      redirectToCart: jest.fn(),
    };

    await act(async () => {
      redirectWhenPortionsOrModifiers(props);
    });

    expect(props.setPopup).toHaveBeenCalled();
  });

  it('should redirect to product details, when not yet in cart', async () => {
    const props = {
      productQuantity: 0,
      redirectToProductDetails: jest.fn(),
      setPopup: jest.fn(),
      label: (s: string) => s,
      redirectToCart: jest.fn(),
    };

    await act(async () => {
      redirectWhenPortionsOrModifiers(props);
    });

    expect(props.redirectToProductDetails).toHaveBeenCalled();
  });
});

describe('getMenuItemQuantity', () => {
  it('should return quantity', () => {
    const cartMenuItems = [
      defaultCartMenuItem,
      {
        id: '',
        menuItemId: 2,
        uomId: 2,
        foodItemId: 2,
        img: '',
        name: '',
        price: 40,
        quantity: 2,
        description: '',
        isVegan: false,
        isVegetarian: false,
        genericCategory: '',
      },
    ];
    const quantity = getMenuItemQuantity(cartMenuItems);

    expect(quantity).toBe(3);
  });
});

describe('getCartMenuItems', () => {
  describe('when on cart page', () => {
    const cartMenuItemId = '12345';

    it('should return cart menu item, when item is in cart', () => {
      const menuItem = { ...defaultMenuItem, price: 20 };
      const cart = {
        ...defaultCart,
        menuPortionItems: [{ ...defaultCart.menuPortionItems?.[0]!, id: cartMenuItemId }],
      };
      const cartMenuItems = getCartMenuItems(menuItem, cart, cartMenuItemId);

      expect(cartMenuItems).toEqual([{ ...defaultCartMenuItem, id: cartMenuItemId }]);
    });
    it('should return [], when item is not in cart', () => {
      const menuItem = { ...defaultMenuItem, menuItemId: 2 };
      const cartMenuItems = getCartMenuItems(menuItem, defaultCart, cartMenuItemId);

      expect(cartMenuItems).toEqual([]);
    });
  });

  describe('when on product details page and item has multiple portions', () => {
    it('should return cart menu item, when selected portion is in cart', () => {
      const cartMenuItems = getCartMenuItems(
        menuItemWithPortions,
        cartWithPortion,
        undefined,
        selectedProductPortion,
        undefined,
        true,
        true
      );

      expect(cartMenuItems).toEqual([
        {
          ...defaultCartMenuItem,
          menuItemId: 2,
          uomId: 222,
          foodItemId: 2,
          price: 11,
          isVegan: true,
          isVegetarian: true,
        },
      ]);
    });

    it('should return [], when selected portion is not in cart', () => {
      const menuItem = { ...defaultMenuItem, menuItemId: 2 };
      const cartMenuItems = getCartMenuItems(
        menuItem,
        defaultCart,
        undefined,
        selectedProductPortion,
        undefined,
        true,
        true
      );

      expect(cartMenuItems).toEqual([]);
    });
  });

  describe('when on another page', () => {
    it('should return cart menu item, when item is in cart', () => {
      const menuItem = { ...defaultMenuItem, price: 20 };
      const cartMenuItems = getCartMenuItems(menuItem, defaultCart);

      expect(cartMenuItems).toEqual([defaultCartMenuItem]);
    });
    it('should return [], when item is not in cart', () => {
      const menuItem = { ...defaultMenuItem, menuItemId: 2 };
      const cartMenuItems = getCartMenuItems(menuItem, defaultCart);

      expect(cartMenuItems).toEqual([]);
    });
  });
});

describe('buildProductDetailsPath', () => {
  it('should return path to product details', () => {
    const today = new Date();
    const path = buildProductDetailsPath({
      menuId: '1',
      menuItemId: '2',
      facilityId: '3',
      date: today,
    });

    expect(path).toBe(`/order/product/3/1/${momentjs(today).format('YYYY-MM-DD')}/2/no-cart`);
  });
});

describe('increaseItem', () => {
  it('should add item to cart, when not there', async () => {
    await act(async () => {
      increaseItem({
        cartMenuItems: [],
        menuItem: defaultMenuItem,
        cartItemPrice: 1,
        onAddFirstItemToCart: () => {},
        dispatch: dispatch,
      });
    });

    expect(addItemToCart).toHaveBeenCalled();
  });

  it('should increase item, when in cart', async () => {
    await act(async () => {
      increaseItem({
        cartMenuItems: [defaultCartMenuItem],
        menuItem: defaultMenuItem,
        cartItemPrice: 1,
        onAddFirstItemToCart: () => {},
        dispatch: dispatch,
      });
    });

    expect(increaseCartItemQuantity).toHaveBeenCalled();
  });
});

describe('buildModifierDisplayText', () => {
  it('should return undefined, when no modifiers are selected', () => {
    const text = buildModifierDisplayText(undefined);

    expect(text).toBeUndefined();
  });

  it('should return string, when modifiers are selected', () => {
    const modifiers: ModifierSelections[] = [
      {
        displayText: 'Vegetable: carrot: 1 lettuce: 1',
        itemIds: ['40472', '40473'],
        itemQuantities: { 40472: 1, 40473: 1 },
        modifierId: 14583,
      },
      {
        displayText: 'Bread: baguette: 1',
        itemIds: ['11111'],
        itemQuantities: { 11111: 1 },
        modifierId: 12345,
      },
    ];
    const text = buildModifierDisplayText(modifiers);

    expect(text).toEqual('Vegetable: carrot: 1 lettuce: 1Bread: baguette: 1');
  });
});

describe('increaseItemWithModifiersOrPortions', () => {
  it('should increase item in cart, when portion is there', async () => {
    await act(async () => {
      increaseItemWithModifiersOrPortions({
        cartMenuItems: cartWithPortion.menuPortionItems,
        menuItem: menuItemWithPortions,
        selectedPortion: selectedProductPortion,
        selectedModifiers: [],
        cartItemPrice: 11,
        dispatch,
      });
    });

    expect(increaseCartItemQuantity).toHaveBeenCalledWith({
      menuItemId: cartWithPortion.menuPortionItems[0].id,
      dispatch,
    });
  });

  it('should add item to cart, when portion is not there', async () => {
    const selectedProductPortion = { ...defaultProductPortion, foodItemId: 2, uomId: 333 };

    await act(async () => {
      increaseItemWithModifiersOrPortions({
        cartMenuItems: cartWithPortion.menuPortionItems,
        menuItem: menuItemWithPortions,
        selectedPortion: selectedProductPortion,
        selectedModifiers: [],
        cartItemPrice: 11,
        dispatch,
      });
    });

    expect(addItemToCart).toHaveBeenCalled();
  });
});

describe('updateCartItem', () => {
  it('should update cart', async () => {
    const selectedProductPortion = { ...defaultProductPortion, foodItemId: 2, uomId: 333 };

    await act(async () => {
      updateCartItem({
        cartMenuItem: cartWithPortion.menuPortionItems[1],
        selectedPortion: selectedProductPortion,
        selectedModifiers: [],
        cartItemPrice: 11,
        dispatch,
        cart: cartWithPortion,
      });
    });

    const newCartItem = {
      ...cartWithPortion.menuPortionItems[1],
      price: 11,
      uomId: selectedProductPortion.uomId,
      modifiers: [],
      modifiersDisplayText: '',
    };

    expect(updateItemInCart).toHaveBeenCalledWith({ menuItem: newCartItem, dispatch });
  });
});

describe('buildProductTotalPrice', () => {
  it('should return price 0, when no portion', async () => {
    const result = buildProductTotalPrice({
      portion: undefined,
      selectedModifiers: [],
      languageCode: 'en-US',
      isoCode: 'USD',
      productQuantity: undefined,
      redeemedFoodItems: undefined,
    });

    expect(result).toEqual({ totalPrice: 0, totalPriceLabel: '$0.00' });
  });

  it('should return correct price for given portion and quantity', async () => {
    const result = buildProductTotalPrice({
      portion: defaultProductPortion,
      selectedModifiers: [],
      languageCode: 'en-US',
      isoCode: 'USD',
      productQuantity: 2,
      redeemedFoodItems: undefined,
    });

    expect(result).toEqual({ totalPrice: 22, totalPriceLabel: '$22.00' });
  });

  it('should return correct price for given portion, quantity and free modifier', async () => {
    const result = buildProductTotalPrice({
      portion: { ...defaultProductPortion, modifiers: [defaultModifier] },
      selectedModifiers: [{ itemIds: ['1'], itemQuantities: { 1: 2 }, modifierId: 16 }],
      languageCode: 'en-US',
      isoCode: 'USD',
      productQuantity: 2,
      redeemedFoodItems: undefined,
    });

    expect(result).toEqual({ totalPrice: 22, totalPriceLabel: '$22.00' });
  });

  it('should return correct price for given portion, quantity and modifier', async () => {
    const result = buildProductTotalPrice({
      portion: {
        ...defaultProductPortion,
        modifiers: [
          {
            ...defaultModifier,
            modifierItems: [{ ...defaultModifier.modifierItems[0], firstFree: 0 }],
          },
        ],
      },
      selectedModifiers: [{ itemIds: ['1'], itemQuantities: { 1: 2 }, modifierId: 16 }],
      languageCode: 'en-US',
      isoCode: 'USD',
      productQuantity: 2,
      redeemedFoodItems: undefined,
    });

    expect(result).toEqual({ totalPrice: 30, totalPriceLabel: '$30.00' });
  });

  it('should return correct price for given portion, quantity, modifier and redeemed item', async () => {
    const result = buildProductTotalPrice({
      portion: {
        ...defaultProductPortion,
        modifiers: [
          {
            ...defaultModifier,
            modifierItems: [{ ...defaultModifier.modifierItems[0], firstFree: 0 }],
          },
        ],
      },
      selectedModifiers: [{ itemIds: ['1'], itemQuantities: { 1: 2 }, modifierId: 16 }],
      languageCode: 'en-US',
      isoCode: 'USD',
      productQuantity: 2,
      redeemedFoodItems: [
        {
          loyaltySchemeId: 1,
          uomId: defaultProductPortion.uomId,
          foodItemId: defaultProductPortion.foodItemId,
          quantity: 1,
        },
      ],
    });

    expect(result).toEqual({ totalPrice: 15, totalPriceLabel: '$15.00' });
  });
});

describe('isCartAnotherContext', () => {
  it('should return isAnotherOrderContext = false, for is cart view', () => {
    const { isAnotherOrderContext, reason } = isCartAnotherContext({
      menuItem: defaultMenuItem,
      date: new Date(),
      menuId: 1,
      cart: defaultCart,
      isCartView: true,
    });

    expect(isAnotherOrderContext).toBeFalsy();
    expect(reason).toBeUndefined();
  });

  it('should return isAnotherOrderContext = false, for empty cart', () => {
    const { isAnotherOrderContext, reason } = isCartAnotherContext({
      menuItem: defaultMenuItem,
      date: new Date(),
      menuId: 1,
      cart: { ...defaultCart, menuPortionItems: [] },
      isCartView: false,
    });

    expect(isAnotherOrderContext).toBeFalsy();
    expect(reason).toBeUndefined();
  });

  it('should return reason = DifferentDate, when cart date and date are different', () => {
    const { isAnotherOrderContext, reason } = isCartAnotherContext({
      menuItem: defaultMenuItem,
      date: toLocalDate(new Date('2023-12-28')),
      menuId: 1,
      cart: { ...defaultCart, date: new Date('2023-12-29') },
      isCartView: false,
    });

    expect(isAnotherOrderContext).toBeTruthy();
    expect(reason).toBe(CartDifferentContextTypeEnum.DifferentDate);
  });

  it('should return reason = DifferentMenu, when dates cart menu and menu are different', () => {
    const date = new Date('2023-12-28');
    const { isAnotherOrderContext, reason } = isCartAnotherContext({
      menuItem: defaultMenuItem,
      date: toLocalDate(date),
      menuId: 1,
      cart: { ...defaultCart, date: date, menuId: 2 },
      isCartView: false,
    });

    expect(isAnotherOrderContext).toBeTruthy();
    expect(reason).toBe(CartDifferentContextTypeEnum.DifferentMenu);
  });

  it('should return reason = DifferentMoment, when dates cart moment and menuItem meal name are different', () => {
    const date = new Date('2023-12-28');
    const { isAnotherOrderContext, reason } = isCartAnotherContext({
      menuItem: { ...defaultMenuItem, mealName: 'AAA' },
      date: toLocalDate(date),
      menuId: 1,
      cart: { ...defaultCart, date: date, menuId: 1, moment: 'BBB' },
      isCartView: false,
    });

    expect(isAnotherOrderContext).toBeTruthy();
    expect(reason).toBe(CartDifferentContextTypeEnum.DifferentMoment);
  });
});

describe('getDefaultPortion', () => {
  it('should return default portion', () => {
    const menuItem = {
      ...defaultMenuItem,
      productPortions: [
        defaultProductPortion,
        { ...defaultProductPortion, isDefault: false, uomId: 222 },
      ],
    };
    const portion = getDefaultPortion(menuItem);

    expect(portion).toBe(defaultProductPortion);
  });
});

describe('hasPortionsOrModifiers', () => {
  it('should return false, when only one portion', () => {
    const menuItem = {
      ...defaultMenuItem,
      productPortions: [defaultProductPortion],
    };
    const result = hasPortionsOrModifiers(menuItem);

    expect(result).toBeFalsy();
  });

  it('should return true, when multiple portions', () => {
    const menuItem = {
      ...defaultMenuItem,
      productPortions: [
        defaultProductPortion,
        { ...defaultProductPortion, isDefault: false, uomId: 222 },
      ],
    };

    const result = hasPortionsOrModifiers(menuItem);

    expect(result).toBeTruthy();
  });

  it('should return true, when modifiers are present', () => {
    const menuItem = {
      ...defaultMenuItem,
      productPortions: [{ ...defaultProductPortion, modifiers: [defaultModifier] }],
    };

    const result = hasPortionsOrModifiers(menuItem);

    expect(result).toBeTruthy();
  });
});

describe('hasMandatoryModifiers', () => {
  it('should return true, when mandatory modifiers', () => {
    const portion = {
      ...defaultProductPortion,
      modifiers: [{ ...defaultModifier, min: 2 }],
    };
    const result = hasMandatoryModifiers(portion);

    expect(result).toBeTruthy();
  });

  it('should return false, when no mandatory modifiers', () => {
    const portion = {
      ...defaultProductPortion,
    };

    const result = hasMandatoryModifiers(portion);

    expect(result).toBeFalsy();
  });
});
