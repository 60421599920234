import { connect } from 'react-redux';

import { State } from '../../../types/state.types';
import { login, migrateOldBiteUser } from '../actions';
import PasswordChange from '../components/PasswordChange';
import withLang from '../context/withLang';

const mapStateToProps = (state: State) => {
  const userFullName = `${state.Core.userMigration?.firstName} ${state.Core?.userMigration?.lastName}`;
  const email = state.Core.userMigration?.email || '';
  const oldPassword = state.Core.userMigration?.oldPassword || '';
  const currentGeoCode =
    state.Core.context.currentGeoCode || state.Shared.geographies.defaultGeoCode;
  const currentLanguageCode = state.Shared.language.currentLanguageCode;
  const preferendLanguageId = state.Core.user?.preferredLanguage?.id;

  return {
    userFullName,
    email,
    oldPassword,
    currentGeoCode,
    currentLanguageCode,
    preferendLanguageId,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    migrateOldBiteUser: (
      email: string,
      password: string,
      newPassword: string,
      languageId: string
    ) => dispatch(migrateOldBiteUser({ email, password, newPassword, languageId })),
    login: (geoCode: string, username: string, psw: string, currentLanguageCode: string) =>
      dispatch(login({ geoCode, username, psw, currentLanguageCode, scope: 'offline_access' })),
  };
};

export default withLang([__filename])(connect(mapStateToProps, mapDispatchToProps)(PasswordChange));
