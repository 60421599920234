import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS, APP_ERRORS } from '../../../../constants';
import withLoginStatus from '../../../../context/withLoginStatus';
import { useSelectSiteMutation } from '../../../Sites/api/api';
import { getSiteByCode } from '../../actions';
import DefaultErrorPage from '../../components/GenericErrorPage/DefaultErrorPage';
import GenericErrorPage from '../../components/GenericErrorPage/GenericErrorPage';
import { pagePaths } from '../../config';
import withLang from '../../context/withLang';

import { SharePageProps, ActionType } from './SharePage.types';

const SharePage = ({ isLoggedIn: userIsLoggedIn, label }: SharePageProps): ReactElement => {
  const [selectSite] = useSelectSiteMutation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const [site, setSite] = useState<{ id: string; name: string }>();
  const [action, setAction] = useState<ActionType>(ActionType.LOADING);
  const { siteCode } = useParams<{ siteCode: string }>();

  const goToLogin = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE, siteCode);
    setAction(ActionType.GO_TO_LOGIN);
  }, [siteCode]);

  useEffect(
    function fetchSiteByCode() {
      (async () => {
        const { responseStatus, responseData } = await dispatch(getSiteByCode({ code: siteCode }));

        if (responseStatus === 401) {
          setAction(ActionType.GO_TO_LOGIN);
        } else if (responseStatus === 404) {
          setAction(ActionType.NOT_FOUND);
          return;
        } else if (responseStatus !== 200) {
          setAction(ActionType.REQUEST_ERROR);
          return;
        }

        if (userIsLoggedIn) {
          setSite(responseData.site);
        } else {
          goToLogin();
        }
      })();
    },
    [dispatch, goToLogin, siteCode, userIsLoggedIn]
  );

  useEffect(
    function setCurrentSite() {
      if (!site) {
        return;
      }

      (async () => {
        await selectSite({
          dispatch,
          site: {
            id: site.id,
            name: site.name,
            locationtype: 'Site',
          },
        });
        replace(pagePaths.Home);
      })();
    },
    [dispatch, replace, selectSite, site]
  );

  switch (action) {
    case ActionType.GO_TO_LOGIN:
      return (
        <Redirect
          to={{
            pathname: pagePaths.Login,
            state: { from: pagePaths.Share.replace(':siteCode', siteCode) },
          }}
        />
      );

    case ActionType.REQUEST_ERROR:
      return <DefaultErrorPage errorId={uuidv4()} />;

    case ActionType.NOT_FOUND:
      return (
        <GenericErrorPage
          withNavbar={userIsLoggedIn && userIsLoggedIn === true ? true : false}
          title={label('Something went wrong')}
          errors={[APP_ERRORS.SITE_LINK_INVALID]}
          actions={[
            {
              label: label('Ref: Select a site'),
              action: () => replace('/sites'),
              look: 'primary',
            },
          ]}
        />
      );

    default:
      return <LoadingPage />;
  }
};

export default withLoginStatus(withLang([__filename])(SharePage));
