import { Capacitor } from '@capacitor/core';
import { FC } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import withLoginStatus from '../../../../context/withLoginStatus';
import { isKiosk } from '../../../../helpers/misc';
import {
  getGuestRestrictedRoutes,
  legalDocType,
  pagePaths,
  pagePathsAvailableWithoutContract,
  pagePathsAvailableWithoutSiteContext,
} from '../../config';

import { PrivateRouteProps } from './PrivateRoute.types';

const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  isLoggedIn,
  termsOfUseToAcknowledge,
  privacyPolicyToAcknowledge,
  isFirstLogin,
  contractId,
  isUserContextReturned,
  isTokenRefreshing,
  shouldSelectSiteContext,
  refreshAccessToken,
  path,
  isGuest,
  ...rest
}) => {
  const history = useHistory();
  if (!isLoggedIn && !isTokenRefreshing) {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    if (refreshToken) {
      refreshAccessToken(refreshToken);
      return <LoadingPage />;
    }
  }

  const isGuestRestrictedRoute = () => {
    const restrictedRoutes = getGuestRestrictedRoutes();
    return isGuest && restrictedRoutes.includes(rest.location.pathname);
  };
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isTokenRefreshing || isUserContextReturned) {
          return <LoadingPage />;
        }

        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/access/login',
                state: { from: location },
              }}
            />
          );
        }

        if (isGuestRestrictedRoute())
          return (
            <Redirect to={{ pathname: pagePaths.GuestRegistration, state: { from: location } }} />
          );

        //specific case for legal docs
        if (rest.computedMatch.url === '/legal') {
          return children;
        }

        if (!isKiosk && termsOfUseToAcknowledge && privacyPolicyToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: '/access/legal',
                state: { from: location },
              }}
            />
          );
        }

        if (!isKiosk && Capacitor.isNativePlatform() && termsOfUseToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: pagePaths['LegalDoc']
                  .replace(':document_type', legalDocType.terms_of_use)
                  .replace(':version', 'current'),
                state: { from: location },
              }}
            />
          );
        }

        if (!isKiosk && privacyPolicyToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: pagePaths['LegalDoc']
                  .replace(':document_type', legalDocType.privacy_policy)
                  .replace(':version', 'current'),
                state: { from: location },
              }}
            />
          );
        }

        if (!contractId) {
          if (!pagePathsAvailableWithoutContract.includes(rest.computedMatch.url)) {
            return (
              <Redirect
                to={{
                  pathname: pagePaths['Onboarding'],
                  state: { from: location },
                }}
              />
            );
          }
        }

        if (
          shouldSelectSiteContext &&
          !pagePathsAvailableWithoutSiteContext.includes(rest.computedMatch.url)
        ) {
          return (
            <Redirect
              to={{
                pathname: pagePaths.Sites,
                state: { from: location },
              }}
            />
          );
        }
        const returnUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.RETURN_URL);

        if (returnUrl && location.pathname === returnUrl) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.RETURN_URL);
        } else if (returnUrl && location.pathname !== returnUrl) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.RETURN_URL);
          if (returnUrl !== '/' && returnUrl !== '/home') {
            // In Some modules will not display the full App Menu
            // The only option to navigate back is by clicking '<' button or Browser back
            // For the SSO scenarion the previous age is to aquire token
            // to allow an SSO user navigate back we insert a fake navigation
            history.push('/home');
            //redirect to new route
            return (
              <Redirect
                to={{
                  pathname: returnUrl,
                  state: { from: location },
                }}
              />
            );
          }
        }

        return children;
      }}
    />
  );
};

export default withLoginStatus(PrivateRoute);
