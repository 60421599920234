import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import ReactionButton from '../../atoms/ReactionButton';
import ReactionOptions from '../../molecules/ReactionOptions';

import { ReactionsProps, ReactionWrapperProps } from './Reactions.types';

import styles from './Reactions.module.css';

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
const Reactions: FC<ReactionWrapperProps> = ({
  children,
  reactions,
  className,
  handleReactionClick,
  tooltipId,
  alignRight,
  'data-testid': dataTestId = 'reactions',
}) => {
  const [showReactionsOptions, setShowReactionsOptions] = useState(false);

  let blurTimeout: ReturnType<typeof setTimeout>;

  const handleFocusOrMouseEnter = () => {
    clearTimeout(blurTimeout);
    setShowReactionsOptions(true);
  };

  const handleBlurOrMouseLeave = () => {
    blurTimeout = setTimeout(() => {
      setShowReactionsOptions(false);
    }, 0);
  };

  const reactionOptionsRef = useRef<HTMLDivElement>(null);
  const reactionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showReactionsOptions) {
      reactionOptionsRef.current?.focus();
    }
  }, [showReactionsOptions]);

  return (
    <div
      className={classNames(styles.wrapper, className)}
      onMouseEnter={handleFocusOrMouseEnter}
      onMouseLeave={handleBlurOrMouseLeave}
      onFocus={handleFocusOrMouseEnter}
      onBlur={handleBlurOrMouseLeave}
      data-testid={dataTestId}
      tabIndex={0}
      aria-expanded={showReactionsOptions}
      ref={reactionsRef}
    >
      <div
        ref={reactionOptionsRef}
        className={classNames(styles.options, alignRight ? styles.right : styles.left)}
        aria-expanded={showReactionsOptions}
        style={{
          display: 'block',
          opacity: showReactionsOptions ? 1 : 0,
        }}
      >
        <ReactionOptions
          tooltipId={tooltipId}
          reactions={reactions}
          handleReactionClick={handleReactionClick}
        />
      </div>
      {children}
    </div>
  );
};
Reactions.displayName = 'Reactions';

const DetailReactions: FC<ReactionsProps> = (props) => {
  const { label } = useCustomTranslation();
  const ariaLabel = props.userReaction
    ? `${props.userReaction} ${label('Ref: reaction. Change reaction')}`
    : `${label('Ref: No reaction. React')}`;

  return (
    <Reactions {...props}>
      <ReactionButton
        userReaction={props.userReaction}
        mainButtonValues={props.mainButtonValues}
        data-testid={`${props['data-testid']}-detail-reaction`}
        aria-label={ariaLabel}
        total={
          props.userReaction
            ? props.reactions.find((reaction) => reaction.type === props.userReaction)?.count
            : undefined
        }
      />
    </Reactions>
  );
};
DetailReactions.displayName = 'Reactions.Detail';

const ListReactions: FC<ReactionsProps> = (props) => {
  const { label } = useCustomTranslation();
  const ariaLabel = props.userReaction
    ? `${props.userReaction} ${label('Ref: reaction. Change reaction')}`
    : `${label('Ref: No reaction. React')}`;

  return (
    <Reactions {...props} className={classNames(props.className, styles.inList)}>
      <ReactionButton
        userReaction={props.userReaction}
        mainButtonValues={props.mainButtonValues}
        data-testid={`${props['data-testid']}-list-reaction`}
        aria-label={ariaLabel}
        hideLabel
      />
    </Reactions>
  );
};
ListReactions.displayName = 'Reactions.List';

export default Object.assign(Reactions, {
  Detail: DetailReactions,
  List: ListReactions,
});
