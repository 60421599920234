import { connect } from 'react-redux';

import { State } from '../../../../../types/state.types';
import { sendForbiddenEmail } from '../../../actions';
import { ForbiddenEmailRequestType } from '../../../types';
import PasswordForgotten from '../PasswordForgotten';

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchSendForbiddenEmail: (request: ForbiddenEmailRequestType) => {
      return dispatch(sendForbiddenEmail(request));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgotten);
