import { pagePaths } from '../../config';
import { useServiceRequestLightTranslation } from '../../hooks/useServiceRequestLightTranslation';

import { StepperCreateProps } from './StepperCreate.types';

import Stepper from '@/components/atoms/Stepper/Stepper';

const StepperCreate = ({ horizontal, stepIndex }: StepperCreateProps) => {
  const { label } = useServiceRequestLightTranslation(__filename);
  const steps = [
    {
      index: 0,
      title: label('Ref: ServiceRequestLightTitle'),
      current: stepIndex === 0,
      path: pagePaths.Create,
      label,
    },
    {
      index: 1,
      title: label('Ref: Summary'),
      current: stepIndex === 1,
      path: pagePaths.Summary,
      label,
    },
  ];

  return <Stepper horizontalOnly={horizontal} steps={steps} />;
};

export default StepperCreate;
