import { useMemo } from 'react';

import { SuggestedMenuItem } from '../../types/orderState.types';

import { defaultDropdownOption } from './SuggestionsWidget.helper';

import { LabelFunc } from '@/context/withLang';

export const useMenuBasedSuggestions = (
  selectedValue: string,
  suggestionsWithAvailableMenus: SuggestedMenuItem[],
  label: LabelFunc
) => {
  return useMemo(() => {
    if (selectedValue === defaultDropdownOption(label).value) {
      return suggestionsWithAvailableMenus;
    } else {
      return suggestionsWithAvailableMenus.filter(
        (item) => item.menuId.toString() === selectedValue
      );
    }
  }, [selectedValue, suggestionsWithAvailableMenus, label]);
};
