import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { useGetSitesQuery, useSelectSiteMutation } from '../api/api';
import { MAP_ZOOM_COVERAGE_IN_MILES, canHidePublicSitesLocation } from '../config';
import { getMarkersFromSites } from '../helpers/sitesHelper';
import { useSiteTranslation } from '../hooks/useSiteTranslation';
import { ISite } from '../types/sites.types';

import { FacilitiesIllustration } from '@/assets/illustrations/index';
import Button from '@/components/atoms/Button';
import { getZoomForPxByMilesForLat } from '@/components/atoms/Map/helpers';
import Map from '@/components/atoms/Map/Map';
import {
  siteActiveGeneratedIcon,
  siteInactiveGeneratedIcon,
} from '@/components/atoms/Map/mapIconsGenerator';
import { MarkDownContent } from '@/components/atoms/RenderContent';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ContentTitle from '@/components/molecules/ContentTitle/ContentTitle';
import ContentDetailsPage from '@/components/templates/ContentDetailsPage/ContentDetailsPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import { getImageSrcFromId } from '@/helpers/images/images.helper';
import { prepareAddress } from '@/helpers/misc';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useLoginStatus from '@/modules/Core/hooks/useLoginStatus';

import styles from './siteDetails.module.css';

const SiteDetails = () => {
  const [site, setSite] = useState<ISite | undefined>(undefined);
  const params = useParams<Pick<ISite, 'id'>>();
  const { data: allSites = [] } = useGetSitesQuery({});
  const { isSSOUser } = useLoginStatus();

  const { currentLanguageCode } = useLanguage();
  const [selectSite] = useSelectSiteMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (allSites.length) {
      setSite(allSites.find((item) => item.id === params.id));
    }
  }, [allSites, params]);

  const inactiveMarkerIcon = siteInactiveGeneratedIcon();
  const activeMarkerIcon = siteActiveGeneratedIcon();
  const { replace } = useHistory();
  const { state } = useLocation<{ onSwitchSitePath: undefined }>();
  const [mainImage, setMainImage] = useState<string | undefined>();
  const [siteIsLoading, setSiteIsLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { hidePublicSitesLocation } = canHidePublicSitesLocation();
  const location = useLocation();
  const { label } = useSiteTranslation(__filename);

  const markers =
    site &&
    getMarkersFromSites({
      sites: [site],
      selectedSiteId: site?.id,
      inactiveMarkerIcon,
      activeMarkerIcon,
    });

  const getImage = async (imageId: string | undefined) => {
    if (!imageId) return;

    const image = await getImageSrcFromId(imageId);
    if (image) {
      setMainImage(image);
    }
  };
  const renderOnSSORestriction = useCallback(() => {
    if (isSSOUser && hidePublicSitesLocation && (site?.siteAccessRestrictionLevelId || 0) === 1) {
      replace({
        pathname: '/error',
        state: { from: location, errorBody: label('Ref: Unavailable site') },
      });
    }
  }, [
    hidePublicSitesLocation,
    replace,
    isSSOUser,
    label,
    location,
    site?.siteAccessRestrictionLevelId,
  ]);

  renderOnSSORestriction();
  useEffect(() => {
    //refresh the main image if need be
    getImage(site?.images?.[0]);
  }, [site?.images]);

  const onSiteSelection = useCallback(async () => {
    if (site) {
      setSiteIsLoading(true);
      await selectSite({ site: site, dispatch });
      replace(state?.onSwitchSitePath ?? '/');
    }
  }, [replace, dispatch, site, state?.onSwitchSitePath, selectSite]);

  const getTitle = useCallback(
    (className: string) => (
      <ContentTitle
        data-testid="content-tile"
        title={
          <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
            {site?.name}
          </Title>
        }
        className={className}
      />
    ),
    [site?.name]
  );

  const zoom = site?.geoCoordinates
    ? getZoomForPxByMilesForLat({
        lat: site?.geoCoordinates.latitude,
        milesToShow: MAP_ZOOM_COVERAGE_IN_MILES,
        totalPxWidth: window.innerWidth,
      })
    : 1;

  if (!site) return <LoadingPage />;

  const preparedAddress = site.address
    ? prepareAddress({
        line1: site.address.line1,
        line2: site.address.line2,
        line3: site.address.line3,
        city: site.address.city,
        stateOrProvince: site.address.stateOrProvince,
        postalCode: site.address.postalCode,
        country: site.address.country,
      })
    : [];

  const address = preparedAddress.length ? (
    <div className={styles.address}>
      {preparedAddress.map((elem) => (
        <Title key={elem} size={TITLE_SIZE.BODYSBOLD}>
          {elem}
        </Title>
      ))}
    </div>
  ) : null;

  const map = site.geoCoordinates ? (
    <div className={styles.map}>
      <Map
        data-testid="site-details"
        zoom={zoom}
        positionCoordinates={site.geoCoordinates}
        center={site.geoCoordinates ?? site.geoCoordinates}
        markers={markers}
      />
    </div>
  ) : null;

  const geoElement =
    map || address ? (
      <Card className={styles.geoCard}>
        <div className={styles.mapAddressContainer}>
          {map}
          {address}
        </div>
      </Card>
    ) : null;

  return (
    <ContentDetailsPage.WithSideBottomSection
      title={label('Ref: Page title')}
      sideBottomSection={geoElement}
      header={{
        imageNode: !mainImage && <FacilitiesIllustration />,
        imageSrc: mainImage,
        imageAlt: site.name,
        children: getTitle(styles.titleSide),
      }}
      actions={
        <Button
          data-testid="site-details-select-site"
          onClick={onSiteSelection}
          loading={siteIsLoading}
        >
          {label('Select site')}
        </Button>
      }
    >
      {getTitle(styles.titleMain)}
      <Card>
        {site.additionalInfo ? (
          <MarkDownContent
            data-testid="site-details-markdown-content"
            language={currentLanguageCode}
            ref={contentRef}
          >
            {site.additionalInfo?.markdown}
          </MarkDownContent>
        ) : (
          <Title size={TITLE_SIZE.BODYSDEFAULT} className={styles.italicText}>
            {label('Ref: Sorry, no description yet')}
          </Title>
        )}
      </Card>
    </ContentDetailsPage.WithSideBottomSection>
  );
};

export default SiteDetails;
