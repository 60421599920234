import { Capacitor } from '@capacitor/core';
import { useCallback, useEffect } from 'react';

import { WithLangProps } from '../../../context/withLang';
import { hasCameraPermission } from '../../../helpers/camera';
import useToggle from '../../../helpers/hooks/useToggle';
import Button, { ButtonProps } from '../../atoms/Button';
import { Alert } from '../Alert';

import BrowserQrCode from './BrowserQrCode';
import NativeQrCode from './NativeQrCode';
import { QRCodeReaderResponse } from './reader.types';

const isNativePlatform = Capacitor.isNativePlatform();

type QrCodeReaderProps = WithLangProps & {
  title: string;
  buttonProps: ButtonProps;
  children?: React.ReactNode;
  onQrCodeScan: (response: QRCodeReaderResponse) => void;
  validateQrCodeScan?: (response: QRCodeReaderResponse) => boolean;
  isScanOpen?: boolean;
  onOpen?: () => void;
  onDismiss?: () => void;
  preventCloseAfterScan?: boolean;
  actions?: React.ReactNode[];
  timeBetweenScans?: number;
  buttonLabel?: string;
  hideScanButton?: boolean;
  componentIdToScroll?: string;
};

const QrCodeReader = ({
  label,
  title,
  children,
  onQrCodeScan,
  validateQrCodeScan,
  buttonProps,
  isScanOpen,
  onOpen,
  onDismiss,
  preventCloseAfterScan,
  actions = [],
  timeBetweenScans,
  buttonLabel,
  hideScanButton = false,
  componentIdToScroll,
}: QrCodeReaderProps) => {
  const {
    state: isCameraAlertOpen,
    toggleOn: openCameraAlert,
    toggleOff: closeCameraAlert,
  } = useToggle(false);

  const { state: isScanStarted, toggleOn: turnOnScan, toggleOff: turnOffScan } = useToggle(false);

  useEffect(() => {
    const openScanner = async () => {
      const hasPermission = await hasCameraPermission();
      if (hasPermission) {
        turnOnScan();
      }
    };

    if (isScanOpen) openScanner();
  }, [isScanOpen, turnOnScan]);

  const onScanButtonClick = useCallback(async () => {
    const hasPermission = await hasCameraPermission();
    if (hasPermission) {
      (onOpen && onOpen()) || turnOnScan();
    } else {
      openCameraAlert();
    }
  }, [onOpen, openCameraAlert, turnOnScan]);

  const handleStopScan = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
    componentIdToScroll && document.getElementById(componentIdToScroll)?.scrollIntoView();
    return turnOffScan();
  }, [componentIdToScroll, onDismiss, turnOffScan]);

  const onCodeScan = useCallback(
    (response: QRCodeReaderResponse) => {
      if ((validateQrCodeScan && validateQrCodeScan(response)) || !validateQrCodeScan) {
        onQrCodeScan(response);
      }
    },
    [validateQrCodeScan, onQrCodeScan]
  );

  return (
    <div className="qrContainer">
      {!hideScanButton && (
        <Button {...buttonProps} onClick={onScanButtonClick} data-testid="qr-code-reader-hide-scan">
          {buttonLabel}
        </Button>
      )}
      {isNativePlatform ? (
        <NativeQrCode
          label={label}
          isScanStarted={isScanStarted}
          stopScan={handleStopScan}
          onQrCodeScan={onCodeScan}
          preventCloseAfterScan={preventCloseAfterScan}
          actions={actions}
          timeBetweenScans={timeBetweenScans}
        >
          {children}
        </NativeQrCode>
      ) : (
        <BrowserQrCode
          title={title}
          hideModal={handleStopScan}
          isModalOpen={isScanStarted}
          onQrCodeScan={onCodeScan}
          preventCloseAfterScan={preventCloseAfterScan}
          actions={actions}
          timeBetweenScans={timeBetweenScans}
        >
          {children}
        </BrowserQrCode>
      )}
      <Alert
        isOpen={isCameraAlertOpen}
        onDismiss={closeCameraAlert}
        className="popup-warning"
        header={label('Ref: camera access request title')}
        message={label('Ref: camera access request body')}
        buttons={[label('Ref: ok')]}
        data-testid="qr-code-reader-camera-alert"
      />
    </div>
  );
};

export default QrCodeReader;
