import classNames from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ShoppingBagIcon } from '../../../assets';
import { PaymentStatusIcon } from '../../../assets/icons';
import ImageWithFallback from '../../../components/atoms/ImageWithFallback/ImageWithFallback';
import Title, { TITLE_TAG, TITLE_SIZE } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import { Tile } from '../../../components/molecules/Tile';
import Column from '../../../components/organisms/Column';
import Container from '../../../components/organisms/Container';
import TilesList from '../../../components/organisms/TilesList/TilesList';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { isValidHttpUrl } from '../../../helpers/urlValidator';
import { useDownloadReceiptMutation, useGetOrderDetailsQuery } from '../api';
import { getPrice } from '../helpers/order.helper';
import { buildVegeChip } from '../helpers/productTile.helpers';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import { OrderInfo } from '../types/order.summary.types';
import { OrderDetailsTile } from '../types/orderHistory.types';
import { OrderDetailsItem } from '../types/orderState.types';

import OrderInfoCard from './OrderInfoCard';
import OrderSummary from './OrderSummary/OrderSummary';
import { getProductIllustration } from './ProductsList/productList.helper';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import DismissButton from '@/components/atoms/DismissButton/DismissButton';
import toast from '@/components/atoms/ModalToast';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { downloadBase64asFile } from '@/helpers/file';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import GenericErrorPage from '@/modules/Core/components/GenericErrorPage/GenericErrorPage';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { defaultState as facilityDefaultState } from '@/modules/Facilities/reducers/facilitiesReducer';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

import styles from './OrderHistoryDetails.module.css';

import { TestingPropsV2 } from 'src/types';

const OrderHistoryDetails = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const { label } = useOrderTranslation(__filename);
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const {
    data: order,
    isError,
    isLoading,
  } = useGetOrderDetailsQuery({ siteId: site.id, orderId: +orderId });
  const { logUserSteps } = useUserStepsInsightsLogging();
  const facilities = useSelector((state: State) => state.Facilities || facilityDefaultState);
  const facilityName = order ? facilities.data[order?.facilityId ?? '']?.title : '';
  const [downloadReceipt, { isLoading: downloadreceiptIsLoading }] = useDownloadReceiptMutation();
  const { currentLanguageCode } = useLanguage();

  useEffect(() => {
    if (!!order?.facilityId && !!facilityName) {
      logUserSteps({
        event: UserSteps.OrderHistoryDetails,
        facilityId: order?.facilityId,
        facilityName,
      });
    }
  }, [logUserSteps, order?.facilityId, facilityName]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError || !order) {
    return <GenericErrorPage />;
  }

  const isoCode = site.currency?.isoCode ?? '';
  const orderInfo: OrderInfo = {
    facilityName,
    ...order,
    instructions: order.instructions,
    phoneNumber: order.phoneNumber,
  };

  const orderItems: OrderDetailsTile[] | undefined = order.orderItems?.map((item, index) => {
    const vegeChip = buildVegeChip({
      isVegan: item.isVegan,
      isVegetarian: item.isVegetarian,
      veganLabel: label('Ref: Vegan'),
      vegeterianLabel: label('Ref: Vegeterian'),
    });

    const finalPrice = item.price - item.promotionDiscount;

    let itemModifiers = item.modifiers
      ?.map((modifier) => {
        const valueStr = modifier.values
          .map((value) => `${value.quantity}x ${value.name}`)
          .join(', ');
        return `${modifier.name}: ${valueStr}`;
      })
      .join('; ');
    itemModifiers = itemModifiers ? `${itemModifiers} - ` : '';

    return {
      id: index,
      title: `${item.quantity}x ${item.name}`,
      description: `${itemModifiers} ${label('Ref: Unit price')} ${getPrice(
        finalPrice,
        currentLanguageCode,
        isoCode ?? ''
      )}`,
      childText: getPrice(finalPrice * item.quantity, currentLanguageCode, isoCode ?? ''),
      strikethroughChildText:
        item.promotionDiscount > 0
          ? getPrice(item.price * item.quantity, currentLanguageCode, isoCode ?? '')
          : undefined,
      chips: vegeChip ? [vegeChip] : [],
      imageUrl: item.imageUrl,
      genericCategory: item?.genericCategory ?? '',
    };
  });

  const getTotalItemsInOrder = (orderItems: OrderDetailsItem[] | undefined) => {
    if (!!orderItems) {
      return orderItems.reduce((totalItem, menuItem) => totalItem + menuItem.quantity, 0);
    }

    return 0;
  };

  const getImageForOrder = (item: OrderDetailsTile) => {
    const imgInfo =
      !!item.imageUrl && isValidHttpUrl(item.imageUrl)
        ? {
            src: item.imageUrl,
            alt: item.description,
          }
        : undefined;

    return (
      <ImageWithFallback
        data-testid="order-history-details"
        imgInfo={imgInfo}
        imgElement={getProductIllustration(item.genericCategory as string)}
      />
    );
  };

  const orderTitle = ['#', order.orderId.toString(), facilityName ? ' - ' + facilityName : ''].join(
    ''
  );
  const orderItemsWithDataTestId = orderItems?.map((item) => ({
    ...item,
    'data-testid': `order-item-${item.id}`,
  }));

  const downloadHandler = async () => {
    try {
      const result = (await downloadReceipt({ siteId: site.id, orderId: order.orderId })) as {
        data: { content: string };
      };
      const content = result.data.content;
      const filename = `${orderTitle}.pdf`;
      downloadBase64asFile(content, filename);
    } catch {
      toast.error(label('Ref: Download error'), {
        position: 'top-center',
        closeOnClick: true,
        type: 'error',
        closeButton: <DismissButton data-testid="download-error-dismiss-btn" label={label} />,
        autoClose: false,
        hideProgressBar: true,
      });
    }
  };

  return (
    <SimpleFormPage title={label('Ref: Order details title')} tabTitle={orderTitle}>
      <Container className={styles.containerFlex}>
        <Column.Main className={styles.columnMainGap}>
          <div>
            <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className={styles.title}>
              {orderTitle}
            </Title>
            <div className={styles.subheader}>
              <div className={styles.group}>
                <ShoppingBagIcon />
                <Title tag={TITLE_TAG.H6} size={TITLE_SIZE.BODYMBOLD}>
                  {order.status ? label(`Ref: ${order.status}`) : ''}
                </Title>
              </div>
              {!order.isPaid ? (
                <div className={classNames(styles.group, styles.notPaid)}>
                  <PaymentStatusIcon />
                  <Title tag={TITLE_TAG.H6} size={TITLE_SIZE.BODYMBOLD}>
                    {label('Ref: Not Paid')}
                  </Title>
                </div>
              ) : null}
            </div>
          </div>
          <Card>
            <div className="mb-M">
              <OrderSummary
                total={order.totalAmount}
                subTotal={order.subTotal}
                delivery={order.delivery}
                deliveryOptionType={order.deliveryOptionType}
                tax={order.tax ?? 0}
                subsidy={order.subsidy}
                promotionDiscount={order.promotionDiscount}
                appliedPromotions={order.appliedPromotions}
                productsCount={getTotalItemsInOrder(order.orderItems)}
              />
            </div>
            <TilesList
              items={orderItemsWithDataTestId}
              itemRenderer={(item: OrderDetailsTile & TestingPropsV2) => {
                return (
                  <Tile
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    childText={item.childText}
                    strikethroughChildText={item.strikethroughChildText}
                    chips={item.chips}
                    children={<></>}
                    data-testid={`order-history-tile-${item.id}`}
                    image={getImageForOrder(item)}
                  />
                );
              }}
            />
          </Card>
        </Column.Main>
        <Column.Side>
          <OrderInfoCard currentLanguageCode={currentLanguageCode} {...orderInfo} />
          <ActionsBar>
            <Button
              fullWidth
              data-testid="download-receipt-button"
              look={BUTTON_LOOK.PRIMARY}
              onClick={downloadHandler}
              loading={downloadreceiptIsLoading}
              disabled={downloadreceiptIsLoading}
            >
              {label('Ref: Download')}
            </Button>
          </ActionsBar>
        </Column.Side>
      </Container>
    </SimpleFormPage>
  );
};

export default OrderHistoryDetails;
