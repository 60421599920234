import { useCallback, useMemo } from 'react';

import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import { LabelFunc } from '../../../../context/withLang';
import { prepareAddress } from '../../../../helpers/misc';
import { ContentPageDetail } from '../../types';

import styles from './MapAddress.module.css';

export default function MapAddress({
  contentPage,
  mapImage,
  label,
}: {
  contentPage: ContentPageDetail;
  mapImage?: string;
  label: LabelFunc;
}) {
  const stdAddress = useMemo(
    () => ({
      line1: contentPage.addressLine1,
      line2: contentPage.addressLine2,
      line3: contentPage.addressLine3,
      city: contentPage.addressCity,
      stateOrProvince: contentPage.addressState,
      postalCode: contentPage.addressPostalCode,
      country: contentPage.addressCountry,
    }),
    [
      contentPage.addressCity,
      contentPage.addressCountry,
      contentPage.addressLine1,
      contentPage.addressLine2,
      contentPage.addressLine3,
      contentPage.addressPostalCode,
      contentPage.addressState,
    ]
  );

  const preparedAddress = useMemo(() => prepareAddress(stdAddress), [stdAddress]);

  const stringifiedAddress = useMemo(
    () => (preparedAddress.length ? preparedAddress.join(', ') : null),
    [preparedAddress]
  );

  const renderGeoContent = useCallback(() => {
    const hasLocation = stdAddress.line1 || stdAddress.line2 || stdAddress.line3;

    const map = mapImage ? <img src={mapImage} data-testid="map-image" alt={label('map')} /> : null;

    const children = (
      <div className={styles.mapAddressContainer}>
        {map}
        {preparedAddress.length ? (
          <div>
            {preparedAddress.map((elem, index) => (
              <Title key={index} size={TITLE_SIZE.BODYSBOLD}>
                {elem}
              </Title>
            ))}
          </div>
        ) : null}
      </div>
    );

    if (hasLocation) {
      return (
        <a
          href={
            'https://maps.apple.com/?z=17&address=' + stringifiedAddress + '&q=' + contentPage.name
          }
          data-testid="map-address-link"
        >
          {children}
        </a>
      );
    }

    if (map) {
      return children;
    }

    return <></>;
  }, [
    contentPage.name,
    label,
    mapImage,
    preparedAddress,
    stdAddress.line1,
    stdAddress.line2,
    stdAddress.line3,
    stringifiedAddress,
  ]);

  const geoElement = useMemo(() => renderGeoContent(), [renderGeoContent]);

  if (geoElement) {
    return (
      <Card data-testid="geo-card" className={styles.geoCard}>
        {geoElement}
      </Card>
    );
  }

  return <></>;
}
