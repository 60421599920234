import { LabelFunc } from '../../../../context/withLang';

import { OpeningHoursType, WeekDayType } from './openingHours.types';

export const SORTED_WEEKDAYS = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
};

export const getFirstWeekDay = (arrDays?: WeekDayType[]) => {
  if (!arrDays?.length) return -1;
  const lowerBound = 7;

  return Math.min(lowerBound, ...arrDays.map((day) => SORTED_WEEKDAYS[day]));
};

export const sortByFirstWeekDay = (a: OpeningHoursType, b: OpeningHoursType) =>
  getFirstWeekDay(a.day) - getFirstWeekDay(b.day);

export const getOpeningHourValue = (openingHour: OpeningHoursType, label: LabelFunc) => {
  if (openingHour.openTime) {
    return [openingHour.openTime, openingHour.closeTime].join('-');
  }
  if (openingHour.isOpenAllDay) {
    return label('open all day');
  }
};

export const getOpeningHourLabel = (openingHours: OpeningHoursType, label: LabelFunc) => {
  if (!openingHours.day?.length) return label('everyday', { textTransform: 'capitalize' });
  return [...openingHours.day]
    .sort((a, b) => SORTED_WEEKDAYS[a] - SORTED_WEEKDAYS[b])
    .map((day) => label(day))
    .join(', ');
};

export const getOpeningHoursItems = (openingHours: OpeningHoursType[], label: LabelFunc) => {
  return [...openingHours].sort(sortByFirstWeekDay).map((openingHours, index) => ({
    id: index.toString(),
    label: getOpeningHourLabel(openingHours, label),
    value: getOpeningHourValue(openingHours, label),
    'data-testid': `opening-hours-item-${index}`,
  }));
};
