import moment from 'moment';

import { pagePaths } from '../config/default';

import { formatDate } from '@/helpers/misc';

const findNextEventDateCommon = (
  eventDates: string[],
  currentLanguageCode: string,
  formatter?: (date: Date, languageCode: string) => string | undefined
): Date | string | undefined => {
  if (!eventDates) return undefined;

  const today = moment();
  for (const eventDateString of eventDates) {
    const eventDate = moment(eventDateString);

    if (eventDate.isSame(today, 'day') || eventDate.isAfter(today)) {
      if (formatter) {
        return formatter(new Date(eventDateString), currentLanguageCode);
      } else {
        return new Date(eventDateString);
      }
    }
  }
  return formatter
    ? formatter(new Date(eventDates[eventDates.length - 1]), currentLanguageCode)
    : new Date(eventDates[eventDates.length - 1]);
};

export const findNextEventDateString = (
  eventDates: string[],
  currentLanguageCode: string
): string | undefined => {
  return findNextEventDateCommon(eventDates, currentLanguageCode, formatDate) as string | undefined;
};

export const findNextEventDate = (
  eventDates: string[],
  currentLanguageCode: string
): Date | undefined => {
  return findNextEventDateCommon(eventDates, currentLanguageCode) as Date | undefined;
};

export const getEventDetailsLink = (eventPieceId: string) =>
  pagePaths['EventDetails'].replace(':id', eventPieceId);
