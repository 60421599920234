import { WithLangProps } from '../../../context/withLang';

export type AccountValidationProps = WithLangProps & {
  firstName: string;
  lastName: string;
};

export enum REDIRECTED_FROM {
  LOGIN = 'LoginPage',
  PASSWORD_CHANGE = 'PasswordChange',
}
