import classNames from 'classnames';
import React from 'react';

import { replaceSizeWithViewBoxInSVG } from '../../../helpers/image';
import { serviceTypes } from '../../../modules/config';

import { ItemIconProps } from './navBar.types';

const ItemIcon = ({
  service,
  customIcons,
  withoutWrapper = false,
  className = 'nav-item-icon',
}: ItemIconProps) => {
  const itemIcon = customIcons[service.navigation.name];

  if (itemIcon) {
    return (
      <div
        className={classNames(className)}
        dangerouslySetInnerHTML={{ __html: replaceSizeWithViewBoxInSVG(itemIcon) }}
      />
    );
  }

  if (serviceTypes[service.name].icon) {
    if (withoutWrapper) {
      return serviceTypes[service.name].icon || <></>;
    }

    return <div className={classNames(className)}>{serviceTypes[service.name].icon}</div>;
  }

  return <></>;
};

export default ItemIcon;
