import { FC, useState } from 'react';

import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import Title, { TITLE_TAG, TITLE_SIZE } from '../../../../components/atoms/Title';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import SurveyQuestion from '../../../Surveys/components/SurveyQuestion';
import { QUESTION_TYPES } from '../../../Surveys/config';
import { ResponseMultipleValue, ResponseSingleValue } from '../../../Surveys/types/survey.types';
import { communicationPreferencesCodes } from '../../config';
import withLang from '../../context/withLang';

import { CommunicationPreferencesPageProps } from './CommunicationPreferencesPage.types';

const CommunicationPreferencesPage: FC<CommunicationPreferencesPageProps> = ({
  updateCommunicationPreferences,
  history,
  label,
  allowContent = '',
  allowOffers = '',
}) => {
  const [response, setReponse] = useState({ allowContent, allowOffers });
  const [isLoading, setLoading] = useState(false);

  const handleResponse = (id: string, value: ResponseMultipleValue[] | ResponseSingleValue) => {
    setReponse({ ...response, [id]: value });
  };

  const answers = [
    { id: communicationPreferencesCodes?.allow, name: label('Yes') },
    { id: communicationPreferencesCodes?.reject, name: label('No') },
  ];

  const preferences = [
    {
      id: 'allowOffers',
      questionText: label('Ref: Would you like to receive update about offers and promotions?'),
      questionType: QUESTION_TYPES.SINGLE_CHOICE,
      answers: answers,
    },
    {
      id: 'allowContent',
      questionText: label('Ref: Would you like to hear about content we think you may like?'),
      questionType: QUESTION_TYPES.SINGLE_CHOICE,
      answers: answers,
    },
  ];

  const questions = preferences.map((preference) => (
    <SurveyQuestion
      key={preference.id}
      id={preference.id}
      questionText={preference.questionText}
      type={preference.questionType}
      answers={preference.answers}
      response={response[preference.id as keyof typeof response]}
      setResponse={handleResponse}
    />
  ));

  return (
    <SimpleFormPage hasBackLink={true} title={label('Ref: Page title')}>
      <Container>
        <Column.Main>
          <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
            {label('Ref: Things happen while you are away')}
          </Title>
          {questions}
        </Column.Main>
        <Column.Complementary>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
        <ActionsBar.Static inMainColumn>
          <Button
            data-testid="communication-preferences-apply"
            onClick={async () => {
              setLoading(true);
              await updateCommunicationPreferences(response.allowContent, response.allowOffers);
              setLoading(false);
              history.push('/account');
            }}
            loading={isLoading}
          >
            {label('apply', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar.Static>
      </Container>
    </SimpleFormPage>
  );
};

export default withLang([__filename])(CommunicationPreferencesPage);
