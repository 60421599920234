import { connect } from 'react-redux';

import GuestRegistrationSuccess from '../components/GuestRegistrationSuccess';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestRegistrationSuccess);
