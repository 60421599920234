import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { useCallback, useMemo, useState } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { Email, CopyIcon, InformationIcon, ErrorCircleIcon } from '../../assets/icons';
import Button, { BUTTON_LOOK } from '../../components/atoms/Button';
import Modal from '../../components/organisms/Modal';
import { WithLangProps } from '../../context/withLang';
import useToggle from '../../helpers/hooks/useToggle';

import useIsRunningInTeams from './useIsRunningInTeams/useIsRunningInTeams';
import useUserStepsInsightsLogging from './useUserStepsInsightsLogging/useUserStepsInsightsLogging';

import { UserSteps } from '@/types/userSteps.types';

import styles from './shareModal.module.css';

export type ShareModalProps = { shareName?: string } & WithLangProps;

const useShareModal = ({ label }: ShareModalProps) => {
  const {
    state: showShareModal,
    toggleOn: openShareModal,
    toggleOff: closeShareModal,
  } = useToggle(false);

  const { logUserSteps } = useUserStepsInsightsLogging();
  const { isRunningInTeams } = useIsRunningInTeams();

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState(window.location.href);

  const copyUrlToClipboard = useCallback(
    async (url: string) => {
      const toastOptions: ToastOptions<{}> = {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        closeButton: false,
      };
      try {
        await navigator.clipboard.writeText(url);
        toast.info(label('Ref: Link was copied', { textTransform: 'capitalize' }), {
          ...toastOptions,
          icon: <InformationIcon />,
        });
      } catch {
        toast.error(label('Ref: unable to copy url', { textTransform: 'capitalize' }), {
          ...toastOptions,
          icon: <ErrorCircleIcon />,
        });
      }
      closeShareModal();
    },
    [closeShareModal, label]
  );

  const openShareOptionsDialog = useCallback(
    async (title: string, url: string) => {
      const { platform } = await Device.getInfo();
      if (platform !== 'web') {
        try {
          return await Share.share({
            title,
            url,
            dialogTitle: title,
          });
        } catch (e) {
          // if the user closes the share popup
          return;
        }
      }
      openShareModal();
      logUserSteps({ event: UserSteps.ShareModalOpened });
    },
    [logUserSteps, openShareModal]
  );

  const triggerShareModal = useCallback(
    async (title: string, urlPath: string) => {
      let url = `${window.location.protocol}//${window.location.host}${urlPath}`;
      const { platform } = await Device.getInfo();
      if (platform !== 'web') {
        url = `${process.env.REACT_APP_HOST}${urlPath}`;
      }
      setTitle(title);
      setUrl(url);
      openShareOptionsDialog(title, url);
    },
    [openShareOptionsDialog]
  );

  const openMailClient = useCallback(() => {
    const encodedUrl = encodeURIComponent(url);
    window.location.href = `mailto:?body=${encodedUrl}&subject=${title}`;
  }, [url, title]);

  const shareOptionsModal = useMemo(
    () => (
      <Modal
        title={label('Ref: Share', { textTransform: 'capitalize' })}
        isOpen={showShareModal}
        contentClassName={styles.modalContentShare}
        id="share_modal"
        onDismiss={() => closeShareModal()}
        data-testid="share-modal"
      >
        <div className={styles.buttonShare}>
          <Button
            contentCenterAlign
            look={BUTTON_LOOK.PRIMARY}
            className={styles.shareByEmail}
            affix={() => <Email />}
            data-testid="button-share-to-email"
            onClick={openMailClient}
          >
            {label('Ref: Send by Email')}
          </Button>
          {!isRunningInTeams && (
            <Button
              contentCenterAlign
              look={BUTTON_LOOK.TERTIARY}
              onClick={() => {
                copyUrlToClipboard(url);
              }}
              affix={() => <CopyIcon />}
              data-testid="button-share-copy-clipboard"
            >
              {label('Ref: Copy the link')}
            </Button>
          )}
        </div>
      </Modal>
    ),
    [
      closeShareModal,
      copyUrlToClipboard,
      isRunningInTeams,
      label,
      openMailClient,
      showShareModal,
      url,
    ]
  );

  return {
    shareOptionsModal,
    triggerShareModal,
  };
};

export default useShareModal;
