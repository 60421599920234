import { useForm } from 'react-hook-form';
import { useHistory, withRouter } from 'react-router';

import withLoginStatus from '../../../../context/withLoginStatus';
import { pagePaths } from '../../config';
import withLang from '../../context/withLang';

import { AccountDeleteProps, AccountDeleteFormFields } from './';

import { AccountIllustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import { HookInput as Input } from '@/components/atoms/Input';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Form from '@/components/molecules/Form';
import { requiredRule } from '@/components/molecules/Form/helpers';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { useDeleteAccountMutation } from '@/modules/Core/api/account/accountApi';

import styles from './AccountDelete.module.css';

function AccountDelete({ label, logout }: AccountDeleteProps) {
  const history = useHistory();
  const REMOVAL_ACCOUNT_WORD = label('delete', { textTransform: 'uppercase' });
  const [deleteAccount] = useDeleteAccountMutation();
  const requiredFieldRule = requiredRule(
    label('Ref: Write "{word}"', { replace: { word: REMOVAL_ACCOUNT_WORD } }),
    label
  );
  requiredFieldRule.validate.match = (value: string) =>
    value === REMOVAL_ACCOUNT_WORD || label('Ref: Words are not matching');

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
  } = useForm<AccountDeleteFormFields>({
    mode: 'onChange',
    defaultValues: {
      word: '',
    },
  });

  const handleDeleteAccount = async () => {
    const result = await deleteAccount({});
    if (result) {
      if ('data' in result) {
        await logout();
        history.push(pagePaths.Home);
      } else {
        history.push(pagePaths.AccountDeletionFailure);
      }
    }
  };

  return (
    <SimpleFormPage title={label('Ref: Delete my account')}>
      <Form onSubmit={handleSubmit(handleDeleteAccount)}>
        <Container>
          <Column.Main>
            <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
              {label('Ref: Are you sure to delete your account?')}
            </Title>
            <Card>
              <p className={styles.infoText}>
                {label(
                  'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.',
                  { replace: { word: REMOVAL_ACCOUNT_WORD } }
                )}
              </p>
              <Input
                control={control}
                name="word"
                placeholder={label('Ref: Write "{word}"', {
                  replace: { word: REMOVAL_ACCOUNT_WORD },
                })}
                rules={requiredFieldRule}
                data-testid="delete-account-confirm-text"
              />
            </Card>
          </Column.Main>
          <Column.Complementary>
            <AccountIllustration />
          </Column.Complementary>
          <ActionsBar.Static inMainColumn>
            <Button
              type="submit"
              loading={isSubmitting}
              data-testid="delete-account-submit"
              disabled={!isValid}
            >
              {label('Ref: Delete my account')}
            </Button>
          </ActionsBar.Static>
        </Container>
      </Form>
    </SimpleFormPage>
  );
}

export default withLoginStatus(withRouter(withLang([__filename])(AccountDelete)));
