import { Filter, FilterType } from '../../../components/atoms/Filters/Filters.types';
import { LabelFunc } from '../../../context/withLang';
import { AuditInviteState } from '../routes/AuditInviteList/AuditInviteList.types';
import { AuditInvite } from '../types';

const useListFilter = ({ label, data }: { label: LabelFunc; data: AuditInvite[] }) => {
  const search = {
    searchableKeys: ['auditName'],
  };
  const filter = {
    filters: [
      {
        id: 'filter_audit_status',
        options: [
          {
            value: AuditInviteState.All.toString(),
            label: label('Ref: Search all audits'),
            default: true,
          },
          {
            value: AuditInviteState.Open.toString(),
            label: label('Ref: Search active audits'),
          },
          {
            value: AuditInviteState.Completed.toString(),
            label: label('Ref: Search completed audits'),
          },
          {
            value: AuditInviteState.Canceled.toString(),
            label: label('Ref: Search canceled audits'),
          },
        ],
        displayType: FilterType.DROPDOWN,
        multiple: false,
        apply: (selectedValues: string[]) => {
          const filteredItems = data
            .filter((item) => {
              if (selectedValues[0] === AuditInviteState.All.toString()) return true;
              const { state } = item;
              return state === parseInt(selectedValues[0]);
            })
            .map((item) => item.id);

          return filteredItems;
        },
      },
    ] as Filter[],
    initialFiltering: { filter_audit_status: { [AuditInviteState.All]: true } },
  };

  return { search, filter };
};

export default useListFilter;
