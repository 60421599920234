import { connect } from 'react-redux';

import withLang from '../../../context/withLang';

import DeletionFailure from './DeletionFailure';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default withLang(['DeletionFailure'])(
  connect(mapStateToProps, mapDispatchToProps)(DeletionFailure)
);
