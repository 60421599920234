import { AccountIcon20 } from '../../../../../assets/icons';
import TitleBar from '../../../../../components/organisms/TitleBar';
import { LinkProps } from '../../../../../components/organisms/TitleBar/TitleBar.types';
import { pagePaths } from '../../../config';
import withLang from '../../../context/withLang';

const AccountWidget = ({ label, hide }: LinkProps) => {
  if (hide) return <></>;

  return (
    <TitleBar.Widget
      label={label}
      labelKey="Account"
      Icon={AccountIcon20}
      linkTo={pagePaths['Account']}
      iconOnTheLeft
    />
  );
};

export default withLang([__filename])(AccountWidget);
