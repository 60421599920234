import { Control, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { WithLangProps } from '../../../../context/withLang';
import { QuestionResponse } from '../../api/api.types';
import { AuditQuestion } from '../../types/Audits';

import { TestingPropsV2 } from 'src/types';

export type QuestionComponentProps = {
  questionResponse?: QuestionResponse;
  isDisabled?: boolean;
  isComplete?: boolean;
  control: Control;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
} & AuditQuestion &
  WithLangProps &
  TestingPropsV2;

export enum QuestionTypes {
  SingleChoice = 1,
  MultiChoice = 2,
  SingleLineText = 3,
  MultiLineText = 4,
  StarRating = 5,
}

export type QuestionInputsProps = {
  [type: string]: (
    props: Omit<
      QuestionComponentProps,
      'label' | 'questionType' | 'text' | 'isRequired' | 'isDisabled'
    > & {
      value?: any;
      rules: Record<string, string | boolean>;
      disabled?: boolean;
    }
  ) => JSX.Element;
};
