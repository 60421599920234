import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { State } from '../../../../types/state.types';
import { logout } from '../../actions';

import LogoutButton from './LogoutButton';

const mapStateToProps = (state: State) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton);
