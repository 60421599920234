import { act, waitFor } from '@testing-library/react';

import {
  defaultCart,
  defaultCartMenuItem,
  menuItemWithPortions,
  selectedProductPortion,
} from '../__mocks/mock';
import { initializeCart } from '../actions';

import {
  getCartMenuItems,
  hasPortionsOrModifiers,
  increaseItem,
  isCartAnotherContext,
} from './menuCartActions.helper';
import { tryAddScannedItemToCart } from './productScanner.helper';

import { toLocalDate } from '@/helpers/misc';

const mockedCart = defaultCart;
const dispatch = jest.fn();
const mockGetState = jest.fn();

jest.mock('../../../store', () => ({
  store: {
    getState: () => mockGetState(),
  },
}));

jest.mock('../actions', () => {
  return {
    initializeCart: jest.fn(),
  };
});

jest.mock('./menuCartActions.helper', () => {
  return {
    isCartAnotherContext: jest.fn(),
    hasPortionsOrModifiers: jest.fn(),
    getCartMenuItems: jest.fn(),
    increaseItem: jest.fn(),
  };
});

const props = {
  date: toLocalDate(mockedCart.date),
  menuId: 12,
  scannedProduct: menuItemWithPortions,
  scannedPortion: selectedProductPortion,
  facilityId: '',
  siteId: '',
  dispatch,
};

const state = {
  Order: {},
  Core: {
    context: {
      site: {
        id: 'xxx-xxx-xxx',
        name: 'Test Site',
      },
    },
  },
};

describe('tryAddScannedItemToCart', () => {
  beforeEach(() => {
    (initializeCart as jest.Mock).mockReturnValue({});
    (increaseItem as jest.Mock).mockReturnValue({});
    (isCartAnotherContext as jest.Mock).mockReturnValue({ isAnotherOrderContext: false });
    (hasPortionsOrModifiers as jest.Mock).mockReturnValue(true);
    (getCartMenuItems as jest.Mock).mockReturnValue({
      ...defaultCartMenuItem,
      menuItemId: 2,
      uomId: 222,
      foodItemId: 2,
      price: 11,
      isVegan: true,
      isVegetarian: true,
    });
  });

  it('should initialize cart, when cart is empty', async () => {
    mockGetState.mockReturnValue(state);
    await act(async () => {
      tryAddScannedItemToCart(props);
    });
    await waitFor(() => expect(initializeCart).toHaveBeenCalled());
  });

  it('should increase/add item, when cart is not empty', async () => {
    mockGetState.mockReturnValue({ ...state, Order: { cart: mockedCart } });
    await act(async () => {
      tryAddScannedItemToCart(props);
    });
    await waitFor(() => expect(increaseItem).toHaveBeenCalled());
  });
});
