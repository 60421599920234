import React from 'react';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';

import withModuleContext from '../../../context/withModuleContext';
import withSetupOptions from '../../../context/withSetupOptions';
import TableCheckIn from '../components/TableCheckIn';
import { moduleId, pagePaths } from '../config';
import Checkout from '../containers/Checkout';
import FailOrder from '../containers/FailOrder';
import SuccessOrder from '../containers/SuccessOrder';
import { getFacilityMenuPath } from '../helpers/order.helper';
import useAlterRedirect from '../hooks/useAlterRedirect';
import { MenuType } from '../types/menuSelector.types';

import Cart from './Cart';
import MenuSelector from './MenuSelector';
import OrderHistory from './OrderHistory';
import OrderHistoryDetails from './OrderHistoryDetails';
import ProductDetails from './ProductDetails';
import ProductsList from './ProductsList/ProductsList';

import TooltipWrapper from '@/components/molecules/TooltipWrapper';

interface IOrderProps extends RouteComponentProps<never> {
  children: React.ReactNode;
}

const Order = ({ children }: IOrderProps) => {
  useAlterRedirect();

  if (children) {
    return <>{children}</>;
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={pagePaths.Module}
          render={() => <MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          exact
          path={pagePaths.MenuModule}
          render={() => (
            <MenuSelector facilityMenuPath={getFacilityMenuPath} menuType={MenuType.NonOrderable} />
          )}
        />
        <Route
          path={pagePaths.MenuListWithLocation}
          render={() => <MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.MenuListWithOutlet}
          render={() => <MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.MenuListWithOutletAndLocation}
          render={() => <MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.MenuWithBarcode}
          children={<MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.MenuRedirection}
          children={<MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.ViewableMenuRedirection}
          children={
            <MenuSelector facilityMenuPath={getFacilityMenuPath} menuType={MenuType.NonOrderable} />
          }
        />
        <Route path={pagePaths.TableCheckInOutlet} children={<TableCheckIn />} />
        <Route path={pagePaths.ProductsList} children={<ProductsList />} />
        <Route path={pagePaths.MenuProductsList} children={<ProductsList />} />
        <Route path={pagePaths.ProductsListWithBarcode} children={<ProductsList />} />
        <Route path={pagePaths.ProductDetails} children={<ProductDetails />} />
        <Route path={pagePaths.MenuProductDetails} children={<ProductDetails />} />
        <Route
          path={pagePaths.RetrievedCartSession}
          children={
            <>
              <TooltipWrapper />
              <Cart />
            </>
          }
        />
        <Route
          path={pagePaths.Cart}
          children={
            <>
              <TooltipWrapper />
              <Cart />
            </>
          }
        />
        <Route exact path={pagePaths.OrderHistory} children={<OrderHistory />} />
        <Route path={pagePaths.OrderHistoryDetails} children={<OrderHistoryDetails />} />
        <Route path={pagePaths.OrderSuccess} children={<SuccessOrder />} />
        <Route path={pagePaths.OrderFail} children={<FailOrder />} />
        <Route path={pagePaths.Checkout} children={<Checkout />} />
      </Switch>
    </div>
  );
};

export default withModuleContext(
  'Facilities',
  'withFacilities',
  true
)(withSetupOptions(moduleId, withRouter(Order)));
