import { connect } from 'react-redux';

import { State } from '../../../types/state.types';
import AccountStartPage from '../components/AccountStartPage';

const mapStateToProps = (state: State) => {
  const contractId = state.Core.user.contract?.id || '';
  const getUserContextLocked = state.Core.locks?.getUserContext;
  return {
    contractId,
    getUserContextLocked,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStartPage);
