import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { FormProvider, useForm } from 'react-hook-form';

import { AccommodationRequestFormFields } from '../../../types';
import CreateSummary, { guestUserPhoneNumber } from '../CreateSummary';

import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { formatDate } from '@/helpers/misc';
import renderComponent from '@/helpers/tests/renderComponent';
import { pagePaths } from '@/modules/AccommodationRequest/config';

const mockCreateAccommodationRequest = jest.fn();
jest.mock('@/modules/Sites/api/api', () => ({
  useGetSitesQuery: () => ({
    data: [
      {
        id: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
        name: 'FMG Camp 145',
        locationtype: 'Site',
        address: {
          line1: 'Sixth St',
          line2: '',
          line3: '',
          city: 'Marble Bar',
          postalCode: '6760',
          stateOrProvince: 'Western Australia',
          country: 'Australia',
        },
        geoCoordinates: {
          longitude: 118.401077,
          latitude: -21.2860432,
        },
        images: [],
        siteMapImage: '',
        selfRegistrationDuration: 30,
        currency: {
          currencyName: 'Australian Dollar',
          currencySymbol: '$',
          isoCode: 'AUD',
          exchangeRate: 1.4,
          precision: 2,
        },
        siteAccessRestrictionLevelId: 1,
      },
    ],
  }),
}));

const mockData = { isLoading: false, isSuccess: true, isError: false };
jest.mock('@/modules/AccommodationRequest/api', () => ({
  useCreateAccommodationRequestMutation: () => [mockCreateAccommodationRequest, mockData],
}));

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

const CreateSumaryTest = () => {
  const formMethods = useForm<AccommodationRequestFormFields>();
  return (
    <FormProvider {...formMethods}>
      <CreateSummary />
    </FormProvider>
  );
};

const currentLanguageCode = 'en-US';

const mockSummaryProps = {
  raisedFor: 'Me',
  phoneNumber: '3224423',
  email: 'test@mail.com',
  site: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
  guestFirstName: 'Guest',
  guestLastName: 'User',
  dateFields: {
    arrivalDate: new Date('2023-09-25T19:02:55.059Z'),
    departureDate: new Date('2023-09-26T10:00:00.059Z'),
  },
  comment: 'I would like to have room with a sea view',
};

jest.mock('react-hook-form', () => ({
  ...jest.requireActual('react-hook-form'),
  useFormContext: () => ({
    handleSubmit: () => jest.fn(),
    getValues: () => {
      return mockSummaryProps;
    },
    reset: () => jest.fn(),
  }),
  Controller: () => [],
}));

describe('CreateSummary', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let summaryTitle: HTMLElement, submitButton: HTMLElement;

    beforeEach(() => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(false);
    });

    renderComponent(CreateSumaryTest);

    beforeEach(() => {
      summaryTitle = screen.getByTestId('accommodation-request-create-summary');
      submitButton = screen.getByTestId('button-action-primary');
    });

    it('should render accomodation summary list item data', () => {
      expect(summaryTitle).toBeTruthy();
      expect(submitButton).toBeTruthy();
      expect(screen.getByText('The person affected by this request')).toBeTruthy();
      expect(screen.getByText('Phone number')).toBeTruthy();
      expect(screen.getByText('Arrival')).toBeTruthy();
      expect(screen.getByText('Departure')).toBeTruthy();
      expect(screen.getByText('Comment')).toBeTruthy();
      expect(screen.getByText('Guest User')).toBeTruthy();
      expect(screen.getByText('3224423')).toBeTruthy();
      expect(screen.getByText('FMG Camp 145')).toBeTruthy();
      expect(screen.queryByText('Email of guest *')).toBeNull();
      const arivalDateLocal = screen.getByTestId(
        'accommodation-request-create-summary-list-arrival-list-item-value'
      );
      expect(arivalDateLocal).toHaveTextContent(
        formatDate(new Date(mockSummaryProps.dateFields.arrivalDate), currentLanguageCode)
      );
      const departureDateLocal = screen.getByTestId(
        'accommodation-request-create-summary-list-departure-list-item-value'
      );
      expect(departureDateLocal).toHaveTextContent(
        formatDate(new Date(mockSummaryProps.dateFields.departureDate), currentLanguageCode)
      );
      expect(screen.getByText('I would like to have room with a sea view')).toBeTruthy();
    });
  });

  describe('on "Submit" button click success', () => {
    let submitButton: HTMLElement;

    renderComponent(CreateSumaryTest);
    beforeEach(() => {
      submitButton = screen.getByText(/submit/i);
    });

    it('should have redirected to Thank you page', () => {
      expect(submitButton).toBeTruthy();

      fireEvent.click(submitButton);
      expect(mockCreateAccommodationRequest).toBeCalled();
      expect(mockHistory.location.pathname).toBe(pagePaths.CreateThankYou);

      const createRequestParam = mockCreateAccommodationRequest.mock.calls[0][0];
      expect(createRequestParam.phoneNumber).toBe(mockSummaryProps.phoneNumber);
      expect(createRequestParam.description).toBe(mockSummaryProps.comment);
    });
  });

  describe('with enable guest request setupOption enabled', () => {
    beforeEach(() => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
    });
    renderComponent(CreateSumaryTest);

    let submitButton: HTMLElement;
    beforeEach(() => {
      submitButton = screen.getByText(/submit/i);
    });

    it('should render accomodation summary list item data', async () => {
      screen.debug(screen.getByTestId('accommodation-request-create-summary-list'));

      expect(screen.queryByText('Email of guest')).toBeTruthy();
      expect(screen.getByText('Guest User')).toBeTruthy();
      expect(screen.queryByText('Phone number')).toBeNull();
    });

    it('should have redirected to Thank you page', () => {
      expect(submitButton).toBeTruthy();

      fireEvent.click(submitButton);
      expect(mockCreateAccommodationRequest).toBeCalled();
      expect(mockHistory.location.pathname).toBe(pagePaths.CreateThankYou);

      const createRequestParam = mockCreateAccommodationRequest.mock.calls[0][0];
      expect(createRequestParam.phoneNumber).toBe(guestUserPhoneNumber);
      expect(createRequestParam.description).toContain(mockSummaryProps.comment);
      expect(createRequestParam.description).toContain(mockSummaryProps.email);
    });
  });
});
