import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cleanCart } from '../../actions';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { CartAnotherContextPopupProps } from '../../types/CartAnotherContextPopup.types';
import { CartDifferentContextTypeEnum } from '../../types/menuCartActions.types';
import { StateWithOrder } from '../../types/orderState.types';

import { Alert } from '@/components/molecules/Alert';
import { formatDate } from '@/helpers/misc';
import useLanguage from '@/modules/Core/hooks/useLanguage';

const CartAnotherContextPopup = ({
  cartDifferentContextType,
  menuDate,
  menuId,
  menuItem,
  onDismiss,
}: CartAnotherContextPopupProps) => {
  const { currentLanguageCode } = useLanguage();
  const { menus, cart } = useSelector((state: StateWithOrder) => state.Order);
  const { label } = useOrderTranslation(__filename);

  const [message, setMessage] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cart && menuItem) {
      if (cartDifferentContextType === CartDifferentContextTypeEnum.DifferentDate) {
        const anotherContextMessage = label(`Ref: Cart addition error message`, {
          replace: {
            from_current: formatDate(cart.date, currentLanguageCode),
            from_selected: formatDate(menuDate, currentLanguageCode),
          },
        });
        setMessage(anotherContextMessage);
      } else if (cartDifferentContextType === CartDifferentContextTypeEnum.DifferentMenu) {
        const cartMenu = menus?.find((menu) => menu.id === cart?.menuId)?.name;
        const selectedMenu = menus?.find((menu) => menu.id === menuId)?.name;
        const anotherContextMessage = label(`Ref: Cart addition error message`, {
          replace: {
            from_current: cartMenu ?? 'unknown',
            from_selected: selectedMenu ?? 'unknown',
          },
        });
        setMessage(anotherContextMessage);
      } else if (cartDifferentContextType === CartDifferentContextTypeEnum.DifferentMoment) {
        const anotherContextMessage = label(`Ref: Cart addition error message`, {
          replace: {
            from_current: cart.moment,
            from_selected: menuItem.mealName,
          },
        });
        setMessage(anotherContextMessage);
      }
    }
  }, [
    cartDifferentContextType,
    cart,
    currentLanguageCode,
    label,
    menuDate,
    menuId,
    menuItem,
    menus,
  ]);

  const buttons = [
    label('Ref: cancel'),
    {
      text: label('Ref: clear cart'),
      handler: () => dispatch(cleanCart()),
      'data-testid': 'clear-cart-action',
    },
  ];

  return cartDifferentContextType ? (
    <Alert
      isOpen={true}
      onDismiss={onDismiss}
      className="popup-warning"
      header={label('Ref: notice')}
      message={message}
      buttons={buttons}
      data-testid="cart-another-context-popup"
    />
  ) : null;
};

export default CartAnotherContextPopup;
