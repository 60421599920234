import { useSelector } from 'react-redux';

import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { State } from '../../../../types/state.types';
import { Geography } from '../../../Core/types';
import { ISite } from '../../types/sites.types';
import SiteTile from '../SiteTile/SiteTile';

export function getMarkersFromSites({
  sites,
  selectedSiteId,
  inactiveMarkerIcon,
  activeMarkerIcon,
}: {
  sites: ISite[];
  selectedSiteId?: string;
  inactiveMarkerIcon: string;
  activeMarkerIcon: string;
}) {
  return (sites || [])
    .filter((site) => Boolean(site?.geoCoordinates))
    .map((site) => ({
      id: site.id,
      icon: site.id === selectedSiteId ? activeMarkerIcon : inactiveMarkerIcon,
      coordinates: {
        longitude: site.geoCoordinates!.longitude,
        latitude: site.geoCoordinates!.latitude,
      },
      data: { site },
    }));
}

export function useCurrentGeography() {
  const defaultGeoCode = useSelector((state: State) => state.Shared.geographies?.defaultGeoCode);
  const geographiesList = useSelector((state: State) => state.Shared.geographies?.list);
  const cachedGeoCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE);
  const currentGeoCode = cachedGeoCode ?? defaultGeoCode;
  return geographiesList.find((geo: Geography) => geo.code === currentGeoCode);
}

export function formatSiteToListItem(site: ISite, handleSiteSelection: () => void) {
  return <SiteTile key={site.id} site={site} onSiteSelection={handleSiteSelection} />;
}

export function sortSitesByDistance(
  sites: ISite[],
  userLocation: { latitude: number; longitude: number } | undefined | null
): ISite[] {
  if (!userLocation) {
    return sites;
  }

  const sitesForSort = [...sites];

  return sitesForSort.sort((siteA, siteB) => {
    const siteAHasCoordinates = Boolean(siteA.geoCoordinates);
    const siteBHasCoordinates = Boolean(siteB.geoCoordinates);

    if (siteAHasCoordinates && !siteBHasCoordinates) {
      return -1;
    } else if (!siteAHasCoordinates && siteBHasCoordinates) {
      return 1;
    } else if (!siteAHasCoordinates && !siteBHasCoordinates) {
      return 0;
    }
    const aDistance = getDistanceFromLatLonInKm(
      userLocation.latitude,
      userLocation.longitude,
      siteA.geoCoordinates!.latitude,
      siteA.geoCoordinates!.longitude
    );
    const bDistance = getDistanceFromLatLonInKm(
      userLocation.latitude,
      userLocation.longitude,
      siteB.geoCoordinates!.latitude,
      siteB.geoCoordinates!.longitude
    );
    return aDistance - bDistance;
  });
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  const earthRadius = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = earthRadius * c; // Distance in km
  return d;
}

export function getSitesByCurrentCountry(sites: ISite[], currentGeo: Geography | undefined) {
  const currentCountrySites = currentGeo
    ? sites.filter(
        (site) => site.address?.country && currentGeo.name.includes(site.address?.country)
      )
    : [];
  return currentCountrySites;
}

export function sortSitesByLastVisited(
  unorderedSites: ISite[],
  lastVisitedSitesWithRightOrder: ISite[]
): ISite[] {
  const sitesForSort = [...unorderedSites];

  return sitesForSort.sort((siteA, siteB) => {
    const visitedA = lastVisitedSitesWithRightOrder.findIndex(
      (visitedSite) => siteA.id === visitedSite.id
    );
    const visitedB = lastVisitedSitesWithRightOrder.findIndex(
      (visitedSite) => siteB.id === visitedSite.id
    );
    if (visitedA === -1 && visitedB === -1) {
      return 0;
    }
    if (visitedA === -1) {
      return 1;
    }
    if (visitedB === -1) {
      return -1;
    }
    return visitedA - visitedB;
  });
}
