import momentjs from 'moment';
import { v4 as uuid } from 'uuid';

import { ImageDefaultFoodImg } from '../../../../assets/images';
import {
  Filter,
  FilterPosition,
  FilterType,
} from '../../../../components/atoms/Filters/Filters.types';
import { SectionType } from '../../../../components/organisms/TilesList/TilesList.types';
import { DATE_FORMAT } from '../../../../constants';
import { LabelFunc } from '../../../../context/withLang';
import { isSmallViewport } from '../../../../helpers/windowSize';
import { pagePaths, selectedProductListFiltersCacheKey } from '../../config';

import {
  DayPart,
  ListSection,
  MappedMenuItem,
  Menu,
  MenuItem,
  ProductListFiltering,
} from './ProductsList.types';

import { TestingPropsV2 } from 'src/types';

const allOptionsLabel = 'all';

const mapProductItemToListItem = (
  menuItem: MenuItem,
  menu: Menu,
  dayPart: DayPart,
  section: string,
  languageCode: string,
  isoCode: string,
  date: string,
  facilityId: string
) => {
  const priceFormatter =
    isoCode &&
    new Intl.NumberFormat(languageCode, {
      style: 'currency',
      currency: isoCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const priceFormatted =
    menuItem.price && priceFormatter
      ? priceFormatter.format(parseInt(menuItem.price || '0', 10))
      : menuItem.priceInText || '';

  return {
    id: `${menu.id}_${menuItem.name}_${section}`,
    category: section,
    key: `${menu.id}_${menuItem.name}_${uuid()}`,
    title: menuItem.name,
    price: menuItem.price,
    description: menuItem.description,
    addendum: priceFormatted,

    imgElement: <ImageDefaultFoodImg />,
    menu: menu.name,
    moment: dayPart.name,
    linkPath: pagePaths.ProductDetails.replace(':facilityId', facilityId)
      .replace(':id', menuItem.id.toString())
      .replace(':date', date),
    strDate: date,
    'data-testid': `product-list-item-${menuItem.id}`,
  };
};

export const mapToListModel = ({
  menu,
  languageCode,
  isoCode,
  date,
  selectedMoment,
  facilityId,
}: {
  menu: Menu;
  languageCode: string;
  isoCode: string;
  date: string;
  selectedMoment: string;
  facilityId: string;
}): MappedMenuItem[] => {
  if (!menu) return [];

  const menuListResult = [];

  const dayParts = selectedMoment
    ? menu.dayparts.filter((item) => item.name.toLowerCase() === selectedMoment)
    : menu.dayparts;

  for (const dayPart of dayParts) {
    for (const section of dayPart.sections) {
      for (const menuItem of section.items) {
        const productItem = mapProductItemToListItem(
          menuItem,
          menu,
          dayPart,
          section.name,
          languageCode,
          isoCode,
          date,
          facilityId
        );
        if (productItem) menuListResult.push(productItem);
      }
    }
  }

  return menuListResult;
};

export const buildSections = (menuItems: MappedMenuItem[]) => {
  return menuItems.reduce<SectionType<MappedMenuItem & TestingPropsV2>[]>((sectionResult, menuItem) => {
    if (sectionResult.find((x) => x.title === menuItem.category)) return sectionResult;
    return [
      ...sectionResult,
      {
        title: menuItem.category,
        key: menuItem.category,
        'data-testid': `product-list-section-${menuItem.category}`,
        filter: (items: MappedMenuItem[]) =>
          items.filter((item: MappedMenuItem) => item.category === menuItem.category),
      },
    ];
  }, []);
};

export const buildFilteringState = ({
  moments,
  defaultFilters = {},
}: {
  moments: string[];
  defaultFilters: ProductListFiltering;
}) => {
  const momentFilter = {
    filter_moment: moments?.length ? { [moments[0]]: true } : {},
  };

  return { ...momentFilter, ...defaultFilters };
};

const getCategoriesOptions = (sections: ListSection[], label: LabelFunc) => {
  const isOnlyOneChoice = sections.length === 1;
  if (isOnlyOneChoice) {
    return [];
  }
  const options = sections.map((section) => ({
    value: section.title,
    label: label(section.title, { textTransform: 'capitalize' }),
  }));

  return [
    {
      value: allOptionsLabel,
      label: label('All'),
      default: true,
    },
    ...options,
  ];
};

export const getFilters = ({
  label,
  menuItemsList,
  sections,
  moments,
  selectedDate,
  setSelectedMoment,
  setSelectedDate,
}: {
  label: LabelFunc;
  menuItemsList: MappedMenuItem[];
  sections: ListSection[];
  moments: string[];
  selectedDate: string;
  setSelectedMoment: (moment: string) => void;
  setSelectedDate: (date: string) => void;
}): Filter[] => {
  const isSmallViewPort = isSmallViewport();
  const getMomentPosition = () => {
    if (moments.length < 2) return FilterPosition.INVISIBLE;
    return !isSmallViewPort ? FilterPosition.NOT_IN_MODAL : FilterPosition.ALWAYS_IN_MODAL;
  };

  const isDateToday = selectedDate === momentjs().format(DATE_FORMAT);
  const isDateTomorrow = selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT);

  return [
    {
      id: 'filter_date',
      position: FilterPosition.NOT_IN_MODAL,
      name: label('Ref: filter label: date', { textTransform: 'capitalize' }),
      options: [
        {
          value: momentjs().format(DATE_FORMAT),
          label: label('today'),
          default: isDateToday,
        },
        {
          value: momentjs().add(1, 'days').format(DATE_FORMAT),
          label: label('tomorrow'),
          default: isDateTomorrow,
          'data-testid': 'tomorrow-button',
        },
      ],
      displayType: FilterType.CALENDAR,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const selectedValue = selectedValues[0] ?? selectedDate;
        setSelectedDate(selectedValue);
        return menuItemsList.map((item) => item.id);
      },
      minDate: momentjs().startOf('day').toDate(),
      maxDate: momentjs().add(10, 'days').startOf('day').toDate(),
    },
    {
      id: 'filter_moment',
      position: getMomentPosition(),
      name: label('Ref: filter label: moment', { textTransform: 'capitalize' }),
      options: moments.map((moment) => ({
        value: moment,
        label: label(moment, { textTransform: 'capitalize' }),
      })),
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        if (!selectedValues?.length) return menuItemsList.map((item) => item.id);
        const selectedValue = selectedValues[0]?.toLowerCase();
        setSelectedMoment(selectedValue);

        return menuItemsList
          .filter((item) => item.moment?.toLowerCase() === selectedValue)
          .map((item) => item.id);
      },
    },
    {
      id: 'filter_category',
      position: !isSmallViewPort ? FilterPosition.NOT_IN_MODAL : FilterPosition.ALWAYS_IN_MODAL,
      name: label('Ref: filter label: category', { textTransform: 'capitalize' }),
      options: getCategoriesOptions(sections, label),
      displayType: FilterType.CONDENSED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        if (!selectedValues?.length || selectedValues[0] === allOptionsLabel)
          return menuItemsList.map((item) => item.id);
        const selectedValue = selectedValues[0]?.toLowerCase();
        const filterMenuItems = menuItemsList.filter(
          (item) => item.category?.toLowerCase() === selectedValue
        );

        return filterMenuItems.map((item) => item.id);
      },
    },
  ];
};

const getCacheKey = (siteId: string, menu: string, date: string) =>
  `${selectedProductListFiltersCacheKey}_${siteId}_${menu}_${date}`;

export const cacheSelectedFilters = (
  filters: ProductListFiltering,
  siteId: string,
  menu: string,
  date: string
) => localStorage.setItem(getCacheKey(siteId, menu, date), JSON.stringify(filters));

export const getDefaultFiltersFromCache = (siteId: string, menu: string, date: string) => {
  const defaultFiltersCache = localStorage.getItem(getCacheKey(siteId, menu, date));
  const defaultFilters = defaultFiltersCache ? JSON.parse(defaultFiltersCache) : null;

  return defaultFilters;
};
