import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';

import { DATE_FORMAT } from '../../../../constants';
import { useGetEventsQuery, useGetEventsPreferencesQuery } from '../../api';
import EventListSkeleton from '../../components/EventListSkeleton/EventListSkeleton';
import EventTile from '../../components/EventTile';
import { eventsConfig } from '../../config';
import { useEventTranslation } from '../../hooks/useEventTranslation';
import { DATE_FILTER, EventsListFilters, EventType, SectionItem } from '../../types';

import { EventIllustration } from '@/assets/illustrations';
import { FilterPosition, FilterType } from '@/components/atoms/Filters/Filters.types';
import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper';
import Column from '@/components/organisms/Column';
import { SectionType } from '@/components/organisms/TilesList/TilesList.types';
import ListPage from '@/components/templates/ListPage/ListPage';
import { FilterPropsType } from '@/components/templates/ListPage/ListPage.types';
import useShareModal from '@/helpers/hooks/useShareModal';
import { formatDate } from '@/helpers/misc';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';

const hasEventsForDate = (items: EventType[], date: Date) => {
  const selectedStr = moment(date.getTime()).format(DATE_FORMAT);
  return items.some((item) => item.eventDates?.includes(selectedStr));
};

const EventsList = () => {
  const { isInterestEnabled, displayPreferencesFilter } = useMemo(() => eventsConfig(), []);
  const { label } = useEventTranslation(__filename);
  const { currentLanguageCode } = useLanguage();
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;

  const [filtering, setFiltering] = useState<EventsListFilters>({
    filter_date: { future: true },
  });

  const [searchString, setSearchString] = useState('');

  const { shareOptionsModal, triggerShareModal } = useShareModal({
    label,
  });

  const [preferredevents, setPreferredevents] = useState(false);
  const [isFuture, setIsFuture] = useState(true);

  const { data: preferences = [] } = useGetEventsPreferencesQuery(
    {},
    {
      skip: !displayPreferencesFilter,
    }
  );

  const { data: list = [], isLoading } = useGetEventsQuery({
    siteId,
    future: isFuture,
    preferredevents,
  });

  const itemFromLabel = useMemo(
    () =>
      label('from (followed by a date, or date and time)', {
        textTransform: 'capitalize',
        end: 'colon',
      }),
    [label]
  );

  const itemToLabel = useMemo(
    () =>
      label('to (followed by a date, or date and time)', {
        start: 'whitespace',
        end: 'colon',
      }),
    [label]
  );

  const search = useMemo(
    () => ({
      searchableKeys: ['name', 'description', 'location'],
      searchString,
      collapsible: isMyVillage(),
      handleSearchChange: setSearchString,
      placeholder: label('Ref: Search'),
    }),
    [label, searchString]
  );

  const items = useMemo(() => {
    return list.map((item) => {
      const start = moment.utc(item.startDate).format('LT');
      const end = moment.utc(item.endDate).format('LT');
      const description = `${itemFromLabel}${start}${itemToLabel}${end}`;
      return { ...item, description };
    });
  }, [list, itemFromLabel, itemToLabel]);

  const eventPreferences = useMemo(() => {
    return preferences?.map(({ id, name, type }) => ({
      value: id,
      label: name,
      group: type,
    }));
  }, [preferences]);

  const filters = useMemo(() => {
    return [
      {
        id: 'filter_date',
        position: FilterPosition.NOT_IN_MODAL,
        name: label('filter label: date', { textTransform: 'capitalize' }),
        options: [
          {
            value: DATE_FILTER.FUTURE,
            label: label('filter label: coming events', {
              textTransform: 'capitalize',
            }),
            default: true,
          },
          {
            value: DATE_FILTER.PAST,
            label: label('filter label: past events', {
              textTransform: 'capitalize',
            }),
            default: false,
          },
        ],
        displayType: FilterType.CALENDAR,
        minDate: isFuture ? new Date() : undefined,
        maxDate: isFuture ? undefined : new Date(),
        multiple: false,
        apply: (selectedValues: Array<DATE_FILTER>): Array<string> => {
          const filter = selectedValues[0];
          if (filter && filter !== DATE_FILTER.PAST && filter !== DATE_FILTER.FUTURE) {
            return items.filter((el) => (el.eventDates ?? []).includes(filter)).map((el) => el.id);
          }

          return items.map((el) => el.id);
        },
        tileClassName: ({ date }: { date: Date }) => {
          const today = moment(date.getTime()).format('YYYY-MM-DD');
          // highlight the day if an event happens that day, and the user has selected it
          const hasEventsAndIsSelectedByUser = items.find(
            (item) => item.eventDates?.includes(today) && item.isUserInterested
          );
          return Boolean(hasEventsAndIsSelectedByUser) ? 'highlighted' : '';
        },
        tileDisabled: ({ date }: { date: Date }) => !hasEventsForDate(items, date),
      },
      ...(isInterestEnabled
        ? [
            {
              id: 'filter_interest',
              position: FilterPosition.NOT_IN_MODAL,
              name: label('filter label: interest', {
                textTransform: 'capitalize',
              }),
              options: [
                {
                  value: 'all_events',
                  label: label('All events'),
                  default: true,
                },
                {
                  value: 'interested_only',
                  label: label('My selection'),
                  default: false,
                },
              ],
              displayType: FilterType.EXPANDED,
              multiple: false,
              apply: (selectedValues: Array<'all_events' | 'interested_only'>) => {
                if (isMyVillage()) {
                  if (selectedValues[0] === 'interested_only') {
                    return items.filter((el) => el.isUserInterested).map((el) => el.id);
                  } else {
                    return items.map((el) => el.id);
                  }
                } else {
                  return items.map((el) => el.id);
                }
              },
            },
          ]
        : []),
      ...(eventPreferences && displayPreferencesFilter
        ? [
            {
              id: 'filter_preferences',
              position: FilterPosition.ALWAYS_IN_MODAL,
              displayType: FilterType.CHECKBOX_GROUP,
              options: eventPreferences,
              isMultipleSelect: true,
              defaultValues: '',
              multiple: true,
              columns: 1,
              apply: (selectedValues: Array<string>) => {
                if (!selectedValues?.length) return list.map((item) => item.id);

                const filteredItems = list.filter((item) =>
                  item.preferences?.some(({ id }) => selectedValues.includes(id))
                );
                return filteredItems.map((item) => item.id);
              },
            },
          ]
        : []),
    ] as FilterPropsType['filters'];
  }, [label, isFuture, isInterestEnabled, eventPreferences, displayPreferencesFilter, items, list]);

  const handleFilteringChange = useCallback((newFiltering: EventsListFilters) => {
    setFiltering({ ...newFiltering });

    if (newFiltering.filter_date?.future) {
      setIsFuture(true);
    } else if (newFiltering.filter_date?.past) {
      setIsFuture(false);
    } else {
      const date = Object.keys(newFiltering.filter_date ?? {})[0];
      const isFuture = moment().isSameOrBefore(date, 'day');
      setIsFuture(isFuture);
    }
    setPreferredevents(!!newFiltering.filter_interest?.interested_only);
  }, []);

  const filter = useMemo<FilterPropsType>(
    () => ({
      filters,
      initialFiltering: filtering,
      handleFilteringChange,
    }),
    [filters, filtering, handleFilteringChange]
  );

  const sections = useMemo((): Array<SectionType<SectionItem>> => {
    let sectionsId: Record<string, boolean> = {};
    const strFilteredDate = Object.keys(filtering?.filter_date || {})[0];

    const sectionsResult = items.reduce<Array<SectionItem>>((acc, currEvent) => {
      for (const strEventDate of currEvent.eventDates) {
        const onDateSectionId = `on_date_${strEventDate}`;
        if (sectionsId[onDateSectionId]) {
          continue;
        }

        //only add the section if we either don't have a date filtering of have a date filtering that matches this section
        if (
          strFilteredDate &&
          strFilteredDate !== DATE_FILTER.PAST &&
          strFilteredDate !== DATE_FILTER.FUTURE
        ) {
          continue;
        }

        const dateEventDate = new Date(strEventDate);

        acc.push({
          id: onDateSectionId,
          key: onDateSectionId,
          timestamp: dateEventDate.getTime(),
          title: formatDate(dateEventDate, currentLanguageCode),
          'data-testid': `section-${onDateSectionId}`,
          filter: (unfilteredItems: Array<EventType>) =>
            unfilteredItems.filter((item: EventType) => item.eventDates.includes(strEventDate)),
        });
        sectionsId[onDateSectionId] = true;
      }
      return acc;
    }, []);

    if (strFilteredDate === DATE_FILTER.FUTURE) {
      return [...sectionsResult].sort((a, b) => a.timestamp - b.timestamp);
    } else if (strFilteredDate === DATE_FILTER.PAST) {
      return [...sectionsResult].sort((a, b) => a.timestamp - b.timestamp).reverse();
    } else {
      return sectionsResult;
    }
  }, [currentLanguageCode, filtering?.filter_date, items]);

  return (
    <>
      <ListPage
        data-testid="events-list"
        hasBackLink={false}
        title={label('Ref: Page title')}
        items={items}
        isLoading={isLoading}
        sections={sections}
        hideFilterTitle={true}
        filter={filter}
        search={search}
        aside={
          <Column.Complementary>
            <EventIllustration />
          </Column.Complementary>
        }
        itemRenderer={(item: EventType) => (
          <EventTile
            tooltipId={TOOLTIP_VARIANTS.DEFAULT}
            key={`${item.id}-${item.startDate}-${item.endDate}`}
            event={item}
            label={label}
            triggerShareModal={triggerShareModal}
          />
        )}
      >
        {isLoading && <EventListSkeleton />}
        {shareOptionsModal}
      </ListPage>
    </>
  );
};

export default EventsList;
