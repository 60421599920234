import Range from '../../../components/atoms/Range/Range';
import RatingStar from '../../../components/atoms/RatingStar/RatingStar';
import Textarea from '../../../components/atoms/Textarea/Textarea';
import { LabelFunc } from '../../../context/withLang';
import { defaultValues } from '../config';
import { Answer, FormState, REVIEW_QUESTION_TYPES } from '../types/reviewForm.types';

export const getQuestionComponent = (
  surveyQuestionId: number,
  questionType: REVIEW_QUESTION_TYPES,
  value: Answer,
  onChange: (answer: Answer) => void,
  label: LabelFunc
) => {
  switch (questionType) {
    case REVIEW_QUESTION_TYPES.STARS:
      const ratingValue = typeof value === 'number' ? value : 0;

      return (
        <RatingStar
          groupName={surveyQuestionId.toString()}
          value={ratingValue}
          onChange={onChange}
          data-testid={`review-question-star-rating`}
        />
      );
    case REVIEW_QUESTION_TYPES.SLIDER:
      const rangeValue = typeof value === 'number' ? value : defaultValues.initialQuestionRange;

      return (
        <Range
          min={defaultValues.minQuestionRange}
          max={defaultValues.maxQuestionRange}
          data-testid="review-form-range-input"
          value={rangeValue}
          onChange={onChange}
          showLabel={false}
          aria-label={label('Ref: Filter range')}
        />
      );
    case REVIEW_QUESTION_TYPES.TEXT:
      const textValue = typeof value === 'string' ? value : '';

      return (
        <Textarea
          placeholder={label('Ref: How was dining with us')}
          value={textValue}
          onChange={(e) => onChange(e.detail.value || '')}
          maxlength={defaultValues.textAreaMaxLength}
        />
      );
    default:
      return <div />;
  }
};

export const getQuestionLabel = ({
  questionText,
  value,
  questionType,
  required,
}: {
  questionText: string;
  value: Answer;
  questionType: REVIEW_QUESTION_TYPES;
  required?: boolean;
}) => {
  const label = `${questionText}${required ? ' *' : ''}`;

  if (questionType === REVIEW_QUESTION_TYPES.SLIDER) {
    return `${label} ${value}/${defaultValues.maxQuestionRange}`;
  }
  return label;
};

export const checkIsInvalidForm = (
  state: FormState,
  isValidEmail: boolean,
  isAnonymous: boolean
) => {
  if (!isAnonymous && !isValidEmail) return true;

  return Object.values(state).some(({ answer, required, type }) => {
    if (type === REVIEW_QUESTION_TYPES.TEXT) {
      const value = typeof answer == 'string' ? answer : '';
      return (required && !answer) || value.length > defaultValues.textAreaMaxLength;
    }
    return required && !answer;
  });
};

export const checkIsValidEmail = (email: string) => {
  const regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regEx.test(email);
};
