import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import { AccountDeleteContainer } from './AccountDelete';
import AccountPage from './AccountPage';
import AccountPasswordChange from './AccountPasswordChange/AccountPasswordChange.container';
import AccountPasswordChangeFailure from './AccountPasswordChange/AccountPasswordChangeFailure';
import AccountPasswordChangeSuccess from './AccountPasswordChange/AccountPasswordChangeSuccess';
import LanguagePage from './LanguagePage';

const Account = () => (
  <Switch>
    <Route path={pagePaths['Language']} children={<LanguagePage />} />
    <Route
      path={pagePaths['AccountPasswordChangeSuccess']}
      children={<AccountPasswordChangeSuccess />}
    />
    <Route
      path={pagePaths['AccountPasswordChangeFailure']}
      children={<AccountPasswordChangeFailure />}
    />
    <Route path={pagePaths['AccountPasswordChange']} children={<AccountPasswordChange />} />
    <Route path={pagePaths['AccountDelete']} children={<AccountDeleteContainer />} />
    <Route path={pagePaths['Account']} children={<AccountPage />} />
  </Switch>
);

export default Account;
