import { Filter, FilterType } from '../../../../components/atoms/Filters/Filters.types';
import { LabelFunc } from '../../../../context/withLang';

import { ContentItem, CONTENT_CATEGORIES } from './ContentList.types';

export const getFilters = ({
  items,
  label,
}: {
  items: ContentItem[];
  label: LabelFunc;
}): Filter[] => {
  return [
    {
      id: 'filter_content_type',
      options: [
        {
          value: CONTENT_CATEGORIES.ALL,
          label: label('Ref: category - all'),
          default: true,
        },
        {
          value: CONTENT_CATEGORIES.PROMOTIONAL,
          label: label('Ref: category - promotional'),
        },
        {
          value: CONTENT_CATEGORIES.FEATURED,
          label: label('Ref: category - featured'),
        },
        {
          value: CONTENT_CATEGORIES.OTHER,
          label: label('Ref: category - other'),
        },
      ],
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const filteredItems = items
          .filter((item) => {
            const type = item.type?.toLowerCase();

            if (selectedValues.includes(CONTENT_CATEGORIES.ALL)) return true;

            if (selectedValues.includes(CONTENT_CATEGORIES.OTHER)) {
              return ![CONTENT_CATEGORIES.FEATURED, CONTENT_CATEGORIES.PROMOTIONAL].includes(
                type as CONTENT_CATEGORIES
              );
            }

            return selectedValues.includes(type);
          })
          .map((item) => item.id);

        return filteredItems;
      },
    },
  ];
};
