import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import FormLine from '../../../../components/atoms/FormLine/FormLine';
import { HookInput as Input, EmailInput, PhoneInput } from '../../../../components/atoms/Input';
import { HookSelect as Select, SelectOption } from '../../../../components/atoms/Select';
import Card from '../../../../components/molecules/Card/Card';
import Form, { getFieldsDefaultValues } from '../../../../components/molecules/Form';
import { requiredRule, trimFields } from '../../../../components/molecules/Form/helpers';
import { BAR_POSITION } from '../../../../components/templates/Layout/BottomBar';
import Layout, { MAIN_WIDTH } from '../../../../components/templates/Layout/Layout';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import withLoginStatus from '../../../../context/withLoginStatus';
import { pagePaths, troubleLoggingInConfig } from '../../config';
import withLang from '../../context/withLang';

import { MyVillageFormFields, TroubleLoggingInFormFields } from './TroubleLoggingIn.types';
import { TroubleLoggingInPageProps, AvailableFormFields } from './TroubleLoggingIn.types';

import { useCreateFeedbackMutation } from '@/modules/Feedback/api/api';
import { CreateFeedbackArgs } from '@/modules/Feedback/api/api.types';
import { feedbackTypes } from '@/modules/Feedback/config';

const withNavBar = false;

const TroubleLoggingInPage = ({
  username,
  geographies,
  currentGeoCode,
  languageCode,
  dispatchSetGeography,
  dispatchCreateSupportEmail,
  label,
  isLoggedIn,
}: TroubleLoggingInPageProps) => {
  const history = useHistory();
  const [createFeedback] = useCreateFeedbackMutation();

  const [geography, setGeography] = useState<string>(currentGeoCode);
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const {
    submitAsFeedback,
    formFields,
  }: { submitAsFeedback: boolean; formFields: AvailableFormFields } = troubleLoggingInConfig();

  const defaultValues = getFieldsDefaultValues(formFields, {
    email: username ?? '',
    geography: geography ?? '',
  });

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<TroubleLoggingInFormFields>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(pagePaths.Home);
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    if (geography !== currentGeoCode) {
      dispatchSetGeography({
        geoCode: geography,
      });
    }
  }, [geography, currentGeoCode, dispatchSetGeography]);

  const submitWithFeedbackModule = async (data: TroubleLoggingInFormFields) => {
    const feedbackCommentValues = [`Language: ${languageCode}`];

    for (const [fieldName] of Object.entries(formFields)) {
      if (fieldName in data) {
        const value = data[fieldName as keyof typeof data] || 'none provided';
        feedbackCommentValues.push(`${label(`Ref: Feedback Content - ${fieldName}`)}: ${value}`);
      }
    }

    const feedbackRequestData: CreateFeedbackArgs = {
      title: label('I have trouble logging in'),
      comment: feedbackCommentValues.join(', \n'),
      feedbackType: {
        id: feedbackTypes['Feedback type'],
        name: 'Feedback type',
      },
      feedbackDate: new Date().toISOString(),
      withAccessToken: false,
    };

    return await createFeedback(feedbackRequestData);
  };

  const submitWithSupportEmail = async (data: TroubleLoggingInFormFields) => {
    return await dispatchCreateSupportEmail(data as MyVillageFormFields);
  };

  const onSubmit: SubmitHandler<TroubleLoggingInFormFields> = async (data) => {
    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<TroubleLoggingInFormFields>(data);

    const submitResponse = submitAsFeedback
      ? await submitWithFeedbackModule(trimmedData)
      : await submitWithSupportEmail(trimmedData);

    if (submitResponse && 'error' in submitResponse) {
      return history.push(pagePaths['GenericFailurePage']);
    }

    history.push(pagePaths['TroubleLoggingInFormSuccessPage']);

    setSubmitButtonIsLoading(false);
  };

  const actions = [
    {
      label: label('submit', { textTransform: 'capitalize' }),
      action: handleSubmit(onSubmit),
      loading: submitButtonIsLoading,
      disabled: !isValid,
      isClickDisabled: true,
    },
  ];

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      withNavBar={withNavBar}
      hasBackLink
      hideAllWidgets
    >
      <Layout
        mainWidth={MAIN_WIDTH.WIDE}
        bottomBar={{
          type: 'actionsBar',
          actions: actions,
          position: BAR_POSITION.STATIC,
        }}
      >
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {formFields.geography && (
              <FormLine data-testid="trouble-logging-in-geography">
                <Select
                  name="geography"
                  data-cy="region-select"
                  data-testid="region-select"
                  placeholder={label('Please select')}
                  label={label('Ref: Geography')}
                  control={control}
                  required={formFields.geography.required}
                  options={geographies?.map((g) => ({
                    label: g.name,
                    value: g.code,
                  }))}
                  onChange={(option: SelectOption) => setGeography(option!.value)}
                  rules={
                    formFields.geography.required
                      ? requiredRule(label('Ref: Geography'), label)
                      : undefined
                  }
                />
              </FormLine>
            )}
            {formFields.fullName && (
              <FormLine data-testid="trouble-logging-in-full-name">
                <Input
                  control={control}
                  name="fullName"
                  inputLabel={label('Full name')}
                  data-cy="input-fullName"
                  data-testid="trouble-logging-full-name"
                  rules={
                    formFields.fullName.required
                      ? requiredRule(label('Ref: Full name'), label)
                      : undefined
                  }
                />
              </FormLine>
            )}
            {formFields.email && (
              <FormLine data-testid="trouble-logging-in-email">
                <EmailInput
                  control={control}
                  name="email"
                  inputLabel={label('Email')}
                  data-cy="input-email"
                  data-testid="trouble-logging"
                  required={formFields.email.required}
                  labelFunc={label}
                />
              </FormLine>
            )}
            {formFields.phone && (
              <FormLine data-testid="trouble-logging-in-phone">
                <PhoneInput
                  control={control}
                  name="phone"
                  inputLabel={label('Mobile phone')}
                  data-cy="input-phone"
                  data-testid="trouble-logging"
                  required={formFields.phone.required}
                  labelFunc={label}
                />
              </FormLine>
            )}
            {formFields.company && (
              <FormLine data-testid="trouble-logging-in-company">
                <Input
                  control={control}
                  name="company"
                  inputLabel={label('Company')}
                  data-cy="input-company"
                  data-testid="trouble-logging-company"
                  rules={
                    formFields.company.required ? requiredRule(label('Company'), label) : undefined
                  }
                  inputMsg={label('Ref: Company field note')}
                />
              </FormLine>
            )}
            {formFields.city && (
              <FormLine data-testid="trouble-logging-in-location">
                <Input
                  control={control}
                  name="city"
                  inputLabel={label('Location')}
                  placeholder={label('Ref: Location field placeholder')}
                  data-cy="input-city"
                  data-testid="trouble-logging-city"
                  rules={
                    formFields.city.required ? requiredRule(label('Location'), label) : undefined
                  }
                  inputMsg={label('Ref: Location field note')}
                />
              </FormLine>
            )}
            {formFields.whatVillage && (
              <FormLine data-testid="trouble-logging-in-village">
                <Input
                  control={control}
                  name="whatVillage"
                  inputLabel={label('Ref: What village are you staying at?')}
                  data-cy="input-whatVillage"
                  data-testid="trouble-logging-village"
                  rules={
                    formFields.whatVillage.required
                      ? requiredRule(label('Ref: What village are you staying at?'), label)
                      : undefined
                  }
                />
              </FormLine>
            )}
            {formFields.employer && (
              <FormLine data-testid="trouble-logging-in-employer">
                <Input
                  control={control}
                  name="employer"
                  inputLabel={label('Ref: Who is your employer?')}
                  data-cy="input-employer"
                  data-testid="trouble-logging-employer"
                  rules={
                    formFields.employer.required
                      ? requiredRule(label('Ref: Who is your employer?'), label)
                      : undefined
                  }
                />
              </FormLine>
            )}
            {formFields.comment && (
              <FormLine data-testid="trouble-logging-in-comment">
                <Input
                  control={control}
                  name="comment"
                  inputLabel={label('Comment')}
                  data-cy="input-comment"
                  data-testid="trouble-logging-comment"
                  rules={
                    formFields.comment.required ? requiredRule(label('Comment'), label) : undefined
                  }
                  inputMsg={label('Ref: Comment field note')}
                  placeholder={label('Ref: Comment field placeholder')}
                />
              </FormLine>
            )}
          </Form>
        </Card>
      </Layout>
    </SimpleFormPage>
  );
};

export default withLoginStatus(withLang([__filename])(TroubleLoggingInPage));
