import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import withSite from '../../../../context/withSite';
import { useGetAvailablePromotionsQuery } from '../../api';
import { findProductByUomId } from '../../helpers/order.helper';
import { getSortedPromotions, isPromotionActive } from '../../helpers/promotions.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { PromotionInfo as PromotionInfoType, StateWithOrder } from '../../types/orderState.types';
import { PromotionInfoProps } from '../../types/promotions.types';

import PromotionInfoItem from './PromotionInfoItem';

import styles from './PromotionInfo.module.css';

const PromotionInfo = ({ site }: PromotionInfoProps) => {
  const { label } = useOrderTranslation(__filename);
  const { data: availablePromotions } = useGetAvailablePromotionsQuery({
    siteId: site.id,
    useErrorBoundary: false,
  });
  const { draft, menus } = useSelector((state: StateWithOrder) => state.Order);

  const getMenuItemsForPromotion = useCallback(
    (promotion: PromotionInfoType) => {
      if (promotion.items?.length) {
        return promotion.items
          .map((item) => {
            const menu = menus.find((menu) => item.menuIds.some((im) => im === menu.id));
            const product = findProductByUomId(menu, item.uomId);
            return {
              facilityId: menu?.facilityId,
              menuId: menu?.id,
              menuItemId: product?.menuItemId,
              date: menu?.date,
            };
          })
          .filter((menuItem) => menuItem);
      } else {
        return;
      }
    },
    [menus]
  );

  const availablePromotionsSorted = useMemo(
    () => getSortedPromotions(availablePromotions, draft, getMenuItemsForPromotion),
    [availablePromotions, draft, getMenuItemsForPromotion]
  );

  const renderPromotionItems = () => {
    return availablePromotionsSorted?.map((item, index) => {
      const isActive = isPromotionActive(item.id, draft?.promotionsApplied);
      return (
        <div
          key={`promotionItem--${index}`}
          className={classNames(styles.promotionCell, {
            [styles.promotionLastRow]:
              availablePromotionsSorted.length % 2 === 0 &&
              index > availablePromotionsSorted.length - 3,
          })}
        >
          <PromotionInfoItem
            id={item.id}
            name={item.name}
            description={item.description}
            isActive={isActive}
            items={getMenuItemsForPromotion(item)}
          />
        </div>
      );
    });
  };

  if (!availablePromotions?.length) return null;

  return (
    <Notification
      look={NOTIFICATION_LOOK.DETAILS}
      title={`${availablePromotions.length} ${label('Ref: Discounts available')}`}
      isCollapsable
    >
      {renderPromotionItems()}
    </Notification>
  );
};

export default withSite(PromotionInfo);
