import { connect } from 'react-redux';

import { State } from '../../../types/state.types';
import { getSiteByCode, updateSiteAndContract } from '../actions';
import AccountOnboardingLocation from '../components/AccountOnboardingLocation';

const mapStateToProps = (state: State) => {
  const isGetSiteLocked = state.Core.locks.getSiteByCode;
  const isOnboarding = state.Core.locks.onboarding ?? false;
  const contractId = state.Core.user.contract?.id;

  return { isGetSiteLocked, isOnboarding, contractId };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getSiteByCode: (code: string) => dispatch(getSiteByCode({ code })),
    updateSiteAndContract: (siteId: string) => dispatch(updateSiteAndContract({ siteId: siteId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOnboardingLocation);
