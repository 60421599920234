import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router';

import FormLine from '../../../../../components/atoms/FormLine/FormLine';
import { PasswordInput, HookInput as Input } from '../../../../../components/atoms/Input';
import Card from '../../../../../components/molecules/Card/Card';
import Form from '../../../../../components/molecules/Form';
import { requiredRule, trimFields } from '../../../../../components/molecules/Form/helpers';
import SimpleFormPage from '../../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { LOCAL_STORAGE_KEYS } from '../../../../../constants';
import { INPUT_TYPE } from '../../../../../constants';
import useQuery from '../../../../../helpers/hooks/useQuery';
import { pagePaths } from '../../../config';
import withLang from '../../../context/withLang';
import { PasswordChangeFormFields } from '../../../types/passwordChange.types';

import { ResetPasswordMatch, ResetPasswordProps } from './ResetPassword.types';

import { CoverIllustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';

import styles from '../ResetForgottenPassword.module.css';

const formDefaultValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = ({ label, dispatchResetPassword }: ResetPasswordProps) => {
  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch<ResetPasswordMatch>();
  const query = useQuery();
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    setError,
    formState: { isValid },
    control,
  } = useForm<PasswordChangeFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<PasswordChangeFormFields> = async (data) => {
    const code = query.get('code');
    const regionCode = query.get('regionCode');
    localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE, regionCode || '');

    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<PasswordChangeFormFields>(data);

    if (trimmedData.password !== trimmedData.confirmPassword) {
      setError('confirmPassword', {
        type: 'custom',
        message: label('Ref: Password does not match'),
      });
      setSubmitButtonIsLoading(false);
      return;
    } else {
      const { ok } = await dispatchResetPassword({
        id: encodeURIComponent(id),
        password: trimmedData.password,
        securityCode: encodeURIComponent(code || ''),
      });
      if (!ok) {
        history.push(pagePaths.ResetPasswordFailure);
      } else {
        history.push(pagePaths.ResetPasswordSuccess);
      }
    }
    setSubmitButtonIsLoading(false);
  };

  const firstColumn = (
    <>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormLine data-testid="reset-password-new">
            <PasswordInput
              control={control}
              name="password"
              inputLabel={label('Password')}
              labelFunc={label}
              data-cy="input-password"
              data-testid="reset-password-new"
              autocomplete="new-password"
              rules={requiredRule(label('Password'), label)}
            />
          </FormLine>
          <FormLine data-testid="reset-password-confirmation">
            <Input
              control={control}
              name="confirmPassword"
              inputLabel={label('Confirm password')}
              inputType={INPUT_TYPE.PASSWORD}
              data-cy="input-password-confirm"
              data-testid="reset-password-confirm"
              autocomplete="new-password"
              rules={requiredRule(label('Confirm password'), label)}
            />
          </FormLine>
        </Form>
      </Card>
    </>
  );

  return (
    <SimpleFormPage
      title={label('Enter your new password')}
      hasBackLink={false}
      withNavBar={false}
      hideAllWidgets
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            {firstColumn}
            <ActionsBar className={styles.actionButton}>
              <Button
                data-testid="continueResetPassword"
                onClick={() => handleSubmit(onSubmit)()}
                loading={submitButtonIsLoading}
                disabled={!isValid}
              >
                {label('Validate')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>

        <Column.Complementary className={styles.sideImage}>
          <CoverIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default withLang([__filename])(ResetPassword);
