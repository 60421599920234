const labels = {
  PrivacyPolicy: 'Gizlilik Politikası',
  TermsOfSale: 'Satış Koşulları',
  TermsOfUse: 'şartlar ve koşullar',
  home: 'Ev',
  anytime: 'Her Zaman',
  anywhere: 'herhangi bir yere',
  Download: 'İndirmek',
  scrollRightButton: 'Sağdaki kategorileri kaydırın',
  scrollLeftButton: 'Soldaki kategorileri kaydırın',
  calendar: 'Takvim',
  'See more': 'Daha fazla gör',
  'See less': 'Daha az gör',
  'from (followed by a date, or date and time)': 'itibaren',
  'to (followed by a date, or date and time)': 'ile',
  'on (followed by a date)': 'üzerinde',
  'at (followed by time)': '-den',
  'from (followed by a location)': 'itibaren',
  'to (followed by a location)': 'ile',
  'on (followed by a site name)': 'üzerinde',
  'from (followed by a person)': 'itibaren',
  previous: 'Önceki',
  next: 'Sonraki',
  success: 'başarı',
  failure: 'arıza',
  today: 'Bugün',
  tomorrow: 'Yarın',
  Monday: 'Pazartesi',
  Tuesday: 'Salı',
  Wednesday: 'Çarşamba',
  Thursday: 'Perşembe',
  Friday: 'Cuma',
  Saturday: 'Cumartesi',
  Sunday: 'Pazar',
  everyday: 'Her gün',
  'open all day': 'Tüm gün açık',
  'opening hours': 'Açılış saatleri',
  status: 'durum',
  'to be confirmed': 'Onaylanacak',
  'only (as in filter only this)': 'bir tek',
  edit: 'Düzenle',
  submit: 'Gönder',
  cancel: 'İptal etmek',
  confirm: 'Onaylamak',
  reset: 'Sıfırla',
  Delete: 'Silmek',
  select: 'seçme',
  switch: 'değiştirmek',
  close: 'Kapat',
  clear: 'açık',
  remove: 'kaldırmak',
  logout: 'çıkış Yap',
  go: 'Git',
  'see (as in consult this page or document)': 'görmek',
  'see all (as in see all items)': 'Her şeyi görün',
  'see more (as in see more details for this item)': 'Daha fazla gör',
  retry: 'yeniden denemek',
  'Ref: ok': 'Tamam',
  'yes (as in yes I accept)': 'Radyo Var',
  'no (as in no I do not accept)': 'Radyo Yok',
  you: 'sen',
  'thank you': 'Teşekkürler',
  'sorry (standalone)': 'üzgünüm',
  'to home (as in navigate to the homepage)': 'ana sayfaya',
  map: 'harita',
  list: 'liste',
  'Loading...': 'Yükleniyor...',
  'Refreshing...': 'güncelleniyor...',
  'Please select': 'Lütfen seçin',
  'Switch site': 'Siteyi burada değiştirin',
  'File size cannot exceed {max file size}': 'Dosya boyutu {max file size} aşamaz',
  Filters: 'Filtreler',
  'Add attachment': 'Ek ekle',
  Search: 'Aramak',
  'More (as in: see more info)': 'Daha fazla',
  'You can attach a maximum of {max files number} files.':
    'En fazla {max files number} dosya ekleyebilirsiniz.',
  'There are no messages to display.': 'Görüntülenecek mesaj yok.',
  'Your selection did not return any result.': 'Seçiminiz herhangi bir sonuç döndürmedi.',
  'Ref: Popup header for missing required fields in a form': 'Lütfen ek bilgi sağlayın',
  'Ref: Required field': '{ref} zorunlu bir alandır.',
  email: 'E -posta',
  phone: 'Telefon numarası',
  Account: 'hesabım',
  Contacts: 'Kişiler',
  Content: 'İçerik',
  Events: 'Etkinlikler',
  Facilities: 'Tesisler',
  Shop: 'Mağaza',
  Menu: 'Menü',
  Menus: 'Menüler',
  Order: 'Sipariş',
  Orders: 'Emirler',
  Info: 'Bilgi',
  FAQ: 'SSS',
  Feedback: 'Yorumlar',
  Review: 'İnceleme',
  Requests: 'İstekler',
  'Request Helpdesk': 'Yardım masası isteyin',
  Location: 'Konum',
  Safety: 'Emniyet',
  FreeCoffee: 'Ücretsiz Kahve',
  Newsfeed: 'Haber akışı',
  MyOrders: 'Siparişlerim',
  LoyaltyStamps: 'Sadakat pulları',
  Stamps: 'Pullar',
  Stays: 'Kalır',
  Suppliers: 'Tedarikçiler',
  Surveys: 'Anketler',
  notifications: 'bildirimler',
  cart: 'araba',
  'Ref: Reset your order': 'Siparişinizi sıfırlayın',
  'Ref: My language': 'Benim dilim',
  Validate: 'Doğrulamak',
  'contact us': 'Bize Ulaşın',
  'Ref: Start typing': 'Yazmaya başlayın',
  Occupancy: 'Doluluk',
  'see less': 'Daha az görmek',
  'see more': 'Daha fazla gör',
  contacts: 'Temas',
  Imprint: 'Baskı',
  'Ref: camera access request title': 'Kameraya erişim sağlayın',
  'Ref: camera access request body':
    'QR kod tarayıcısını kullanmak için bu sitede bir kamera kullanımını etkinleştirmelisiniz.',
  or: 'veya',
  'Ref: Input required': '{input_name} gerekli',
  'Enter a valid email address': 'Geçerli bir e-posta adresi girin',
  'Ref: Input error E-mail already exists':
    'Girdiğiniz bu e -postayla zaten bir hesap var. Lütfen bunun yerine giriş yapın.',
  'Password must contain': 'Parola içermelidir:',
  'Ref: Input password error strength':
    'Parola en az 8 karakter, 1 sayı, 1 harf ve 1 özel karakter içermelidir',
  Password: 'Şifre',
  'New password': 'Yeni Şifre',
  'Confirm password': 'Şifreyi Onayla',
  'Ref: Password does not match': 'Şifre ve şifre eşleşmiyor',
  'Ref: Old password does not match': 'Eski şifre eşleşmiyor',
  'Ref: Password validation min8Characters': 'En az 8 karakter',
  'Ref: Password validation min1Number': 'En az 1 hane',
  'Ref: Password validation min1Letter': 'En az 1 harf',
  'Ref: Password validation min1SpecialChar': 'En az 1 özel karakter',
  Continue: 'Devam et',
  'Enter a valid phone number': 'Geçerli bir telefon numarası girin',
  'Phone is required': 'Telefon gerekli',
  'Go to home': 'Eve gitmek',
  'Something went wrong': 'Bir şeyler yanlış gitti',
  'Something went wrong, data not loaded': 'Bir şeyler ters gitti, veriler yüklenmedi',
  'Change password': 'Şifre değiştir',
  'remove one': 'Birini kaldır',
  'add one': 'Bir tane ekle',
  'Confirm Filters': 'Filtreleri onaylayın',
  'Reset Filters': 'Filtreleri sıfırla',
  '{selectedNum} active filters': '{selectedNum} aktif filtreler',
  'Wrong input icon': 'Yanlış Giriş simgesi',
  'Correct input icon': 'Doğru Giriş simgesi',
  'Show input text icon': 'Giriş metni simgesini göster',
  'Hide input text icon': 'Giriş metni simgesini gizle',
  'Required fields info': '* - Zorunlu Alanlar',
  'Ref: Select site on the map': 'Sitenizi haritada seçin',
  'Ref: Geolocation disabled':
    'Coğrafi konum devre dışı bırakılır. Yakındaki siteleri görmek için lütfen etkinleştirin.',
  'Ref: Reaction - LIKE': 'Sevmek',
  'Ref: Reaction - LOVE': 'Aşk',
  'Ref: Reaction - INTERESTING': 'İlginç',
  'Ref: Reaction - CONGRATE': 'Tebrik etmek',
  'Ref: Share': 'Paylaşmak',
  'Ref: Send by Email': 'E-posta ile göndermek',
  'Ref: Copy the link': 'Bağlantıyı kopyalayın',
  'Ref: Link was copied': 'Bağlantı kopyalandı',
  'Ref: unable to copy url': "URL'yi kopyalayamıyor",
  'Ref: External link': 'Harici bağlantı',
  'Ref: Step': 'Adım',
  'input field': 'giriş alanı',
  'Ref: Zoom': 'Zum',
  'Increase hours': 'Saatleri artırmak',
  'Decrease hours': 'Saatleri Azaltır',
  Hours: 'Saat',
  Minutes: 'Dakikalar',
  'Ref: filter label: favorites': 'Sadece favorilerimi göster',
  'Ref: filter label: max calories': 'Maksimum kalori',
  'Ref: filter label: dishes': 'Bulaşıklar',
  'Ref: filter label: allergens': 'Allerjiye sebep olabilenler',
  'Ref: filter label: highlight': 'Bulaşıkları olan filtreleri vurgulayın veya gizle',
  'Ref: Highlight': 'Vurgulamak',
  'Ref: Hide': 'Saklamak',
  'Ref: Vegan': 'Vegan',
  'Ref: Vegeterian': 'Vejetaryen',
  'Ref: Mindful': 'Dikkatli',
  'Ref: Highlight text':
    'Burada, bazı alerjenleri içeren menüdeki yemekleri yıkayabilir veya gizleyebilirsiniz.',
  'Ref: Just now': 'Şu anda',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} dakika önce',
  'Ref: 1 hour ago': '1 saat önce',
  'Ref: {amountHours} hours ago': '{amountHours} saatler önce',
  'Ref: Yesterday at {time}': 'Dün {time}',
  'Ref: {date} at {time}': '{time} adresinde {date}',
  'Ref: filter label: date': 'Tarih',
  'Ref: expand menu': 'Menü Genişlet',
  'Ref: collapse menu': 'çökme menüsü',
  'Ref: My site': 'Benim sitem',
  'Ref: Recent': 'Son',
  'Ref: Services': 'Hizmetler',
  'Ref: products in cart': 'Sepetteki ürün sayısı',
  'Ref: no product in cart': 'Sepette ürün yok',
  'Ref: Survey': 'Anket',
  'Access to camera was denied. Please allow access and try again':
    'Kameraya erişim reddedildi. Lütfen erişin ve tekrar deneyin',
  'Add an attachment': 'Bir ek ekle',
  'Take a picture': 'Bir fotoğraf çek',
  'Add a file from your device': 'Cihazınızdan bir dosya ekleyin',
  'Ref: Loading page content, please wait': 'Sayfa içeriği yükleme, lütfen bekleyin',
  Star: 'Yıldız',
  Stars: 'Yıldızlar',
  'Accommodation Request': 'Konaklama talebi',
  'Ref: Guest Accomodation Request': 'Misafir Talebi',
  'Ref: Search a menu, product or facility': 'Bir menü, ürün veya tesis arayın',
  ConnectionLost: 'Ağ bağlantısı Kayıp',
  ConnectionRestored: 'Ağ bağlantısı geri yüklendi',
  'Ref: Skip to main content': 'Ana İçeriğe Atla',
  'Ref: No options': 'Seçenek yok',
  'Ref: Global search modal': 'Global arama modeli',
  'Ref: Search a facility': 'Bir Tesis Ara',
  'Ref: Search a menu or product': 'Bir menü veya ürün arayın',
  'Ref: Reaction': 'Reaksiyon',
  'Ref: times': 'zamanlar',
  'Ref: reaction. Change reaction': 'reaksiyon. Reaksiyonu değiştir',
  'Ref: No reaction. React': 'Tepki yok. Tepki',
  'Ref: LIKE': 'Beğenmek',
  'Ref: LOVE': 'Aşk',
  'Ref: INTERESTING': 'İlginç',
  'Ref: CONGRATE': 'Kongre etmek',
  'Ref: Open search': 'Arama kutusunu aç',
  'Ref: Close search': 'Arama kutusunu kapatın',
  'Ref: Total file size msg': 'Toplam dosya boyutu {max file size}',
  'Ref: Oops Title': 'Oops!',
  'Ref: Well Done': 'Tebrikler {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigasyon oku - Devam et',
  'Ref: Reload': 'Tekrar yükle',
  'Ref: All Dates': 'Tüm Tarihler',
  'Ref: Today': 'Bugün',
  'Ref: Validate': 'Doğrulamak',
  'Ref: toast error title': 'Oops!',
  'Ref: toast error subtitle': 'Bir hata oluştu. Lütfen tekrar deneyin.',
  'Ref: Calendar': 'Takvim',
  'Ref: Selected': 'Seçilmiş',
  'Ref: Previous Month': 'Geçtiğimiz ay',
  'Ref: Next Month': 'Gelecek ay',
  'This module has been retired': 'Bu modül kullanımdan kaldırıldı',
};
export default labels;
