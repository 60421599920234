import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { INPUT_TYPE, LOCAL_STORAGE_KEYS } from '../../../../../constants';
import { PromiseResult } from '../../../../../types';
import { pagePaths } from '../../../config';
import { isSSOUser, invokeSSORedirect } from '../../../helpers/onboardingHelpers';
import { RegisterGuestRequestType, RegisterRequestType } from '../../../types';
import GeographySelector from '../../GeographySelector/GeographySelector';

import { AccountInformationProps, AccountInformationFormFields } from './AccountInformation.types';

import { CoverIllustration } from '@/assets/illustrations';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Divider from '@/components/atoms/Divider';
import FormLine from '@/components/atoms/FormLine/FormLine';
import { EmailInput, HookInput as Input, PasswordInput } from '@/components/atoms/Input';
import { TITLE_TAG, TITLE_SIZE } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Form from '@/components/molecules/Form';
import { requiredRule, trimFields } from '@/components/molecules/Form/helpers';
import { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { useLazyGetIdentityContextQuery } from '@/modules/Core/api/account/accountApi';
import { useLazyGetGeographiesQuery } from '@/modules/Core/api/geolocations/geolocationsApi';

import styles from './AccountInformation.module.css';

const guestAccountEnabled = process.env.REACT_APP_USE_GUEST_ACCESS === 'true';
const withNavBar = false;
const formDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
};

const OnboardingInformation = ({
  label,
  currentGeoCode,
  currentRegionCode,
  geographies,
  isLoggedIn,
  locks,
  dispatchRegister,
  dispatchRegisterGuestUser,
  dispatchSetGeography,
  dispatchCheckEmailExists,
}: AccountInformationProps) => {
  const history = useHistory();
  const [getIdentityContext] = useLazyGetIdentityContextQuery();
  const [getGeographies] = useLazyGetGeographiesQuery();
  const [displayFullForm, setDisplayFullForm] = useState<boolean>(false);
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);
  const [gestRegistrationButtonIsLoading, setGuestRegistrationButtonIsLoading] =
    useState<boolean>(false);
  const [validatedEmail, setValidatedEmail] = useState<string>('');

  const {
    handleSubmit,
    setError,
    formState: { isValid },
    control,
    clearErrors,
  } = useForm<AccountInformationFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<AccountInformationFormFields> = async (data) => {
    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<AccountInformationFormFields>(data);

    if (validatedEmail !== trimmedData.email) {
      const userIdentity = await getIdentityContext({ username: trimmedData.email });

      if (userIdentity.data && isSSOUser(userIdentity.data)) {
        await invokeSSORedirect(
          userIdentity.data.tokenUrl,
          trimmedData.email,
          currentGeoCode,
          currentRegionCode,
          userIdentity.data.useSystemBrowser,
          history
        );
        return;
      }

      // Check whether the account is already in the database
      const emailExists = await dispatchCheckEmailExists({
        email: trimmedData.email,
      });
      if (emailExists.responseStatus === 200) {
        setError('email', {
          type: 'custom',
          message: label('Ref: Input error E-mail already exists'),
        });
        setSubmitButtonIsLoading(false);
        return;
      }

      setValidatedEmail(trimmedData.email);
    }

    // Display all fields
    if (displayFullForm === false) {
      setDisplayFullForm(true);
      setSubmitButtonIsLoading(false);
      return;
    }

    if (trimmedData.password !== trimmedData.confirmPassword) {
      setError('confirmPassword', {
        type: 'custom',
        message: label('Ref: Password does not match'),
      });
      setSubmitButtonIsLoading(false);
      return;
    }

    // Submit registration form
    const registerRequestData: RegisterRequestType = {
      ...trimmedData,
    };
    const registerResponse = await dispatchRegister(registerRequestData);
    if (registerResponse.ok) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, registerRequestData.email);
      history.replace({
        pathname: pagePaths.Login,
        state: {
          notification: {
            look: NOTIFICATION_LOOK.SUCCESS,
            title: label('Ref: Registration success title'),
            children: label('Ref: Registration success content'),
            dismissable: true,
          },
        },
      });

      return;
    }

    setSubmitButtonIsLoading(false);
  };

  const handleGuestRegistration = useCallback(async () => {
    setGuestRegistrationButtonIsLoading(true);

    const registerGuestRequestData: RegisterGuestRequestType = {
      currentGeoCode,
    };
    const registerGuestUserResponse: PromiseResult = await dispatchRegisterGuestUser(
      registerGuestRequestData
    );

    if (registerGuestUserResponse.ok) {
      history.replace(pagePaths.Home);
    }
    setGuestRegistrationButtonIsLoading(true);
  }, [currentGeoCode, history, dispatchRegisterGuestUser]);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(pagePaths.Home);
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    if (geographies.length === 0) {
      getGeographies();
    }
  }, [geographies, getGeographies, locks]);

  const handleGeographyChange = (geoCode: string) => {
    if (geoCode !== currentGeoCode) {
      dispatchSetGeography({
        geoCode,
      });
    }
  };

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      hasBackLink={true}
      actions={[]}
      withNavBar={withNavBar}
      hideAllWidgets={true}
      actionsBarTopContent={null}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            {guestAccountEnabled && (
              <>
                <Card>
                  <Button
                    look={BUTTON_LOOK.SECONDARY}
                    inheritStyle={classNames('d-block')}
                    onClick={handleGuestRegistration}
                    loading={gestRegistrationButtonIsLoading}
                    data-testid="onboarding-account-continue-as-guest"
                  >
                    {label('Continue as guest')}
                  </Button>
                </Card>
                <Divider data-testid="account-info-divider">{label('or')}</Divider>
              </>
            )}
            <Card
              overTitle={{
                tag: TITLE_TAG.H1,
                size: TITLE_SIZE.HEADLINES,
                children: label('Ref: Form title'),
              }}
              className={'mb-M'}
            >
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormLine data-testid="account-info-email">
                  <EmailInput
                    control={control}
                    name="email"
                    inputLabel={label('Email')}
                    labelFunc={label}
                    data-ci="input-email"
                    data-testid="account-info"
                    required
                  />
                </FormLine>
                {displayFullForm && (
                  <>
                    <FormLine data-testid="account-info-first-name">
                      <Input
                        control={control}
                        name="firstName"
                        inputLabel={label('First name')}
                        data-cy="input-first-name"
                        data-testid="account-info-first-name"
                        rules={requiredRule(label('First name'), label)}
                      />
                    </FormLine>
                    <FormLine data-testid="account-info-last-name">
                      <Input
                        control={control}
                        name="lastName"
                        inputLabel={label('Last name')}
                        data-ci="input-last-name"
                        data-testid="account-info-last-name"
                        rules={requiredRule(label('Last name'), label)}
                      />
                    </FormLine>
                    <FormLine data-testid="account-info-password">
                      <PasswordInput
                        control={control}
                        name="password"
                        inputLabel={label('Password')}
                        labelFunc={label}
                        data-cy="input-password"
                        data-testid="account-info-new"
                        autocomplete="new-password"
                        rules={requiredRule(label('Password'), label)}
                        onFocus={() => {
                          clearErrors(['confirmPassword']);
                        }}
                      />
                    </FormLine>
                    <FormLine data-testid="account-info-confirm-password">
                      <Input
                        control={control}
                        name="confirmPassword"
                        inputLabel={label('Confirm password')}
                        inputType={INPUT_TYPE.PASSWORD}
                        data-cy="input-password-confirm"
                        data-testid="account-info-confirm-password"
                        autocomplete="new-password"
                        rules={requiredRule(label('Confirm password'), label)}
                      />
                    </FormLine>
                  </>
                )}
              </Form>
              <p className={styles.fieldsDisclaimer}>{label('Required fields info')}</p>
            </Card>
            <GeographySelector
              geographies={geographies}
              label={label}
              handleGeographySubmit={handleGeographyChange}
            />
            <ActionsBar className={styles.actionButton}>
              <Button
                data-testid="loginSubmit"
                look={BUTTON_LOOK.PRIMARY}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
                loading={submitButtonIsLoading}
                isClickDisabled={true}
              >
                {displayFullForm ? label('Sign up') : label('Continue')}
              </Button>
              <Button
                data-testid="troubleLoggingIn"
                onClick={() => history.push('/')}
                look={BUTTON_LOOK.TERTIARY}
              >
                {label('Already have an account? Login')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <CoverIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default OnboardingInformation;
