import { StepsConsumer } from '../../../context/StepsContext';

import StepperComponent from './Stepper';
import { StepperContainerProps } from './Stepper.types';

const Stepper = ({ horizontalOnly = false }: StepperContainerProps) => {
  return (
    <StepsConsumer>
      {(steps) => <StepperComponent steps={steps} horizontalOnly={horizontalOnly} />}
    </StepsConsumer>
  );
};

export default Stepper;
