import classNames from 'classnames';
import momentjs from 'moment';

import {
  Calendar2Icon,
  RefreshIcon,
  Restaurant,
  WarningOutlinedIcon,
} from '../../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title from '../../../../components/atoms/Title';
import Calendar from '../../../../components/molecules/Calendar';
import Dropdown from '../../../../components/molecules/Dropdown';
import { TileSkeleton, TILE_VARIANT } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Modal from '../../../../components/organisms/Modal';
import WidgetComponent from '../../../../components/organisms/Widget';
import BigWithSmallElementRow from '../../../../components/templates/BigWithSmallElementRow';
import { DATE_FORMAT, SIZE } from '../../../../constants';
import withSite from '../../../../context/withSite';
import { formatDate } from '../../../../helpers/misc';
import { suggestionWidgetDateCacheKey, suggestionWidgetMenuCacheKey } from '../../config';
import ProductTile from '../../containers/ProductTile';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { PageType } from '../../types/menuCartActions.types';

import { mapItemToProductItem } from './SuggestionsWidget.helper';
import { SuggestPlace, SuggestionsWidgetProps } from './SuggestionsWidget.types';
import { useIsSuggestionsEnabled } from './useIsSuggestionsEnabled';
import { useSuggestionsWidget } from './useSuggestionsWidget';

import { BackgroundWidgetSuggestionIllustration } from '@/assets/illustrations';

import styles from './SuggestionsWidget.module.css';

const SuggestionsWidget = ({ site }: SuggestionsWidgetProps) => {
  const { label } = useOrderTranslation(__filename);
  const {
    currentLanguageCode,
    promotionalDiscounts,
    isDateToday,
    selectedDate,
    setSelectedDate,
    menus,
    selectedMenu,
    setSelectedMenu,
    isLoading,
    setLastDisplayedIndex,
    setScanSelected,
    isCalendarModalOpen,
    openCalendarModal,
    closeCalendarModal,
    dropdownOptions,
    menuBasedSuggestions,
    getNextThreeSuggestions,
    suggestionsToDisplay = [],
    scannerComponent,
    fetchingError,
  } = useSuggestionsWidget(site, label);

  const isSuggestionsOnHome = useIsSuggestionsEnabled(SuggestPlace.HOME);

  const placeholderTitle = fetchingError
    ? label('Something went wrong, data not loaded')
    : label('Ref: No suggestions');

  if (!isSuggestionsOnHome) {
    return null;
  }
  return (
    <>
      <WidgetComponent data-testid="suggestions-widget">
        <WidgetComponent.Header illustration={<BackgroundWidgetSuggestionIllustration />}>
          <WidgetComponent.Title>
            <Title>{label('Suggestion')}</Title>
            <BigWithSmallElementRow>
              <Dropdown
                value={selectedMenu?.value}
                onChange={(menu) => {
                  setSelectedMenu(menu);
                  localStorage.setItem(
                    `${suggestionWidgetMenuCacheKey}_${site.id}`,
                    JSON.stringify(menu)
                  );
                  setLastDisplayedIndex(0);
                }}
                buttonLook={BUTTON_LOOK.TERTIARY}
                options={dropdownOptions}
              />
              <Button
                look={BUTTON_LOOK.PRIMARY}
                size={SIZE.SMALL}
                onClick={() => openCalendarModal()}
                data-testid="suggestions-widget-calendar-button"
                aria-label={label('Ref: Suggestions calendar button')}
              >
                <Calendar2Icon />
              </Button>
            </BigWithSmallElementRow>
          </WidgetComponent.Title>
        </WidgetComponent.Header>
        <WidgetComponent.Body>
          <>
            <div className={classNames('mb-M', 'bodyMDefault')}>
              {isDateToday
                ? label('today')
                : formatDate(momentjs(selectedDate, DATE_FORMAT).toDate(), currentLanguageCode)}
            </div>
            {isLoading ? (
              <>
                <TileSkeleton
                  withoutActions
                  withoutChildren={false}
                  className={classNames('mb-M')}
                  data-testid="skeleton-placeholder"
                />
                <TileSkeleton
                  withoutActions
                  withoutChildren={false}
                  className={classNames('mb-M')}
                  data-testid="skeleton-placeholder"
                />
                <TileSkeleton
                  withoutActions
                  withoutChildren={false}
                  data-testid="skeleton-placeholder"
                />
              </>
            ) : menus && suggestionsToDisplay.length > 0 ? (
              suggestionsToDisplay.map((item) => {
                const productItem = mapItemToProductItem(item);

                const props = {
                  ...productItem,
                  date: selectedDate,
                  menu: menus.find((menu) => menu.id === item.menuId),
                  promotionalDiscounts,
                  variant: TILE_VARIANT.STANDARD,
                  oneLine: true,
                  canAddToFavorite: true,
                };

                return (
                  <ProductTile
                    key={props.id}
                    pageType={PageType.suggestionWidget}
                    isSuggestion={true}
                    {...props}
                    onScanButtonClick={() => setScanSelected(item)}
                  />
                );
              })
            ) : (
              <WidgetPlaceholder
                icon={fetchingError ? <WarningOutlinedIcon /> : <Restaurant />}
                title={placeholderTitle}
              />
            )}
          </>
        </WidgetComponent.Body>
        {suggestionsToDisplay.length > 0 && (
          <WidgetComponent.Footer>
            <Button
              look={BUTTON_LOOK.SECONDARY}
              size={SIZE.SMALL}
              inheritStyle={styles.refreshButton}
              contentCenterAlign={true}
              disabled={menuBasedSuggestions.length <= 3}
              suffix={RefreshIcon}
              onClick={getNextThreeSuggestions}
              data-testid="suggestions-widget-refresh-button"
            >
              {label('Other')}
            </Button>
          </WidgetComponent.Footer>
        )}
      </WidgetComponent>
      {scannerComponent}
      <Modal
        title={label('Ref: Modal title')}
        isOpen={isCalendarModalOpen}
        onDismiss={() => closeCalendarModal()}
      >
        <Calendar
          value={momentjs(selectedDate).toDate()}
          minDetail="month"
          minDate={momentjs().startOf('day').toDate()}
          maxDate={momentjs().add(10, 'days').startOf('day').toDate()}
          onChange={(date: Date) => {
            setSelectedDate(momentjs(date).format(DATE_FORMAT));
            localStorage.setItem(
              `${suggestionWidgetDateCacheKey}_${site.id}`,
              momentjs(date).format(DATE_FORMAT)
            );
            closeCalendarModal();
          }}
        />
      </Modal>
    </>
  );
};

export default withSite(SuggestionsWidget);
