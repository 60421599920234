import withSite from '../../../context/withSite';
import { ISite } from '../../Sites/types/sites.types';
import { getPrice } from '../helpers/order.helper';

import styles from './PriceWithDiscount.module.css';

const PriceWithDiscount = ({
  discountPrice,
  originalPrice,
  site,
  languageCode,
}: {
  discountPrice?: number;
  originalPrice: number;
  site: ISite;
  languageCode: string;
}) => {
  const isoCode = site?.currency?.isoCode ?? '';

  return discountPrice ? (
    <span data-testid="price-discount" className={styles.pricesContainer}>
      <span>{getPrice(discountPrice, languageCode, isoCode)}</span>
      <span className={styles.priceWithoutDiscounts}>
        {getPrice(originalPrice, languageCode, isoCode)}
      </span>
    </span>
  ) : (
    <span data-testid="price-no-discount">{getPrice(originalPrice, languageCode, isoCode)}</span>
  );
};

export default withSite(PriceWithDiscount);
