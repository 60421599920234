import { WithLangProps } from '../../../../context/withLang';

export type AppUpdatePageRequest = {
  appId: string;
  platform: string;
  appVersion: string;
  contractId?: string;
  siteId?: string;
};

export type AppUpdatePageProps = WithLangProps & {
  dispatchGetAppVersionInfo: (request: AppUpdatePageRequest) => void;
  dispatchSkipAppVersionCheck: () => void;
  appInfo?: AppVersionInfo;
};

export type AppVersionInfo = {
  displayStoreButton: boolean;
  id: string;
  message: string;
  storeUrl: string;
  title: string;
  type: string;
};

export enum AppUpdateFlags {
  FORCE = 'Force',
  SOFT = 'Soft',
}
