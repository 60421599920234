import { WithLangProps } from '../../../context/withLang';
import { PromiseResult, PromiseResultWithData } from '../../../types';

export type PasswordChangeProps = WithLangProps & {
  userFullName: string;
  email: string;
  oldPassword: string;
  currentGeoCode: string;
  currentLanguageCode: string;
  preferendLanguageId: string;
  migrateOldBiteUser: (
    email: string,
    password: string,
    newPassword: string,
    languageId: string
  ) => Promise<PromiseResultWithData<MigrationResult>>;
  login: (
    geoCode: string,
    username: string,
    psw: string,
    currentLanguageCode: string
  ) => Promise<PromiseResult>;
};

type MigrationResult = {
  status: MIGRATION_STATUS;
  firstName: string;
  lastName: string;
};

export enum MIGRATION_STATUS {
  INVALID_CREDENTIALS = 'InvalidCredentials',
  MIGRATION_DISABLED = 'MigrationDisabled',
  USER_MIGRATED = 'UserMigrated',
  PASSWORD_WEAK = 'PasswordToWeek',
}

export type PasswordChangeFormFields = {
  password: string;
  confirmPassword: string;
};
