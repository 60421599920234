import Tunnel from '../../../../components/Tunnel';
import { pagePaths } from '../../config';
import AccountOnboardingLocation from '../../containers/AccountOnboardingLocation';
import AccountStartPage from '../../containers/AccountStartPage';

const OnboardingTunnel = () => {
  return (
    <Tunnel
      steps={[
        {
          id: 'start',
          path: `${pagePaths.Onboarding}/start`,
          component: AccountStartPage,
          getApplicability: () => true,
          getNextStepId: () => 'location',
        },
        {
          id: 'location',
          path: `${pagePaths.Onboarding}/location`,
          component: AccountOnboardingLocation,
          getApplicability: () => true,
          getNextStepId: () => undefined,
        },
      ]}
      startStepId="start"
      hideStepper
      exitToPath=""
    />
  );
};

export default OnboardingTunnel;
