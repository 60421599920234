import { connect } from 'react-redux';

import { State } from '../../../../types/state.types';
import { getAppVersionInfo } from '../../actions';

import AppUpdatePage from './AppUpdatePage';
import { AppUpdatePageRequest } from './AppUpdatePage.types';

const mapStateToProps = (state: State) => {
  const appInfo = state.Core.appVersionInfo;
  return {
    appInfo,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    dispatchGetAppVersionInfo: (request: AppUpdatePageRequest) =>
      dispatch(getAppVersionInfo({ ...request })),
    dispatchSkipAppVersionCheck: () => dispatch({ type: 'GET_APP_VERSION_FAILURE' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppUpdatePage);
