import { RegisterOptions, FieldValues } from 'react-hook-form';

import { INPUT_TYPE } from '../../../../constants';
import { LabelFunc, LabelOptions } from '../../../../context/withLang';
import Input from '../HookInput';
import { HookInputProps } from '../Input.types';

const PhoneInput = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  labelFunc,
  'data-testid': dataTestId,
  ...rest
}: HookInputProps<TFieldValues> & {
  labelFunc: LabelFunc;
}) => {
  // TODO need to change either label prop in Input or switch to i18next to avoid conflict
  const labelFix = (str: string, options?: LabelOptions): string => {
    if (labelFunc) {
      return labelFunc(str, options);
    }
    return str;
  };

  const rules: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> = {
    pattern: {
      value: /^[ -+().]*(?:\d[ -+().]*){6,}$/, // At least 6 numbers, space, - + ( ) . allowed
      message: labelFix('Enter a valid phone number'),
    },
  };

  if ('required' in rest) {
    rules.required = labelFix('Ref: Input required', {
      replace: { input_name: rest.inputLabel || labelFix('Phone') },
    });
  }

  return <Input data-testid={`${dataTestId}-phone`} inputType={INPUT_TYPE.TEL} rules={rules} name={name} control={control} {...rest} />;
};

export default PhoneInput;
